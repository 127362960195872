<div [ngSwitch]='userRole' class='container'>
  <ng-container *ngSwitchCase="'External Provider'">
    <p>Please reach out to the EY ESG Market Insights Portal team at the email below if you have any questions or
      concerns.</p>
    <a href='mailto:ESG.Market.Insights.Portal@es.ey.com'>ESG.Market.Insights.Portal&#64;es.ey.com</a>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <p>If you are experiencing any issues or bugs with the platform, please raise a ticket in ServiceNow.</p>
    <p>If you have observe an outdated profile (name of player or offering, structure of the profile is off,
      information is inconsistent, key characteristics are missing) or you have feedback and suggestions, please email
      us at:</p>
    <a href='mailto:ESG.Market.Insights.Portal@es.ey.com'>ESG.Market.Insights.Portal&#64;es.ey.com</a>
  </ng-container>
</div>

