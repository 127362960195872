<div class="modal__header">
    <h3 class="modal__title">{{ title | translate | titlecase }}</h3>
    <button
      (click)="closeModal($event)"
      aria-label="Close"
      class="modal__close"
      type="button"
    ></button>
</div>

<div class="modal-body">

    <div class="header">
        <h2 class="title">{{data?.title}}</h2>

        <mat-chip class="mat-chip">
            <span [style]="{ 'color': data?.noteCategory.color }">
                {{data?.noteCategory.name}}
            </span>
        </mat-chip>
    </div>

    <p class="text">
        {{data?.text}}
    </p>

    <!-- details in small letters -->
    <div class="details">
        <div class="username-icon-cont">
            <svg
            version="1.1"
            id="usermenu-launch"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
            >
            <path
              d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M256,74.667
              c67.635,0,122.667,55.031,122.667,122.667S323.635,320,256,320s-122.667-55.031-122.667-122.667S188.365,74.667,256,74.667z M256,469.333c-69.707,0-131.52-33.755-170.473-85.615c42.676-20.534,103.621-42.385,170.473-42.385
              c66.857,0,127.807,21.854,170.474,42.383C387.521,435.577,325.708,469.333,256,469.333z"
              />
            </svg>
            
            <p class="username">
                {{data?.user.username}}
            </p>
        </div>
            
        <p class="date">
            {{data?.createdDate | moment : 'lll'}}
        </p>
    </div>

    <p [hidden]="data?.attachments.length === 0">
        {{ 'components.modal.product.note.read.attachments' | translate : 'Attachments' }}
    </p>

    <app-attachment-carousel 
        [attachments]="data?.attachments"
        [action]="'download'"
        [note]="data"
        [showEdit]="false"
    ></app-attachment-carousel>
</div>