import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EndSessionRequest, IPublicClientApplication, Logger, PublicClientApplication } from '@azure/msal-browser';
import { Location } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class MockMsalService extends MsalService {
  private _isAuthenticatedSubject = new BehaviorSubject<boolean>(true);
  isAuthenticated$ = this._isAuthenticatedSubject.asObservable();

  // added just to avoid errors on newer angular and typescript versions
  constructor(location: Location){
    const _mockPublicClientApplication: IPublicClientApplication = new PublicClientApplication({
      auth: {
        clientId: 'mock-client-id',
        authority: 'https://mock-authority-url.com',
        redirectUri: 'http://localhost:4200',
      },
    });
    super(_mockPublicClientApplication, location);
  }

  private _userProfile: any = {
    idToken: 'mock-id-token',
    email: 'mock-email@example.com',
  };

  private _mockAuthenticationResult: AuthenticationResult = {
    accessToken: 'mock-access-token',
    expiresOn: new Date(Date.now() + 3600000),
    uniqueId: 'mock-unique-id',
    account: {
      username: this._userProfile.email,
      homeAccountId: 'mock-home-account-id',
      environment: 'mock-environment',
      tenantId: 'mock-tenant-id',
      localAccountId: 'mock-local-account-id',
    },
    idToken: this._userProfile.idToken,
    authority: 'https://mock-authority-url.com',
    tenantId: 'mock-tenant-id',
    scopes: ['mock-scope'],
    idTokenClaims: {
      sub: 'mock-sub',
      aud: 'mock-aud',
      exp: Math.floor((Date.now() + 3600000) / 1000),
      iat: Math.floor(Date.now() / 1000),
      iss: 'https://mock-issuer-url.com',
    },
    state: 'mock-state',
    fromCache: false,
    tokenType: 'Bearer',
    correlationId: 'mock-correlation-id',
  };

  override acquireTokenSilent() {
    return of(this._mockAuthenticationResult);
  }

  override ssoSilent() {
    return of(this._mockAuthenticationResult);
  }

  override handleRedirectObservable() {
    return of(this._mockAuthenticationResult);
  }

  override logout(logoutRequest?: EndSessionRequest): Observable<void> {
    return of(undefined);
  }

  private _mockPublicClientApplication: IPublicClientApplication = new PublicClientApplication({
    auth: {
      clientId: 'mock-client-id',
      authority: 'https://mock-authority-url.com',
      redirectUri: 'http://localhost:4200',
    },
  });

  private _mockAccount: AccountInfo = {
    homeAccountId: 'mock-homeAccountId',
    environment: 'mock-environment',
    tenantId: 'mock-tenantId',
    username: 'mock-email@example.com',
    localAccountId: 'mock-localAccountId',
    name: 'Mock User'
  };

  override instance: IPublicClientApplication = {
    ...this._mockPublicClientApplication,
    getAllAccounts: () => [
      {
        username: 'mock-email@example.com',
      },
    ] as any,
    getLogger: () => new Logger({}),
    getActiveAccount: () => this._mockAccount,

  };

  setActiveAccount(account: any) { }
}
