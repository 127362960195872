import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api/api-service/api.service';
import { PageModel } from '../api/pagination-service/model/page.model';
import { HistoricProviderValidation } from 'src/app/models/historic-provider-validation.model';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoricProviderValidationService {
  readonly modelName = 'historicProviderValidation';

  constructor(
    public apiService: ApiService,
    private http: HttpClient,
  ) { }

  /**
  * Create a Provider Validation Comment
  * @param data
  * @returns
  */
  createValidationComment$(data: Omit<HistoricProviderValidation, 'id'>) {
    const request$ = this.apiService.post(
      `${this.modelName}`,
      JSON.stringify(data),
      'components.modal.validation.createSuccessMsg'
    );
    return request$;
  }

   /**
  * Get a Provider Validation Comment
  * @param data
  * @returns
  */
   getProviderValidationData$(id: string, params: { [keyof: string]: any } = {}) {    
    const slug = `${this.modelName}/${id}`;
    const request$ = this.apiService.get<PageModel<HistoricProviderValidation>>(
      slug,
      params,
      ''
    );
    return request$;
  }

  getProviderValidationDataDataWithoutPagination$(id: string) {
    return this.getProviderValidationData$(id).pipe(
      map(result=> result.data),
    );
  }
}
