import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  readonly apiUrl: string = `${environment.genAiApiUrl}/`;
  // readonly apiUrl: string = `https://euwdtdfevdwap09.azurewebsites.net/`;
  
  // Obj that holds the current chat state, so when it is minimize it keeps the current chat id and search input
  private chatState = new BehaviorSubject<any>({ chat_id: '',  search: '', historyOpen: false, title: '' });
  private chatHistoryState = new BehaviorSubject<boolean>(false);

  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {}
  // Send new message to the chatbot
  sendMessage(message: ChatMessage): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    const endpoint = `chat`;
    return this.http.post(`${this.apiUrl}${endpoint}`, message, { headers });
  }

  // Create a new chat
  createNewChat(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    const endpoint = `chat/new`;
    return this.http.get(`${this.apiUrl}${endpoint}`, { headers });
  }

  // Get chat history by user id
  getChatHistoryByUserId(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    const endpoint = `chat/history`;
    return this.http.get(`${this.apiUrl}${endpoint}`, { headers });
  }

  // Get chat messages by chat id
  getChatMessagesByChatId(chatId: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    const endpoint = `chat/conversation/${chatId}`;
    return this.http.get(`${this.apiUrl}${endpoint}`, { headers });
  }

  filterChatHistoryBySearch(search: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    const endpoint = `chat/history/filter`;
    const keywords = search.replace(' ', '%20');
    return this.http.get(`${this.apiUrl}${endpoint}?keywords=${keywords}`, { headers });
  }    

  // Delete chat by chat id
  deleteChatByChatId(chatId: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    const endpoint = `chat/${chatId}`;
    return this.http.delete(`${this.apiUrl}${endpoint}`, { headers });
  }

  // Like or dislike a chat message
  likeOrDislikeMessage(chatId: string, messageId: string, like: boolean | null): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    const endpoint = `chat/feedback`;
    const body = {
      chat_id: chatId,
      message_id: messageId,
      like: like
    }
    return this.http.patch(`${this.apiUrl}${endpoint}`, body, { headers });
  }

  // Flag a chat message
  flagMessage(chatId: string, messageId: string, flag: boolean): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    const endpoint = `chat/feedback`;
    const body = {
      chat_id: chatId,
      message_id: messageId,
      flag: flag
    }
    return this.http.patch(`${this.apiUrl}${endpoint}`, body, { headers });
  }

  // Update current chat state
  setChatState(newState: any) {
    this.chatState.next({ ...this.chatState.getValue(), ...newState });    
  }

  // Update current chat state
  setChatHistoryState(newState: boolean) {
    this.chatHistoryState.next(newState);    
  }

  // Get current chat state
  chatStateSub(): Observable<any> {
    return this.chatState.asObservable();
  }

  chatHistoryStateSub(): Observable<boolean> {
    return this.chatHistoryState.asObservable();
  }

  getChatState() {
    return this.chatState.getValue();
  }
}
