import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterLabel } from '../filters/filters.interfaces';

export interface ResponsiveTableButton {
  name: string;
  action: (data: any) => void;
  noDataDisabled?: boolean;
}

@Component({
  selector: 'app-responsive-table-header',
  templateUrl: './responsive-table-header.component.html',
  styleUrls: ['./responsive-table-header.component.scss']
})
export class ResponsiveTableHeaderComponent implements OnInit {

  @Output() buttonClicked = new EventEmitter<((data: any) => void)>()
  @Output() showFiltersClicked = new EventEmitter<boolean>();
  @Input() length: number = 0;
  @Input() totalElements: number = 0;
  @Input() pageSize: number = 100;
  @Input() useFilters: boolean = false;
  @Input() buttons: ResponsiveTableButton[] = [];
  @Input() filterLabels: FilterLabel[] = [];
  @Input() showElementCount: boolean = false;
  @Input() dataType: string = "";
  @Input() isEndlessScroll = false;
  @Input() allElements!: number;
  @Input() currentListLength!: number;

  public showFilters: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  public onShowFilters() {
    this.showFilters = !this.showFilters;
    this.showFiltersClicked?.emit(this.showFilters);
  }

}
