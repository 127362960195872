import { PageModel } from 'src/app/services/api/pagination-service/model/page.model';
// export type SettingTableType = {
//   dataid: string; // is the id property that you want to use for the select option.
//   hasSelect: boolean; // It is a boolean to configure or not a column on the right of the table with a select option on each row.
//   hasSelectedFunctions?: boolean; // Optional atribute, it means that *changeSelectedItems* and *isSelected* are going to be used.
//   changeSelectedItems?: () => void; // Adds, remove or operation with items from parent component where is stored array of items, when click on input type checkbox
//   isSelected?: () => void; // Function that check if it has come items selected, needed due to pagination
//   hasEditPermissions: () => boolean; //this.hasEditPermissions(), to control the permissions on the select option.
//   getDataFromDatabase: () => void; // pass the name of the function that make the call to the api.
//   actionsOnSelected: { //In this example it is allow only one action, delete documents, so it sets an element with a *click* property, a *class* to put an icon on the top of the column when a row is selected and a *title* for the icon, *limitItems* is an optional attribute the number of selected items to show the icon otherwise will be greater than 0, *minimumItems* is an optional attribute the number of minimum selected items to show the icon otherwise will be greater than 0.
//     click: () => void // this.pagination ( is a pagination Model ), if we want to request with those pagination options
//     ; class: string; title: string;
//   }[]; actionsOnResponsive: {
//     click: () => void; // this.onEditUser.bind(this),
//     class: string;
//   }[]; responsiveTitle: {
//     // an object to set what you want to show on the responsive card on small screen.
//     label: string;
//   }; paginationClass: string; //  a CSS class to the pagination-control component, the class 'relative-pag' is inside the scss of the column-responsive-table and it is for set the pagination in the footer of the table instead of in the footer of the page. Changes the position:absolute to position:relative.
//   pageSize: number;
// };


export class SettingTableType<T> {
  // is the id property that you want to use for the select option.
  dataId = 'id';
  // It is a boolean to configure or not a column on the right of the table with a select option on each row.
  hasSelect = true;
  // if true => the selects in the table are single, if false => multiple, by default they are multiple
  hasSingleSelect = false;
  // Optional atribute, it means that *changeSelectedItems* and *isSelected* are going to be used.
  hasSelectedFunctions = false; //if true => changeItems(settings.changeSelectedItems, row, _data) will be executed ELSE changeSelectedItems(row, 'id');
  // Adds, remove or operation with items from parent component where is stored array of items, when click on input type checkbox
  changeSelectedItems = () => console.log('changeSelectedItems it was called, changed configuring settings table');
  // Function that check if it has come items selected, needed due to pagination
  isSelected = () => console.log('isSelected it was called, changed configuring settings table');
  actionsOnSelected: TableAction[] = [new TableAction()];
  //In this example it is allow only one action, delete documents, so it sets an element with a *click* property, a *class* to put an icon on the top of the column when a row is selected and a *title* for the icon, *limitItems* is an optional attribute the number of selected items to show the icon otherwise will be greater than 0, *minimumItems* is an optional attribute the number of minimum selected items to show the icon otherwise will be greater than 0.
  // this.pagination ( is a pagination Model ), if we want to request with those pagination options
  actionsOnResponsive: TableAction[] = [new TableAction(
    'One of the actionsOnSelected icons your can press was called, ONLY MOBILE, changed configuring settings table',
  )];

  hasEditPermissions = () => true; //this.hasEditPermissions(), to control the permissions on the select option.
  getDataFromDatabase = () => { }; //new PageModel<T>(); // pass the name of the function that make the call to the api.
  // an object to set what you want to show on the responsive card on small screen. the attribute name
  responsiveTitle = { label: 'id', status: '', value: '', formatDate: 'lll', second: '' }
  //  a CSS class to the pagination-control component, the class 'relative-pag' is inside the scss of the column-responsive-table and it is for set the pagination in the footer of the table instead of in the footer of the page. Changes the position:absolute to position:relative.
  paginationClass: 'relative-pag' | 'position:absolute' | 'position:relative' = 'relative-pag';
  pageSize = 10;



  // Not explained on Readme.md
  useFilters: boolean = false;
  extraColumns: string[] = [];
  hasDownloadOptions: boolean = false;
  actionsOnDownload: Function = (row: T) => console.log(`actionOnDownload  happening on row: `, row)
  hasOnClickRow = false;
  actionOnClickRow = (row: T) => console.log(`actionOnClickRow happening on row: `, row, ` `)

  clickLink = (row: any, $event: any) => console.log('clickLink = (row, $event)')

  /**
   * Render function is to show or not checkbox of selection
   * and depending on hasSelectedFunctions it shows one checkbox or another
   * TODO: Delete the dependency with hasSelectedFunction or with renderFunction
   * @param row
   * @returns
   */
  renderFunction: Function = (row: T) => {
    // console.log(`renderFunction is to show or not checkbox. Happening on row: `, row, ` then render one checkbox approach or another depending on hasSelectedFunctions`);
    // console.log('we are returning always true to renderFunction')

    return true;
  }
  // Dunno what they are
  maintainSelectedDeep?= false;
  previouslySelected: T[] = []
};

export class TableAction {
  get class() { return this.cssClass; }
  set class(value: string) { this.cssClass = value; }
  limitItems?: number;
  minimumItems?: number;
  button = false; // TODO: Seems a boolean to show or not a button


  text = '';
  constructor(
    public title: string = 'Some Action',
    public click: Function = () => console.log('One of the actionsOnSelected icons your can press was called, NOT MOBILE, changed configuring settings table'),
    public cssClass = 'fa fa-trash',
  ) {

  }

  /**
   *
   * @param title title or id translation of the action E.g: delete
   * @param click function that delete list of items
   * @param cssClass icon or other styles that we want to add E.g: 'fa fa-trash'
   * @returns TableAction
   */
  static getDeleteTableAction(
    title: string,
    click: Function = () => console.log('One of the actionsOnSelected icons your can press was called, NOT MOBILE, changed configuring settings table'),
    cssClass = 'fa fa-trash',
  ) {
    return new TableAction(
      title,
      click,
      cssClass,
    )
  }
}
