import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, filter, from, mergeMap, tap } from 'rxjs';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';
import { IModalFormData } from 'src/app/components/modals/general-modal/general-modal.component';
import { Reason } from 'src/app/models/close-reason.model';
import { Note } from 'src/app/models/note.model';
import { Product } from 'src/app/models/product.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { GenerateCrudModalFormService } from 'src/app/services/generate-crud-modal-form/generate-crud-modal-form.service';
import { NoteService } from 'src/app/services/note/note.service';
import { ProductService } from 'src/app/services/product/product.service';
import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';
import { RoutingInfoService } from 'src/app/services/routing-info/routing-info.service';
import { UploadFilesService } from 'src/app/services/upload-files/upload-files.service';
import { ReadModeModalComponent } from '../modals/read-mode-modal/read-mode-modal.component';
import { NoteCreationComponent } from '../note-creation/note-creation.component';
import { NotesAttachmentsViewerComponent } from '../notes-attachments-viewer/notes-attachments-viewer.component';

@Component({
  selector: 'app-note-card',
  templateUrl: './note-card.component.html',
  styleUrls: ['./note-card.component.scss']
})
export class NoteCardComponent {

  @Input() product!: Product;
  @Input() provider!: ProviderModel;
  @Input() note!: Note | any;
  @Input() products: Product[] = [];
  @Input() isProviderProduct: boolean = false;
  
  @Output() refresh = new EventEmitter();
  canEditAndDelete: boolean = false;

  constructor(
    public productService: ProductService,
    protected modalService: NgbModal,
    protected modalCreator: GenerateCrudModalFormService,
    protected uploadFilesService: UploadFilesService,
    protected providerService: ProviderCollectionService,
    protected noteService: NoteService,
    protected routingInfo: RoutingInfoService,    
    public alertService: AlertService,
    protected authenticationService: AuthenticationService
  ) {}

  ngOnInit(){
    const currentUser = this.authenticationService.getLoggedInUser()
    if(this.note.user.userId == currentUser._id || currentUser.role.name == 'EY Admin'){
      this.canEditAndDelete = true;
    }
  }

  /**
 * Load modal to confirm user(s) removal
 */
  private deleteNotesModal(notes: Note[]) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: 'note',
      notAllowed: notes.length < 1
    };

    from(modalWindowRef.result).pipe(
      filter((reason: Reason) => Reason.submitBtn == reason),
      tap(_ => console.log(notes)),
      mergeMap(_ => this.noteService.deleteNote$(notes[0])),  
      tap(_ => this.refresh.emit())
    ).subscribe()
    // TODO: Unsubscribe
  }

  
  /*
  * Delete single note on click event
  */
  deleteNote(note: Note){
    this.deleteNotesModal([note])
  }

  /**
   * Open edit note modal
   */
  onEditNote(event: MouseEvent, note: Note) {

    let attachments: any[];
    let creationFormValues: any;

    let modalTitle: string;
    let attachmentName: string = this.note.attachments[0]?.name;
    let attachmentId: any = this.note.attachments[0]?.id;
    

    if (!!this.product || this.isProviderProduct) {
      modalTitle = 'Edit note for offering';
    } else {
      modalTitle = 'Edit note for player';
    }

    const buttonText: string = 'Update' 
    
    const modalRef = this.modalCreator.createClassicModalWithInjectedComponent(NoteCreationComponent, modalTitle, buttonText);
    const aSub: Subscription = modalRef.shown.pipe(
      tap(_=> modalRef.componentInstance.componentInjectedInstance.isProduct = !!this.product || this.isProviderProduct),
      tap(_=> modalRef.componentInstance.componentInjectedInstance.data = note ),
      tap(_=> modalRef.componentInstance.componentInjectedInstance.ngOnInit() ),
      tap(_=> this.modalCreator.formSub.next(modalRef.componentInstance.componentRef.instance.myForm) ),
    ).subscribe(_ => aSub.unsubscribe());

    from(modalRef.result).pipe(
      filter(r => r.action !== 'close'),
      tap(r => {
        creationFormValues = r.componentRef.instance.myForm.value;
        // join the name with the extension
        creationFormValues.attachments.forEach((attachment: any) => attachment.name = attachment.name + attachment.extension);
        attachments = creationFormValues.attachments;
        delete creationFormValues.attachments;
      }),
      mergeMap(() => {
        if(this.product){
          return this.productService.updateNote$(this.product.id, creationFormValues);
        }
        if(this.provider){
          return this.providerService.updateNote$(this.provider.id, creationFormValues);
        }
      }),
      tap((note:any) => {
        if(this.product){
          if(attachments[0].changed){
            // remove the old attachment and upload the new one
            this.productService.replaceNoteAttachment(attachments, creationFormValues.id, attachmentId, attachmentName)
            .subscribe({
              next: (res: any) => {console.log("Replacing note attachment"); this.refresh.emit()},
              error: (err: any) => console.error(err)
            });
          }
          else{
            if(attachmentName != creationFormValues.attachmentName){
  
              // rename the file
              this.productService.renameNoteAttachment$(creationFormValues.id, attachmentId, attachmentName, creationFormValues.attachmentName)
              .subscribe({
                next: (res: any) => {console.log("Renaming note attachment"); this.refresh.emit()},
                error: (err: any) => console.error(err)
              });
            }
          }
        }
        if(this.provider){
          if(attachments[0].changed){
            // remove the old attachment and upload the new one
            this.providerService.replaceNoteAttachment(attachments, creationFormValues.id, attachmentId, attachmentName)
            .subscribe({
              next: (res: any) => {console.log("Replacing note attachment"); this.refresh.emit()},
              error: (err: any) => console.error(err)
            });
          }
          else{
            if(attachmentName != creationFormValues.attachmentName){
  
              // rename the file
              this.providerService.renameNoteAttachment$(creationFormValues.id, attachmentId, attachmentName, creationFormValues.attachmentName)
              .subscribe({
                next: (res: any) => {console.log("Renaming note attachment"); this.refresh.emit()},
                error: (err: any) => console.error(err)
              });
            }
          }
        }
        
      }),
    ).subscribe(_ => {});
      /**TODO: Unsubscribe */
  }

  openNoteDetails(note: Note){
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options: IModalFormData<Note> = {
      type: 'new',
      action: 'retrieve',
      data: note,//Note.getEmptyProvider(),
    };
    const modalWindowRef = this.modalService.open(
      ReadModeModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.title = 'Note Details';
    modalWindowRef.componentInstance.data = note;
  }

  goToProduct(){
    this.routingInfo.goToProductPage(this.note.product.productId);
  }
  
  getProductName(productId: string): string {
    const product = this.products.find(p => p.id === productId);
    return product ? product.name : '';
  }

  isAttachmentValid(){
    return this.note.attachments.some((att: { filepath: string; }) => /(jpeg|jpg|gif|png|pdf)$/i.test(att.filepath));
  }

  previewAttachment(attachment:any){
    const modalTitle: string = 'Document Viewer'    
    const modalRef = this.modalCreator.createClassicModalWithInjectedComponent(NotesAttachmentsViewerComponent, modalTitle, 'noshow');
    const aSub: Subscription = modalRef.shown.pipe(
       tap(_=> modalRef.componentInstance.componentInjectedInstance.attachment = this.note ),
       tap(_=> modalRef.componentInstance.componentInjectedInstance.ngOnInit() ),
       tap(_=> this.modalCreator.formSub.next(modalRef.componentInstance.componentRef.instance.myForm) ),
     ).subscribe(_ => aSub.unsubscribe()); 
     from(modalRef.result).pipe(
      filter(r => r.action !== 'close'),
      tap(_ => this.refresh.emit())
    ).subscribe({
      next: () => {
        const message = 'components.modal.product.attachment.editSuccessMsg';
        const keepAlertOnNavigate = false;
        this.alertService.success(message, keepAlertOnNavigate);
      },
      error: (error) => {
        const message = 'components.modal.product.attachment.editErrorMsg';
        const keepAlertOnNavigate = false;
        this.alertService.error(message, keepAlertOnNavigate);
      }
    });
  }

}
