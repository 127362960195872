import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { NoteCategoryService } from 'src/app/services/note-category/note-category.service';

@Component({
  selector: 'app-note-creation',
  templateUrl: './note-creation.component.html',
  styleUrls: ['./note-creation.component.scss']
})
export class NoteCreationComponent implements OnInit{
  
  public categories: any[] = []; //change this
  public selectedFiles: any[] = []; //change this
  public filesFromDB: any[] = []; 
  public myForm: FormGroup | any;
  @Input() data!:any
  @Input() isProduct!: boolean;

  constructor(
    private noteCategoryService: NoteCategoryService,
    private alertService: AlertService
  ){}
  

  ngOnInit(): void {
    const productORproviderNotes = this.noteCategoryService.productORproviderNoteType; 
    this.noteCategoryService.getNoteCategories$().subscribe((categories: any) => {
      if (productORproviderNotes === 'provider') {
        this.categories = categories.data.filter((c: any) => c.name === 'Quality of Support and Service' || c.name === 'Other');
      } else {
        this.categories = categories.data;
      }
    });
    
    if(this.data){
      this.fillForm();
    }else{
      this.createForm();
    }
  }

  createForm(){
    this.myForm = new FormGroup({
      title: new FormControl('', [Validators.pattern(/^[A-Za-z0-9 áéíóúÁÉÍÓÚ,.:]*$/), Validators.required]),
      category: new FormControl('', Validators.required),
      noteCategory: new FormControl('', Validators.required),
      text: new FormControl('', [Validators.pattern(/^[A-Za-z0-9 áéíóúÁÉÍÓÚ,.:]*$/), Validators.required]),
      attachments: new FormControl([]),
      attachmentName: new FormControl('')
    })
  }

  fillForm(){
    for(let i = 0; i < this.data.attachments.length; i++){
      const file = this.data.attachments[i];
      const blobed = new File([''], file.name);
      const fileName = this.extractFileName(file.name)
      // TODO: Unify backend parameters to receive the same
      // in all cases
      this.selectedFiles.push({name: fileName, file: blobed}); //to show chips for attachments
      this.filesFromDB.push(file)
    }
    this.myForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.data._id),
      title: new UntypedFormControl(this.data.title, [Validators.pattern(/^[A-Za-z0-9 áéíóúÁÉÍÓÚ,.:]*$/), Validators.required]),
      category: new UntypedFormControl(this.data.noteCategory.name, Validators.required),
      noteCategory: new UntypedFormControl(this.data.noteCategory, Validators.required),
      text: new UntypedFormControl(this.data.text, [Validators.pattern(/^[A-Za-z0-9 áéíóúÁÉÍÓÚ,.:]*$/), Validators.required]),
      attachments: new UntypedFormControl(this.selectedFiles),
      attachmentName: new UntypedFormControl(this.data.attachments[0]?.name)
    })

  }

  extractFileName(fileNameWithExtension: string): string{
    // Use a regular expression to match the name at the beginning of the string
    const match = fileNameWithExtension.match(/^(.+)\.[a-zA-Z0-9]+$/);

    if (match) {
      // Index 1 of the match contains the name
      const name = match[1];
      return name;
    } else {
      // If no match is found, return the original string or handle it as needed
      return fileNameWithExtension;
    }
  }

  mapCategoryValue(){
    const category = this.categories.find((c:any) => c.name === this.myForm.value.category)
    category['noteCategoryId'] = category['id']
    // delete category['id']
    // delete category['_id']
    this.myForm.patchValue({'noteCategory': category });
  }
  
  onSubmit(){}

  onRemove(){
    this.selectedFiles = [];
    this.myForm.patchValue({'attachments': this.selectedFiles})
  }
  
  onFilesSelected(event: any) {
    const maxFileSize = 20 * 1024 * 1024; // 20MB
    const allowedFormats = [".txt", ".doc", ".docx", ".pdf", ".csv", ".xlsx", ".png", ".jpg", ".jpeg", ".gif", ".bmp"];
  
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      // Get file extension
      const fileExtension = '.' + file.name.split('.').pop();
      // Verify if the file has multiple extensions
      const nameParts = file.name.split('.');
      const hasMultipleExtensions = nameParts.length > 2;
  
      if (!hasMultipleExtensions && file.size <= maxFileSize && allowedFormats.includes(fileExtension.toLowerCase())) {
        this.selectedFiles.push({ name: this.extractFileName(file.name), extension: fileExtension, file: file, changed: true });
      } else {
        // Manage the cases where the file is not allowed
        if (hasMultipleExtensions) {
          const message = `File "${file.name}" is not allowed because it has multiple extensions.`;
          this.alertService.error(message, false);
        } else if (file.size > maxFileSize) {
          const message = `File "${file.name}" exceeds the maximum allowed size (20MB).`;
          this.alertService.error(message, false);
        } else {
          const message = `File format "${fileExtension}" is not allowed for "${file.name}".`;
          this.alertService.error(message, false);
        }
      }
    }
  
    this.myForm.patchValue({ 'attachments': this.selectedFiles });
  }

  handleRenamedAttachment(event: any) {

    let fileName: string = '';
    
    if(this.data){
      const extension = this.data.attachments[0]?.name.split(/[.]/)[1] || event.extension.split(/[.]/)[1];
      fileName = `${event.newName}.${extension}`
    } else {
      fileName = `${event.newName}${event.extension}`
    }
    
    this.myForm.patchValue({'attachmentName': fileName });
  }

  hasErrorPattern(): boolean{
    return this.myForm.get('title').hasError('pattern') || this.myForm.get('text').hasError('pattern');
  }

}
