<form [formGroup]="myForm">
    <div class="modal__body">
        <div class="modal__row modal__row--flex">
            <mat-form-field class="mat-form-textarea">
                <mat-label>{{ 'components.modal.form.note.text' | translate : 'Description' }}</mat-label>
                <textarea
                    matInput
                    formControlName="description"
                    id="description"
                    name="description"
                    [ngClass]="{ 'invalid-border': hasErrorPattern('description') === true }"
                    required>
                </textarea>
            </mat-form-field>
        </div>

        <mat-hint class="hint" *ngIf="hasErrorPattern('description') === true">
            Only alphanumeric characters, whitespaces, '.', ',' and ':' are allowed.
        </mat-hint>

        <div class="selected-files-div">
            <button
                [appHasPermission]="['can-edit-attachments']"
                type="button"
                class="pagehead__button pagehead__button--new"
                (click)="fileInput.click()"
                [disabled]="selectedFiles.length >= 1"
                [ngClass]="{ 'pagehead__primary-button--disabled': selectedFiles.length >= 1 }"
            >
                {{'components.modal.form.note.attachment.add' | translate : 'Add Document'}}
            </button>
            <input type="file" (change)="onFilesSelected($event)" #fileInput hidden required>

            <div class="attch-list">
                <app-attachment-carousel
                    [attachments]="selectedFiles"
                    [blobReferences]="filesFromDB"
                    (onRemove)="onRemove()"
                    (renamedAttachment)="handleRenamedAttachment($event)"
                ></app-attachment-carousel>
            </div>
        </div>
    </div>
</form>
