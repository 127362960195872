<app-table-spinner-ball
    *ngIf="loading"
    [spinnerStyles]="'la-ball-circus'"
></app-table-spinner-ball>

<div class="sections-container" *ngIf="!loading">
    <div class="fields-column">
        <div class="fields-info-box">
          <div class="fields-title">{{ 'page.comparison.tab.product.charscompared' |
            translate : 'Characteristics' }}</div>
        </div>
        <!-- Dynamic fields -->
        <div #field class="field" *ngFor="let field of fields; let i = index">

            <app-auto-complete-select
                [placeholder]="'Select or search characteristic'"
                appearance="fill"
                (onSelectedItem)="onAddFieldAutocomplete($event, i)"
                [multilevel]="false"
                [showArrowIconSuffix]="true"
                [width]="'medium'"
                [formControl]="fieldSelect"
                [source]="listOfProviderProperties"
                [defaultFieldValue]='field'
            ></app-auto-complete-select>
            <mat-icon *ngIf="i > 0 || (i === 0 && fields.length > 1)" class="del-field-icon" (click)="removeField(i)">close</mat-icon>
        </div>
        <!-- Add field box -->
        <div class="add-field-box" (click)="addField()">
            <img src="assets/img/icons/plus.svg" alt="add-icon">
        </div>
    </div>

    <div class="comparison-column">
        <div class="comparison-header">
          <h4>{{ 'page.comparison.tab.product.players' | translate : "Players" }}</h4>
        </div>
        <div class="provider-horizontal-list">
          <button class="expand-space"></button>
            <div class="provider" *ngFor="let provider of providers; let i = index">
                <app-auto-complete-select
                    label="{{ 'Player ' + (i + 1)}}"
                    [placeholder]="'Select or search player'"
                    appearance="fill"
                    (onSelectedItem)="onAddProviderAutocomplete($event, i)"
                    (onEmptyInputValue)="deleteProvider(i)"
                    [multilevel]="false"
                    [showArrowIconSuffix]="true"
                    [width]="'small'"
                    [formControl]="providerSelect"
                    [source]="providersData"
                ></app-auto-complete-select>
                <mat-icon *ngIf="providers.length > 2" class="del-field-icon" (click)="removeProvider(i)">close</mat-icon>
            </div>
            <div class="add-prov-box" *ngIf="providers.length < 5" (click)="addProvider()">
                <img src="assets/img/icons/plus.svg" alt="add-icon">
            </div>
        </div>

        <div #fieldValueBoxRow class="field-value-boxes" *ngFor="let field of fieldValues; let i = index">
          <button class="expand" (click)="toggle(i)" [ngClass]="{'disabled': !isTextOverflow(i) && !buttonClickedStates.get(i)}">
            <img [src]="buttonClickedStates.get(i) ? 'assets/icons/collapse.svg' : 'assets/icons/expand.svg'">
          </button>
            <div class="field-value-box" *ngFor="let provider of providerValues; let j = index" [class.expanded-box]="isExpanded(j, i)">
                <div class="tooltip-container">
                  <span id="{{ i }}-{{ j }}" [class.ellipsis]="!isExpanded(j, i)"  *ngIf="!provider.isLoading">
                    {{ getProviderFieldValue(provider.name, field.name) | formatFieldValue }}
                  </span>
                  <app-table-spinner-ball *ngIf="provider.isLoading" class="spinner" [spinnerStyles]="'la-ball-circus'"></app-table-spinner-ball>
                </div>
            </div>
          <div class="add-prov-box empty" *ngIf="providers.length < 5" ></div>
        </div>
    </div>
</div>
