<div class="providers-list">
    <div class="provider-row" *ngFor="let provider of providers; let i = index">
        <div class="provider-row-info">          
            <div class="provider-name-count">
                <a class="provider-link" target="_blank" [href]="getPath(provider.id)">{{provider.name}}</a>
                <span class="pill transparent">{{provider.products.length}}</span>
            </div>
                        
            <!-- Collapse arrow -->
            <span class="collapse-arrow" (click)="provider.collapsed = !provider.collapsed">
                <mat-icon>{{ provider.collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
            </span>   
        </div>
        
        <!-- Collapsed content -->
        <div class="provider-collapsed-info" *ngIf="!provider.collapsed">
            <app-product-card-list
                [products]="provider.products"
            ></app-product-card-list>
        </div>
    </div>
</div>