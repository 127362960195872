import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexNoData,
  ApexNonAxisChartSeries,
  ApexOptions,
  ApexPlotOptions,
  ApexResponsive,
  ApexStates,
  ApexStroke,
  ApexTheme,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';

import { ChartsService } from '../services/charts-service/charts.service';
import { UtilsService } from '../services/utils-service/utils.service';

/**
 * Chart options that can be overriden through the overridenChartOptions input.
 */
export interface ApexOverridenChartOptions {
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  dataLabels?: ApexDataLabels;
  stroke?: ApexStroke;
  legend?: ApexLegend;
  fill?: ApexFill;
  tooltip?: ApexTooltip;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  markers?: ApexMarkers;
  noData?: ApexNoData;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
  grid?: ApexGrid;
  states?: ApexStates;
  title?: ApexTitleSubtitle;
  subtitle?: ApexTitleSubtitle;
  theme?: ApexTheme;
}

/**
 * Base class for the apex charts components.
 */
@Directive()
export abstract class ApexChartBase implements OnInit {
  /**
   * Event emitter raised when the chart has been mounted.
   */
  @Output() mounted: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Chart data that will be rendered.
   */
  @Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries = [];

  /**
   * Chart labels.
   */
  @Input() labels: string[] = [];

  /**
   * Chart colors by series.
   */
  @Input() colors: any[] = [];

  /**
   * Height of the chart.
   */
  @Input() height?: number;

  /**
   * Height of the chart.
   */
  @Input() width?: number;

  /**
   * If set to true the animations will be disabled
   */
  @Input() disableAnimations?: boolean = false;

  /**
   * Chart properties that will be overriden if different from undefined.
   */
  @Input() overridenChartOptions?: ApexOverridenChartOptions;

  /**
   * Apex charts options;
   */
  public chartOptions: ApexOptions | any = {};

  /**
   * The font family used for the charts. It will be set by default in the constructor.
   */
  protected fontFamily: string;

  /**
   * Hex color used for the charts' font. It will be set by default in the constructor.
   */
  protected fontColor: string;

  /**
   * Hex color used for the charts' axis. It will be set by default in the constructor.
   */
  protected axisColor: string;

  /**
   * Default height in case the dimensions input are not set. It must be defined in the constructor.
   */
  protected defaultHeight: number = 0;

  /**
   * Default width in case the dimensions input are not set. It must be defined in the constructor.
   */
  protected defaultWidth: number = 0;

  constructor(
    protected chartsService: ChartsService,
    protected utilsService: UtilsService
  ) {
    this.fontFamily = this.chartsService.getFontFamily();
    this.fontColor = this.chartsService.getFontColor();
    this.axisColor = this.chartsService.getAxisColor();
  }

  ngOnInit(): void {
    this.setChartOptions();
    if (this.overridenChartOptions) {
      this.overrideChartOptions();
    }
    this.setDisableAnimations();
    this.setMountedCallback();
    this.setDimensions();
  }

  /**
   * This method should be used to set the chartOptions property.
   * It can be executed for instance in ngOnInit.
   */
  protected abstract setChartOptions(): void;

  protected overrideChartOptions(): void {
    if (this.overridenChartOptions) {
      for (const property in this.overridenChartOptions) {
        const value =
          this.overridenChartOptions[
            property as keyof ApexOverridenChartOptions
          ];
        if (value) {
          this.chartOptions[property as keyof ApexOptions] =
            value as unknown as undefined;
        }
      }
    }
  }

  protected getColorArray(color: string, array: any[]): string[] {
    let colors = [];
    for (const _label of array) {
      colors.push(color);
    }
    return colors;
  }

  protected getColorArrayByLength(color: string, length: number): string[] {
    let colors = [];
    for (let i = 0; i < length; i++) {
      colors.push(color);
    }
    return colors;
  }

  protected setMountedCallback() {
    this.utilsService.accessNestedProperty(
      this.chartOptions,
      (chart: any, options?: any) =>
        this.mounted?.emit({
          chart,
          options,
        }),
      ['chart', 'events', 'mounted']
    );
  }

  protected setDisableAnimations() {
    if (!this.disableAnimations) {
      return;
    }
    this.utilsService.accessNestedProperty(
      this.chartOptions,
      !this.disableAnimations,
      ['chart', 'animations', 'enabled']
    );
  }

  protected setDimensions() {
    if (!this.chartOptions?.chart) {
      throw new Error('Missing chart options.');
    }
    if (this.height) {
      this.chartOptions.chart.height = this.height;
    }
    if (this.width) {
      this.chartOptions.chart.width = this.width;
    }
    if (this.checkDimensions()) {
      if (this.defaultHeight) {
        this.chartOptions.chart.height = this.defaultHeight;
      }
      if (this.defaultWidth) {
        this.chartOptions.chart.width = this.defaultWidth;
      }
    }
  }

  protected checkDimensions() {
    return !this.height && !this.width;
  }
}
