import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { ExternalFilters, FilterLabel, FilterResults, FromToDateFilter, ModalFilter, ModalFilterResult } from './filters.interfaces';


@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  @Output() filtered: EventEmitter<FilterResults> = new EventEmitter<FilterResults>();
  @Output() clearedFilters: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public set columns(value: any[]) {
    this._columns = value.filter(column => column['useFilter']);
  }
  public get columns(): any[] {
    return this._columns;
  }
  private _columns: any[] = [];
  @Input() externalFilters!: ExternalFilters;
  @Input() showFilters: boolean = false;

  public filterLabels: FilterLabel[] = [];
  public form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    // private authenticationService: AuthenticationService,
    private momentAdapter: DateAdapter<any>
  ) {
    // const locale = this.authenticationService.getLoggedInLanguage();
    this.momentAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {
    const fields = this.getFormBuilderGroup()
    this.form = this.formBuilder.group(fields);
  }

  public onSearch() {
    if (Object.keys(this.form.value).length === 0) {
      return;
    }
    this.filterLabels = []
    this.setLabels();
    this.filtered?.emit({
      result: this.form.value,
      labels: this.filterLabels
    });
  }

  public onClear() {
    this.form.reset();
    this.filterLabels = [];
    this.clearedFilters?.emit()
  }

  private setLabels() {
    this.setLabel(this.columns, 'header', 'name');
    this.setLabel(this.externalFilters!.additionalFilters!, 'header', 'name');
    this.setLabel(this.externalFilters!.dropdownFilters!, 'header', 'id');
    this.setLabel(this.externalFilters!.modalFilters!, 'header', 'id', true);
    this.setFromToLabel(this.externalFilters!.fromToDateFilter!);
  }

  private setLabel(filters: any[], headerField: string, resultField: string, iterateModalResults?: boolean) {
    if (!filters) {
      return;
    }
    for (const filter of filters) {
      if (this.form.value[filter[resultField]]) {
        if (iterateModalResults) {
          for (const value of filter['names']) {
            this.filterLabels.push({
              name: filter[headerField],
              value: value
            })
          }
        } else {
          this.filterLabels.push({
            name: filter[headerField],
            value: filter['type'] === 'aging-date' ?
              moment(this.form.value[filter[resultField]]).format('llll') : this.form.value[filter[resultField]]
          })
        }
      }
    }
  }

  get fromDateFilterInvalid(): boolean {
    const fromId = this.externalFilters.fromToDateFilter?.fromId;
    if (fromId) {
      return !!this.form.get(fromId)?.invalid
    }

    return false;
    // fromToDateFilter.toId
  }
  private setFromToLabel(filter: FromToDateFilter) {
    if (!filter) {
      return;
    }
    this.filterLabels.push({
      name: filter.fromHeader,
      value: moment(this.form.value[filter.fromId]).format('llll')
    },
      {
        name: filter.toHeader,
        value: moment(this.form.value[filter.toId]).format('llll')
      })
  }

  private getFormBuilderGroup(): any {
    let fields = {}
    this.getFormBuilderFields(fields, this.columns, 'name');
    this.getFormBuilderFields(fields, this.externalFilters!.additionalFilters!, 'name');
    this.getFormBuilderFields(fields, this.externalFilters!.dropdownFilters!, 'id');
    this.getFormBuilderFields(fields, this.externalFilters!.modalFilters!, 'id');
    this.getFromToDateFormBuilderField(fields, this.externalFilters!.fromToDateFilter!);
    return fields;
  }

  private getFormBuilderFields(fields: any, filters: any[], resultField: string) {
    if (!filters) {
      return;
    }
    for (const filter of filters) {
      fields[filter[resultField]] = ['']
      if (filter.filterRequired) {
        fields[filter[resultField]].push(Validators.required)
      }
    }
  }

  private getFromToDateFormBuilderField(fields: any, filter: FromToDateFilter) {
    if (!filter) {
      return;
    }
    fields[filter.toId] = [''];
    fields[filter.fromId] = [''];
    if (filter.filterRequired) {
      fields[filter.toId].push(Validators.required);
      fields[filter.fromId].push(Validators.required);
    }
  }

  public formatDate(filterName: string) {
    const control = this.form.get(filterName)!;
    if (!moment.isMoment(control.value)) {
      return;
    }
    const value = moment(control.value)
    control.setValue(value.format())
  }

  public formatDateToMoment(filterName: string) {
    return moment(this.form.get(filterName)!.value);
  }

  public onModalFilterClicked(filter: ModalFilter) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    }
    const modalWindowRef = this.modalService.open(
      filter.modalComponent,
      modalOptions
    );
    if (this.form.value[filter.id]) {
      if (!filter.options) {
        filter.options = {}
      }
      filter.options.results = this.form.value[filter.id]
    }
    modalWindowRef.componentInstance.settings = filter.options;
    modalWindowRef.componentInstance.savedEvent.subscribe((data: ModalFilterResult) => {
      this.form.get(filter.id)!.setValue(data.results);
      filter['result'] = data.display.displayName;
      filter['names'] = data.display.names;
    },
    );
  }

}
