import { Component, Input, OnInit } from '@angular/core';
import { ProviderModel } from 'src/app/models/provider.model';

@Component({
  selector: 'app-provider-collapsible-list',
  templateUrl: './provider-collapsible-list.component.html',
  styleUrls: ['./provider-collapsible-list.component.scss']
})
export class ProviderCollapsibleListComponent implements OnInit {

  @Input() providers!: ProviderModel[] | any[];

  constructor(){}

  ngOnInit(): void {}

  getPath(providerId: string): string{
    // return `/market-discovery/provider/${providerId}`;
    return `/profile/player/${providerId}`;
  }
}
