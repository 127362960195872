import { Injectable } from '@angular/core';
import { ApiService } from '../api/api-service/api.service';
import { PageModel } from '../api/pagination-service/model/page.model';

@Injectable({
  providedIn: 'root'
})
export class ProvidersFieldsBackupService {
  readonly model: string = 'providersFieldsBackup'

  constructor(
    public apiService: ApiService,
  ) { }

  saveFieldBackup(backup: any) {
    const slug = `/${this.model}`;
    return this.apiService.post(slug, backup, '');
  }

  getFieldsBackupWithPagination$(providerId: string, params: { [keyof: string]: any } = {}) {
    const slug = `${this.model}/${providerId}`
    return this.apiService.get<PageModel<any>>(slug, params, "");
  }

}
