import { Injectable } from '@angular/core';
import { ApiService } from '../api/api-service/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductDatasetService {

  readonly model: string = 'product-dataset'

  constructor(
    private apiService: ApiService
  ) { }

  getProductDataset$(productId: string): Observable<any>{
    const slug = `${this.model}/${productId}`
    return this.apiService.get(slug, {}, 'Product Data Set downloaded successfully')
  }

  getDatasetTemplate$(productId: string): Observable<any> {
    const slug = `${this.model}`;
    return this.apiService.get(slug, {}, 'Data Set Template downloaded successfully')
  }
  
  uploadExcelToProductDataset$(productId: string, data: any): Observable<any>{
    const slug = `${this.model}/${productId}`;
    console.log("The file is being uploaded")
    return this.apiService.post(slug, data, 'Excel Data Uploaded correctly')
  }

  deleteProductDatasets$(productId: any, datasetName: any) {
    const slug = `${this.model}/${productId}/deleteDataset/${datasetName}`
    return this.apiService.post(slug, {}, 'DataSet Removed successfully');
  }
}
