import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nested-menu-options',
  templateUrl: './nested-menu-options.component.html',
  styleUrls: ['./nested-menu-options.component.scss']
})
export class NestedMenuOptionsComponent implements OnInit, AfterViewInit {
  @Input() groups: any[] = [];

  constructor(
    public cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    console.log('this.groups', this.groups);
  }

  ngAfterViewInit(): void {
    console.log('Groups in AfterViewInit:', this.groups);
    this.cdr.detectChanges();
  }
}
