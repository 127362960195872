import { Component, ElementRef, EventEmitter, Input, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ProductDatafieldChangelogComponent } from '../product-datafield-changelog/product-datafield-changelog.component';
import { ProviderDatafieldChangelogComponent } from '../provider-datafield-changelog/provider-datafield-changelog/provider-datafield-changelog.component';
import { GenerateCrudModalFormService } from 'src/app/services/generate-crud-modal-form/generate-crud-modal-form.service';
import { Observable, Subscription, filter, from, of, tap, delay, switchMap } from 'rxjs';
import { COUNTRY_NAMES } from 'src/app/models/constants.model';
import { DataMasterService } from 'src/app/services/data-master-service/data-master';
import { FormatFieldValuePipe } from 'src/app/pipes/format-field-value.pipe';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  providers: [ FormatFieldValuePipe ],
  animations: [
    trigger('openClose', [
      transition('void => *', [
        style({ height: 0 }),
        animate('0.3s ease-in', style({ height: '*' }))
      ]),
      transition('* => void', [
        animate('0.3s ease-out', style({ height: 0 }))
      ])
    ])
  ]
})
export class AccordionComponent {
  @Input () category: any;
  @Input () numOfAllFields: number = 0;
  @Input () isOpen = false;
  @Input () isEditingMode = false;
  @Input () characteristicsGlossary: any;
  @Input () providerGlossary: any;
  @Input () categoryIndex: any;
  @Input () toggleToReRender: boolean = false;
  @Input () pageProfile: string = 'offering'; // 'offering' or 'player'

  @Input() productId!: string | any;
  @Input() providerId!: string | any;
  @Input() productName!: string | any;
  @Input() providerName!: string | any;
  // Output category and index in object
  @Output() categoryUpdated = new EventEmitter<any>();

  @ViewChildren('textarea') textareas: QueryList<ElementRef> | any;

  iconDictionary: any = {
    'Offering Characteristics': 'assets/box-iso.svg',
    'Player Characteristics': 'assets/player-icon.svg',
    'Offering Notes': 'assets/light-bulb.svg',
  }
  categoryIcon: String = '';
  singleSelectOptions: any = []; // This one also stores values for multi selector with no sections
  multiSelectOptionsWithSections: [] | any = [];
  multiSelectValuesSelected: any = [];
  currentFieldToShowChangeLog: any = null;
  selectedValue = '';

  // for country and city related fields
  countryOptions = COUNTRY_NAMES;
  countriesArray: string[] = [];
  dataMasterCountries: any[] = [];
  cities$: Observable<string[]> | any;

  constructor(
    public modalCreator: GenerateCrudModalFormService,
    protected dataMasterService: DataMasterService,
    protected formatFieldValuePipe: FormatFieldValuePipe
  ){
    this.dataMasterCountries = this.dataMasterService.getCountries();
    this.countriesArray = COUNTRY_NAMES.map(country => country.name);
  }

  ngOnInit(): void {
    this.categoryIcon = this.iconDictionary[this.category.names] || 'assets/light-bulb.svg';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isEditingMode'] && changes['isEditingMode'].currentValue === true) {
      // Adjust textareas height to fit all content
      setTimeout(() => {
        this.textareas.forEach((textarea: { nativeElement: any; }) => {
          this.textAreaAdjust(textarea.nativeElement);
          textarea.nativeElement.value = this.formatFieldValuePipe.transform(textarea.nativeElement.value);
        });
      });
    }
  }

  toggleAccordion() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      setTimeout(() => {
        this.textareas.forEach((textarea: { nativeElement: any; }) => {
           this.textAreaAdjust(textarea.nativeElement);
           textarea.nativeElement.value = this.formatFieldValuePipe.transform(textarea.nativeElement.value);
        });
      });
    }
  }

  isFieldEditable(fieldName: { fieldGlossaryId: string | number; name: string | number; }) {
    const hasField = this.pageProfile === 'offering' ? 
      this.characteristicsGlossary.hasOwnProperty(fieldName.fieldGlossaryId) : 
      this.providerGlossary.hasOwnProperty(fieldName.name);

    if (hasField) {
      const isEditable = this.pageProfile === 'offering' ? 
        this.characteristicsGlossary[fieldName.fieldGlossaryId].can_be_edited_in_offering_edition === "yes" : 
        this.providerGlossary[fieldName.name].can_be_edited_in_player_edition === "yes";
      
      return isEditable;
    }
    return false;
  }

  dataType(fieldName: { fieldGlossaryId: string | number; name: string | number; }) {
    const hasField = this.pageProfile === 'offering' ? 
      this.characteristicsGlossary.hasOwnProperty(fieldName.fieldGlossaryId) : 
      this.providerGlossary.hasOwnProperty(fieldName.name);
    
    if (hasField) {
      const data_type = this.pageProfile === 'offering' ? 
        this.characteristicsGlossary[fieldName.fieldGlossaryId].data_type : 
        this.providerGlossary[fieldName.name].data_type;
      
      return data_type;
    }
    return false;
  }

  textAreaAdjust(element: any) {
    element.style.height = "1px";
    element.style.height = (25+element.scrollHeight)+"px";
    this.updateField();
  }

  setSelectOptions(fieldName: { fieldGlossaryId: string | number; name: string | number; }) {
    const hasField = this.pageProfile === 'offering' ? 
      this.characteristicsGlossary.hasOwnProperty(fieldName.fieldGlossaryId) :
      this.providerGlossary.hasOwnProperty(fieldName.name);
    
    if (hasField) {
      if(this.pageProfile === 'offering'){
        this.singleSelectOptions = this.characteristicsGlossary[fieldName.fieldGlossaryId].possible_values.split(/[;,]/);
      } else {
        if(fieldName.name === "Headquarters Country"){
          this.singleSelectOptions = this.countriesArray;
        } else if(fieldName.name === "Headquarters City"){
          const field = this.category?.fields.find((field: any) => field.name === "Headquarters Country");
          const country = this.dataMasterCountries.find(obj => obj.name?.toLowerCase() === field.value?.toLowerCase());

          if(country){
            const cities = country.cities.slice();
            const batchSize = 1000;
            let citiesArray: string[] = [];
            
            const loadCities = (remainingCities: string[]) => {
              if (remainingCities.length === 0){
                return; // Base case
              } 
              const batch = remainingCities.splice(0, batchSize);
              of(batch).pipe(
                tap(chunk => {
                  // Append the batch to the existing array
                  citiesArray = [...citiesArray, ...chunk];
                  this.cities$ = of(citiesArray);
                })
              ).subscribe(() => {
                loadCities(remainingCities);
              });
            };

            loadCities(cities)
          }
          
        } else {
          this.singleSelectOptions = this.providerGlossary[fieldName.name].possible_values.split(/[;,]/)
        }
      }
      return this.singleSelectOptions;
    }
  }

  setMultiSelectOptions( characterictic: {fieldGlossaryId: string | number; name: string | number; }) {
    const hasField = this.pageProfile === 'offering' ? 
      this.characteristicsGlossary.hasOwnProperty(characterictic.fieldGlossaryId) : 
      this.providerGlossary.hasOwnProperty(characterictic.name);

    this.multiSelectOptionsWithSections = [];
    this.singleSelectOptions = [];
    if (hasField) {
      const array =  this.pageProfile === 'offering' ? 
        this.characteristicsGlossary[characterictic.fieldGlossaryId].possible_values : 
        this.providerGlossary[characterictic.name].possible_values;
      
      if (Array.isArray(array))  {
        const sections = this.pageProfile === 'offering' ? 
          this.characteristicsGlossary[characterictic.fieldGlossaryId].possible_values : 
          this.providerGlossary[characterictic.name].possible_values;
        
        for(let section of sections) {
          let options = section.values.split(/[;,]/);
          // Remove empty values
          options = options.filter((option: string) => option.trim() !== '');
          this.multiSelectOptionsWithSections.push({
            name: section.name,
            options: options
           });

       }
      }
      else {
        this.singleSelectOptions = this.pageProfile === 'offering' ? 
          this.characteristicsGlossary[characterictic.fieldGlossaryId].possible_values.split(/[;,]/) : 
          this.providerGlossary[characterictic.name].possible_values.split(/[;,]/);
      }

    }

    return true;
  }

  hasSections( characterictic: {fieldGlossaryId: string | number; name: string | number; }) {
    this.multiSelectOptionsWithSections = [];
    this.singleSelectOptions = [];
    const hasField = this.pageProfile === 'offering' ? 
      this.characteristicsGlossary.hasOwnProperty(characterictic.fieldGlossaryId) : 
      this.providerGlossary.hasOwnProperty(characterictic.name);

    if (hasField) {
      const array =  this.pageProfile === 'offering' ? 
        this.characteristicsGlossary[characterictic.fieldGlossaryId].possible_values : 
        this.providerGlossary[characterictic.name].possible_values;
      
      if (Array.isArray(array))  {
        this.setMultiSelectOptions(characterictic);
        return true;
      }
    }
    this.setMultiSelectOptions(characterictic);
    return false;
  }

  updateField() {
    // Emit category and index
    this.categoryUpdated.emit({category: this.category, index: this.categoryIndex});
  }

  addMultiValues(event: any, currentValues: any, characterictic: any) {
    if (event.target.value === 'null'){
      return;
    }
    this.multiSelectValuesSelected = currentValues ? currentValues.split(/[;,]/) : [];

    let found = false;
    for (let i = 0; i < this.multiSelectValuesSelected.length; i++) {
        if (this.multiSelectValuesSelected[i].toLowerCase().trim() === event.target.value.toLowerCase().trim()) {
            found = true;
            break;
        }
    }

    if (!found) {
        this.multiSelectValuesSelected.push(event.target.value);
     }

     for (let field of this.category?.fields) {
       if (field.name === characterictic.name) {
         field.value = this.multiSelectValuesSelected.join(';');
        }
      }
      this.updateField();
  }

  removeMultiValues(valueToRemove: any, currentValues: any, characterictic: any) {
    this.multiSelectValuesSelected = currentValues.split(/[;,]/);
    // Search for the value in the array
    const index = this.multiSelectValuesSelected.indexOf(valueToRemove);
    // If the value is in the array, remove it
    if (index !== -1) {
      this.multiSelectValuesSelected.splice(index, 1);
    }
    // Update the category
    for (let field of this.category?.fields) {
      if (field.name === characterictic.name) {
        field.value = this.multiSelectValuesSelected.join(';');
      }
    }

    this.updateField();
  }

  isOptionSelected( currentValues: string, option: string) {
    if (!currentValues) {
      return false;
    }
    for (let value of currentValues.split(/[;,]/)) {
      if (value.toLowerCase().trim() === option.toLowerCase().trim()) {
        return true;
      }
    }
    return false;
  }

  // Return true if all the options in this section are selected
  isSectionSelected( currentValues: string, section: any) {
    if (!currentValues) {
      return false;
    }
    for (let option of section.options) {
      if (!this.isOptionSelected(currentValues, option)) {
        return false;
      }
    }
    return true;
  }
  
  onShowChangeLog(field: any){
    this.currentFieldToShowChangeLog = field;

    const modalTitle: string = '';
    const instance = this.pageProfile === 'offering' ? ProductDatafieldChangelogComponent : ProviderDatafieldChangelogComponent;
    const modalIcon: string = "assets/img/icons/changelog-star.svg";
    const iconHasHue: boolean = true;

    const modalRef = this.modalCreator.createClassicModalWithInjectedComponent(instance, modalTitle, 'noshow', modalIcon, iconHasHue);
    const aSubscription: Subscription = modalRef.shown.pipe(
      tap(_=> modalRef.componentInstance.componentRef.instance.field = this.currentFieldToShowChangeLog),
      tap(_=> modalRef.componentInstance.componentRef.instance.productName = this.productName),
      tap(_=> modalRef.componentInstance.componentRef.instance.providerName = this.providerName),
      tap(_=> modalRef.componentInstance.componentRef.instance.productId = this.productId),
      tap(_=> modalRef.componentInstance.componentRef.instance.providerId = this.providerId),
      tap(_=> modalRef.componentInstance.componentRef.instance.providerGlossary = this.providerGlossary),
      tap(_ => modalRef.componentInstance.componentInjectedInstance.ngOnInit() ),
    ).subscribe(_ => aSubscription.unsubscribe());

    from(modalRef.result).pipe(
      filter(r => r.action !== 'close'),
      tap(r => field.value = r.field.previousValue), // after applying the changelog update input in real time
    ).subscribe(_ => {})
  }

  getFieldDescription(categoryName: string, characterictic: {fieldGlossaryId: string | number; name: string | number; }){
    if (categoryName === 'Player Characteristics' || categoryName == 'EY Specific Information') {
      return  this.providerGlossary[characterictic.name].description_of_data_field
    }
    else {
      return this.characteristicsGlossary[characterictic.fieldGlossaryId].description_of_data_field
    }
  }

  getFieldInstructionsForEditorMode(categoryName: string, characterictic: {fieldGlossaryId: string | number; name: string | number; }){
    if (categoryName === 'Player Characteristics' || categoryName == 'EY Specific Information') {
      return  this.providerGlossary[characterictic.name]?.instructions_for_editor_mode
    }
    else {
      if (this.characteristicsGlossary[characterictic.fieldGlossaryId]) {
        return this.characteristicsGlossary[characterictic.fieldGlossaryId].instructions_for_editor_mode;
      }
      return '';
    }
  }
}
