import { ProviderModel } from "./provider.model";
import { RoleModel } from "./role.model";

export class UserModel {
  _id?: string
  providers: ProviderModel[] = [];
  sector: string = '';
  serviceLine: string = '';
  subServiceLine: string = '';
  country: string = '';
  region: string = '';
  hasAllProviders = false;
  constructor(
    public id: string,
    public username: string,
    public email: string,
    public createdDate: string,
    public roleId: string,
    public role: RoleModel,
  ) {
  }


  static getEmptyUser() {
    return new UserModel(
      "", "", "", UserModel.formattedTimestamp(new Date()), "", RoleModel.getEmptyRoleModel()
    )
  }

  static formattedTimestamp(date: Date){
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2,'0');
    const hours = String(date.getUTCHours()).padStart(2,'0');
    const minutes = String(date.getUTCMinutes()).padStart(2,'0');
    const seconds = String(date.getUTCSeconds()).padStart(2,'0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
