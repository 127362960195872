<app-table-spinner-ball
    *ngIf="isLoading"
    [spinnerStyles]="'la-ball-circus'"
  ></app-table-spinner-ball>
<div id="outerContainer">
  <div class="pdf-container" *ngIf="!isImage && !isLoading">
    <ngx-extended-pdf-viewer
      [src]="fileUrl"
      [rotation]="0"
      [zoom]="80"     
      [showToolbar]="false"
      [theme]="'dark'"
      style="width: 100%; height: 600px;"
    ></ngx-extended-pdf-viewer>
  </div>
  <div class="pdf-container" *ngIf="isImage && !isLoading">
    <img [src]="imageUrl"/>
  </div>
</div>