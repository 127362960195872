import { Component, OnInit } from '@angular/core';
import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
import { PercentagePipe } from 'src/app/pipes/percentage.pipe';
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

@Component({
  selector: 'app-apex-radar-chart',
  templateUrl: './apex-radar-chart.component.html',
  styleUrls: ['./apex-radar-chart.component.scss'],
})
export class ApexRadarChartComponent extends ApexChartBase implements OnInit {
  constructor(
    chartsService: ChartsService,
    private percentage: PercentagePipe,
    utilsService: UtilsService
  ) {
    super(chartsService, utilsService);
    this.defaultHeight = 300;
  }

  protected setChartOptions() {
    this.chartOptions = {
      chart: {
        type: 'radar',
        toolbar: {
          show: false,
        },
        fontFamily: this.chartsService.getFontFamily(),
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      stroke: {
        width: 2,
        colors: this.colors,
      },
      fill: {
        opacity: 0.5,
      },
      xaxis: {
        categories: this.labels,
        labels: {
          show: true,
          style: {
            colors: this.getColorArray(this.fontColor, this.labels),
            fontSize: '12px',
          },
        },
        axisBorder: {
          color: this.axisColor,
        },
      },
      yaxis: {
        show: false,
        tickAmount: 5,
        forceNiceScale: true,
        floating: false,
        labels: {
          show: false,
        },
      },
      plotOptions: {
        radar: {
          polygons: {
            connectorColors: this.axisColor,
            strokeColors: this.axisColor,
            fill: {
              colors: ['transparent'],
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        floating: false,
        labels: {
          colors: [this.fontColor],
          useSeriesColors: false,
        },
        fontSize: '12px',
        itemMargin: {
          horizontal: 4,
          vertical: 0,
        },
      },
      tooltip: {
        theme: 'dark',
        enabled: true,
        y: {
          formatter: this.percentage.transform,
        },
      },
    };
  }
}
