<div class="product-card-container" [ngClass]="{'three-column-grid': changeGrid}">
    <div *ngFor="let product of products" class="product-card">
      <a class="product-card__name" target="_blank" [href]="getPath(product.id)" [title]="product.name">{{ product.name }}</a>
      <a class="product-card__provider-name" target="_blank" [href]="getProviderPath(product.provider.providerId)">{{ product.provider.name || product.provider.playerName }}</a>

      <div class="icons-row">
        <div class="offering-icon-container">
          <span *ngFor="let type of getOfferingTypes(product)" [matTooltip]="getOfferingIconTooltip(type) | translate" class="offering-icon" [ngClass]="getOfferingIconClass(type)">
            <img  [src]="getOfferingIconPath(type)" alt="off-ico">
          </span>
        </div>

        <div class="exact-match-container" *ngIf="showMatch">
          <span [ngClass]="{ exact: product?.exact, potential: product?.potential }">{{ product?.potential? 'Potential Match' : product?.exact ? 'Match' : '' }}</span>
          <img *ngIf="product?.potential" src="assets/img/icons/warning-triangle.svg" alt="thumb-ico">
          <img *ngIf="product?.exact" src="assets/img/icons/thumbs-up.svg" alt="thumb-ico">
        </div>
      </div>

    </div>
</div>
