export interface IPermissionDefinition {
  id: number;
  code: string;
  name: string;
  description: string;
  index?: number;
}
function generatePermissionsDefinition() {
  const permissionsDefinition: IPermissionDefinition[] = [];

  const set_permissionId = new Set();
  const set_permissionName = new Set();

  let index = -1;

  /**
   * Define user permission.
   *
   * Permission names and descriptions can be safely changed
   *
   * Changing the permission code
   * will change the id of the permission
   * and invalidate the users' permission arrays
   */
  function definePermission(code: string, name: string, description: string) {
    let id = 0;
    for (let i = 0; i < code.length; i++) {
      id = id * 256 + code.charCodeAt(i);
    }

    if (set_permissionId.has(id)) {
      throw new Error(`duplicate permission id ${id} ('${code}')`);
    }
    set_permissionId.add(id);

    if (set_permissionName.has(name)) {
      throw new Error(`duplicate permission name '${name}'`);
    }

    set_permissionName.add(name);

    ++index;

    permissionsDefinition.push({
      id: id,
      code: code,
      name: name,
      description: description,
      index: index
    });
  }

  definePermission('CSAP', 'can-see-admin-panel', 'ESG: Can see admin panel');
  definePermission('CSLO', 'can-see-landscape-overview', 'ESG: Can see landinscape overview');
  definePermission('CSSE', 'can-see-search-engine', 'ESG: Can see search engine');
  definePermission('CSEP', 'can-see-editor-panel', 'ESG: Can see editor panel');
  definePermission('CSPROVP', 'can-see-provider-profile', 'ESG: Can see provider profile');
  definePermission('CSPRODP', 'can-see-product-profile', 'ESG: Can see product profile');
  definePermission('CEPROVP', 'can-edit-provider-profile', 'ESG: Can edit provider profile');
  definePermission('CEPRODP', 'can-edit-product-profile', 'ESG: Can edit product profile');
  definePermission('CSHP', 'can-see-home-page', 'ESG: Can see home page');
  definePermission('CST', 'can-see-tools', 'ESG: Can see tools');
  definePermission('CSP', 'can-see-profiles', 'ESG: Can see profiles');
  definePermission('CSLM', 'can-see-license-management', 'ESG: Can see license management');

  definePermission(
    'SAAP',
    'surveys-access-admin-panel',
    'Assess Tool: can access admin panel.'
  );
  definePermission(
    'SCM',
    'surveys-client-management',
    'Assess Tool: can do CRUD of clients.'
  );
  definePermission(
    'SACRU',
    'surveys-assign-client-responsible-user',
    'Assess Tool: can assign responsible user to client.'
  );
  definePermission(
    'SQTM',
    'surveys-questionnaire-template-management',
    'Assess Tool: can do CRUD of questionnaire templates.'
  );


  definePermission(
    'SAAsP',
    'surveys-access-assessments-panel',
    'Assess Tool: can access assessments panel.'
  );
  definePermission(
    'SAM',
    'surveys-assessment-management',
    'Assess Tool: can do CRUD of assessments, activate assessments.'
  );
  definePermission(
    'SAAM',
    'surveys-active-assessment-management',
    'Assess Tool: can view and deactivate active assessments.'
  );

  definePermission(
    'SADP',
    'surveys-access-dashboard-panel',
    'Assess Tool: can access dashboard.'
  );

  definePermission(
    "AEA",
    "assessments-external-access",
    "Access to assessments for an external user",
  );

  return permissionsDefinition;
}

export const definitions = generatePermissionsDefinition();
