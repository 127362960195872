import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateformatter'
})
export class DateformatterPipe implements PipeTransform {
  transform(d: string, fmt: string): string {
    const isoDate = new Date(d);
    const datePipe = new DatePipe('en-US');
    try {
      const formattedDate = datePipe.transform(isoDate, fmt);
      return formattedDate!;
    } catch (e) {
      return 'Invalid date';
    }
  }
}
