<mat-card class="note-card" [ngStyle]="{'border-left': '5px solid' + note?.noteCategory?.color }">
    <mat-card-header>
      <div class="card-header-row">
        <mat-card-title class="note-title" *ngIf="!isProviderProduct" [title]="note?.title">{{ note?.title }}</mat-card-title>
        <mat-card-title class="note-title" *ngIf="isProviderProduct" >{{ note?.title }}</mat-card-title>
        <div ngbDropdown>
          <mat-icon ngbDropdownToggle class="mat-toggle-icon">more_vert</mat-icon>
          <div ngbDropdownMenu class="dropdown-cont">
            <!-- TODO: Manage showing of these options -->
            <button *ngIf="!isProviderProduct && canEditAndDelete" [appHasPermission]="['can-edit-notes']" ngbDropdownItem (click)="onEditNote($event, note)">{{ 'page.product.notes.card.edit' | translate : 'Edit Note'}}</button>
            <button *ngIf="!isProviderProduct && canEditAndDelete" [appHasPermission]="['can-edit-notes']" ngbDropdownItem (click)="deleteNote(note)">{{ 'page.product.notes.card.delete' | translate : 'Delete Note'}}</button>
            <button  [appHasPermission]="['can-see-notes']"ngbDropdownItem (click)="openNoteDetails(note)">{{ 'page.product.notes.card.note.details' | translate : 'See Note Details'}}</button>
            <button  *ngIf="isAttachmentValid()" ngbDropdownItem (click)="previewAttachment(note)">{{ 'page.product.notes.card.preview' | translate : 'Preview Document'}}</button>
          </div>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <p class="note-text">{{ note?.text }}</p>

      <div class="note-container">
        <p class="note-date">{{ note.createdDate | date }}</p>
      
        <mat-chip [title]="'Offering note: ' + getProductName(note?.product?.productId)" class="mat-chip prod" *ngIf="isProviderProduct" (click)="goToProduct()" [ngStyle]="{ 'background-color': '#ceced3', 'margin-left': 'auto' }">
          Offering note: {{ getProductName(note?.product?.productId) }}
        </mat-chip>
        
        <mat-chip class="mat-chip" *ngIf="!isProviderProduct" [ngStyle]="{ 'background-color': '#424242', 'margin-left': 'auto' }">
        </mat-chip>
        
        <mat-icon class="right" [hidden]="note.attachments.length<1">attach_file</mat-icon>
      </div>

      <div class="username-and-chip">
        <div class="username-icon-cont">
          <svg
            version="1.1"
            id="usermenu-launch"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
          >
            <path
              d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M256,74.667
              c67.635,0,122.667,55.031,122.667,122.667S323.635,320,256,320s-122.667-55.031-122.667-122.667S188.365,74.667,256,74.667z M256,469.333c-69.707,0-131.52-33.755-170.473-85.615c42.676-20.534,103.621-42.385,170.473-42.385
              c66.857,0,127.807,21.854,170.474,42.383C387.521,435.577,325.708,469.333,256,469.333z"
            />
          </svg>

          <p class="note-username">{{note?.user?.username}}</p>
        </div>

        <mat-chip class="mat-chip" *ngIf="note?.noteCategory" [ngStyle]="{ 'background-color': note?.noteCategory?.color}">
          {{note?.noteCategory?.name}}
        </mat-chip>
      </div>
    </mat-card-content>
  </mat-card>