<div class="modal__header">
  <h3 class="modal__title">{{ 'components.modal.submitFilters.title' | translate }}</h3>
  <button
    type="button"
    class="modal__close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  ></button>
</div>

<div class="modal__body">
 {{ 'components.modal.submitFilters.body' | translate }}
</div>

<div class="modal__footer">
  <button
      color="primary"
      mat-flat-button
      (click)="activeModal.close('Agree')"
    >
      {{ 'components.modal.submitFilters.confirm' | translate }}
    </button>
</div>
