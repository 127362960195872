import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from 'src/app/models/close-reason.model';
@Component({
  selector: 'app-refresh-token',
  templateUrl: './refresh-token.component.html',
  styleUrls: ['./refresh-token.component.scss']
})
export class RefreshTokenComponent implements OnInit {
  @Input() options: {isAboutToExpire: boolean} | any;
  public reason: Reason | any;
  public isAboutToExpire: boolean | any;
  constructor(
    public activeModal: NgbActiveModal
    ) {}
  ngOnInit() {
    this.isAboutToExpire = this.options.isAboutToExpire;
  }
  public onConfirm(reason: Reason): void {
    this.activeModal.close(reason);
  }
}
