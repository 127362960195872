import { PipeModule } from 'src/app/pipes/pipe.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslatePipe } from './pipes/translate-pipe/translate.pipe';
import { msalInstanceConfig, msalGuardConfig, msalInterceptorConfig } from './security/conf/msal.config';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, } from "@azure/msal-angular";
import { MockMsalService } from './security/services/mock-msal-service/mock-msal.service';
import { environment } from 'src/environments/environment';
import { ComponentsModule } from './components/components.module';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MomentPipe } from './pipes/moment.pipe';

const AddMsalRedirect = environment.mock_authentication ? [] : [MsalRedirectComponent];
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MsalModule.forRoot(
      msalInstanceConfig(),
      msalGuardConfig(),
      msalInterceptorConfig()
    ),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,   
    FontAwesomeModule,
    PipeModule,
    ComponentsModule,

    MatMenuModule,
    DragDropModule,

    ComponentsModule,
  ],
  providers: [
    TranslatePipe,
    MomentPipe,
    NgbActiveModal,

    //Msal Authentication
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MsalService,
      useClass: environment.mock_authentication ? MockMsalService : MsalService,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceConfig
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfig
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfig
    },
    MsalGuard,
    MsalBroadcastService,
    // End MsalAuthentication
  ],
  bootstrap: [AppComponent, ...AddMsalRedirect]
})
export class AppModule { }
