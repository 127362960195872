import { ComponentRef, Injectable, Type } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, filter, map, tap, Subject, BehaviorSubject } from 'rxjs';
import { IModalFormData, GeneralModalComponent } from 'src/app/components/modals/general-modal/general-modal.component';
import { ModalForInjectComponentComponent } from 'src/app/components/modals/modal-for-inject-component/modal-for-inject-component.component';


@Injectable({
  providedIn: 'root'
})
export class GenerateCrudModalFormService {

  //this subject will emit the form that will be added to the 
  //injected component
  public formSub = new BehaviorSubject<NgForm | undefined>(undefined);

  constructor(
    private modalService: NgbModal,

  ) {

  }

  createClassicModalWithInjectedComponent<C>(injectedComponent: C, modalTitle = "Injected Title", buttonText = "Submit", modalIcon = "", iconHasHue = false): NgbModalRef & {
    onLoaded$: Subject<ComponentRef<any>>;
    onCreatedChildComponent$: Subject<ComponentRef<any>>;
  } {

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };

    const modalWindowRef = this.modalService.open(
      ModalForInjectComponentComponent,
      modalOptions
    );

    const componentInstance = modalWindowRef.componentInstance as ModalForInjectComponentComponent;
    componentInstance.componentInsertedClass = injectedComponent;
    componentInstance.modalTitle = modalTitle;
    componentInstance.buttonText = buttonText;
    componentInstance.modalIcon = modalIcon;
    componentInstance.iconHasHue = iconHasHue;

    const createModalForInjectComponentComponent$ = componentInstance.onLoaded$.pipe(
      tap(c => componentInstance.createComponent()),
    );

    const instanceEvents = { onLoaded$: componentInstance.onLoaded$, onCreatedChildComponent$: componentInstance.onCreatedChildComponent$ }

    // TODO: unsubscribe
    createModalForInjectComponentComponent$.subscribe();
    (modalWindowRef as any).onLoaded$ = componentInstance.onLoaded$;
    (modalWindowRef as any).onCreatedChildComponent$ = componentInstance.onCreatedChildComponent$
    return modalWindowRef as NgbModalRef & {
      onLoaded$: Subject<ComponentRef<any>>;
      onCreatedChildComponent$: Subject<ComponentRef<any>>;
    };
  }

  createClassicModalFormForCreation$<T>(classConstructor: Type<T>, modalTitle: string, excludedFields: (keyof T)[] = []): Observable<T> {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };

    const options: IModalFormData<T> = {
      type: 'new',
      action: 'create',
      data: {} as any,
    };

    const modalWindowRef = this.modalService.open(
      GeneralModalComponent,
      modalOptions
    );

    //TODO: This is setting @INPUT we don't need to use options in that way
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.componentInstance.title = modalTitle;
    modalWindowRef.componentInstance.excludeFields = excludedFields;

    modalWindowRef.componentInstance.classType = classConstructor;
    // console.log(modalWindowRef.componentInstance.onFormInitialized)

    return from(modalWindowRef.result).pipe(
      filter(modalResult => modalResult.action === 'create'),
      map(modalResult => modalResult.data as T),
    );
  }


  // createClassicModalFormForEdition$<T extends { constructor: new (...args: any[]) => {} }>(instanceToUpdate: T, modalTitle: string, excludedFields: (keyof T)[] = []): Observable<T> {
  createClassicModalFormForEdition$<T>(instanceToUpdate: T, modalTitle: string, excludedFields: (keyof T)[] = []): Observable<T> {

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };

    const options: IModalFormData<T> = {
      type: 'edit',
      action: 'update',
      data: instanceToUpdate,
    };

    const modalWindowRef = this.modalService.open(
      GeneralModalComponent,
      modalOptions
    );

    //TODO: This is setting @INPUT we don't need to use options in that way
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.componentInstance.title = modalTitle;
    modalWindowRef.componentInstance.excludeFields = excludedFields;

    // modalWindowRef.componentInstance.classType = instanceToUpdate.constructor;
    modalWindowRef.componentInstance.classType = (instanceToUpdate as any).constructor as Constructor<T>;
    // console.log(modalWindowRef.componentInstance.onFormInitialized)

    return from(modalWindowRef.result).pipe(
      filter(modalResult => modalResult.action === 'update'),
      map(modalResult => modalResult.data as T),
    );
  }
}
type Constructor<T> = new (...args: any[]) => T;
