import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, catchError, of, tap, Subscription, Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class TranslateService implements OnDestroy {
  data: any = {};
  languageUpdated$ = new Subject<any>();
  subscriptions = new Subscription();
  lastLangRequested: 'es' | 'en' = 'en';

  constructor(private http: HttpClient) {
    this.subscriptions.add(
      this.getTranslations$(this.lastLangRequested).subscribe()
    )
  }

  getTranslations$(lang: 'es' | 'en') {
    this.lastLangRequested = lang || 'en';
    const langPath = `assets/locale/${this.lastLangRequested}.json`;
    const langDictRequest$ = this.http.get<{}>(langPath);
    const assignData$ = langDictRequest$.pipe(
      tap(
        translation => {
          this.data = Object.assign({}, translation || {});
          this.languageUpdated$.next(this.data);
        }
      ),
    );
    const defaultValueOnError$: Observable<{}> = assignData$.pipe(
      catchError(error => {
        this.data = {};
        console.error('fetching translation got an error:', error);
        return of({});
      })
    );

    return defaultValueOnError$;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
