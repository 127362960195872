import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataMasterService } from 'src/app/services/data-master-service/data-master';
import { MarketDiscoveryService } from 'src/app/services/market-discovery/market-discovery.service';

@Component({
  selector: 'app-product-creation',
  templateUrl: './product-creation.component.html',
  styleUrls: ['./product-creation.component.scss']
})
export class ProductCreationComponent implements OnInit {
  @Input() fields: any[] = [];
  @Input() data: any;
  @Input() isEditing: boolean = false;
  public myForm: FormGroup | any = this.formBuilder.group({});
  protected loading: boolean = true;

  constructor(
    protected dataMasterService: DataMasterService,
    protected marketDiscoveryService: MarketDiscoveryService,
    protected activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder
  ){}

  ngOnInit(): void{
    if(this.data){
      this.fillForm();
    }else{
      if(this.fields.length > 0){
        this.createForm();
      }
    }
  }

  createForm(){
    for(const field of this.fields){
      const name = this.marketDiscoveryService.toCamelCase(field.characteristic_name);
      // const regex = field.unique_ID === "ID_O_005" ? /^[A-Za-z0-9 áéíóúÁÉÍÓÚäëïöüÄËÏÖÜ,.:_–\-\/]*$/ : /^[A-Za-z0-9 áéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ,.:_–\-\/\(\)\&\'\"\n\r\%\º]*$/;
      const regex = /^[^<>{};]*$/;
      const control = this.formBuilder.control('', [
        Validators.pattern(regex), 
        Validators.required
      ]);

      this.myForm.addControl(name, control);
    }
    this.loading = false;
  }
  
  fillForm(){       
    for(const field of this.fields){
      const name = this.marketDiscoveryService.toCamelCase(field.characteristic_name);
      // const regex = field.unique_ID === "ID_O_005" ? /^[A-Za-z0-9 áéíóúÁÉÍÓÚäëïöüÄËÏÖÜ,.:_–\-\/]*$/ : /^[A-Za-z0-9 áéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ,.:_–\-\/\(\)\&\'\"\n\r\%\º]*$/;
      const regex = /^[^<>{};]*$/;
      const disabled = field.unique_ID === "ID_O_012"; // if it is "Offering Type" we disable the field when editing from the modal
      const control = this.formBuilder.control(
        { value: this.data[name], disabled: disabled }, 
        [ Validators.pattern(regex), Validators.required ]
      );

      this.myForm.addControl(name, control);
    }
    this.loading = false;
  }

  fieldToShow(field: any): number {
    switch(field.data_type_number){
      case 0: // Multi-select (no-hierarchy)
        return 0;
      case 1: // Single-select
        return 1;
      case 2: //"Free Text"
        // 32 is just a number i picked to specify that it is a Free text but with a bigger area to write 
        return field.unique_ID === "ID_O_010" ? 32 : 2;
      case 3: //"Numeric"
        return 3;
      case 4: // "Multi-Select" (hierarchy)
        return 4;
      default:
        return 10; // not existing data type, won't check any condition
    }
  }

  getPossibleValuesSingleSelect(field: any) {
    return field.possible_values.split(/[;,]/).map((value: string)=> value.trim())
  }

  convertToCamel(formFieldName: string): string {
    return this.marketDiscoveryService.toCamelCase(formFieldName);
  }

  hasError(formFieldName: string): boolean {
    const name = this.marketDiscoveryService.toCamelCase(formFieldName);
    return this.myForm.get(name).hasError('pattern') || this.myForm.get(name).hasError('required');
  }

  // only pattern error
  hasPatternError(formFieldName: string): boolean {
    const name = this.marketDiscoveryService.toCamelCase(formFieldName);
    return this.myForm.get(name).hasError('pattern');
  }

  cancel(){
    this.activeModal.close({
      action: 'close'
    });
  }

  create(){
    this.activeModal.close({
      action: 'create',
      offering: this.myForm.value
    });
  }
}
