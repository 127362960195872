import { Component, OnInit } from '@angular/core';

import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
import { PercentagePipe } from 'src/app/pipes/percentage.pipe';
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

@Component({
  selector: 'app-apex-horizontal-bar-chart',
  templateUrl: './apex-horizontal-bar-chart.component.html',
  styleUrls: ['./apex-horizontal-bar-chart.component.scss'],
})
export class ApexHorizontalBarChartComponent
  extends ApexChartBase
  implements OnInit
{
  constructor(
    private percentage: PercentagePipe,
    chartsService: ChartsService,
    utilsService: UtilsService
  ) {
    super(chartsService, utilsService);
  }

  protected setChartOptions(): void {
    this.chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        fontFamily: this.fontFamily,
      },

      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 100,
        },
      },

      dataLabels: {
        enabled: false,
      },

      yaxis: {
        labels: {
          show: true,
          style: {
            colors: this.fontColor,
          },
        },
      },

      tooltip: {
        theme: 'dark',
        enabled: true,
        y: {
          formatter: this.percentage.transform,
        },
      },

      xaxis: {
        categories: this.labels,
        labels: {
          show: true,
          style: {
            colors: this.fontColor,
            fontSize: '12px',
          },
          formatter: (value: string) =>
            `${Math.round(Number.parseFloat(value) * 100)}%`,
        },
        axisTicks: {
          show: true,
          color: this.axisColor,
        },
        axisBorder: {
          show: false,
        },
      },

      stroke: {
        width: 2,
        curve: 'smooth',
        colors: this.colors,
      },

      fill: {
        opacity: 0.5,
        colors: this.colors,
      },

      grid: {
        borderColor: this.axisColor,
      },

      colors: this.colors,
    };
  }
}
