<div class="modal__header">
  <h3 class="modal__title">
    {{ 'components.modal.delete.title' | translate }}
    {{ option | translate: 'Delete' | titlecase }}
  </h3>
  <button
    type="button"
    class="modal__close"
    aria-label="Close"
    (click)="activeModal.close(reason.closeBtn)"
  ></button>
</div>

<div class="modal__body">
  <p>
    {{ body | translate : 'Do you want to remove this?' }}{{ option | translate
    }}{{ !options.notAllowed ? '?' : '' }}
  </p>
</div>

<div *ngIf="!options.notAllowed" class="modal__footer">
  <button
    mat-stroked-button
    color="accent"
    (click)="activeModal.close(reason.closeBtn)"
  >
    {{ 'components.modal.common.cancel' | translate : 'Cancel'}}
  </button>
  <button
    mat-flat-button
    color="warn"
    (click)="onConfirmDelete(reason.submitBtn)"
  >
    {{ 'components.modal.delete.agree' | translate : 'Delete' }}
  </button>
</div>
