import { Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { AppliedFilterInfo, CheckboxOption } from '../advance-filters.interfaces';

@Component({
  selector: 'app-advance-filter-checkboxes',
  templateUrl: './advance-filter-checkboxes.component.html',
  styleUrls: ['./advance-filter-checkboxes.component.scss']
})
export class AdvanceFilterCheckboxesComponent implements OnInit {
  
  @Input() filter!: any;
  @Output() onSelected = new EventEmitter<AppliedFilterInfo>();
  @Output() emitRemoval = new EventEmitter<AppliedFilterInfo>();
  selectedOptions: CheckboxOption[] = [];
  @Input() mappedSelectedOptions!: any;

  constructor(){}

  ngOnInit(): any {
    if(this.mappedSelectedOptions){
      if(this.filter.type === 4){
        this.selectedOptions = this.mappedSelectedOptions.flatMap((mapped: any) => mapped.values.filter((opt: any) => opt.checked === true));
      }else {
        this.selectedOptions = this.mappedSelectedOptions.filter((opt:any) => opt.checked !== false);
      }
    }
  }

  checkOption(option: CheckboxOption){

    if(!option.disabled){
      const index = this.selectedOptions.findIndex(opt => opt.name === option.name);
      option.checked = !option.checked;

      if(option.checked && index === -1){
        this.selectedOptions.push(option);
      }else {
        this.selectedOptions.splice(index, 1)
      }

      const toEmit: AppliedFilterInfo = {
        name: this.filter.name,
        type: this.filter.type,
        options: this.selectedOptions
      }

      this.onSelected.emit(toEmit)
    }
    
  }

  clear(){
    if(this.filter.type === 4){
      this.filter.options.forEach((opt: CheckboxOption) => {
        opt.values?.forEach((value: CheckboxOption) => value.checked = false);
      });
    }else {
      this.filter.options.forEach((opt: CheckboxOption) => opt.checked = false);
    }
    this.selectedOptions = [];
    const toEmit: AppliedFilterInfo = {
      name: this.filter.name,
      type: this.filter.type,
      options: []
    };
    this.onSelected.emit(toEmit);
  }

  remove(){
    this.clear();
    this.emitRemoval.emit();
  }
}
