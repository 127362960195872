import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TotalElementsDetailComponent } from './total-elements-detail/total-elements-detail.component';



@NgModule({
  declarations: [
    TotalElementsDetailComponent,
  ],
  imports: [
    CommonModule
  ],

  exports:[
    TotalElementsDetailComponent,
  ]
})
export class TotalElementDetailsModule { }
