import { Component, EventEmitter, OnInit, Input, Output, ViewChild, forwardRef, AfterViewInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, map, tap, from } from 'rxjs';

@Component({
  selector: 'app-auto-complete-select',
  templateUrl: './auto-complete-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutoCompleteSelectComponent),
      multi: true
    }
  ],
  styleUrls: ['./auto-complete-select.component.scss']
})
export class AutoCompleteSelectComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit{
  @Input() required = false;
  @Input() source!: any;
  @Input() placeholder = 'Search ...';
  @Input() label = '';
  @Input() multilevel: boolean = false;
  @Input() showHint: boolean = false;
  @Input() showSearchIconPrefix: boolean = false;
  @Input() showArrowIconSuffix: boolean = false;
  @Input() showSearchIconSuffix: boolean = false;
  @Input() width: string = "small";
  @Input() defaultFieldValue?: string | {name: string};
  @Output() onSelectedItem = new EventEmitter();
  @Output() onSelectedProvider = new EventEmitter();
  @Output() onEmptyInputValue = new EventEmitter();
  @Output() onEnter = new EventEmitter<any[]>();

  filteredOptions!: Observable<string[] | any>;

  control = new FormControl();

  selectedProvider: string = '';
  isInvalidData: boolean = true;

  @ViewChild('autoInput') autoInput!: any;
  @ViewChild('auto') autocomplete!: MatAutocomplete;

  constructor(){}

  ngOnInit() {
    this.updateFilteredOptions();
    this.isNoValidData().subscribe(isInvalid => {
      this.isInvalidData = isInvalid;
    });
  }

  ngAfterViewInit() {
    this.control.setValue(''); // to trigger the observable on value changes
    this.setDefaultValues();
  }

  onEnterPressed() {
    const filteredSections = this._filterValues(this.control.value);
    const filteredItems = this.multilevel ? filteredSections.flatMap(section => section.items) : filteredSections;
    this.onEnter.emit(filteredItems);
    this.autocomplete.showPanel = false;
  }

  // to register the changes on source modification
  onOptionsReduce(newSource: string[] | any[]) {
    this.filteredOptions = from([newSource]);
  }

  private _filterValues(value: string): any[] {
    const filterValue = value.toLowerCase();

    if (this.multilevel) {
      const filteredOptions: any[] = [];

      for (const section of this.source) {
        const filteredItems = section.items.filter((item: string) =>
          item?.toLowerCase().includes(filterValue)
        );
        if (filteredItems.length > 0) {
          const filteredSection = { header: section.header, items: filteredItems };
          filteredOptions.push(filteredSection);
        }
      }

      if (filteredOptions.length === 0 && value) {
        return [{ header: 'No results', items: ['Invalid value'], disabled: true }];
      }

      return filteredOptions;
    } else {
      const filteredData = this.source.filter((data: any) =>
        data?.toLowerCase().includes(filterValue)
      );

      if (filteredData.length === 0 && value) {
        return ['Invalid value'];
      }

      return filteredData;
    }
  }


  clearControlValue(){
    this.control.setValue('');
  }

  selectItem(item: any) {
    this.onSelectedItem.emit(item.value);
    this.onSelectedProvider.emit(item);

    if (this.multilevel) {
      const selectedProduct = this.source.find((section: any) => section.items?.includes(item.value));
      this.selectedProvider = selectedProduct ? selectedProduct.header : '';
    }

    this.updateFilteredOptions();
  }
  setDefaultValues(): void {
    let valueToSet = '';
    if (typeof this.defaultFieldValue === 'string') {
      valueToSet = this.defaultFieldValue;
    }
    else if (this.defaultFieldValue && typeof this.defaultFieldValue === 'object' && 'name' in this.defaultFieldValue) {
      valueToSet = this.defaultFieldValue.name;
    }
    if (valueToSet) {
      this.control.setValue(valueToSet);
    }
  }

  updateFilteredOptions(){
    this.filteredOptions = this.control.valueChanges.pipe(
      map(value => this._filterValues(value)),
      map(data => data.length === 0 ? [{
        displayValue: 'Invalid value',
        isDisabled: true,
        level: 0,
        selectedValue: null
      }] : data),
      tap(_ => this.checkInputValue(this.control.value))
    );
  }

  checkInputValue(value: any){
    if(value === ''){
      this.onEmptyInputValue.emit()
    }
  }

  // Methods required for ControlValueAccessor
  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: any): void {}

  validate(control: AbstractControl): ValidationErrors | null {
    return this.required && !control.value ? { required: true } : null;
  }
  isNoValidData(): Observable<boolean> {
    return this.filteredOptions.pipe(
      map(options => {
        if (Array.isArray(options) && options.length > 0) {
          if (this.multilevel && options[0].header === 'No results' && options[0].items.includes('Invalid value')) {
            return true;
          }
          else if (!this.multilevel && options.length === 1 && options[0] === 'Invalid value') {
            return true;
          }
        }
        return false;
      })
    );
  }
}
