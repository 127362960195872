<form [formGroup]="myForm">
    <div class="modal__body">
        <div class="modal__row modal__row--flex">
            <mat-form-field>
                <mat-label>{{ 'components.modal.form.note' | translate : 'Title' }}</mat-label>
                <input
                matInput
                formControlName="title"
                id="title"
                name="title"
                required
                />
            </mat-form-field>

            <mat-form-field>
                <mat-label>
                    {{ 'components.modal.form.note.' + 'NoteCategory'
                    | translate : 'Category' }}
                </mat-label>
                <mat-select
                    formControlName="category"
                    id="category"
                    name="category"
                    (selectionChange)="mapCategoryValue()"
                    required
                >
                    <mat-option *ngFor="let category of categories" [value]="category.name">
                    {{category.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div>
            <!-- TODO: Add this type of field to the inputs generation function -->
            <mat-form-field class="mat-form-textarea">
            <mat-label>{{ 'components.modal.form.note.text' | translate : 'Text' }}</mat-label>
            <textarea
                matInput
                formControlName="text"
                id="text"
                name="text"
                required>
            </textarea>
            </mat-form-field>
        </div>

        <mat-hint class="hint" *ngIf="hasErrorPattern() === true">
            Only alphanumeric characters, whitespaces, '.', ',' and ':' are allowed.
        </mat-hint>

        <div>
            <button
               [appHasPermission]="['can-edit-attachments']"
               class="pagehead__button pagehead__button--new"
               [ngClass]="{ 'pagehead__button--disabled': selectedFiles.length >= 10 }"
                type="button"
                (click)="fileInput.click()"
                [disabled]="selectedFiles.length >= 1"
            >
                {{'components.modal.form.note.attachment' | translate : 'Add Attachment(s)'}}
            </button>
            <input type="file" (change)="onFilesSelected($event)" #fileInput hidden>

            <div class="attch-list">
                <app-attachment-carousel
                    [note]="data"
                    [attachments]="selectedFiles"
                    [blobReferences]="filesFromDB"
                    [action]="'remove'"
                    (onRemove)="onRemove()"
                    (renamedAttachment)="handleRenamedAttachment($event)"
                ></app-attachment-carousel>
            </div>
        </div>
    </div>
</form>
