import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToggleCollapseComponent } from './toggle-collapse.component';

@NgModule({
  declarations: [
    ToggleCollapseComponent,
  ],
  imports: [CommonModule],
  exports: [
    ToggleCollapseComponent,
  ],
})
export class ToggleCollapseModule {}
