import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionModel } from 'src/app/models/permission.model';
import { PageModel } from 'src/app/services/api/pagination-service/model/page.model';
import { PaginationModel } from 'src/app/services/api/pagination-service/model/pagination.model';
import { GenerateCrudModalFormService } from 'src/app/services/generate-crud-modal-form/generate-crud-modal-form.service';
import { RoutingInfoService } from 'src/app/services/routing-info/routing-info.service';
import { UserPermissionsService } from 'src/app/services/user-permissions/user-permissions.service';
import { ColumnBuilderService } from '../responsive-table/column-builder/column-builder.service';
import { SettingTableType, TableAction } from '../responsive-table/model/setting-table.model';


@Component({
  selector: 'app-user-permissions-table',
  templateUrl: './user-permissions-table.component.html',
  styleUrls: ['./user-permissions-table.component.scss']
})
export class UserPermissionsTableComponent {
  totalItems = 0;

  onSelected($event: Event) {
    console.log(`onSelected:`, $event);
  }
  selectedItems: PermissionModel[] = [];
  onSelectedItems(selectedItems: PermissionModel[]) {

    console.log(`Selected items: ${selectedItems.length}`);
    this.selectedItems = selectedItems;
  }
  showFilters: boolean = false;

  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  onTablePageChange([pageToChange, resetSelected]: [number, boolean]) {
    this.permissionPaginationConfiguration.page = pageToChange;
  }

  onTableSort([orderBy, resetSelected]: [string, boolean]) {
    this.permissionPaginationConfiguration.orderBy = orderBy;
    this.permissionPaginationConfiguration.page = 1;
  }

  loading = true;
  backgroundLoading = true;

  permissionTableRows?: PageModel<PermissionModel>;
  permissionPaginationConfiguration = PaginationModel.createDefaultConfiguration('name');
  permissionTableColumnConfiguration: { header: string; name: keyof PermissionModel; class: string; orderBy?: string | undefined; }[] = [];
  settingTable = new SettingTableType<PermissionModel>();

  @Input() permissions: PermissionModel[] | undefined;
  @Input() data!: any;

  constructor(
    public columnBuilder: ColumnBuilderService,
    public userPermissions: UserPermissionsService,
    protected modalService: NgbModal,
    public modalCreator: GenerateCrudModalFormService,
    public router: RoutingInfoService,
  ) {
  }

  ngOnInit(): void {
    this.setProductTableColumConfiguration();
    this.setSettingsTable();
    this.setPermissions();
  }

  ngOnChanges() {
    this.setProductTableColumConfiguration();
    this.setSettingsTable();
    this.setPermissions();
  }
  setProductTableColumConfiguration() {
    const confColumns = this.columnBuilder.configureColumns<PermissionModel>(PermissionModel, {id:0, name: '', description:'',permissionValue: false });
    this.permissionTableColumnConfiguration = this.columnBuilder.getTableColumnConfiguration<PermissionModel>(
      confColumns,[],['description','permissionValue'])
    for (const col of this.permissionTableColumnConfiguration) {
      const customCol = col as any;
      col.orderBy = undefined;
      col.class = 'center-header'
      switch (col.name) {
        case "name":
          break;
        case "permissionValue":
          customCol.type = 'toggle';
          break;
      }

      col.header = `page.permissions.table.permission.column.${col.header}`;
    }
  }

  refresh(): void {
    this.setPermissions();
  }
  setSettingsTable() {
    this.settingTable.dataId = 'id';
    this.settingTable.hasSelect = false;
    this.settingTable.getDataFromDatabase = this.setPermissions.bind(this);
    this.settingTable.actionsOnResponsive = [
      new TableAction(
        'edit',
        () => console.log('show a modal to edit item selected.'),
        'fa fa-edit'
      ),
    ];

    this.settingTable.responsiveTitle.label = 'name';
  }

  setPermissions(params: { [keyof: string]: any } = {}) {
    this.loading = true;
    this.backgroundLoading = true;
  
    this.userPermissions.getPermissionsWithPagination$(params).subscribe(
      (permissionsData: PageModel<PermissionModel>) => {
        const hasPermission = (permissionName: string): boolean => {
          return this.data && this.data.role.permissions.some((p: any) => p.name === permissionName);
        };
  
        permissionsData.data.forEach((permission: PermissionModel) => {
          permission.permissionValue = hasPermission(permission.name);
  
          if (permission.description === 'Can see player profile') {

            const providers = this.data.providers && this.data.providers.length > 0
              ? this.data.providers.map((providerObj: any) => providerObj.provider)
              : null;

            if (providers) {
              permission.description = `Can see player profile [${providers.join(', ')}]`;
            }
          }
          if (permission.description === 'Can edit player profile') {

            const providers = this.data.providers && this.data.providers.length > 0
              ? this.data.providers.map((providerObj: any) => providerObj.provider)
              : null;

            if (providers) {
              permission.description = `Can edit player profile [${providers.join(', ')}]`;
            }
          }
        });
  
        this.permissionTableRows = permissionsData;
        this.totalItems = permissionsData.totalElements;
        this.loading = false;
        this.backgroundLoading = false;
      },
      (error: any) => {
        console.error('Error fetching permissions:', error);
        this.loading = false;
        this.backgroundLoading = false;
      }
    );
  }

  public onLoadingChange(isLoading: boolean) {
    console.log('onLoadingChange isLoading', isLoading);
    this.loading = isLoading;
  }

  public onSearch(filters: Partial<PermissionModel> | any) {
    this.setPermissions(filters);
  }
}