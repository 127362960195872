import {
  Component,
  EventEmitter,
  Output,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { ChatbotService } from 'src/app/services/chatbot-service/chatbot.service';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { chatbotQuestions } from '../chatbot-questions.config';

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss'],
})
export class ChatViewComponent {
  @ViewChild('chatContainerElement') chatContainerElement!: ElementRef;

  @Output() closeChat = new EventEmitter();

  loggedUser: any;
  inputText: string = '';
  chatMessages: ChatMessage[] = [];
  isWaitingForResponse: boolean = false;
  showChatHistory: boolean = false;
  curretChatId: string = '';
  isChatAtBottom: boolean = true;
  isLoadingChat: boolean = false;
  questions = chatbotQuestions;
  inputOnFocus: boolean = false;

  constructor(
    private chatbotService: ChatbotService,
    private authService: AuthenticationService,
    public alertService: AlertService,
  ) {}

  ngOnInit(): void {

    this.loggedUser = this.authService.getLoggedInUser();
    this.chatbotService.chatStateSub().subscribe((chatState) => {
      // If there is a chat id, get the chat messages to load conversation (it means the chat was minimized with a chat open)
      if (chatState.chat_id) {
        this.isLoadingChat = true;
        this.chatbotService
          .getChatMessagesByChatId(chatState.chat_id)
          .subscribe((chatMessages) => {
            this.chatMessages = chatMessages.map((message: { response: string; }) => { 
              return {
                ...message,
                response: message.response.replace(/\n/g, '<br />')
              }
            })
            console.log(this.chatMessages);
            
            this.isLoadingChat = false;
            setTimeout(() => {
              this.scrollToBottom();
            }, 50);
          });
      }

      this.curretChatId = chatState.chat_id;
    });

    this.chatbotService.chatHistoryStateSub().subscribe((chatHistoryState) => {
      // Keep the history tab open if it was open before minimizing the chat
      this.showChatHistory = chatHistoryState;
    });
  }

  // ngAfterViewInit(): void {
  //   this.chatContainerElement.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  // }

  scrollToBottom(): void {
    try {
      this.chatContainerElement.nativeElement.scrollTop =
        this.chatContainerElement.nativeElement.scrollHeight;
    } catch (err) {}
  }

  sendMessage(sampleQuestion ='') { // Sample question is when user clicks one of the predefined questions
    this.isWaitingForResponse = true;
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);

    this.inputText = sampleQuestion.length > 0 ? sampleQuestion : this.inputText.trim();

    if (sampleQuestion.length === 0 && this.inputText.length === 0) {
      this.isWaitingForResponse = false;
      return;
    }

    // If there is no chat, create a new one and send the message
    if (this.chatMessages.length === 0) {
      this.createNewChat().subscribe((res) => {
        const newMessage = new ChatMessage(
          this.loggedUser.id,
          res.chat_id,
          this.inputText
        );
        this.chatbotService.setChatState({
          chatId: res.chat_id,
          title: this.inputText,
        });
        this.curretChatId = res.chat_id;
        // Add the new message to the chat messages array (without the answer yet), so it can be displayed in the chat
        this.chatMessages.push(newMessage);

        this.chatbotService.sendMessage(newMessage).subscribe((res) => {
          if (this.isWaitingForResponse) {
            res.response = res.response.replace(/\n/g, '<br />');
            this.chatMessages[this.chatMessages.length - 1].response =
              res.response; // this adds the response to the last message in the array, so the answer can be displayed in the chat
          }
          this.isWaitingForResponse = false;
        });

        this.inputText = '';
      });
    }
    // If there is a chat, send the message using the chat id of the first message
    else {
      const newMessage = new ChatMessage(
        this.loggedUser.id,
        this.curretChatId,
        this.inputText
      );
      this.chatMessages.push(newMessage);
      this.chatbotService.sendMessage(newMessage).subscribe((res) => {
        if (this.isWaitingForResponse) {
          res.response = res.response.replace(/\n/g, '<br />');
          this.chatMessages[this.chatMessages.length - 1].response =
            res.response; // this adds the response to the last message in the array, so the answer can be displayed in the chat
        }
        this.isWaitingForResponse = false;
      });
      this.inputText = '';
    }
  }

  createNewChat(): Observable<any> {
    this.chatMessages = [];
    return this.chatbotService.createNewChat();
  }

  emptyChat() {
    this.chatMessages = [];
    this.chatbotService.setChatState({ chat_id: '' });
  }

  closeChatView() {
    this.closeChat.emit();
  }

  toggleChatHistory() {
    this.showChatHistory = !this.showChatHistory;
    this.chatbotService.setChatHistoryState(this.showChatHistory);
  }

  stopGeneratingResponse() {
    this.isWaitingForResponse = false;
  }

  likeMessage(message: ChatMessage) {
    if (message.like == null || message.like == false) {
      message.like = true;
    } else {
      message.like = "";
    }
    this.chatbotService
      .likeOrDislikeMessage(this.curretChatId, message.message_id, message.like)
      .subscribe((res) => {
        console.log(res);
      });
}

  disLikeMessage(message: ChatMessage) {
    if (message.like === false) {
      message.like = "";  //
    } else {
      message.like = false;
    }
    this.chatbotService
      .likeOrDislikeMessage(this.curretChatId, message.message_id, message.like)
      .subscribe((res) => {
        console.log(res);
      });
  }

  toggleFlag(message: ChatMessage) {
    message.flag = !message.flag;
    this.chatbotService
      .flagMessage(this.curretChatId, message.message_id, message.flag)
      .subscribe((res) => {
        console.log(res);
      });
  }

  async copyToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  handleCopyToClipboard(content: string): void {
    this.copyToClipboard(content)
      .then(() => {
        const successMessage = 'chatbot.clipboardConfirmation';
        this.alertService.success(successMessage);
      })
      .catch((error) => {
        console.error('Failed to copy: ', error);

        const errorMessage = 'chatbot.clipboardConfirmation';
        const keepAlertOnNavigate = false;
        this.alertService.error(errorMessage, keepAlertOnNavigate);
      });
  }
}
