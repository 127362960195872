import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { AlertService } from './alert.service';
// import { AuthenticationService } from '../security/authentication.service';
import { Router } from '@angular/router';
import { throwError, of, Observable, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MockService } from '../mock-service/mock.service';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../alert-service/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private url: string = `${environment.apiUrl}${environment.apiVersion}`;
  private withCredentials = false;
  //alertService: any;

  constructor(
    public http: HttpClient,
    public alertService: AlertService,
    // public authService: AuthenticationService,
    public router: Router,
    // public mockService: MockService
  ) { }

  private getCookie(name: string) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    return parts.length === 2 ? parts.pop()?.split(';').shift() : '';
  }

  private createheaders(contentType: boolean | string = true, loginHeader?: string) {
    const headersObj: any = {
      // 'X-CSRFToken': this.getCookie('csrftoken'),
      // 'Ocp-Apim-Subscription-Key': environment.api_manager_token,
      // [environment.tokenHeader]: this.authService.getToken(),
      // [environment.tokenHeaderApi]: environment.tokenApiKey
    };

    if (contentType) {
      headersObj['Content-Type'] =
        typeof contentType === 'string' ? contentType : 'application/json';
    }
    // if (this.authService.getIdTenantHeader() !== undefined) {
    //   headersObj['x-id-tenant'] = this.authService.getIdTenantHeader();
    // } else {
    //   headersObj['x-name-tenant'] = loginHeader;
    // }
    return new HttpHeaders(headersObj);
  }

  private getQueryString(params: any) {
    if (typeof params === 'undefined' || typeof params !== 'object') {
      params = {};
      return params;
    }

    let query = '?';
    let index = 0;

    for (const i in params) {
      index++;
      const param = i;
      const value = params[i];
      query += index === 1 ? param + '=' + value : '&' + param + '=' + value;
    }
    return query;
  }

  public get<T>(
    slug: string,
    paramsServer: any,
    message: string,
    contentType: boolean | string = true,
    requestParams = {},
    loginHeader = '',
    showError: boolean | boolean = true
  ): Observable<T> {
    // if (environment.mock) {
    //   return this.mockService.get<T>(slug, paramsServer);
    // } else {
    let finalUrl = this.url + slug;
    if (paramsServer !== '') {
      finalUrl = finalUrl + this.getQueryString(paramsServer);
    }
    if (slug !== 'login/') {
      // TODO: This should be done on an interceptor, is repeated for every method
      // this.authService.isValidToken();
    }
    return this.http
      .get<T>(finalUrl, {
        headers: this.createheaders(contentType, loginHeader),
        withCredentials: this.withCredentials,
        ...requestParams
      }).pipe(
        map(res => {
          if (message) {
            // TODO: This should be done on an interceptor, is repeated for every method
            console.log('get: ' +message);

           this.alertService.success(message);
          }

          return res;
        }),
        catchError(error => {
          if (showError) {
            const errorMessage = this.getErrorMessage(error);
            this.checkIfLogged(error);
            return this.handleError(error, errorMessage);
          } else {
            return throwError(error);
          }
        }),);
    // }
  }

  public post<T>(
    slug: string,
    data: T,
    message: string,
    contentType: boolean | string = true,
    requestParams = {},
    keepAlertOnNavigate: boolean = false
  ) {
    // if (environment.mock) {
    //   return this.mockService.post(message);
    // } else {
    const finalUrl = this.url + slug + '/';
    if (slug !== 'login') {
      // TODO: This should be done on an interceptor, is repeated for every method
      // this.authService.isValidToken();
    }
    return this.http
      .post(finalUrl, data, {
        headers: this.createheaders(contentType),
        withCredentials: false,
        ...requestParams
      }).pipe(
        map(res => {
          const obj = this.parseData(res);
          if (message) {
            // TODO: This should be done on an interceptor, is repeated for every method
            console.log('post: ' +message);
            this.alertService.success(message, keepAlertOnNavigate);
          }
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error);
          return this.handleError(error, errorMessage);
        },));
    // }
  }

  private checkIfLogged(error: any) {
    if (error.status === 401) {
      // TODO: This should be done on an interceptor, is repeated for every method
      // this.alertService.error(
      //   'Your session has expired, please log in again',
      //   false
      // );
      this.router.navigate(['/']);
    }
  }

  public put<T>(
    slug: string,
    id: string,
    data: T,
    message: string,
    contentType: boolean | string = true,
    requestParams = {},
    keepAlertOnNavigate: boolean = false
  ) {
    // if (environment.mock) {
    //   return this.mockService.put(message);
    // } else {
    const finalUrl = this.url + slug + id + '/';
    if (slug !== 'login') {
      // TODO: This should be done on an interceptor, is repeated for every method
      // this.authService.isValidToken();
    }

    return this.http
      .patch(finalUrl, data, {
        headers: this.createheaders(contentType),
        withCredentials: false,
        ...requestParams
      }).pipe(
        map(res => {
          const obj = this.parseData(res);
          // TODO: This should be done on an interceptor, is repeated for every method
          console.log('put: ' +message);
          this.alertService.success(message, keepAlertOnNavigate);
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error);
          return this.handleError(error, errorMessage);
        }),);
    // }
  }

  public patch<T>(
    slug: string,
    id: string,
    data: T,
    message: string,
    contentType: boolean | string = true,
    requestParams = {}
  ) {
    // if (environment.mock) {
    //   return this.mockService.put(message);
    // } else {
    const finalUrl = this.url + slug + id + '/';
    if (slug !== 'login') {
      // TODO: This should be done on an interceptor, is repeated for every method
      // this.authService.isValidToken();
    }

    return this.http
      .patch(finalUrl, data, {
        headers: this.createheaders(contentType),
        withCredentials: false,
        ...requestParams
      }).pipe(
        map(res => {
          const obj = this.parseData(res);
          console.log('patch: ' +message)
          // TODO: This should be done on an interceptor, is repeated for every method
          this.alertService.success(message, false);
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error);
          return this.handleError(error, errorMessage);
        }),);
    // }
  }

  public delete(
    slug: string,
    id: string,
    message: string,
    contentType: boolean | string = true,
    requestParams = {}
  ) {
    const finalUrl = this.url + slug + id + '/';
    if (slug !== 'login') {
      // TODO: This should be done on an interceptor, is repeated for every method
      //this.authService.isValidToken();
    }

    return this.http
      .delete(finalUrl, {
        headers: this.createheaders(contentType),
        withCredentials: false,
        ...requestParams
      }).pipe(
        map(res => {
          const obj = this.parseData(res);

          if (message) {
            // TODO: This should be done on an interceptor, is repeated for every method
            console.log('delete: ' +message);
           this.alertService.success(message, false);
          }

          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error);
          return this.handleError(error, errorMessage);
        }));
  }


  public uploadFile<T>(
    slug: string,
    data: T,
    contentType: boolean | string = true,
    requestParams = {}
  ) {
    const finalUrl = this.url + slug;
    if (slug !== 'login') {
      // TODO: This should be done on an interceptor, is repeated for every method
      // this.authService.isValidToken();
    }

    return this.http
      .post(finalUrl, data, {
        headers: this.createheaders(contentType),
        withCredentials: false,
        ...requestParams
      }).pipe(
        map(res => {
          const obj = this.parseData(res);
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error);
          return this.handleError(error, errorMessage);
        }));
  }

  public downloadFile<T>(
    slug: string,
    data: T
  ) {
    // if (environment.mock) {
    //   return this.mockService.post(message);
    // } else {
    const finalUrl = this.url + slug + '/';

    return this.http
      .post(finalUrl, data, {
        responseType: 'blob',
        observe: 'response'
      }).pipe(
        map(res => {
          console.log(res)
          return res;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error);
          return this.handleError(error, errorMessage);
        },));
    // }
  }

  public forkJoin(
    httpArray: Array<Observable<any>>,
    successMsg: string,
    failureMsg: string,
    callback?: any
  ) {
    return forkJoin(
      httpArray.map(httpRequest =>
        httpRequest.pipe(catchError(() => of(undefined)))
      )
    ).subscribe(responses => {
      const failures = responses.filter(response => response === undefined);
      // TODO: This should be unsubscribe in some point
      // TODO: This should be done on an interceptor, is repeated for every method
      // if (failures.length > 0) {
      //   this.alertService.error(failureMsg, false);
      // } else {
      //   this.alertService.success(successMsg, false);
      // }
    });
  }

  private parseData(res: any) {
    return res;
  }

  private getErrorMessage(error: any) {
    if (typeof error.error !== 'object') {
      return error.error;
    } else {
      let m = '';
      for (const key in error.error) {
        m = m + error.error[key] + '.';
      }
      if (m !== '') {
        return m;
      }
    }
    if (error.message) {
      return error.message;
    }
    return error.toString();
  }

  private handleError(error: any, errorMessage: string) {
    // this.alertService.error(errorMessage, false);
    return throwError(error);
  }
}
