import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert-service/alert.service';
import { ApiService } from '../api/api-service/api.service';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private alertService: AlertService
  ) { }
  private url: string = `${environment.apiUrl}${environment.apiVersion}`;

  createFormData(files: any[]) {
    const formData = new FormData();
    const descriptions: string[] = [];
    const maxFileSize = 20 * 1024 * 1024; // 20MB
    const allowedFormats = [".txt", ".doc", ".docx", ".pdf", ".csv", ".xlsx", ".png", ".jpg", ".jpeg", ".gif", ".bmp"];
    const errors: string[] = [];
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i].file;
      // Checks if the file has more than one extension
      const nameParts = file.name.split('.');
      const hasMultipleExtensions = nameParts.length > 2;
  
      if (!hasMultipleExtensions) {
        if (file.size <= maxFileSize) {
          // Verify if the file format is allowed
          const fileExtension = '.' + file.name.split('.').pop();
          if (allowedFormats.includes(fileExtension.toLowerCase())) {
            formData.append('files', file, files[i].name);
            descriptions.push(files[i].description);
          } else {
            // Manage the case where the file format is not allowed
            const message = `File format "${fileExtension}" is not allowed for "${file.name}".`;
            errors.push(message);
          }
        } else {
          // Manage the case where the file exceeds the maximum allowed size
          const message = `File "${file.name}" exceeds the maximum allowed size (20MB).`;
          errors.push(message);
        }
      } else {
        // Manage the case where the file has multiple extensions
        const message = `File "${file.name}" is not allowed because it has multiple extensions.`;
        errors.push(message);
      }
    }
    formData.append('descriptions', JSON.stringify(descriptions));
  
    return { formData, errors };
  }

  postFormData(endpoint: string, formData: FormData) {
    return this.http.post(`${this.url}${endpoint}`, formData)
  }

}
