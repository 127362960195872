import { Component, OnInit } from '@angular/core';
import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

@Component({
  selector: 'app-apex-pie-chart',
  templateUrl: './apex-pie-chart.component.html',
  styleUrls: ['./apex-pie-chart.component.scss'],
})
export class ApexPieChartComponent extends ApexChartBase implements OnInit {
  constructor(chartsService: ChartsService, utilsService: UtilsService) {
    super(chartsService, utilsService);
    this.defaultHeight = 200;
  }

  protected setChartOptions(): void {
    this.chartOptions = {
      chart: {
        type: 'pie',
        fontFamily: this.fontFamily,
      },

      plotOptions: {
        pie: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 360,
          donut: {
            background: 'inherit',
          },
        },
      },

      legend: {
        show: true,
        labels: {
          colors: this.fontColor,
          useSeriesColors: false,
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],

      stroke: {
        width: 2,
        colors: this.colors,
      },

      fill: {
        opacity: 0.6,
        colors: this.colors,
      },

      dataLabels: {
        style: {
          fontFamily: this.fontFamily,
          fontWeight: 400,
        },
        dropShadow: {
          enabled: false,
        },
      },
    };
  }
}
