import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from '../../../models/close-reason.model';
import { ApiService } from 'src/app/services/api/api-service/api.service';
import { TokenBlackList } from 'src/app/models/tokenBlacklist.model';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutModalComponent implements OnInit {
  public reason: Reason | any;
  public body: string = '';
  public option: string = '';
  constructor(public activeModal: NgbActiveModal, public apiService: ApiService, public authService: AuthenticationService) {}

  ngOnInit() {
    this.body = 'components.modal.delete.';
  }

  onConfirm(reason: Reason) {
    this.confirmLogout();
  }

  confirmLogout(){
    this.blackListToken();
    setTimeout(()=> {
      this.closeModal()
    }, 1000);
  }

  blackListToken(){
    let token = JSON.stringify(new TokenBlackList("Bearer "+this.authService.getToken()))
    this.apiService.post<string>("tokenBlackList", token, '').subscribe();
  }

  closeModal(){
    this.activeModal.close(1);
  }
}
