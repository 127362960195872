import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-additive-filters',
  templateUrl: './additive-filters.component.html',
  styleUrls: ['./additive-filters.component.scss']
})
export class AdditiveFiltersComponent implements OnInit{
  
  @Input() additiveFilters: any[] = [];
  @Input() forProviders: boolean = false;
  @Output() checkEmitter = new EventEmitter<any>();

  constructor() { }
  
  public ngOnInit(): void {
  }

  onCheck(event: any, filter: string){
    this.checkEmitter.emit({isAdding: event.checked, filter: filter});
  }

  getOfferingTypeColorClass(filterName: string): { [key: string]: boolean }{
    filterName = filterName.toLowerCase();

    return {
      'data': filterName === 'data',
      'software': filterName === 'software',
      'service': filterName === 'service',
      'physical-good': filterName === 'physical good',
      'financial-product': filterName === 'financial product',
      '': filterName === '',
      // to add the class for provider specific name shortening
      'prov': this.forProviders === true
    };
  }

}
