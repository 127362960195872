import { Component, OnInit } from '@angular/core';

import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
import { DurationPipe } from 'src/app/pipes/duration.pipe';
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

@Component({
  selector: 'app-apex-stacked-bar-chart',
  templateUrl: './apex-stacked-bar-chart.component.html',
  styleUrls: ['./apex-stacked-bar-chart.component.scss'],
})
export class ApexStackedBarChartComponent
  extends ApexChartBase
  implements OnInit
{
  constructor(
    private duration: DurationPipe,
    chartsService: ChartsService,
    utilsService: UtilsService
  ) {
    super(chartsService, utilsService);
    this.defaultWidth = 400;
    this.defaultHeight = 350;
  }

  protected setChartOptions(): void {
    this.chartOptions = {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false,
        },
        fontFamily: this.fontFamily,
      },

      plotOptions: {
        bar: {
          horizontal: true,
        },
      },

      stroke: {
        width: 2,
        colors: this.colors,
      },

      tooltip: {
        theme: 'dark',
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: this.duration.transform,
        },
      },

      fill: {
        opacity: 0.6,
      },

      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 0,
        fontSize: '10px',
        labels: {
          colors: this.fontColor,
          useSeriesColors: false,
        },
        itemMargin: {
          horizontal: 10,
          vertical: 5,
        },
      },

      xaxis: {
        categories: this.labels,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },

      yaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },

      grid: {
        show: false,
      },

      dataLabels: {
        style: {
          fontFamily: this.fontFamily,
          fontWeight: 400,
        },
      },
    };
  }
}
