import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RoleModel } from 'src/app/models/role.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { ApiService } from 'src/app/services/api/api-service/api.service';
import { SubmitFilterModalComponent } from './modal/submit-filter/submit-filter-modal.component';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { LicenseModel } from 'src/app/models/license.model';

interface DropdownSection {
  label: string;
  options: { id?: string; name: string }[];
}

@Component({
  selector: 'app-validations-filter',
  templateUrl: './validations-filter.component.html',
  styleUrls: ['./validations-filter.component.scss'],
})
export class ValidationsFilterComponent implements OnInit {
  @Output() onSearch = new EventEmitter();
  @Output() paginationTypeChange = new EventEmitter<string>();

  @Input() groups: any[] = [];
  @Input() statusList: any[] = [];
  @Input() rolesList: RoleModel[] = [];
  @Input() licensesList: LicenseModel[] = [];
  @Input() paginationType = 'users';
  @Input() hasSelectedItems: boolean = false;
  @Input() settings: { [keyof: string]: any } = {};
  @Input() fields: any[] = [];
  @Input() key: string = ''; //key to access the current key in the dictionary
  @Input() dropdownData: DropdownSection[] = [];
  @Input() filtersTitle: string = "";

  /**Enrichment files list and filter related variables */
  subscriptionEnrichmentTypes: Subscription = new Subscription();
  rows: { [keyof: string]: any } = {};
  showWarning: boolean = false;
  type: number = 0;
  loadingTable: boolean;

  /* Filter variables */
  filters: { [keyof: string]: any } = {};

  myForm: FormGroup = this.formBuilder.group({});

  protected validationPaginations: string[] = [
    'documentvalidationsassigned',
    'documentvalidations'
  ];

  public selectFilter: UntypedFormControl = new UntypedFormControl('1');

  public _userPermissions: any;
  constructor(
    protected global: GlobalService,
    protected modalService: NgbModal,
    protected apiService: ApiService,
    protected translate: TranslatePipe,
    protected formBuilder: FormBuilder
  ) {
    this.loadingTable = true;
  }

  /* Individual field clearing (small 'X') */
  checkReset(fieldName: string) {
    const control = this.myForm.controls[fieldName];
    return control?.value ? control.value.length > 0 : false;
  }
  resetField(fieldName: string) {
    this.myForm.controls[fieldName].setValue("");
    this.emitSearch();
  }

  ngOnInit() {
    for (let field of this.fields) {
      if(field.type === 'datepicker-range'){
        this.myForm.addControl(field.name + 'Start', this.formBuilder.control(''));
        this.myForm.addControl(field.name + 'End', this.formBuilder.control(''));
      } else if (field.type === 'numericpicker-range'){
        this.myForm.addControl(field.name + 'Min', this.formBuilder.control(''));
        this.myForm.addControl(field.name + 'Max', this.formBuilder.control(''));
      } else {
        this.myForm.addControl(field.name, this.formBuilder.control(''));
      }
    }
  }

  /**
   * Send filter parameters
   */
  public sendParamFilter() {
    if (this.hasSelectedItems) {
      this.modalService
        .open(SubmitFilterModalComponent, {})
        .result.then(result => {
          if (result === 'Agree') {
            this.sendParams();
          }
        });
    } else {
      this.sendParams();
    }
  }

  protected sendParams() {
    this.emitSearch();
  }

  /**
   * Clean filters and search results
   */
  public cleanFilters() {
    this.myForm.reset();
    this.emitSearch(true);
  }

  /**
   * Emit search event to parent component
   */
  protected emitSearch(reset?: boolean) {
    const searchObject: any = {};

    for (let field of this.fields) {
      if(field.type === 'datepicker-range'){
        if(this.myForm?.value[field.name + 'Start'] && this.myForm?.value[field.name + 'End']){
          const startDateString = `${this.myForm?.value[field.name + 'Start']?.getFullYear()}-${String(this.myForm?.value[field.name + 'Start']?.getMonth() + 1).padStart(2, '0')}-${String(this.myForm?.value[field.name + 'Start']?.getDate()).padStart(2, '0')}T00:00:00.000Z`;
          const endDateString = `${this.myForm?.value[field.name + 'End']?.getFullYear()}-${String(this.myForm?.value[field.name + 'End']?.getMonth() + 1).padStart(2, '0')}-${String(this.myForm?.value[field.name + 'End']?.getDate()).padStart(2, '0')}T23:59:59.999Z`;
          searchObject[field.name] = `${startDateString} - ${endDateString}`;
        }else {
          searchObject[field.name] = null
        }
      } else if (field.type === 'numericpicker-range'){
        if(this.myForm?.value[field.name + 'Min'] || this.myForm?.value[field.name + 'Max']){
          const minRange = this.myForm?.value[field.name + 'Min'] || 0;
          const maxRange = this.myForm?.value[field.name + 'Max'] || 100;
          searchObject[field.name] = `${minRange} - ${maxRange}`;
        }else {
          // in case it does not exists set it to null
          searchObject[field.name] = null
        }
      } else{
        searchObject[field.name] = this.myForm?.value[field.name];
      }
    }
    this.onSearch.emit(searchObject);
    this.paginationTypeChange.emit(this.paginationType);
  }

  crossValidationForRanges(field: any): boolean {
    return this.myForm.controls[field.name + 'Max'].value && this.myForm.controls[field.name + 'Min'].value > this.myForm.controls[field.name + 'Max'].value;
  }

  noValidMinRange(field: any): boolean {
    return this.myForm.controls[field.name + 'Min']?.hasError('min') || this.myForm.controls[field.name + 'Min']?.hasError('max');
  }

  noValidMaxRange(field: any): boolean {
    return this.myForm.controls[field.name + 'Max']?.hasError('min') || this.myForm.controls[field.name + 'Max']?.hasError('max');
  }
}
