<form [formGroup]="myForm">
    <div class="modal__body">
        <div class="modal__row modal__row--flex">
            <mat-form-field> 
                <mat-label>{{ 'components.modal.provider.name' | translate : 'Player Name' }}</mat-label>
                <input 
                  matInput 
                  formControlName="playerName" 
                  id="playerName" 
                  name="playerName" 
                  required
                />
            </mat-form-field>
            <mat-form-field> 
                <mat-label>{{ 'components.modal.provider.providerResponsible' | translate : 'Player Responsible Email' }}</mat-label>
                <input 
                  matInput 
                  formControlName="providerResponsible" 
                  id="providerResponsible"
                  name="providerResponsible" 
                  email
                />
                <mat-hint
                class="hint"
                *ngIf="myForm.controls['providerResponsible'].hasError('invalidEmail') && myForm.controls['providerResponsible'].dirty">
                  {{'components.modal.provider.warningEmail' | translate : "Warning: Warning: example@example.com"}} 
                  <mat-icon class="warn-icon">warning</mat-icon>
                </mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'EY Responsible'}}</mat-label>
              <input
                (click)="fill()"
                type="text"
                matInput
                formControlName="eyResponsibleName"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="mapEYResponsibleId()">
                <mat-option
                  *ngFor="let eyResponsible of filteredEyResponsible | async"
                  [value]="eyResponsible"
                >
                  {{ eyResponsible }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint
                class="hint"
                matTooltip="You're about to give edit permissions to an EY Contributor for this player"
                *ngIf="showWarning">
                  {{'components.modal.provider.warningRestricted' | translate : "Warning: This user is an EY Contributor"}} 
                  <mat-icon class="warn-icon">warning</mat-icon>
                </mat-hint>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="mat-form-textarea"> 
            <mat-label>{{ 'components.modal.provider.playerDescription' | translate : 'Player Description' }}</mat-label>
            <textarea 
                matInput 
                formControlName="playerDescription" 
                id="playerDescription" 
                name="playerDescription" 
                required>
            </textarea>
            </mat-form-field>
        </div>
    </div>
</form>