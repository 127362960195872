<form *ngIf="myForm" [formGroup]="myForm">
  <div class="modal__body">
    <div class="modal__row modal__row--flex">
      <mat-form-field>
        <mat-label>{{ 'components.modal.form.product.dataset' | translate : 'Data Set Name' }}</mat-label>
        <mat-select formControlName="availableDatasets" id="availableDatasets" name="availableDatasets">
          <mat-option *ngFor="let set of availableDatasets" [value]="set">
            {{ set }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
