<!-- TODO: Work on carousel side motion -->
<div class="summary-info-attachment">
  <!-- <div class="item" *ngFor="let attachment of files">
    <mat-form-field *ngIf="attachment.showDescriptionInput">
      <mat-label>{{ 'Description' }}</mat-label>
      <input [(ngModel)]="attachment.description" matInput type="text" />
    </mat-form-field>
  </div> -->
  <span class="item"
    >Selected {{ selectedFiles.length }} elements out of
    {{ files.length }}</span
  >
  <div *ngIf="withActionButtons" class="item general-action-btn">
    Select all
  </div>
  <div *ngIf="withActionButtons" class="item general-action-btn">
    Deselect all
  </div>
  <div *ngIf="withActionButtons" class="item general-action-btn remove">
    Remove Selected ({{ selectedFiles.length }})
  </div>
  <div *ngIf="withActionButtons" class="item general-action-btn download">
    Download Selected ({{ selectedFiles.length }})
  </div>
</div>
<div class="attachments" [ngClass]="{ center: files.length == 0 }">
  <ng-container *ngFor="let attachment of files">
    <app-file
      imageSource="assets/img/icons/document.svg"
      [fileName]="attachment?.name"
      (isSelected)="updateSelected(attachment, $event)"
    ></app-file>
    <!-- (mouseenter)="attachment.showDescriptionInput = true"
      (mouseleave)="attachment.showDescriptionInput = false" -->
    <mat-form-field *ngIf="attachment.showDescriptionInput">
      <mat-label>{{ 'Description' }}</mat-label>
      <input [(ngModel)]="attachment.description" matInput type="text" />
    </mat-form-field>
  </ng-container>

  <div *ngIf="files.length == 0">No Files associated</div>
</div>
