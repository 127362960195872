import { DurationPipe } from './duration.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { DateformatterPipe } from './dateformatter.pipe';
import { MomentPipe } from './moment.pipe';
import { HighlightTextPipe } from './highlight-text.pipe';
import { TrimDecimalPipe } from './decimal.pipe';
import { SecureImagePipe } from './secure-image.pipe';
import { TranslatePipe } from './translate-pipe/translate.pipe';
import { FormatFieldValuePipe } from './format-field-value.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FilterPipe,
    DateformatterPipe,
    MomentPipe,
    HighlightTextPipe,
    TrimDecimalPipe,
    SecureImagePipe, // <-------------
    TranslatePipe,
    DurationPipe,
    FormatFieldValuePipe,
  ],
  exports: [
    FilterPipe,
    DateformatterPipe,
    MomentPipe,
    HighlightTextPipe,
    TrimDecimalPipe,
    SecureImagePipe, // <-------------
    TranslatePipe,
    DurationPipe,
    FormatFieldValuePipe,
  ],
})
export class PipeModule { }
