import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './has-permission.directive';
import { NotHasPermissionDirective } from './not-has-permission.directive';
import { ResizableDirective } from './resizable.directive'
// import { EventTrackerDirective } from './event-tracker.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    HasPermissionDirective,
    NotHasPermissionDirective,
    ResizableDirective
    // EventTrackerDirective
  ],
  exports: [
    HasPermissionDirective,
    NotHasPermissionDirective,
    ResizableDirective
    // EventTrackerDirective
  ]
})
export class DirectivesModule { }
