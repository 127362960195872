import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/services/api/pagination-service/pagination.service';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { GenerateCrudModalFormService } from 'src/app/services/generate-crud-modal-form/generate-crud-modal-form.service';
import { UserService } from 'src/app/services/user-service/user.service';
import { ColumnBuilderService } from '../responsive-table/column-builder/column-builder.service';
import { PageModel } from 'src/app/services/api/pagination-service/model/page.model';
import { PaginationModel } from 'src/app/services/api/pagination-service/model/pagination.model';
import { SettingTableType } from '../responsive-table/model/setting-table.model';
import { HistoricProductValidation } from 'src/app/models/historic-product-validation.model';
import { tap } from 'rxjs/operators';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { HistoricProductValidationService } from 'src/app/services/historic-product-validation-service/historic-product-validation.service';
import { DataMasterService } from 'src/app/services/data-master-service/data-master';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-historical-display-product',
  templateUrl: './historical-display-product.component.html',
  styleUrls: ['./historical-display-product.component.scss']
})
export class HistoricalDisplayProductComponent implements OnInit{

  @Input() productId:any;

  totalItems = 0;
  rows?: PageModel<HistoricProductValidation>;
  pagination?: PaginationModel;
  paginationType: string = 'product';  
  loading = true;
  backgroundLoading = true;
  rolesList: any[] = [];

  productTableRows?: PageModel<HistoricProductValidation>;
  productPaginationConfiguration = PaginationModel.createDefaultConfiguration('lastValidatedAt', 1, 10,1);
  productTableColumnConfiguration: { header: string; name: keyof HistoricProductValidation; class: string; orderBy?: string | undefined; }[] = [];
  settingTable = new SettingTableType<HistoricProductValidation>();
  fields: [ { name: keyof HistoricProductValidation, type: string, data?: any} ] | any;
  filters: any = [];
 
  constructor(
    public columnBuilder: ColumnBuilderService, 
    protected modalService: NgbModal,
    public modalCreator: GenerateCrudModalFormService,
    public historicProdService: HistoricProductValidationService,
    public userService: UserService,
    private paginationService: PaginationService,
    public alertService: AlertService,
    private translate: TranslatePipe,
    public dataMasterService: DataMasterService,
  ) {}

  ngOnInit(): void {
    this.pagination = this.paginationService.getDefaultPagination('products');
    this.fillFields();
    this.setProductTableColumConfiguration();
    this.setSettingsTable();
    if(this.productId){
      this.getProducts();
    }
  }

  setProductTableColumConfiguration() {
    const confColumns = this.columnBuilder.configureColumns<HistoricProductValidation>(HistoricProductValidation, HistoricProductValidation.getEmptyHistoricProductValidation());
    this.productTableColumnConfiguration = this.columnBuilder.getTableColumnConfiguration<HistoricProductValidation>(confColumns,
      [],
      ['username', 'lastValidatedAt', 'role', 'comment']
    )
    for (const col of this.productTableColumnConfiguration) {
      const customCol = col as any;
      // Customize product name column
      switch(col.name){
        case 'username':
          customCol.header = this.translate.transform('username', 'User Name');
          customCol.name = 'username';
          break;
        case 'role':
          customCol.header = this.translate.transform('role', 'Role and Permissions');
          break;
        case 'lastValidatedAt':
          customCol.type = 'date';
          customCol.format = 'lll'
          break;
        case 'comment':
          customCol.type = 'long-text';
          break;
      }

      // Add translation to headers
      col.header = `page.admin.table.validate.column.${col.header}`;
    }
  }

  refresh(): void {
    this.getProducts();
  }

  setSettingsTable() {
    this.settingTable.dataId = 'id';
    this.settingTable.hasSelect = false;
    this.settingTable.getDataFromDatabase = this.getProducts.bind(this);    
    this.settingTable.responsiveTitle.label = 'username';
  }
  
  private setParams() {
    this.productPaginationConfiguration.desc = 1;
    this.productPaginationConfiguration.orderBy = 'lastValidatedAt';
    this.productPaginationConfiguration.page = 1;
  }

  getProducts(params: { [keyof: string]: any } = {}) {
    this.getDataFromDatabase(this.productPaginationConfiguration);
  }

  private getDataFromDatabase(params: any) {
    if (!params){
      params = this.setParams();
    }
    const getProducts$ = this.historicProdService.getProductValidationData$(this.productId, { ...this.productPaginationConfiguration, ...params });
    const assignProductsToTable$ = getProducts$.pipe(
      tap(data => {
        data.data.forEach((p: HistoricProductValidation, index: number) => {
          p['username'] = p.lastValidatedBy.username;
          p['role'] = p.lastValidatedBy.role;
        });
        this.productTableRows = data;
        this.totalItems = data.totalElements;
    }));

    const assignToPagination$ = assignProductsToTable$.pipe(
      tap(data => {
        this.paginationService.setPage<HistoricProductValidation>('products', data);
        if(this.paginationService.getPage('products').orderBy === null || this.paginationService.getPage('products').orderBy === undefined) {
          // even when we are seeing that big pageSize here, it must be changed in the corresponding .srv file in api
          const paginationObj = PaginationModel.createDefaultConfiguration('lastValidatedAt', 1, 9999, this.productPaginationConfiguration.page);
          this.pagination = this.paginationService.setPagination('products', paginationObj);
        }
        
        this.loading = false;
        this.backgroundLoading = false;
      })
    ).subscribe();
  }
  
  public onLoadingChange(isLoading: boolean) {
    this.loading = isLoading;
  }

  onSelected($event: Event) {}

  selectedItems: Product[] = [];
 
  onSelectedItems(selectedItems: Product[]) {
    this.selectedItems = selectedItems;
  }
  
  showFilters: boolean = false;

  /**
  * Variable to show the filter panel or not
  */
  public toggleShowFilters(event: MouseEvent) {
    this.showFilters = !this.showFilters;
  }
  
  /**
   * When pagination is going to start a change
   * @param pageToChange: page to change
   * @param resetSelected: maintain or not the current selection
  */
  onTablePageChange([pageToChange, resetSelected]: [number, boolean]) {
    this.productPaginationConfiguration.page = pageToChange;
  }
  /**
   * When click on sort a field
   * @param orderBy: fieldName that is going to be orderBy
   * @param resetSelected:
   */
  onTableSort([orderBy, resetSelected]: [string, boolean]) {
    this.productPaginationConfiguration.orderBy = orderBy;
    this.productPaginationConfiguration.page = 1;
    this.productPaginationConfiguration.desc = this.productPaginationConfiguration.desc === 0 ? 1 : 0;
  }

  public onSearch(filters: any) {
    this.filters = {
      username:
        filters['username'] !== undefined && 
        filters['username'] !== null ? 
        filters['username'] : 
        '',
      role: 
        filters['role'] !== undefined && 
        filters['role'] !== null ? 
        filters['role'] : 
        '',
      lastValidatedAt: 
        filters['lastValidatedAt'] !== undefined && 
        filters['lastValidatedAt'] !== null ? 
        filters['lastValidatedAt'] : 
        '',
      comment: 
        filters['comment'] !== undefined && 
        filters['comment'] !== null ? 
        filters['comment'] : 
        ''
    };
    let filterConfig = {...this.productPaginationConfiguration, ...this.filters}
    this.getDataFromDatabase(filterConfig);
  }

  fillFields(){
    this.rolesList = this.dataMasterService.getRoles();
    this.rolesList.forEach(r => delete r['id']); // we delete it so it does not take the id as value for this dropdown options
    this.fields = [ 
      { name: "username", type: "input" }, 
      { name: "role", type: "dropdown", data: this.rolesList }, 
      { name: "lastValidatedAt", type: "datepicker-range", placeholder: "Date of Validation" },
      { name: "comment", type: "input"}
    ]
  }

}
