import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import * as questionnaireConstants from 'src/app/constants/questionnaireConstants';
import * as userPermissionsConstants from 'src/app/constants/userPermissions';

import { ClientModel } from 'src/app/models/client.model';
import { DomainModel } from 'src/app/models/domain.model';
import { RoleModel } from 'src/app/models/role.model';
import { UserModel } from 'src/app/models/user.model';
import { UtilsService } from '../utils-service/utils.service';
// import { UtilsService } from '../utils.service';
// import { ApplicationModel } from '../models/application.model';
// import { DashboardModel } from '../models/dashboard.model';

/**
 * Global data.
 */
export class GlobalData {
  roles: RoleModel[] = [];
  domains: DomainModel[] = [];
  clients: ClientModel[] = [];
  // internalApplications: ApplicationModel[];
  // dashboards: DashboardModel[];
}

/**
 * Global data service.
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private getGlobalData$: Observable<GlobalData>;

  constructor(private http: HttpClient, private utils: UtilsService) {
    // create global data observable

    const globalDataUrl = this.utils.getApiUrl('global');
    this.getGlobalData$ = this.http
      .get<GlobalData>(globalDataUrl)
      .pipe(shareReplay());
  }

  // /**
  //  * Get user dashboards
  //  */
  // getDashboards(): Observable<DashboardModel[]> {
  //   return this.getGlobalData$.pipe(
  //     map<GlobalData, DashboardModel[]>((data: GlobalData) => data.dashboards)
  //   );
  // }

  /**
   * Get user roles.
   */
  getRoles(): Observable<RoleModel[]> {
    return this.getGlobalData$.pipe(
      map<GlobalData, RoleModel[]>((data: GlobalData) => data.roles)
    );
  }

  /**
   * Get domains.
   */
  getDomains(): Observable<DomainModel[]> {
    return this.getGlobalData$.pipe(
      map<GlobalData, DomainModel[]>((data: GlobalData) => data.domains)
    );
  }

  // /**
  //  * Get domain by URL.
  //  */
  // getDomainByUrl(domainURLName: string): Observable<DomainModel> {
  //   return this.getDomains().pipe(
  //     map<DomainModel[], DomainModel>((domains: DomainModel[]) =>
  //       domains.find(
  //         (domain: DomainModel) => domain.domainURLName == domainURLName
  //       )
  //     )
  //   );
  // }

  // /**
  //  * Get domains assigned to user.
  //  */
  // getUserDomains(user: UserModel): Observable<DomainModel[]> {
  //   return this.getDomains().pipe(
  //     map<DomainModel[], DomainModel[]>((domains: DomainModel[]) =>
  //       user?.domainIds && !user.admin
  //         ? domains.filter((domain: DomainModel) =>
  //           user.domainIds.includes(domain.id)
  //         )
  //         : domains
  //     )
  //   );
  // }

  // /**
  //  * Get domain by domain URL name.
  //  */
  // getDomainByUrlName(urlName: string): Observable<DomainModel> {
  //   return this.getDomains().pipe(
  //     map<DomainModel[], DomainModel>((domains: DomainModel[]) =>
  //       domains.find((domain: DomainModel) => domain.domainURLName == urlName)
  //     )
  //   );
  // }

  /**
   * Get clients.
   */
  getClients(): Observable<ClientModel[]> {
    return this.getGlobalData$.pipe(
      map<GlobalData, ClientModel[]>((data: GlobalData) => data.clients)
    );
  }

  // /**
  //  * Get internal applications.
  //  */
  // getInternalApplications(): Observable<ApplicationModel[]> {
  //   return this.getGlobalData$.pipe(
  //     map<GlobalData, ApplicationModel[]>(
  //       (data: GlobalData) => data.internalApplications
  //     )
  //   );
  // }

  // /**
  //  * Get internalApplications assigned to user
  //  */
  // getUserInternalApplications(user: UserModel): Observable<ApplicationModel[]> {
  //   return this.getInternalApplications().pipe(
  //     map<ApplicationModel[], ApplicationModel[]>(
  //       (internalApps: ApplicationModel[]) =>
  //         user.admin
  //           ? internalApps
  //           : internalApps.filter((app: ApplicationModel) =>
  //             user.internalApplicationIds.includes(app.id)
  //           )
  //     )
  //   );
  // }

  /**
   * Get questionnaire constants.
   */
  getQuestionnaireConstants() {
    return questionnaireConstants;
  }

  /**
   * Get user permissions constants.
   */
  public getUserPermissionsConstants(): any {
    return userPermissionsConstants;
  }
}
