import { Injectable } from '@angular/core';
import { LocaleModel } from 'src/app/models/locale.model';

export const STORAGE_KEY_PROJECTS = 'projects';
export type StorageKeys =
  | typeof STORAGE_KEY_PROJECTS
  | 'collections'
  | 'voicemodels'
  | 'voicemodelProject'
  | 'transcriptions'
  | 'phrases'
  | 'languagemodels'
  | 'voicemodelsDataset'
  | 'voicemodel'
  | 'agents';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private locales: LocaleModel[] = [];
  private items: any = {};
  constructor() {}

  /**
   * Stores an object by key.
   * @param key Object by key from enum.
   * @param item Object to store.
   */
  public setItem(key: StorageKeys, item: any) {
    this.items[key] = item;
  }

  /**
   * Gets an item by name.
   * @param key Name of the key to be casted.
   */
  public getItemByName(key: StorageKeys) {
    return this.items[key];
  }

  /**
   * Gets stored object by key.
   * @param key Key from StorageKey enum as a string or enum.
   * @returns undefined if object is not exits. Else the object.
   */
  public getItem(key: StorageKeys) {
    if (this.items && key) {
      return this.items[key];
    }
  }

  /**
   * Checks if an item is available in the service.
   * @param key
   */
  public checkItem(key: StorageKeys) {
    return this.items?.[key] != undefined;
  }

  public removeItem(key: StorageKeys) {
    if (this.items?.[key]) {
      delete this.items[key];
    }
  }

  public getLocales() {
    if (this.locales?.length > 0) {
      return this.locales;
    }

    return [];
  }

  public setLocales(locales: LocaleModel[]) {
    if (locales) {
      this.locales = locales;
    }
  }
}
