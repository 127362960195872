import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { AutoCompleteTreeSourceComponent } from './auto-complete-tree-source/auto-complete-tree-source.component';
import { NestedMenuOptionsComponent } from './nested-menu-options/nested-menu-options.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteSelectComponent } from './auto-complete-select/auto-complete-select.component';



@NgModule({
  declarations: [
    AutoCompleteTreeSourceComponent,
    NestedMenuOptionsComponent,
    AutoCompleteSelectComponent,
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatIconModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatFormFieldModule,

    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  exports: [
    AutoCompleteTreeSourceComponent,
    NestedMenuOptionsComponent,
    AutoCompleteSelectComponent,
  ]
})
export class AutocompleteModule { }
