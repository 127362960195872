import { Note } from 'src/app/models/note.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColumnBuilderService {

  constructor() { }
  // TODO: Take into account names like attributeCategory that should be Attribute Category

  // WIP: Looking for ngOnChanges
  // setTableType DUNNO we can skip it
  // getColumns // Configure Columns
  configureColumns<T extends { [keyof: string]: any }>(Class: any, instance?: T) {
    if (!instance) {
      instance = new Class();
    }
    const attributes = Object.getOwnPropertyNames(instance);
    const result: Partial<{ [K in keyof T]: T[K] }> = {};
    for (const att of attributes) {
      let typeAtt: any = undefined;
      if (instance && instance[att]) {
        typeAtt = getTypeOfProperty<T>(instance, att)
      }
      (result as any)[att] = this.createColumConfiguration<T>(
        att, att as keyof T, '', true, instance!, typeAtt
      )
    }

    return result;

  }

  /**
* Returns an array of table column configurations based on the provided column configuration object
* and optional parameters for including or excluding specific fields.
* by Default it will include all the fields of the object.
* If includesFields is specified, it will be priority over any other configuration, and it will return fields specified in includesFields.
* If includesFields is NOT specified, then it will check for excluded fields, which will exclude only the specified fields, otherwise it will return all the fields
*
* @template T - The type of the column configuration object.
* @param {Partial<T>} confColumns - An object containing column configuration information.
* @param {string[]} [excludeFields] - An optional array of strings specifying fields to exclude from the resulting array of table column configurations.
* @param {string[]} [includeFields] - An optional array of strings specifying fields to include in the resulting array of table column configurations.
* Takes priority over excludeFields.
* @returns {GeneralColumnType<T>[]} An array of table column configurations.
*/

  getTableColumnConfiguration<T>(confColumns: Partial<T>, excludeFields?: (keyof T)[], includeFields?: (keyof T)[]): GeneralColumnType<T>[] {
    const allFields = Object.keys(confColumns).map(k => ((confColumns as any)[k] as GeneralColumnType<T>))
    if (includeFields) {
      return allFields.filter(f => includeFields.includes(f.name))
    }
    if (excludeFields) {
      return allFields.filter(f => !excludeFields.includes(f.name))
    }

    return allFields;
  }

  // TODO: Create service Columns
  /**
   * THOSE ARE THE COMMON Attributes of each column type, the other attributes are specific of a col
   * SPECIAL TYPES OF COLUMNS are link, date, tags
   * @param header: is the text of the header or It is the key string for the the column's header. A key string from the en.json or the es.json to apply the translate pipe after in the table component.
   * @param name: attribute of Model, it will be the bind attribute column
   * @param orderBy: boolean, // If enable or not the possibility of sort by this field
   * @param 'cssClass'**: to add to the *td* element a class.
   *
   */
  createColumConfiguration<T>(
    header: string,
    name: keyof T,//ProviderModel, // TODO: Use T type here
    cssClass: string, //to add to the *td* element a class.
    orderBy: boolean, // If enable or not the possibility of sort by this field
    instance: T,
    type?: string,
    customValueExtraction?: (row: T) => string,
  ) {

    let tmp: GeneralColumnType<T> = { header, name, 'class': cssClass, type, customValueExtraction };
    if (orderBy) {
      tmp = { ...tmp, orderBy: name as string }
    }
    switch (type) {
      case "date":
        tmp = this.createDefaultColumnDate(tmp);
        break;
      case "array":
        tmp = this.createDefaultColumnArray<T>(tmp, name, instance);
        break;
      default:
    }


    return tmp;
  }

  createDefaultColumnDate(tmp: any) {
    return { ...tmp, format: 'lll' }
  }


  /**
   * The configuration needs the field second, so if I have an object Profile.docs
   * And I want to show the title of the doc on column second will be title.
   * And we can make profile.docs.map(d => doc[secondValue]) === profile.docs.map(d => doc.title)
   * @param tmp
   * @param instance
   * @returns
   */
  createDefaultColumnArray<T>(tmp: any, mainField: keyof T, instance: T) {
    const result = {
      ...tmp,
      icon: false,
      type: 'chips',
      maxChips: 2,
      second: 'name',
    }
    const second = this.getStringProperty(instance[mainField] as any);
    if (!!second) {
      return { ...result, second }
    }

    return result;
  }

  getStringProperty<T extends object>(obj: T) {
    const allFields = Object.keys(obj)
    return allFields.length > 0 ? allFields[0] : null;
  }
}

export type GeneralColumnType<T> = { header: string, name: keyof T, 'class': string, orderBy?: string, type?: string, format?: 'lll', customValueExtraction?: (row: T) => string }
export type AttributesTypes = "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function" | "date" | 'array';
function getTypeOfProperty<T>(obj: T, key: keyof T): AttributesTypes {
  let typeAtt: AttributesTypes = typeof obj[key];

  if (Array.isArray(obj[key])) {
    typeAtt = 'array'
  } else if (typeAtt === 'object') {
    if (obj[key] instanceof Date) {
      typeAtt = 'date'
    }
  }
  return typeAtt;
}
