import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { NoteService } from 'src/app/services/note/note.service';

@Component({
  selector: 'app-notes-attachments-viewer',
  templateUrl: './notes-attachments-viewer.component.html',
  styleUrls: ['./notes-attachments-viewer.component.scss']
})
export class NotesAttachmentsViewerComponent implements OnInit{
    @Input() attachment!: any;
    fileUrl!:any;
    imageUrl!:any;
    isImage!:any;
    isLoading: boolean = false; 
    selectedAttachment: any;
    filteredAttachments: any[] = [];
    singleFilteredAttachment: boolean = false;
  
    constructor(
      private sanitizer: DomSanitizer,
      private notesService:NoteService
    ){  }
  
    ngOnInit(): void {
      this.filteredAttachments = this.getPdfAndImageAttachments();
      if (this.filteredAttachments.length === 1) {
        this.singleFilteredAttachment = true;
        this.selectedAttachment = this.filteredAttachments[0];
        this.onAttachmentSelected();
      } 
    }
  
    onAttachmentSelected() {
      this.isLoading = true;
      let downloadObservable: Observable<any>;
      downloadObservable = this.notesService.downloadNoteAttachmentFromBlob(this.attachment._id, this.selectedAttachment._id);
      downloadObservable.subscribe({
        next: (response: any) => {  
          this.isImage = /(jpeg|jpg|gif|png)$/i.test(this.selectedAttachment.filepath)
          const blob = new Blob([response.body]);      
          if(this.isImage){
            const fileUrl = window.URL.createObjectURL(blob);
            this.imageUrl=this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl)
          }else{
            this.fileUrl = window.URL.createObjectURL(blob);
          }
          this.isLoading = false;
        }
      });
    }
   
    getPdfAndImageAttachments() {
       return this.attachment.attachments
           .filter((att: { filepath: string; }) => /(jpeg|jpg|gif|png|pdf)$/i.test(att.filepath));
    }
}