import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appResizable]' // Attribute selector
})

export class ResizableDirective implements OnInit {


  @Input() resizableGrabWidth = 8;
  @Input() sidebarResizeLimit = -10;
  @Input() resizableMinWidth = 450;
  @Input() resizableMaxWidthPercent = 98;

  dragging = false;

  constructor(private el: ElementRef) {

    const self = this;

    const EventListenerMode = { capture: true };

    function preventGlobalMouseEvents() {
      document.body.style['pointer-events' as any] = 'none';
    }

    function restoreGlobalMouseEvents() {
      document.body.style['pointer-events' as any] = 'auto';
    }


    const newWidth = (wid: any) => {
      const maxWidth = window.innerWidth * (this.resizableMaxWidthPercent / 100);
      const newWidth = Math.min(Math.max(this.resizableMinWidth, wid), maxWidth);
      el.nativeElement.style.width = newWidth + "px";
    }


    const mouseMoveG = (evt: any) => {
      if (!this.dragging) {
        return;
      }
      newWidth(window.innerWidth - evt.clientX)
      evt.stopPropagation();
    };

    const dragMoveG = (evt: any) => {
      if (!this.dragging) {
        return;
      }
      const maxWidth = window.innerWidth * (this.resizableMaxWidthPercent / 100);
      const width = Math.min(Math.max(this.resizableMinWidth, evt.clientX - el.nativeElement.offsetLeft), maxWidth);
      el.nativeElement.style.width = width + "px";
      evt.stopPropagation();
    };

    const mouseUpG = (evt: any) => {
      if (!this.dragging) {
        return;
      }
      restoreGlobalMouseEvents();
      this.dragging = false;
      evt.stopPropagation();
    };

    const mouseDown = (evt: any) => {
      if (this.inDragRegion(evt)) {
        this.dragging = true;
        preventGlobalMouseEvents();
        evt.stopPropagation();
      }
    };


    const mouseMove = (evt: any) => {
      if (this.inDragRegion(evt) || this.dragging) {
        el.nativeElement.style.cursor = "col-resize";
      } else {
        el.nativeElement.style.cursor = "default";
      }
    }


    document.addEventListener('mousemove', mouseMoveG, true);
    document.addEventListener('mouseup', mouseUpG, true);
    el.nativeElement.addEventListener('mousedown', mouseDown, true);
    el.nativeElement.addEventListener('mousemove', mouseMove, true);
  }

  ngOnInit(): void {
    this.el.nativeElement.style["border-left"] = this.resizableGrabWidth + "px";
  }

  inDragRegion(evt: any) {
    return (evt.clientX - this.el.nativeElement.offsetLeft < this.resizableGrabWidth)
     && (evt.clientX - this.el.nativeElement.offsetLeft  > this.sidebarResizeLimit );
  }

}
