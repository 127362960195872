import { AutocompleteUtilsService } from 'src/app/services/autocomplete-utils/autocomplete-utils.service';
import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subject, Subscription, tap } from 'rxjs';
import { ApiService } from 'src/app/services/api/api-service/api.service';
import { PaginationModel } from 'src/app/services/api/pagination-service/model/pagination.model';
import { PaginationService } from 'src/app/services/api/pagination-service/pagination.service';
import { PageModel } from 'src/app/services/api/pagination-service/model/page.model';
import { RoleModel } from 'src/app/models/role.model';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { IUserData, UserModalComponent } from '../modals/user-modal/user-modal.component';
import { UserModel } from 'src/app/models/user.model';
import { DeleteModalComponent } from '../modals/delete/delete.component';
import { GenerateCrudModalFormService } from 'src/app/services/generate-crud-modal-form/generate-crud-modal-form.service';
import { UserPermissionsTableComponent } from '../user-permissions-table/user-permissions-table.component';
import { COUNTRY_NAMES, REGION_NAMES, SERVICE_LINES, SECTOR_FOCUS, ROLE_TOOLTIPS_TEXT } from 'src/app/models/constants.model';
import { DataMasterService } from 'src/app/services/data-master-service/data-master';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { ColumnBuilderService } from 'src/app/components/responsive-table/column-builder/column-builder.service';
import { UserService } from 'src/app/services/user-service/user.service';
import { SettingTableType, TableAction } from 'src/app/components/responsive-table/model/setting-table.model';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit, OnDestroy {
  // Seems like the name of the table 'users'
  // TODO: We don't need paginationType since it will be always 'users'
  paginationType: string = 'users';
  @Input() params: {} = [];

  user: any;
  loading = true;
  backgroundLoading = false;
  // TODO:  UserTableComponent can be converted on ModelTableComponent<T> where T is UserModel
  pagination?: PaginationModel;
  // pagination?: PaginationModel;
  columns: any[] = [];
  // TODO: add type to settingTable following: front/src/app/components/responsive-table/responsive-table/README.md# Keys # Table settings
  //settingTable: any = {};// new SettingTableType()//: { [key: string]: any } = {};
  settingTable = new SettingTableType<UserModel>();
  // rows: any = {};
  showFilters: boolean = false;

  userTableRows?: PageModel<UserModel>;
    // Const
  _userPermissions: any;
  userPaginationConfiguration = PaginationModel.createDefaultConfiguration('');
  userTableColumnConfiguration: { header: string; name: keyof UserModel; class: string; orderBy?: string | undefined; }[] = [];
  filters: any = [];
  // TODO: Add interface type to labelfiler { name: "Assignment", value: "Assigned users" }
  labelFilters: any[] = [];
  totalUsers?: number;

  rolesList: RoleModel[] = [];
  sectorList: any[] = [];
  usersList: UserModel[] = [];
  regionList: any[] = [];
  serviceLineList: any[] = [];
  enableDataLoading: boolean = false;
  //Property to emit filter to delete in the validations component
  filterEvent: Subject<string> = new Subject<string>();

  fields: [{ name: keyof UserModel, type: string, data?: any }] | any;
  // Validation assign users
  @Output() assignFromTable = new EventEmitter();
  projectsIds: string = "";

  constructor(
    private dataMasterService: DataMasterService,
    protected autocompleteUtilsService: AutocompleteUtilsService,
    private apiService: ApiService,
    public columnBuilder: ColumnBuilderService,
    // private authService: AuthenticationService,
    private paginationService: PaginationService,
    private modalService: NgbModal,
    private router: Router,
    private translate: TranslatePipe,
    private global: GlobalService,
    private changeDetector: ChangeDetectorRef,
    public generalModal: GenerateCrudModalFormService,
    public alertService: AlertService,
    public userService: UserService,

  ) {
    this._userPermissions = this.global.getUserPermissionsConstants();
    // this.user = authService.getLoggedInUser();
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(void 0);
    this.ngUnsubscribe.complete();
  }
  protected ngUnsubscribe = new Subject();


  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.pagination = this.paginationService.getDefaultPagination('users');
    this.setUserTableColumConfiguration();
    this.setSettingsTable();
    this.getUsers();
    //mock function
  }

  /**
  * Variable to show the filter panel or not
  */
  public toggleShowFilters(event: MouseEvent) {
    this.showFilters = !this.showFilters;
  }

  /**
   * Handle background loading data status
   */
  public onBackgroundLoadingChange(isLoadingInBackground: boolean) {
    this.backgroundLoading = isLoadingInBackground;
  }

  onSelected($event: Event) {}
  selectedItems: UserModel[] = [];
  onSelectedItems(selectedItems: UserModel[]) {

    this.selectedItems = selectedItems;
  }

  /**
   * Load modal to assign a user for review the selected validations
   * @param filters
   */
  public onSearch(filters: any) {
    // TODO: Filter looks like depengin on the case depending on the type
    // The filter type looks like the model that is being filter by
    // Filter it should be Filter<Entity> where Entity = User | Reviewrs | ...
    this.filters = {
      username:
        filters['username'] !== undefined &&
          filters['username'] !== null ?
          filters['username'] :
          '',
      roleId:
        filters['roleId'] !== undefined &&
          filters['roleId'] !== null ?
          filters['roleId'] :
          '',
      sector:
        filters['sector'] !== undefined &&
          filters['sector'] !== null ?
          filters['sector'] :
          '',
      serviceLine:
        filters['serviceLine'] !== undefined &&
          filters['serviceLine'] !== null ?
          filters['serviceLine'] :
          '',
      country:
        filters['country'] !== undefined &&
          filters['country'] !== null ?
          filters['country'] :
          '',
      region:
        filters['region'] !== undefined &&
          filters['region'] !== null ?
          filters['region'] :
          ''
    };
    this.getDataFromDatabase();
  }


  /**
   * Function to set the params to
   * pass to the API call depending
   * on the parent component
   * TODO ADD IN reviwers projecstids: this.projectsIds, page_size: 5
   */
  private getParams() {
    switch (this.paginationType) {
      case 'users':
        return { ...this.pagination, ...this.filters, complete: true };
      case 'createUser':
        this.pagination!.page = this.userTableRows?.numPages! + 1;
        this.paginationType = 'users';
        return { ...this.pagination, ...this.filters, complete: true };
      case 'reviewers':
        return {
          ...this.pagination,
          ...this.filters,
          complete: true,
          page_size: 5
        };
      default:
        return { ...this.filters, complete: true, page_size: 5 };
    }
  }

  /**
   * Get users from database
   */
  private getDataFromDatabase() {
    const params = this.getParams();
    const getUsers$ = this.apiService.get<PageModel<UserModel>>('user', params, '');
    const assignUsersToTable$ = getUsers$.pipe(
      tap(data => {
        this.usersList = data.data;
        this.userTableRows = data;
        this.totalUsers = data.totalElements;
        this.loading = false;
        this.backgroundLoading = false;
        this.fillFields();
      }));
    // TODO: When pagination uncomment this
    const assignToPagination$ = assignUsersToTable$.pipe(
      tap(data => {
        this.paginationService.setPage<UserModel>('users', data);
        if (this.paginationService.getPage('users').orderBy == null) {
          const paginationObj = PaginationModel.createDefaultConfiguration('', 0, data.pageSize, this.userPaginationConfiguration.page);
          this.pagination = this.paginationService.setPagination(
            'users', paginationObj);
        }
        this.loading = false;
        this.backgroundLoading = false;
      })
    );
    assignToPagination$.subscribe();
  }



  fillFields() {
    if (!this.dataMasterService.passedWatcherUtils) {
      this.dataMasterService.watchUtils().subscribe(() => {
        this.getFieldsData();
        this.enableDataLoading = true;
      });
    } else {
      this.getFieldsData();
      this.enableDataLoading = true;
    }

  }

  getFieldsData() {
    this.rolesList = this.dataMasterService.getRoles();
    this.sectorList = this.dataMasterService.getSectors();
    this.serviceLineList = this.dataMasterService.getServiceLines();
    this.regionList = this.dataMasterService.getRegions();

    this.fields = [
      { name: "username", type: "autocomplete", data: this.autocompleteUtilsService.convertUsersToTreeGroup(this.usersList)},
      { name: "roleId", type: "dropdown", data: this.rolesList },
      { name: "sector", type: "dropdown", data: this.sectorList },
      { name: "serviceLine", type: "dropdown", data: this.serviceLineList },
      { name: "country", type: "autocomplete", data: this.autocompleteUtilsService.convertCountriesToTreeGroup() },
      { name: "region", type: "dropdown", data: this.regionList },
    ];
  }


  /**
   * Return loaded columns with the information of each column
   * of the table we want to show
   */
  setUserTableColumConfiguration() {
    const confColumns = this.columnBuilder.configureColumns<UserModel>(UserModel, UserModel.getEmptyUser());
    this.userTableColumnConfiguration = this.columnBuilder.getTableColumnConfiguration<UserModel>(confColumns,
      [],
      ['username', 'role', 'sector', 'serviceLine', 'country',]);
    for (const col of this.userTableColumnConfiguration) {
      const customCol = col as any;
      // Customize provider name column
      switch (col.name) {
        case 'username':
          customCol.header = this.translate.transform('username', 'User Name'),
          customCol.type = 'link',
          customCol.name = 'username'
          customCol.class = 'pop-up',
          customCol.clickLink = (user: UserModel) => this.onEditUser(user);
          break;
        case 'role':
          customCol.header = this.translate.transform('role', 'Role and Permissions'),
          customCol.second = 'name',
          // customCol.type = 'underlined',
          customCol.type = 'span-tooltip',
          customCol.orderBy = 'roleId',
          customCol.tooltipText = (rowData: UserModel) => this.getTooltipText(rowData.role.name); // Add this line
          break;
        case 'sector':
          customCol.header = this.translate.transform('sector', 'Sector Focus'),
          customCol.name = 'sector',
          customCol.orderBy = 'sector',
          customCol.type = 'two-records'
          break;
        case 'serviceLine':
          customCol.header = this.translate.transform('serviceLine', 'Service Line'),
          customCol.name = 'serviceLine';
          customCol.type = 'two-records',
          customCol.second = 'subServiceLine'
          break;
        case 'country':
          customCol.header = this.translate.transform('country', 'Geography');
          customCol.type = 'geography';
          break;
      }
      // Add translation to headers
      col.header = `${col.header}`;
    }
  }

  onTablePageChange([pageToChange, resetSelected]: [number, boolean]) {
    this.userPaginationConfiguration.page = pageToChange;
  }
  /**
   * When click on sort a field
   * @param orderBy: fieldName that is going to be orderBy
   * @param resetSelected:
   */
  onTableSort([orderBy, resetSelected]: [string, boolean]) {
    this.userPaginationConfiguration.orderBy = orderBy;
    this.userPaginationConfiguration.page = 1;
  }

  showPermissions(user: UserModel, column?: {
    header: string;
    name: keyof UserModel;
    class: string;
    orderBy?: string | undefined;
  }) {
    const modalRef = this.generalModal.createClassicModalWithInjectedComponent(
      UserPermissionsTableComponent,
      'Role Permissions',
      'noshow'
    );
    const aSub: Subscription = modalRef.shown.pipe(
      tap(_ => modalRef.componentInstance.componentInjectedInstance.data = user),
      tap(_ => modalRef.componentInstance.componentInjectedInstance.ngOnInit()),
    ).subscribe(_ => aSub.unsubscribe());
  }

  refresh(): void {
    this.getUsers();
  }

  setSettingsTable() {
    // Following src/app/components/responsive-table/responsive-table/README.md:68 these are the common attributes
    this.settingTable.dataId = 'id';
    this.settingTable.hasSelect = true;
    this.settingTable.getDataFromDatabase = this.getUsers.bind(this);
    this.settingTable.actionsOnSelected = [(
      new TableAction(
        'page.admin.tabs.modal.delete.selected',
        () => {
          const usersToDelete = this.selectedItems.map(item => item.id)
          this.deleteSelectedUsers(usersToDelete);
        },
        'fa fa-trash',
      )
    )];

    this.settingTable.actionsOnResponsive = [
      new TableAction(
        'edit',
        () => this.onEditUser.bind(this), // this.onEditUser.bind(this),
        'fa fa-edit'
      ),
      new TableAction(
        'page.admin.tabs.modal.delete.selected',
        () => this.deleteUser.bind(this),
        'fa fa-trash',
      )];
    this.settingTable.responsiveTitle.label = 'username';
  }


  /**
   * Delete single provider
   */
  public deleteProvider(providers: UserModel[]) {
    //this.deleteProvidersModal(providers.map(p => p.id));
  }

  /**
   * Get users from storage or database
   *
  }
   */
  getUsers(params: { [keyof: string]: any } = {}) {
    this.getDataFromDatabase();// TODO: Manage unsubscription
  }

  //From component validation-filter
  changePaginationChild(event: string) {
    this.paginationType = event;
    this.pagination = this.paginationService.getDefaultPagination(
      this.paginationType
    );
    this.setUserTableColumConfiguration();
    this.setSettingsTable();
  }

  /**
   * Returns wether it should render checkbox or not
   * depending of status of row
   * @param row row to check its status
   */
  public shouldRender(row: any) {
    let shouldRender = true;
    if (
      // row.roleId ===  &&
      this.paginationType !== 'users'
    ) {
      shouldRender = false;
    }
    return shouldRender;
  }

  /**
    * Handle loading data status from the child
    */
  public onLoadingChange(isLoading: boolean) {
    this.loading = isLoading;
  }

  public getTagsIcon(tags: any) {
    return 'fa fa-info';
  }

  /**
   * Function to add different custom options
   * to the setting table depending on which
   * component is the parent
   */

  /**
   * Check if user has edit permissions
   */
  private hasEditPermissions() {
    return true; // TODO: Fix with authentication
    // return this.user && this.getAdditionalPerm();
  }

  /**
   * Load modal to edit user
   */
  public onCreateUser(event: MouseEvent) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options: IUserData = {
      type: 'new',
      action: 'create',
      user: UserModel.getEmptyUser(),
    };
    const modalWindowRef = this.modalService.open(
      UserModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.componentInstance.usersList = this.usersList;
    modalWindowRef.result.then(userResult => {
      // TODO: Remove if train model permission is included in the modal
      if (userResult.action!! && userResult.action !== 'close') {
        const {
          action,
          user: { id, ...userParams }
        } = userResult;

        this.apiService
          .post(
            'user',
            JSON.stringify(userParams),
            'components.modal.user.createSuccessMsg'
          )
          .subscribe({
            next: () => {
              // const tenantName = this.authService.getTenantName();
              // this.router.navigate([`/${tenantName}`, 'admin']);
              this.paginationType = 'createUser';
              this.loading = true;
              this.backgroundLoading = true;
              this.getUsers();
            },
            error: (error) => {

              let message = 'components.modal.user.createErrorMsg';
              const keepAlertOnNavigate = false;
              if(error.status == 409){
                message = 'components.modal.user.existingUserErrorMsg';
              }
              this.alertService.error(message, keepAlertOnNavigate);
            }
          });
      }
    });
  }


  // START ADMIN --> USERS FUNCTIONS BLOCK
  /**
   * Load modal to edit user
   */
  public onEditUser(user: UserModel) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options = {
      type: 'edit',
      action: 'update',
      user: user
    };
    const modalWindowRef = this.modalService.open(
      UserModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(userResult => {
      if (userResult.action!! && userResult.action !== 'close') {
        const {
          action,
          user: { id, ...userParams }
        } = userResult;
        if (userParams['roleId']) {
          // look for role id and assign it
          const role = this.rolesList.find(r => r.name === userParams['roleId']);
          userParams['roleId'] = role?.id;
        }
        this.loading = true;
        this.apiService
          .put(
            'user/',
            id,
            // TODO: This should be userParams when we activate auth
            JSON.stringify(userParams),
            'components.modal.user.editSuccessMsg'
          )
          .subscribe({
            next: () => {
              this.loading = true;
              this.getDataFromDatabase();
            },
            error: (error) => {
              const message = 'components.modal.user.editErrorMsg';
              const keepAlertOnNavigate = false;
              this.alertService.error(message, keepAlertOnNavigate);
            }
          });
      }
    });
  }

  /**
   * Load modal to confirm user(s) removal
   */
  private deleteUsersModal(usersids: string[]) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      type: 'user',
      notAllowed: usersids.length < 1
    };

    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loading = true;
          const requestbody = { ids: usersids };
          this.sendDeleteRequest(requestbody);
        }
      },
      reason => { }
    );
  }

  /**
  * Send delete request to API endpoint with payload
  *
  */
  private sendDeleteRequest(requestbody: { ids: string[] }) {
    this.apiService
      .post(
        'user/delete',
        // 'admin/user/delete',
        requestbody,
        'components.modal.delete.success'
      )
      .subscribe(
        object => {
          // Remove deleted users from view while request from db
          this.userTableRows!.data = this.userTableRows!.data.filter(
            // this.rows = this.rows['data'].filter(
            el => requestbody.ids.indexOf(el.id.toString()) < 0
          );
          this.getDataFromDatabase();
        },
        error => {
          this.loading = false;
        }
      );
  }

  /**
   * Delete single user
   */
  public deleteUser(user: UserModel) {
    this.deleteUsersModal([user.id]);
  }

  /**
   * Delete multiple users
   */
  public deleteSelectedUsers(selectedItems: string[]) {
    this.deleteUsersModal(selectedItems);
  }

  /**
   * Action when click on the user name
   * To select it and set it on the modal component
   * @param user
   */
  public onSelectUser(user: UserModel) {
    this.assignFromTable.emit(user);
  }

  /**
   * Send delete request to API endpoint with payload
   *
   */

  private sendRequest(requestbody: any, assignAction: string) {
    const url = `user/${assignAction}`;
    // TODO: Create proper message
    this.apiService.post(url, requestbody, '', '').subscribe(
      () => {
        this.getDataFromDatabase();
      },
      error => {
        this.loading = false;
        console.error('Error changing assigned user(s) to project');
      }
    );
  }

  public cleanIndividualFilter(filter: string, value: any) {
    if (filter === 'Assignment' && value === "Not assigned users") {
      this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
      this.labelFilters.push({ name: "Assignment", value: "Assigned users" })

      filter = 'unassignedprojectusers'

      this.filterEvent.next(filter)
      this.onBackgroundLoadingChange(true);
      this.getDataFromDatabase();
    } else {
      this.labelFilters = this.labelFilters.filter(f => f.name !== filter);

      if (filter === 'Role') {
        filter = 'roleid'
      }

      this.filterEvent.next(filter)
      this.filters[filter] = '';
      this.onBackgroundLoadingChange(true);
      this.getDataFromDatabase();
    }
  }

  getTooltipText(role: string): string {
    return ROLE_TOOLTIPS_TEXT[role] || '';
  }


  /**
 * Return label translation of role filter
 *
 */

  private roleIdToString(roleId: string): string {
    let selectedRole;
    // this.rolesList.forEach(role => {
    //   if (role.roleid === roleId) selectedRole = role;
    // });
    // return this.translate.transform(
    //   'components.modal.user.roleList.' + selectedRole.roleName
    // );
    return 'components.modal.user.roleList.'
  }
}
