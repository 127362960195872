/**
 * Permission.
 */
export class PermissionModel {
  permissionValue: any;
  constructor(
    public id: number,
    public name: string,
    public description: string
  ) {}
}
