import { Product } from "./product.model";

export class ProductEditorComment {
  static getEmptyProductEditorComment(): ProductEditorComment | undefined {
    const result = new ProductEditorComment();
    return result;
  }

  username:string=''
  lastUpdatedAt: string = ProductEditorComment.formattedTimestamp(new Date());
  comment: string = '';
  product:Product| any;

  static formattedTimestamp(date: Date){
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2,'0');
    const hours = String(date.getUTCHours()).padStart(2,'0');
    const minutes = String(date.getUTCMinutes()).padStart(2,'0');
    const seconds = String(date.getUTCSeconds()).padStart(2,'0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
