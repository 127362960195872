import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { ApiService } from '../services/api/api-service/api.service';
import { PageModel } from '../services/api/pagination-service/model/page.model';
import { ProductEditorComment } from './product-editor-comment.model';

@Injectable({
  providedIn: 'root'
})
export class ProductEditorCommentsService {
  readonly modelName = 'historicProductEditorComments';

  constructor( public apiService: ApiService,
    private http: HttpClient,) { }

  /**
  * Create a Produtc Validation Comment
  * @param data
  * @returns
  */
  createProductEditorComment$(data: Omit<ProductEditorComment, 'id'>) {
    const request$ = this.apiService.post(
      `${this.modelName}`,
      JSON.stringify(data),
      'components.modal.editorComment.createSuccessMsg'
    );
    return request$;
  }

   /**
  * Get a Product Validation Comment
  * @param data
  * @returns
  */
   getProductEditorComment$(id: string, params: { [keyof: string]: any } = {}) {    
    const slug = `${this.modelName}/${id}`;
    const request$ = this.apiService.get<PageModel<ProductEditorComment>>(
      slug,
      params,
      ''
    );
    return request$;
  }

  getProductEditorCommentWithoutPagination$(id: string) {
    return this.getProductEditorComment$(id).pipe(
      map(result=> result.data),
    );
}

}
