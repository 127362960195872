import { Component, Input, OnInit } from '@angular/core';
import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './apex-bar-chart.component.html',
  styleUrls: ['./apex-bar-chart.component.scss'],
})
export class ApexBarChartComponent extends ApexChartBase implements OnInit {
  @Input() legendFormatter: (legendName: string, opts?: any) => string = (
    legendName: string
  ) => legendName;

  @Input() tooltipFormatter: (val: number, opts?: any) => string = (
    val: number
  ) => String(val);

  constructor(chartsService: ChartsService, utilsService: UtilsService) {
    super(chartsService, utilsService);
    this.defaultHeight = 260;
    this.defaultWidth = 500;
  }

  protected setChartOptions(): void {
    const colors = this.chartsService.getChartColorsRawBySpeaker(
      this.series.length
    );
    this.chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        fontFamily: this.fontFamily,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
        colors,
      },
      grid: {
        borderColor: this.axisColor,
      },
      xaxis: {
        axisBorder: {
          show: true,
          color: this.axisColor,
        },
        labels: {
          show: true,
          style: {
            colors: this.fontColor,
          },
        },
        axisTicks: {
          show: true,
          color: this.axisColor,
        },
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          show: true,
          style: {
            colors: this.fontColor,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 2,
        },
      },
      legend: {
        show: true,
        position: 'top',
        floating: false,
        labels: {
          colors: this.fontColor,
        },
        formatter: this.legendFormatter,
      },
      tooltip: {
        theme: 'dark',
        enabled: true,
        y: {
          formatter: this.tooltipFormatter,
        },
      },
      fill: {
        opacity: 0.5,
      },
    };
  }
}
