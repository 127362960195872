import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAttachment } from '../upload-files-components/attachment.interface';
import { saveAs } from 'file-saver';
import { ProviderModel } from 'src/app/models/provider.model';
import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';

@Component({
  selector: 'app-file-with-remove-without-selection',
  templateUrl: './file-with-remove-without-selection.component.html',
  styleUrls: ['./file-with-remove-without-selection.component.scss']
})
export class FileWithRemoveWithoutSelectionComponent {

  @Input() provider!: ProviderModel;
  @Input() file!: IAttachment;
  @Input() canDelete = true;
  @Input() imageUrl = 'assets/img/icons/document.svg';
  @Output() onRemove = new EventEmitter<MouseEvent>();

  constructor(
    protected providerService: ProviderCollectionService
  ) {}

  /*
  downloadAttachment(){
    console.log(this.file)
    const aSub: Subscription = this.providerService.downloadProviderAttachmentFromBlob(this.provider.id, this.file._id).subscribe( (response:any) => {
      saveAs(response.body, this.file.name);
      aSub.unsubscribe()})
  }
  */

  _onRemove(event: MouseEvent) {
    this.onRemove.emit(event)
  }
}
