<div class="filter-content">
  <div *ngIf="!backgroundLoading" class="action-buttons">
    <button
      [ngStyle]="showFilters ? {'opacity': '0.6'} : {}"
      id="create-user"
      class="pagehead__button pagehead__button--filter"
      (click)="toggleShowFilters($event)"
    >
      {{ 'page.admin.button.filter.user' | translate : 'Filter' }}
    </button>
  </div>
  <!-- start filters panel -->
  <div [hidden]="!showFilters || backgroundLoading" class="reviewers__filters">
    <app-validations-filter
      (onSearch)="onSearch($event)"
      [licensesList]="licenseList"
      [paginationType]="paginationType"
      [fields]="fields"
      [key]="'license.'"
      #validationsFilter
    >
    </app-validations-filter>
  </div>
  <!-- end filters panel -->
  <!-- start table -->

  <div class="filter-content">
    <app-table-spinner-ball
      *ngIf="loading"
      [spinnerStyles]="'la-ball-circus'"
    ></app-table-spinner-ball>
    <app-responsive-table
      *ngIf="!loading"
      [loading]="loading"
      [backgroundLoading]="backgroundLoading"
      [settings]="settingTable"
      [columns]="productTableColumnConfiguration"
      [dataType]="'products'"
      [data]="productTableRows"
      [isEndlessScroll]="true"
      (loadingChange)="onLoadingChange($event)"
      (selected)="onSelected($event)"
      (onSelectedItems)="onSelectedItems($event)"
      (onSortEvent)="onTableSort($event)"
      (onPageChangeEvent)="onTablePageChange($event)">
    </app-responsive-table>
  </div>
</div>
