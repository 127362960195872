import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { DataFieldModel } from 'src/app/models/data.model';

import { environment } from 'src/environments/environment';
import { AlertService } from '../alert-service/alert.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiVersion}`;

  constructor(private alertService: AlertService, private router: Router) { }

  /**
   * 'forEach' but the callback function is async
   * and the iterator awaits each callback call.
   * @param array array over which to iterate.
   * @param callback callback that receives the item, the index,
   * and the array.
   */
  public async asyncForEach<Item>(
    array: Item[],
    callback: (item: Item, index: number, array: Item[]) => Promise<void>
  ) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  public async asyncForEachObject(
    object: any,
    callback: (item: any, key: string, object: any) => Promise<void>
  ): Promise<void> {
    for (let key in object) {
      await callback(object[key], key, object);
    }
  }

  /**
   * Get full URL with API URL prepended to route.
   */
  getApiUrl(route: string): string {
    return this.apiUrl + route;
  }

  /**
   * Redirect to root route if server response indicates that
   * the session has expired.
   */
  checkIfLogged(
    error: HttpErrorResponse,
    navigateToRoot: boolean = true
  ): void {
    if (error.status === 401) {
      this.alertService.error('common.sessionExpired', false);
      if (navigateToRoot) {
        this.router.navigate(['/']);
      }
    }
  }

  /**
   * Display error message in alert & call {@link throwError}.
   */
  handleError(
    error: HttpErrorResponse,
    errorMessage: string
  ): Observable<never> {
    this.alertService.error(errorMessage, false);
    return throwError(error);
  }

  /**
   * remove parameter with value undefined | '' | null
   * Add key-value pairs to HTTP params.
   *
   * The original HTTP params objects is not modified
   * (it is immutable),
   * a new HTTP params object is returned.
   *
   * @param httpParams original HTTP params.
   * @param data object whose properties are added to
   * the HTTP params.
   */
  addHttpParams(httpParams: HttpParams, data: any): HttpParams {
    for (const key in data) {
      const value = data[key];

      if (value !== undefined && value !== '' && value !== null) {
        httpParams = httpParams.set(key, value);
      }
    }

    return httpParams;
  }

  // public async setSelectedRespontableElements(
  //   data: any[],
  //   results: string[],
  //   idString: string
  // ): Promise<any> {
  //   if (results.length === 0) {
  //     return;
  //   }
  //   await this.asyncForEach(data, async (dataPiece: any) => {
  //     for (const result of results) {
  //       if (data[idString] === result) {
  //         dataPiece.respontableIsSelected = true;
  //         const index = results.indexOf(result);
  //         results.splice(index, 1);
  //         break;
  //       }
  //     }
  //   });
  // }

  public getItemId(item: string, url: string) {
    return url.split(`${item}/`)[1].split('/')[0];
  }

  public getLanguageFromLocale(locale: string): string {
    return locale.substring(0, 2);
  }

  /**
   * Access recursively the fields of a nested object until the innermost object and set its value.
   * @param object The outermost object to access.
   * @param value The value assigned to the innermost property.
   * @param keys The array containing the names of the nested fields.
   */
  public accessNestedProperty(object: any, value: any, keys: string[]): void {
    const lastKeyIndex = keys.length - 1;
    keys.reduce((currentObject: any, key: any, index: number) => {
      if (index < lastKeyIndex) {
        let nextObject = currentObject[key];
        if (!nextObject) {
          nextObject = currentObject[key] = {};
        }
        return nextObject;
      } else {
        currentObject[key] = value;
        return null;
      }
    }, object);
  }

  /**
   * Get value of data field.
   */
  getDataFieldValue(
    data: DataFieldModel[],
    fieldName: string,
    defaultValue: string = ''
  ): string {
    return (
      data?.find((field: DataFieldModel) => field.name === fieldName)?.value ??
      defaultValue
    );
  }
}
