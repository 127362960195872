import { U } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api/api-service/api.service';

@Injectable({
  providedIn: 'root'
})
export class DataMasterService {
  constructor(private apiService: ApiService) { }

  roles: any[] = [];
  countries: any[] = [];
  regions: any[] = [];
  sectors: any[] = [];
  serviceLines: any[] = [];
  subServiceLines: any[] = [];
  status: any[] = [];
  flags: any[] = [];
  products: any[] = [];
  productTypes: any[] = [];
  extentsOfSpecialization: any[] = [];
  niches: any[] = [];
  private utilsSub = new Subject<any>();
  public passedWatcherUtils = false;



  /**
   * Observable of utils
   */
  public watchUtils(): Observable<any> {
    return this.utilsSub.asObservable();
  }

  /**
   * API call to global that retrieve  data
   */
  public getDropdownDataAPI() {
    return this.apiService.get(`dropdownData`, {}, '').toPromise();
  }

  public async updateDropdownData() {
    let utils: any;
    utils = await this.getDropdownDataAPI();
    this.setRoles(utils.roles);
    this.setSectors(utils.sectors);
    this.setCountries(utils.countries);
    this.setFlags(utils.flags);
    this.setRegions(utils.regions);
    this.setServiceLines(utils.serviceLines);
    this.setSubServiceLine(utils.subServiceLines);
    this.setStatus(utils.status);
    this.passedWatcherUtils = true;
    this.utilsSub.next(utils);
    this.setProductTypes(utils.productTypes)
    this.setExtentsOfSpecialization(utils.extentsOfSpecialization);
    this.setNiches(utils.niches)
  }


  getRoles(): any[] {
    return this.roles;;
  }

  /**
  * set roles groups function
  * @param roles groups to store
  */
  public setRoles(roles: any[]) {
    this.roles = roles;
    return;
  }

  getCountries(): any[] {
    return this.countries;
  }

  /**
   * set countries groups function
   * @param country groups to store
   */
  public setCountries(country: any[]) {
    this.countries = country;
    return;
  }

  getRegions(): any[] {
    return this.regions;
  }

  /**
 * set regions groups function
 * @param region groups to store
 */
  public setRegions(region: any[]) {
    this.regions = region;
    return;
  }

  getSectors(): any[] {
    return this.sectors;
  }

  /**
  * set sectors groups function
  * @param sector groups to store
  */
  public setSectors(sector: any[]) {
    this.sectors = sector;
    return;
  }


  getServiceLines(): any[] {
    return this.serviceLines;
  }

  /**
  * set servicelines groups function
  * @param sector groups to store
  */
  public setServiceLines(serviceLine: any[]) {
    this.serviceLines = serviceLine;
    return;
  }

  getSubServiceLines(): any[] {
    return this.subServiceLines;
  }

  /**
  * set subservicelines groups function
  * @param subserviceline groups to store
  */
  public setSubServiceLine(subServiceLine: any[]) {
    this.subServiceLines = subServiceLine;
    return;
  }

  getStatus(): any[] {
    return this.status;
  }

  /**
  * set Statuss groups function
  * @param status groups to store
  */
  public setStatus(status: any[]) {
    this.status = status;
    return;
  }

  getFlags(): any[] {
    return this.flags;
  }

  /**
  * set flags groups function
  * @param flag groups to store
  */
  public setFlags(flag: any[]) {
    this.flags = flag;
    return;
  }

  getProduct(): any[] {
    return this.products;
  }

  /**
  * set products groups function
  * @param product groups to store
  */
  public setProducts(product: any[]) {
    this.products = product;
    return;
  }

  /**
  * set License groups function
  * @param license groups to store
  */

  getProductTypes(): any[] {
    return this.productTypes;
  }

  /**
  * set Product Types function
  * @param productTypes to store
  */
  public setProductTypes(productTypes: any[]) {
    this.productTypes = productTypes;
    return;
  }


  getExtentsOfSpecialization(): any[] {
    return this.extentsOfSpecialization;
  }

  /**
  * set Extents of specialization function
  * @param Extents of specialization to store
  */
  public setExtentsOfSpecialization(extentsOfSpecialization: any[]) {
    this.extentsOfSpecialization = extentsOfSpecialization;
    return;
  }


  getNiches(): any[] {
    return this.niches;
  }

  /**
  * set niches (ESG specializations) function
  * @param niches (ESG specializations) to store
  */
  public setNiches(niches: any[]) {
    this.niches = niches;
    return;
  }

  public formattedTimestamp(date: Date){
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2,'0');
    const hours = String(date.getUTCHours()).padStart(2,'0');
    const minutes = String(date.getUTCMinutes()).padStart(2,'0');
    const seconds = String(date.getUTCSeconds()).padStart(2,'0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}