import { Component, Input } from '@angular/core';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-general-table-tree',
  templateUrl: './general-table-tree.component.html',
  styleUrls: ['./general-table-tree.component.scss']
})
export class GeneralTableTreeComponent {
  @Input() inlineTable = false; // Decide if one table will be show or another

  @Input() product!: Product;
  @Input() category!: any;
  @Input() title: string = '';
  @Input() selected: boolean = false;

  loading = false;


  private _collapsed: boolean = false;

  public get collapsed(): boolean {
    return this._collapsed;
  }
  public set collapsed(val: boolean) {
    this._collapsed = val;
    if (val) {
      this.toggleCollapseAll();
    } else {
      this.toggleExpandAll();
    }
  }
  public toggles: Map<number, boolean> = new Map<number, boolean>();

  public toggleCollapse(index: number) {
    const value = !this.toggles.get(index);
    this.toggles.set(index, value);
  }

  private toggleCollapseAll() {
    for (let i = 0; i < this.toggles.size; i++) {
      this.toggles.set(i, false);
    }
  }

  private toggleExpandAll() {
    for (let i = 0; i < this.toggles.size; i++) {
      this.toggles.set(i, true);
    }
  }

  public checkToggledState(index: number) {
    return this.toggles.get(index);
  }

  public mapTitle(title: string): string {
    title = title.replace(/^_+|_+$/g, '').toLowerCase();
  
    let words = title.split('_').map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    const result = words.join(' ');

    return result;
  }

}
