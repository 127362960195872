<div class="modal-content-padding">
  <header *ngIf="!loading">
    <h2>Change Log of Previous Data Field Values</h2>
  </header>
  
  <div class="datafield-info" *ngIf="showHeading">
    <span><span class="info-item">Characteristic</span>: {{ field?.name }}</span>
    <span><span class="info-item">Player</span>: {{ providerName }}</span>
  </div>
  
  <div class="subheader subitems action-button-inner" *ngIf="!loading">
    <div class="button-container">
      <button
        id="create-user"
        class="pagehead__button pagehead__button--filter"
        (click)="toggleShowFilters($event)"
      >
        {{ 'page.admin.button.filter.user' | translate : 'Filter' }}
      </button>
      <button
        *ngIf="!loading"
        mat-flat-button
        class="btn-submit"
        color="primary"
        [disabled]="!selectedItem"
        (click)="reApplyValue(selectedItem)"
        [ngClass]="{ 'active': selectedItem }"
      >
        {{ "page.product.profile.changelog.modal.restore" | translate : 'Restore Selected Value' }}
      </button>
    </div>
  </div>

  <div class="content">
    <div class="filter-content">
      <div [hidden]="!showFilters" class="reviewers__filters">
        <app-validations-filter
          (onSearch)="onSearch($event)"
          [paginationType]="'changelogs'"
          [key]="'changelog.'"
          [fields]="filterFields"
          #validationsFilter
        ></app-validations-filter>
      </div>
  
      <app-table-spinner-ball
        *ngIf="loading"
        [spinnerStyles]="'la-ball-circus'"
      ></app-table-spinner-ball>
  
      <app-responsive-table
        *ngIf="!loading"
        [loading]="loading"
        [settings]="settingTable"
        [currentListLength]="currentListLength"
        [columns]="changelogTableColumnConfiguration"
        [dataType]="'changelogs'"
        [data]="changelogTableRows"
        (onPageChangeEvent)="onTablePageChange($event)"
        (onSortEvent)="onTableSort($event)"
        (onSelectedItems)="onSelectedItems($event)"
      ></app-responsive-table>
    </div>
  </div>

</div>