import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  constructor() {}

  transform(milliseconds: number): string {
    const duration = moment.duration(milliseconds);
    let durationString: string = '';
    if (duration.hours() > 0) {
      durationString += `${duration.hours()} h `;
    }
    if (duration.minutes() > 0) {
      durationString += `${duration.minutes()} min `;
    }
    if (duration.seconds() > 0) {
      durationString += `${duration.seconds()} s `;
    }
    if (duration.milliseconds() > 0 && durationString === '') {
      durationString += `${duration.milliseconds()} ms `;
    }
    return durationString;
  }
}
