<div class="chart">
    <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [plotOptions]="chartOptions.plotOptions"
        [labels]="chartOptions.labels"
        [fill]="chartOptions.fill"
        [stroke]="chartOptions.stroke"
    ></apx-chart>
</div>
