import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TagCloudComponent } from 'angular-tag-cloud-module';

import { ApexAreaChartComponent } from './apex-area-chart/apex-area-chart.component';
import { ApexBarChartComponent } from './apex-bar-chart/apex-bar-chart.component';
import { ApexDonutChartComponent } from './apex-donut-chart/apex-donut-chart.component';
import { ApexHorizontalBarChartComponent } from './apex-horizontal-bar-chart/apex-horizontal-bar-chart.component';
import { ApexPieChartComponent } from './apex-pie-chart/apex-pie-chart.component';
import { ApexRadarChartComponent } from './apex-radar-chart/apex-radar-chart.component';
import { ApexRadialbarChartComponent } from './apex-radialbar-chart/apex-radialbar-chart.component';
import { ApexStackedBarChartComponent } from './apex-stacked-bar-chart/apex-stacked-bar-chart.component';
import { ApexStrokedRadialChartComponent } from './apex-stroked-radial-chart/apex-stroked-radial-chart.component';
import { ApexTreemapChartComponent } from './apex-treemap-chart/apex-treemap-chart.component';
import { WordCloudChartComponent } from './word-cloud-chart/word-cloud-chart.component';

@NgModule({
  declarations: [
    ApexAreaChartComponent,
    ApexBarChartComponent,
    ApexDonutChartComponent,
    ApexHorizontalBarChartComponent,
    ApexPieChartComponent,
    ApexRadarChartComponent,
    ApexRadialbarChartComponent,
    ApexStackedBarChartComponent,
    ApexStrokedRadialChartComponent,
    ApexTreemapChartComponent,
    WordCloudChartComponent,
  ],
  imports: [CommonModule, NgApexchartsModule, TagCloudComponent],
  exports: [
    ApexAreaChartComponent,
    ApexBarChartComponent,
    ApexDonutChartComponent,
    ApexHorizontalBarChartComponent,
    ApexPieChartComponent,
    ApexRadarChartComponent,
    ApexRadialbarChartComponent,
    ApexStackedBarChartComponent,
    ApexStrokedRadialChartComponent,
    ApexTreemapChartComponent,
    WordCloudChartComponent,
  ],
})
export class GraphsModule {}
