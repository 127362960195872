import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { msalDefaultSilentRequest } from 'src/app/security/conf/msal.config';

@Injectable({
  providedIn: 'root'
})
export class MsalAuthenticationService implements OnDestroy {

  /**
   * Current msal account logged in
   */
  protected _activeAccount: AccountInfo | any;
  public get activeAccount(): AccountInfo {
    this.checkActiveAccount();
    return this._activeAccount;
  }
  /**
   * Temporal Subscriptions that can be deleted
   * through the disposeTemporalSubscription method
   */
  private temporalSubscriptions: Subscription[] = [];
  /**
   * Permanent subscriptions that should be deleted only on destroy.
   */
  private permanentSubscriptions: Subscription[] = [];

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) public msalGuardConfig: MsalGuardConfiguration,
  ) {}

  /**
   * Logs out from msal
   */
  public logOut(): Observable<EventMessage> {
    const result = new Observable<EventMessage>(
      (observer) => {
        const errorSubscription = this.logoutError()
          .subscribe(
            (error) => {
              observer.next(error);
              observer.complete();
              this.disposeTemporalSubscriptions();
            }
          )
        this.temporalSubscriptions.push(errorSubscription);
        const successSubscription = this.msalService.logoutRedirect()
          .subscribe(
            () => {
              observer.next(undefined);
              observer.complete();
              this.disposeTemporalSubscriptions();
            }
          )
        this.temporalSubscriptions.push(successSubscription);
      }
    )
    return result;
  }

  /**
   * Renews the token
   * @returns an object containing the new token and the new expirationDate.
   * Null in case of error.
   */
  public renewToken(): Observable<any> {
    const result = new Observable<any>(
      (observer) => {
        const subscription = this.acquireTokenSilent(true)
          .subscribe((token: string) => {
            if (this._activeAccount != null && token != null) {
              observer.next({
                token,
                expirationDate: this.getExpirationDate()
              })
            } else {
              observer.next(null);
            }
            observer.complete();
          })
        this.temporalSubscriptions.push(subscription);
      }
    )
    return result;
  }

  /**
   * Acquires the token from msal
   * without reloading the page
   * @param renew If true the token will be renewed
   * @param handleError If true, errors will be handled
   * @returns A observable that streams the token or null if error
   * and handleError is set to true
   */
  public acquireTokenSilent(renew: boolean, handleError: boolean = true): Observable<string> {
    this.checkActiveAccount();
    const silentRequest = msalDefaultSilentRequest(renew);
    const result = new Observable<string>(
      (observer) => {
        if (handleError) {
          const failureSubscription = this.tokenAcquiredFailed()
            .subscribe((result: EventMessage) => {
              console.error(
                'Could not acquire token during redirect: ',
                result
              );
              observer.next(undefined);
              observer.complete();
              this.disposeTemporalSubscriptions();
            })
          this.temporalSubscriptions.push(failureSubscription);
        }
        const successSubscription = this.msalService
          .acquireTokenSilent(silentRequest)
          .subscribe((authResult: AuthenticationResult) => {
            if (renew) {
              this.checkActiveAccount();
            }
            observer.next(authResult.accessToken);
            observer.complete();
            this.disposeTemporalSubscriptions();
          })
        this.temporalSubscriptions.push(successSubscription);
      }
    )
    return result;
  }

  /**
   * The expiration date of the token
   * @returns The expiration date or null if no msal active is active
   */
  public getExpirationDate(): Date | any{
    if (!this.activeAccount) {
      console.error('No msal active account found!')
      return null;
    }
    if(!this.activeAccount.idTokenClaims) {
      console.error('No msal active account found!')
      return null; 
    }
    return this.activeAccount.idTokenClaims['exp'] ?  new Date(this.activeAccount.idTokenClaims['exp'] * 1000) : null;
  }

  /**
   * Redirects to acquire the token
   */
  public onTokenExpired(): void {
    /*const redirectRequest = msalDefaultRedirectRequest();
    const failureSubscription = this.tokenAcquiredFailed()
      .subscribe((result: EventMessage) => {
        console.error('Could not acquire token during redirect: ', result);
        this.disposeTemporalSubscriptions();
      })
    this.temporalSubscriptions.push(failureSubscription);
    this.msalService.acquireTokenRedirect(redirectRequest);*/
    this.msalService.logout();
  }

  /**
   * Gets the msal current active account
   * @returns
   */
  public checkActiveAccount(): void {

    let currentAccount = this.msalService.instance.getActiveAccount();
    if (currentAccount) {
      this._activeAccount = currentAccount;
      return;
    }

    currentAccount = this.getActiveAccountFromMsal();
    if (currentAccount) {
      this._activeAccount = currentAccount;
      this.msalService.instance.setActiveAccount(currentAccount);
    } else {
      console.error('No msal active accounts found!');
      this._activeAccount = null;
    }
  }

  /**
   * Gets the current account from the available array of accounts
   * @returns
   */
  private getActiveAccountFromMsal(): AccountInfo | any {
    const allActiveAccounts = this.msalService.instance.getAllAccounts();
    if (allActiveAccounts?.length > 0) {
      return allActiveAccounts[0];
    } else {
      return null;
    }
  }

  /**
   * @returns An observable that notifies in case the token acquisition
   * fails
   */
  public tokenAcquiredFailed(): Observable<EventMessage> {
    const observable = this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      );
    return observable;
  }

  /**
   * @returns An observable that notifies in case the login
   * fails
   */
  public loginFailure(): Observable<EventMessage> {
    const observable = this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      );
    return observable;
  }

  /**
   * @returns An observable that notifies in case the logout
   * fails
   */
  public logoutError(): Observable<EventMessage> {
    const observable = this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_FAILURE),
      );
    return observable;
  }

  /**
   * Disposes the temporal subscriptions
   */
  private disposeTemporalSubscriptions(): void {
    for (const sub of this.temporalSubscriptions) {
      sub?.unsubscribe();
    }
    this.temporalSubscriptions = [];
  }

  ngOnDestroy(): void {
    for (const sub of this.permanentSubscriptions) {
      sub?.unsubscribe();
    }
  }
}
