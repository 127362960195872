<div class="reviewers__filters">
  <form class="filters filters__body" (ngSubmit)="onSearch()" [formGroup]="form">
    <div class="filters__filters">
      <div *ngFor="let column of columns" [ngSwitch]="column['type']">
        <mat-form-field *ngSwitchCase="'aging-date'">
          <input 
            matInput  
            [max]="column['maxFilterDate']"
            [placeholder]="column['header'] | translate" 
            [name]="column['name']" 
            [formControlName]="column['name']"
            [id]="column['name']" 
            type="text" 
          />
          <mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
        </mat-form-field>

        <mat-form-field *ngSwitchDefault>
          <mat-label>{{ column["header"] | translate }}</mat-label>
          <input matInput [name]="column['name']" [formControlName]="column['name']" [id]="column['name']"
            type="text" />
          <mat-error *ngIf="form.get(column['name'])!.invalid">
            {{ "common.requiredField" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngFor="let filter of externalFilters?.additionalFilters" [ngSwitch]="filter.type">
        <mat-form-field *ngSwitchCase="'aging-date'">
          <input 
            matInput 
            [max]="filter.maxFilterDate"
            [placeholder]="filter.header | translate" 
            [name]="filter.name" 
            [formControlName]="filter.name"
            [id]="filter.name" 
            (ngModelChange)="formatDate(filter.name)" 
            type="text" 
          />
          <mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
          <mat-error *ngIf="form.get(filter.name)!.invalid">
            {{ "common.requiredField" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngSwitchDefault>
          <mat-label>{{ filter.header | translate }}</mat-label>
          <input matInput [name]="filter.name" [formControlName]="filter.name" [id]="filter.name" type="text" />
          <mat-error *ngIf="form.get(filter.name)!.invalid">
            {{ "common.requiredField" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="externalFilters?.fromToDateFilter">
        <mat-form-field>
          <input 
            matInput [max]="externalFilters.fromToDateFilter!.maxFilterDate"
            [placeholder]="externalFilters.fromToDateFilter!.fromHeader | translate" 
            [name]="externalFilters.fromToDateFilter!.fromId"
            [formControlName]="externalFilters.fromToDateFilter!.fromId" 
            [id]="externalFilters.fromToDateFilter!.fromId"
            (ngModelChange)="formatDate(externalFilters.fromToDateFilter!.fromId)" 
            type="text" 
          />
          <mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
          <mat-error *ngIf="fromDateFilterInvalid">
            {{ "common.requiredField" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="externalFilters?.fromToDateFilter">
        <mat-form-field>
          <input 
            matInput 
            [max]="externalFilters.fromToDateFilter!.maxFilterDate"
            [min]="formatDateToMoment(externalFilters.fromToDateFilter!.fromId)" 
            [placeholder]="externalFilters.fromToDateFilter!.toHeader | translate" 
            [name]="externalFilters.fromToDateFilter!.toId"
            [formControlName]="externalFilters.fromToDateFilter!.toId" 
            [id]="externalFilters.fromToDateFilter!.toId"
            (ngModelChange)="formatDate(externalFilters.fromToDateFilter!.toId!)" 
            type="text" 
            [disabled]="fromDateFilterInvalid" 
          />
          <mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
          <mat-error *ngIf="fromDateFilterInvalid">
            {{ "common.requiredField" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngFor="let filter of externalFilters?.modalFilters">
        <div class="filters__modal-filter">
          <button class="filters__modal-filter--search" [ngClass]="{
              'filters__modal-filter--searched': form.get(filter.id)!.value
            }" [attr.label]="filter.header | translate" (click)="onModalFilterClicked(filter)" type="button">
            {{
            form.get(filter.id)!.value
            ? filter["result"]
            : (filter.header | translate)
            }}
          </button>
        </div>
      </div>
    </div>

    <div class="filters__buttons">
      <button mat-stroked-button color="accent" (click)="onClear()" type="button">
        {{ "documentFilter.clear" | translate }}
      </button>

      <button mat-flat-button color="primary" [disabled]="!form.valid" type="submit">
        {{ "documentFilter.search" | translate }}
      </button>
    </div>
  </form>
</div>