<div class='input-box' [ngClass]="{}">
  <mat-label>{{ label }}</mat-label>
  <ng-container *ngIf='allowMultiple; else singleSelect'>
    <mat-form-field 
      [ngClass]='{ 
        multi: cssMulti,
        "no-valid-data": isNoValidData() 
      }'
    >
      <mat-chip-grid #chipGrid aria-label='Value selection'>
        <mat-chip-row
          *ngFor='let item of selectedFlattenedOptions'
          (removed)='onRemove(item)'
          [title]='item.displayValue'>
          <span>{{ item.displayValue }}</span>
          <button matChipRemove [attr.aria-label]="'remove ' + item">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        #autocompleteInput
        [hidden]='!allowMultiple && selectedFlattenedOptions.length === 1'
        placeholder='{{placeholder}}'
        [formControl]='control'
        [matChipInputFor]='chipGrid'
        [matAutocomplete]='autoGroup'
        #trigger='matAutocompleteTrigger'
        [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
        (matChipInputTokenEnd)='addItem($event)' />
      <mat-autocomplete
        #autoGroup='matAutocomplete'
        (optionSelected)='onSelected($event)'>
        <mat-option
          *ngFor='let option of filteredOptions'
          [value]='option'
          [disabled]='option.isDisabled'
          [style.padding-left.px]="option.level * 20"
        >
          {{ option.displayValue }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-container>
  <ng-template #singleSelect>
    <mat-form-field [ngClass]='{"no-valid-data": isNoValidData()}'>
      <ng-container *ngIf="label === 'Country'; else newControl">
        <input 
          matInput
          placeholder='{{placeholder}}'
          [formControl]='control'
          [matAutocomplete]='autoGroup'
        />
        <mat-icon *ngIf='!control.value'>arrow_drop_down</mat-icon>
        <button
          matSuffix mat-icon-button aria-label="Clear"
          *ngIf='control.value && !displayControl.value'
          (click)="clearInput(control)"
        >
          <mat-icon>{{'clear'}}</mat-icon>
        </button>
      </ng-container>
      <ng-template #newControl>
        <input 
          matInput
          placeholder='{{placeholder}}'
          [formControl]='displayControl'
          [matAutocomplete]='autoGroup'
        />
        <mat-icon *ngIf='!displayControl.value'>arrow_drop_down</mat-icon>
      </ng-template>
      <mat-autocomplete
        #autoGroup='matAutocomplete'
        (optionSelected)='onSelected($event)'>
        <mat-option
          *ngFor='let option of filteredOptions'
          [value]='option'
          [disabled]='option.isDisabled'
          [style.padding-left.px]="option.level * 20"
        >
          {{ option.displayValue }}
        </mat-option>
      </mat-autocomplete>
      <button
        matSuffix mat-icon-button aria-label="Clear"
        *ngIf='displayControl.value'
        (click)="clearInput(displayControl)"
      >
        <mat-icon>{{'clear'}}</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>
</div>
