/**
 * Encapsulates state of an array of boolean properties that can be toggled.
 */
export class ToggleablesList {
  private list: Toggleable[];

  /**
   * @param numItems number of items in list.
   * @param initialState all items' state will be set to this.
   */
  constructor(numItems: number, initialState: boolean = false) {
    this.list = [];
    for (let i = 0; i < numItems; ++i) {
      this.list[i] = new Toggleable(initialState);
    }
  }

  getItem(index: number): Toggleable {
    return this.list[index];
  }

  get(index: number): boolean {
    return this.list[index].isTrue;
  }

  set(index: number, isTrue: boolean): void {
    this.list[index].isTrue = isTrue;
  }

  toggle(index: number): void {
    this.list[index].toggle();
  }

  length(): number{
    return this.list.length;
  }

  collapseAll(): void{
    for(let i=0;i<this.list.length;i++){
      this.list[i].collapse();
    }
  }

  expandAll(): void{
    for(let i=0;i<this.list.length;i++){
      this.list[i].expand();
    }
  }
}

/**
 * Encapsulates state of a boolean property that can be toggled.
 */
export class Toggleable {
  constructor(public isTrue: boolean) {}

  /**
   * Toggle state.
   */
  toggle(): void {
    this.isTrue = !this.isTrue;
  }

  collapse(): void{
    this.isTrue = true;
  }

  expand(): void{
    this.isTrue = false;
  }
}
