import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAttachment } from '../upload-files-components/attachment.interface';
import { ProviderModel } from 'src/app/models/provider.model';

@Component({
  selector: 'app-file-management-grid',
  templateUrl: './file-management-grid.component.html',
  styleUrls: ['./file-management-grid.component.scss']
})
export class FileManagementGridComponent {

  @Input() provider!: ProviderModel;
  @Input() files: IAttachment[] = [];
  @Output() onRemove = new EventEmitter<number>();

  _onRemove(file: IAttachment, index: number, event: MouseEvent) {
    // this.files.splice(index, 1);
    this.onRemove.emit(index);
  }
}
