<mat-optgroup
  *ngFor="let group of groups; let index = index"
  [label]="group.letter"
>
  <ng-container *ngFor="let subgroup of group.subgroups">
    <mat-option
      *ngIf="!subgroup.subgroups; else recursiveTemplate"
      [value]="subgroup.letter"
    >
      {{ subgroup.letter }}
    </mat-option>
    <ng-template #recursiveTemplate>
      <app-nested-menu-options
        [groups]="[subgroup]"
        style="padding-left: 20px"
      ></app-nested-menu-options>
    </ng-template>
  </ng-container>
</mat-optgroup>
