<!-- TODO: Work on carousel side motion -->
<div class="attachments">
    <div class="attachment" *ngFor="let attachment of attachments; let i = index" [ngClass]="{'attachmentEdit': showEdit}">
        <img src="assets/img/icons/document.svg" alt="doc-icon" class="doc-icon">
        <p *ngIf="!showEdit" class="attachment-name">
            {{attachment?.name}}
        </p>       
        <div class="att-overlay" *ngIf="action == 'remove'&& !showEdit" (click)="removeAttachment(i)">
            <img src="assets/img/icons/garbage-2.svg" alt="delete-icon" class="del-icon">
        </div>
        <div *ngIf="showEdit">
            <input [(ngModel)]="attachment.name" class="attachment-rename" (ngModelChange)="renameAttachment(i)">  
            <img src="assets/img/icons/garbage-2.svg" alt="delete-icon" class="del-icon1"  (click)="removeAttachment(i)">      
        </div>
        <!-- TODO: Add input properties to have different icons in different cases -->
        <div class="att-overlay" *ngIf="action == 'download'" (click)="downloadAttachment(attachment)">
            <img src="assets/img/icons/download.svg" alt="download-icon" class="down-icon">
        </div>
    </div>
</div>