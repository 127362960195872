<div class="spinner-container" *ngIf="loading">
  <app-spinner-ball
    [spinnerStyles]="'la-ball-circus'"
  ></app-spinner-ball>
</div>
<form [formGroup]="myForm" *ngIf="!loading">
  <div class="modal__body">
    <p class="modal-instruction-text" *ngIf="!isEditing">
      Please populate the characteristics below to create the new offering. However, please refer to the offering profile to populate the rest of the information
    </p>
    <div class="modal__row modal__row--flex" *ngFor="let field of fields">
      <!-- for single-select -->
       <div *ngIf="fieldToShow(field) === 1">
         <mat-form-field> 
           <mat-label>{{ 'Select ' + field.characteristic_name }}</mat-label>
           <mat-select
           [formControlName]="convertToCamel(field.characteristic_name)"
           [id]="convertToCamel(field.characteristic_name)"
           required
           >
           <mat-option *ngFor="let value of getPossibleValuesSingleSelect(field)" [value]="value">{{value}}</mat-option>
          </mat-select>
          <mat-icon matSuffix [matTooltip]="field.instructions_for_editor_mode" class="custom-info-icon">info</mat-icon>
        </mat-form-field>
      </div>

      <!-- for normal free text -->
      <div *ngIf="fieldToShow(field) === 2">
        <mat-form-field> 
          <mat-label>{{ field.characteristic_name }}</mat-label>
          <input 
            matInput 
            [formControlName]="convertToCamel(field.characteristic_name)"
            [id]="convertToCamel(field.characteristic_name)"
            [name]="convertToCamel(field.characteristic_name)"
            [ngClass]="{ 'is-invalid': hasError(field.characteristic_name) === true }"
            required
          />
          <mat-icon matSuffix [matTooltip]="field.instructions_for_editor_mode" class="custom-info-icon">info</mat-icon>
        </mat-form-field>
        <div *ngIf="hasPatternError(field.characteristic_name)" class="error-message">
          {{'components.modal.user.hint.offering.freetext' | translate : "The symbols < > { } ; are not allowed for this field" }}
        </div> 
      </div>
        
      <!-- Specific for bigger free text -->
      <div *ngIf="fieldToShow(field) === 32">
        <mat-form-field class="mat-form-textarea"> 
          <mat-label>{{ field.characteristic_name }}</mat-label>
          <textarea 
            matInput 
            [formControlName]="convertToCamel(field.characteristic_name)"
            [id]="convertToCamel(field.characteristic_name)"
            [name]="convertToCamel(field.characteristic_name)"
            [ngClass]="{ 'is-invalid': hasError(field.characteristic_name) === true }"
            required
          ></textarea>
        </mat-form-field>
        <div *ngIf="hasPatternError(field.characteristic_name)" class="error-message">
          {{'components.modal.user.hint.offering.freetext' | translate : "The symbols < > { } ; are not allowed for this field" }}
        </div> 
      </div> 

      <!--  -->
    </div>

    <div class="btn-container">
      <button class="pagehead__secondary-button" (click)="cancel()">Cancel</button>      
      <button 
        class='pagehead__primary-button' 
        (click)="create()"
        [ngClass]="{ 'pagehead__primary-button--disabled': !myForm?.valid }"
        [disabled]='!myForm?.valid'
      >{{ isEditing ? 'Update' : 'Create' }}</button>
    </div>
    <!--  -->
  </div>
</form>
