import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderEditorControl } from 'src/app/models/provider-editor-control.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { ProviderToExcelService } from 'src/app/services/provider-to-excel/provider-to-excel.service';

@Component({
  selector: 'app-provider-editor-control',
  templateUrl: './provider-editor-control.component.html',
  styleUrls: ['./provider-editor-control.component.scss']
})
export class ProviderEditorControlComponent {
  @Input() providerName!: string;
  @Input() editorControl!: ProviderEditorControl;
  @Input() provider!: ProviderModel;
  @Input() fieldsToSend!: any;

  loading: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
    private providerToExcel: ProviderToExcelService
  ){}

  ngOnInit(): void {
    if(this.providerName && this.editorControl){
      this.loading = false;
    }
  }

  discardMyChanges() {
    this.activeModal.close({
      action: 'discard'
    });
  }

  saveMyChanges() {
    this.activeModal.close({
      action: 'save'
    });
  }

  downloadMyChanges(){
    this.providerToExcel.downloadExcel(this.provider, this.fieldsToSend, `${this.providerName} Current Changes`)
    this.activeModal.close({
      action: 'download'
    });
  }
}
