<div class="filters-modal">  
    <div class="toggle-characteritics">
      <header>Display of characteristics</header>
      <div class="display-options">
        <div class="option">
          <input type="radio" id="option1" name="displayOption" value="showAll" [(ngModel)]="selectedFilter">
          <label for="option1">Show all</label>
        </div>
        <div class="option">
          <input type="radio" id="option2" name="displayOption" value="showEmpty" [(ngModel)]="selectedFilter">
          <label for="option2">Show empty</label>
        </div>
        <div class="option">
          <input type="radio" id="option3" name="displayOption" value="showPopulated" [(ngModel)]="selectedFilter">
          <label for="option3">Show populated</label>
        </div>
      </div>
      <p class="display-description">Show all = Shows all characteristics, including empty and populated ones.</p>
      <p class="display-description">Show empty = Shows only empty characteristics. For example, this may be useful if you want to populate empty characteristics.</p>
      <p class="display-description">Show populated = Shows only populated characteristics. For example, this may be useful if you don't care to see empty characteristics.</p>
    </div>
  
    <div class="options-btn-container">
      <button class="btn options-btn cancel" (click)="cancel()">Cancel</button>
      <button class="btn options-btn" (click)="apply()">Apply</button>
    </div>  
</div>