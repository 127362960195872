import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ProductToExcelService {

  constructor() { }

  downloadExcel(data: any[], filename: string) {
    const transformedData = this.transformData(data);

    const flatFields = this.flattenFields(transformedData);

    // Store the 'wasEdited' information separately
    const wasEditedInfo = flatFields.map(({ wasEdited }) => wasEdited);
  
    // Remove the 'wasEdited' property from the data
    const dataWithoutWasEdited = flatFields.map(({ wasEdited, ...rest }) => rest);
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
  
    // Add header row without 'wasEdited'
    const headerRowWithoutWasEdited = Object.keys(dataWithoutWasEdited[0]);
    const headerRow = worksheet.addRow(headerRowWithoutWasEdited);
  
    // Find the column index for the 'value' property
    let valueColumnIndex = -1;
    headerRow.eachCell((cell, colNumber) => {
      if (cell.value === 'value') {
        valueColumnIndex = colNumber;
      }
    });
  
    // Add data rows without 'wasEdited'
    dataWithoutWasEdited.forEach((row, rowIndex) => {
      const rowDataWithoutWasEdited = headerRowWithoutWasEdited.map((key) => row[key]);
      const dataRow = worksheet.addRow(rowDataWithoutWasEdited);
  
      // Apply styling to the 'value' cell if wasEdited is true
      if (wasEditedInfo[rowIndex]) {
        const cell = dataRow.getCell(valueColumnIndex);
        if (cell) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFEB5757' }, // Rose red color
          };
        }
      }
    });
  
    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), filename + '.xlsx');
    });
  }

  private transformData(data: any[]): any[] {
    return data.map((item) => {
      return {
        names: item.names,
        description: item.description,
        fields: item.fields.map((field: any) => {
          return {
            name: field.name,
            description: field.description,
            value: field.value,
            wasEdited: field.wasEdited
          };
        }),
      };
    });
  }

  private flattenFields(data: any[]): any[] {
    return data.reduce((flatFields, item) => {
      return flatFields.concat(item.fields);
    }, []);
  }
}
