import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ey-button',
  templateUrl: './ey-button.component.html',
  styleUrls: ['./ey-button.component.scss']
})
export class EyButtonComponent {
  @Input() text = '';
  @Input() color: 'primary' | 'accent' = 'primary';
  @Input() type: 'submit' | 'button' = 'button';
  classSelectors = {
    'primary': ['mat-button', 'mat-flat-button'],
    'accent': ['mat-stroked-button']
  }
}
