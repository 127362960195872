<!-- TODO: Work on carousel side motion -->
<div class="summary-info-attachment">
  <span class="item">Number of attachments: {{ files.length }}</span>
</div>
<div class="attachments center">
  <ng-container *ngFor="let attachment of files; let index = index">
    <app-file-with-remove-without-selection
      [provider]="provider"
      [file]="attachment"
      (onRemove)="_onRemove(attachment, index, $event)"
    ></app-file-with-remove-without-selection>
  </ng-container>

  <div *ngIf="files.length == 0">No Files associated</div>
</div>
