<form [formGroup]="myForm">
  <div class="modal__body">
    <p>Are you sure you want to validate this content? This covers all changes made by you and other editors and reviewers</p>
    <div class="modal__row modal__row--flex">
      <textarea
        class="comment-section"
        placeholder="Comment (required)"
        formControlName="comment"
        id="comment"
        name="comment"
        rows="1"
      ></textarea>
    </div>
  </div>
</form>
  