import { Component, OnInit } from '@angular/core';

import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

@Component({
  selector: 'app-apex-area-chart',
  templateUrl: './apex-area-chart.component.html',
  styleUrls: ['./apex-area-chart.component.scss'],
})
export class ApexAreaChartComponent extends ApexChartBase implements OnInit {
  constructor(chartsService: ChartsService, utilsService: UtilsService) {
    super(chartsService, utilsService);
    this.defaultHeight = 300;
    this.defaultWidth = 500;
  }

  protected setChartOptions(): void {
    this.chartOptions = {
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
        fontFamily: this.chartsService.getFontFamily(),
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        borderColor: this.axisColor,
      },
      xaxis: {
        categories: this.labels,
        axisBorder: {
          show: this.labels,
          color: this.axisColor,
        },
        labels: {
          show: true,
          style: {
            colors: this.fontColor,
          },
        },
        axisTicks: {
          show: true,
          color: this.axisColor,
        },
      },
      yaxis: {
        decimalsInFloat: 1,
        tickAmount: 4,
        min: -1,
        max: 1,
        labels: {
          show: true,
          style: {
            colors: this.fontColor,
          },
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: this.fontColor,
        },
      },
      tooltip: {
        theme: 'dark',
        enabled: true,
      },
    };
  }
}
