import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppliedFilterInfo } from '../advance-filters.interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MarketDiscoveryService } from 'src/app/services/market-discovery/market-discovery.service';
import { Product } from 'src/app/models/product.model';
import { ProviderModel } from 'src/app/models/provider.model';

@Component({
  selector: 'app-advanced-filters',
  templateUrl: './advanced-filters.component.html',
  styleUrls: ['./advanced-filters.component.scss']
})
export class AdvancedFiltersComponent implements OnInit{
  @Input() showOfferings!: boolean;

  label: string = "Select or search characteristic";
  placeholder: string = "Select or search characteristic";
  filtersSource: any[] = [];
  filtersSourceBackup: any[] = [];
  filtersOptions: any[] = [];
  selectedFilters: any[] = [];
  filtersToApply: AppliedFilterInfo[] = [];
  filterSelect = new FormControl('');

  prodsToLimitBackup: Product[] | any[] = [];
  provsToLimitBackup: Product[] | any[] = [];

  loading: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
    public marketDiscoveryService: MarketDiscoveryService,
  ){}

  get arrayToMap(): AppliedFilterInfo[] { return [...this.marketDiscoveryService.appliedAdvancedFilters] }

  ngOnInit(): void {

    this.filtersSource = [];
    this.filtersToApply = [];
    this.marketDiscoveryService.mapPropertiesToFilter(this.filtersSource);
    this.marketDiscoveryService.mapPropertiesToFilter(this.filtersSourceBackup);
    this.filtersOptions = this.marketDiscoveryService.makeFilterOptions(this.filtersSource);

    // deep copy the service aux variables
    this.prodsToLimitBackup = this.marketDiscoveryService.serviceProductsBackup.map((product: Product) => ({...product}));
    this.provsToLimitBackup = this.marketDiscoveryService.serviceProvidersBackup.map((provider: ProviderModel) => ({...provider}));

    if(this.arrayToMap.length > 0){
      this.filtersToApply = this.arrayToMap.map(filter => ({...filter}));
      this.filtersSource = this.updateSourceFilters();
      this.selectedFilters = [];
      this.updateSelectedFilters();
    }

    this.loading = false
  }

  onSelectFilterAutocomplete(filter: string){
    this.filtersSource = this.filtersSourceBackup;

    this.filtersSource =
    this.filtersSource.map((group: any) => ({
      ...group,
      collapsed: !group.items.some((item: any) => item.name === filter),
      items: group.items.filter((item: any) => item.name === filter),
    })).filter((group: any) => group.items.length > 0);

    // to check if any filter was applied before hand and show it as we filter
    if(this.selectedFilters.length > 0){
      this.filtersSource.forEach((filter: any) => {
        const filterApplied = filter.items.find((item: any) => this.selectedFilters.find((selected:any) => selected.name?.toLowerCase() === item.name?.toLowerCase() ));
        filterApplied.applied = true;
      });
    }
  }

  onApplyAllFilters(filteredItems: string[]) {
    const normalizedFilteredItems = filteredItems.map(item => item.toLowerCase().trim());
    this.filtersSource = this.filtersSourceBackup.map(box => {
      const items = box.items.filter((item: any) =>
        normalizedFilteredItems.includes(item.name.toLowerCase().trim())
      );
      return items.length ? { ...box, items, collapsed: false } : null;
    }).filter(box => box !== null);
  }

  onResetFilters(){
    this.filtersSource = this.filtersSourceBackup;
  }

  /**
   * Collapsible and filters column related function
   */
  uncollapseOne(group: any){
    this.filtersSource.forEach(g => {
      if(g.header === group.header && g.collapsed){
        g.collapsed = false;
      }else{
        g.collapsed = true;
      }
    })
  }

  atLeastOneApplied(): boolean{
    // const isAnyItemChecked = this.filtersSource.some(box => this.hasAppliedItems(box.items));
    // const isAnyBoxChecked = this.hasAppliedItems(this.filtersSource);

    // return isAnyItemChecked || isAnyBoxChecked;

    // to prevent dissapearing when we use the search bar
    return this.selectedFilters.length > 0;
  }

  hasAppliedItems(items: any[]) {
    return items.some(item => item.applied);
  }

  /**
   * Filters passing from left to right functions
   */

  addToSelectedFilters(item: any){
    item.applied = !item.applied

    if(item.applied && !this.selectedFilters.some(filter => filter.name === item.name)){
      this.selectedFilters.push(item)
    }else{
      const index = this.selectedFilters.findIndex(filter => filter.name === item.name);
      const indexInService = this.arrayToMap.findIndex(filter => filter.name === item.name);
      const indexInFilter = this.filtersToApply.findIndex(filter => filter.name === item.name);

      this.deselectOptionsOnRemove(this.selectedFilters[index]);

      if(index !== -1){
        this.selectedFilters.splice(index, 1);
      }
      if(indexInService !== -1){
        this.arrayToMap.splice(indexInService, 1);
      }
      if(indexInFilter !== -1){
        this.filtersToApply.splice(indexInFilter, 1);
      }
    }
  
    this.reductionOnFilterAdd();
  }

  deselectOptionsOnRemove(element: any){

    if(element.type === 0){
      element.options.forEach((option: any) => {
        option.checked = false;
      });
    } else if(element.type === 1){
      element.options.forEach((option: any) => {
        option.selected = false;
      });
    } else if (element.type === 3){
    } else if (element.type === 4){
      element.options.forEach((option: any) => {
        option.values.forEach((value: any) => value.checked = false);
      });
    }
  }

  // for type 0, this means this one will bring the list with the selected options
  onCheckboxesSelected(event: AppliedFilterInfo){
    this.pushToFiltersToApply(event);
  }

  // for type 1, this means this one will only bring one at a time
  onChipSelected(event: AppliedFilterInfo) {
    this.pushToFiltersToApply(event);
  }

  // for type 3, this means this one will bring the selected number for the slider
  onSliderValueChange(event: AppliedFilterInfo) {
    this.pushToFiltersToApply(event)
  }

  pushToFiltersToApply(value: AppliedFilterInfo){
    const filter: AppliedFilterInfo = { ...value }; // Create a shallow copy of the filter

    const filterIndex = this.filtersToApply.findIndex(f => f.name === filter.name);

    if (filter.options?.length !== 0) {
      if (filterIndex === -1) {
        this.filtersToApply.push(filter);
      } else {
        // If filter type is 1, replace the entire options array
        if (this.filtersToApply[filterIndex].type === 1) {
          this.filtersToApply[filterIndex].options = [...filter.options];
        } else {
          this.filtersToApply[filterIndex].options = [...filter.options];
        }
      }
    } else {
      if (filterIndex !== -1) {
        this.filtersToApply.splice(filterIndex, 1);
      }
    }

    this.reductionOnFilterAdd();
  }

  /**Filter appliance functions */
  applyFilters(){
    this.activeModal.close({
      action: 'apply',
      filtersToApply: [...this.filtersToApply]
    });
  }

  cancelFilterApplication(){
    this.activeModal.close({
      action: 'close',
      filtersToApply: this.filtersToApply,
    });
  }

  //must change as the types changed with new data
  updateSourceFilters(){
    return this.filtersSource.map(filterGroup => {
      if (filterGroup.items) {
        filterGroup.items.forEach((filter:any) => {
          const matchingTarget = this.filtersToApply.find(targetFilter => targetFilter.name === filter.name);

          if (matchingTarget) {
            if (matchingTarget.options) {
              filterGroup.collapsed = false; // Only uncollapse the applied ones
              filter.applied = true;

              if(filter.type === 0) { // Multi-select
                filter.options.forEach((option: any) => {
                  const matchingOption = matchingTarget.options.find(targetOption => targetOption.name === option.name);
                  option.checked = matchingOption ? true : false;
                });
              } else if (filter.type === 1) { // Single select
                filter.options.forEach((option: any) => {
                  const matchingOption = matchingTarget.options.find(targetOption => targetOption.name === option.name);
                  option.selected = matchingOption ? true : false;
                });
              } else if (filter.type === 3) { // Number option
                // No need to do anything
              } else if (filter.type === 4) { // Multi-select hierarchy
                filter.options.forEach((option: any) => {
                  option.values.forEach((value: any) => {
                    const matchingOption = matchingTarget.options.find(targetOption => targetOption.name === value.name);
                    value.checked = matchingOption ? true : false;
                  });
                });
              }
            }
          }
        });
      }

      return filterGroup;
    });
  }

  updateSelectedFilters(){
    this.filtersSource.forEach(f => {
      f.items.forEach((item: any) => {
        if(item.applied){
          this.selectedFilters.push(item)
        }
      })
    })
  }

  reductionOnFilterAdd(){
    // this.marketDiscoveryService.providers = this.provsToLimitBackup;
    // this.marketDiscoveryService.products = this.prodsToLimitBackup;

    const comparativeProds = this.marketDiscoveryService.applyAdvancedFilters(this.filtersToApply, this.prodsToLimitBackup, true)
    const comparativeProvs = this.marketDiscoveryService.applyAdvancedFilters(this.filtersToApply, this.provsToLimitBackup, false)

    // Iterate through each filter in filtersSource
    this.filtersSource.forEach((filter: any) => {
      filter.items.forEach((item: any) => {
        // Iterate through each option in the filter
        item.options.forEach((option: any) => {
          
          const matchCountForProds: boolean[] = [];
          const matchCountForProvs: boolean[] = [];

          const matchCountTypeFourForProds: { name: string, matches: boolean[] }[] = [];
          const matchCountTypeFourForProvs: { name: string, matches: boolean[] }[] = [];
                    
          this.forComparativeProds(comparativeProds, item, option, matchCountForProds, matchCountTypeFourForProds);
          this.forComparativeProvs(comparativeProvs, item, option, matchCountForProvs, matchCountTypeFourForProvs);

          // after the comparativeProds array checks
          if(item.type === 0){
            const disabled = this.isPlayerFilter(filter) ? 
              matchCountForProvs.some((match: any) => match === true) : 
              matchCountForProds.some((match: any) => match === true);
            option.disabled = !disabled;
          } 
          
          if(item.type === 1){
            const disabled = this.isPlayerFilter(filter) ? 
              matchCountForProvs.some((match: any) => match === true) : 
              matchCountForProds.some((match: any) => match === true);
            
            option.disabled = !disabled;                          
          }

          if(item.type === 3){
            const disabled = this.isPlayerFilter(filter) ? 
              matchCountForProvs.some((match: any) => match === true) : 
              matchCountForProds.some((match: any) => match === true);
            
            const lenghtControl = this.isPlayerFilter(filter) ? matchCountForProvs.length === 0 : matchCountForProds.length === 0

            option.disabled = !disabled && lenghtControl;  
            
            const toEmit = {
              name: item.name, 
              disabled: option.disabled 
            }

            this.marketDiscoveryService.sendNumericDisabled.next(toEmit);
          }
          
          if(item.type === 4){
            option.values.forEach((val: { name: string, checked: boolean, disabled: boolean }) => {
              const match = this.isPlayerFilter(filter) ? 
                matchCountTypeFourForProvs.find(m => m.name === val.name) : 
                matchCountTypeFourForProds.find(m => m.name === val.name);

              if(match){
                const disabled = match.matches.some((mt: boolean) => mt === true);
                val.disabled = !disabled;
              } else {
                val.disabled = true;
              }
            });
          }

        });
      });
    });
  
    //
  }

  // we compare the header of our filter to see if it is the same as a group of player characteristics
  isPlayerFilter(filter: {header: string, collapsed: boolean, items: any[]}):boolean {
    const groups: string[] = [] 
    
    this.marketDiscoveryService.providerDataValidations.characteristics.forEach((char: any) => {
      if(!groups.includes(char.group_of_player_characteristics)){
        groups.push(char.group_of_player_characteristics)
      }
    });
    return groups.some((group: string) => group === filter.header);
  }

  // manage the logic for the products
  forComparativeProds(comparativeProds: Product[], item: any, option: any, matchCount: boolean[], matchCountForFour: {name: string, matches: boolean[]}[]): void{
    // Iterate through each product
    comparativeProds.forEach((product: Product | any) => {
      // Iterate through categories in each product
      product.categories.forEach((category: any) => {
        // Iterate through fields in each category
        category.fields.forEach((field: any) => {
          // Check if the field has a value
          if (field.value !== null) {
            const logic: boolean = this.showOfferings ? this.marketDiscoveryService.quickFiltersAppliedForProducts : this.marketDiscoveryService.quickFiltersAppliedForProviders;

            // Compare the field's value with the option's name         
            // checkboxes
            if(item.type === 0 && item.name === field.name){
              const possibleValuesArray = field.value?.toLocaleString().split(/[;,]/).map((value: string) => value.trim());
              const isOptionMatching = logic ?
                possibleValuesArray.some((possibleValue: string) => possibleValue.toLowerCase() === option.name?.toLowerCase()) && !option.disabled :
                possibleValuesArray.some((possibleValue: string) => possibleValue.toLowerCase() === option.name?.toLowerCase());
              
              if(!matchCount.includes(isOptionMatching)){
                matchCount.push(isOptionMatching);
              }
            }
              
            // chips
            if(item.type === 1 && item.name === field.name){

              if(logic){
                this.marketDiscoveryService.resetTypesOne.next(option);
              }

              const isOptionMatching = logic ?
                field.value?.toLocaleString().toLowerCase() === option.name?.toLowerCase() && !option.disabled :
                field.value?.toLocaleString().toLowerCase() === option.name?.toLowerCase();
              
              if(!matchCount.includes(isOptionMatching)){
                matchCount.push(isOptionMatching);
              }
            }

            // slider
            if(item.type === 3 && item.name === field.name){
              const isOptionMatching = logic ?
                field.value >= option.min && field.value <= option.max && !option.disabled :
                field.value >= option.min && field.value <= option.max;
              
              if(!matchCount.includes(isOptionMatching)){
                matchCount.push(isOptionMatching);
              }
            }
            
            // this check on equal names is to avoid weird edge cases 
            // hierarchy checkboxes
            if(item.type === 4 && item.name === field.name) {
              option.values.some((val: {checked: boolean, disabled: boolean, name: string}) => {
                const possibleValuesArray = field.value.split(/[;,]/).map((value: string) => value.trim());

                const isOptionMatching = logic ? 
                  possibleValuesArray.some((possibleValue: string) => possibleValue.toLowerCase() === val.name?.toLowerCase()) && !val.disabled :
                  possibleValuesArray.some((possibleValue: string) => possibleValue.toLowerCase() === val.name?.toLowerCase());
                
                const existingEntry = matchCountForFour.find(match => match.name?.toLowerCase() === val.name?.toLowerCase());
                
                if (existingEntry) {
                  // If an entry exists, update the matches array
                  if(!existingEntry.matches.includes(isOptionMatching)){
                    existingEntry.matches.push(isOptionMatching);
                  }
                } else {
                  // If no entry exists, create a new entry
                  matchCountForFour.push({ name: val.name, matches: [isOptionMatching] });
                }

              });

            } 
            // 
          }
        });
      });
    });
    //
  }

  // manage the logic for the providers
  forComparativeProvs(comparativeProvs: ProviderModel[], item: any, option: any, matchCount: boolean[], matchCountForFour: {name: string, matches: boolean[]}[]): void{
    // Iterate through each product
    comparativeProvs.forEach((provider: ProviderModel | any) => {

      const itemName = this.marketDiscoveryService.toCamelCase(item.name);
      const logic: boolean = this.showOfferings ? this.marketDiscoveryService.quickFiltersAppliedForProducts : this.marketDiscoveryService.quickFiltersAppliedForProviders;
      
      if(provider.hasOwnProperty(itemName) && provider[itemName] !== null){
        
        // checkboxes
        if(item.type === 0){
          const possibleValuesArray = provider[itemName].split(/[;,]/).map((value: string) => value.trim());
          const isOptionMatching = logic ?
          possibleValuesArray.some((possibleValue: string) => possibleValue.toLowerCase() === option.name?.toLowerCase()) && !option.disabled :
          possibleValuesArray.some((possibleValue: string) => possibleValue.toLowerCase() === option.name?.toLowerCase());
          
          if(!matchCount.includes(isOptionMatching)){
            matchCount.push(isOptionMatching);
          }
        }
        
        // chips
        if(item.type === 1){
          
          if(logic){
            this.marketDiscoveryService.resetTypesOne.next(option);
          }

          const isOptionMatching = logic ?
            provider[itemName]?.toLowerCase() === option.name?.toLowerCase() && !option.disabled :
            provider[itemName]?.toLowerCase() === option.name?.toLowerCase();

          if(!matchCount.includes(isOptionMatching)){
            matchCount.push(isOptionMatching);
          }
        }

        // slider
        if(item.type === 3){
          const isOptionMatching = logic ?
            provider[itemName] >= option.min && provider[itemName] <= option.max && !option.disabled :
            provider[itemName] >= option.min && provider[itemName] <= option.max;
          
          if(!matchCount.includes(isOptionMatching)){
            matchCount.push(isOptionMatching);
          }
        }

        // hierarchy checkboxes
        if(item.type === 4) {
          option.values.some((val: {checked: boolean, disabled: boolean, name: string}) => {
            const possibleValuesArray = provider[itemName]?.split(/[;,]/).map((value: string) => value.trim());
            const isOptionMatching =  logic ?
              possibleValuesArray.some((possibleValue: string) => possibleValue.toLowerCase() === val.name?.toLowerCase()) && !val.disabled :
              possibleValuesArray.some((possibleValue: string) => possibleValue.toLowerCase() === val.name?.toLowerCase());
            
            const existingEntry = matchCountForFour.find(match => match.name === val.name);

            if (existingEntry) {
              // If an entry exists, update the matches array
              if(!existingEntry.matches.includes(isOptionMatching)){
                existingEntry.matches.push(isOptionMatching);
              }
            } else {
              // If no entry exists, create a new entry
              matchCountForFour.push({ name: val.name, matches: [isOptionMatching] });
            }

          });
        } 
      }else {
        return;
      }
    });
    //
  }

}
