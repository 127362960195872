export const chatbotQuestions = [
  {
    question: "Which data products are available for compliance with EU taxonomy?"
  },
  {
    question: "Which software products are available for risk modelling?"
  },
  {
    question: "Which market player has solutions for all sustainable finance regulations?"
  },
  {
    question: "Which data products are available for the asset class private equity?"
  },
  {
    question: "Which software products can be used for data management for corporate sustainability?"
  }
];
