import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage',
})
export class PercentagePipe implements PipeTransform {
  transform(percentage: number): string {
    return `${Math.round((percentage + Number.EPSILON) * 100)}%`;
  }
}
