<!-- Shown elements -->
<div>
  <app-responsive-table-header 
    [buttons]="buttons" 
    [length]="amountOfRecordsPerPage" 
    [totalElements]="totalElements"                           
    [currentListLength]="currentListLength"                           
    [pageSize]="pageSize" 
    [useFilters]="settings.useFilters" 
    [isEndlessScroll]="isEndlessScroll" 
    [showElementCount]="showElementCount" 
    [dataType]="dataType"
    [buttons]="buttons" 
    [filterLabels]="filterLabels" 
    [allElements]="allElements" 
    (buttonClicked)="executeHeaderButtonAction($event)"                           
    (showFiltersClicked)="showFilters = $event"
  ></app-responsive-table-header>

  <!--
  [externalFilters]="externalFilters"
    [buttons]="buttons"
  -->
  <app-filters *ngIf="settings.useFilters && showFilters" [columns]="columns" (filtered)="onFiltered($event)"
    (clearedFilters)="onClearedFilters()">
  </app-filters>
</div>

<!-- End shown elements -->
<!-- start table -->
<div class="respontable" *ngIf="_data" >
  <div class="respontable--loading" [ngClass]="{ 'is-active': backgroundLoading }"></div>
  <table *ngIf="_data" id="respontable-table">
    <thead id="respontable-head">
      <tr>
        <!-- Start control's columns block //Delete selected, etc-->
        <td *ngIf="settings.hasSelect && settings.hasEditPermissions" class="align-center ellipsis-text medium-size-column">
          <ng-container *ngFor="let a of settings.actionsOnSelected">
            <i *ngIf="limitMinimumItems(a) && !a.text" (click)="a.click(selectedItems)" [ngClass]="a.class"
              [title]="a.title | translate"></i>
            <a *ngIf="limitMinimumItems(a) && a.text && !a.button" [ngClass]="a.class" [title]="a.title | translate"
              (click)="a.click(selectedItems)">{{ a.text | translate }}
            </a>
            <button *ngIf="a.button" [title]="a.title | translate" [disabled]="!limitMinimumItems(a)"
              [ngClass]="getButtonActionClass(limitMinimumItems(a), a)" (click)="a.click(selectedItems)">
              {{ a.text | translate }}
            </button>
          </ng-container>
        </td>
        <!-- End control's columns block -->

        <!-- Rest of the header columns -->
        <td *ngFor="let column of columns" (click)="column.orderBy ? onSort(column.orderBy) : false"
          [ngClass]="getDynamicClass(column)" [ngStyle]="{'width': column.name === 'comment' ? '60%' : 'auto'}" >
          {{ column.header | translate }}
          <mat-icon *ngIf="column.name === 'percentageOfFilledFields'" class="small-icon"
          [matTooltip]='percentageTooltip | translate'>info</mat-icon>
        </td>
        <!-- Start extra columns block -->
        <td *ngFor="let col of settings.extraColumns">
          {{ col | translate : 'showing' }}
        </td>
        <td *ngIf="settings.hasDownloadOptions" class="download">Download</td>
        <!-- End extra columns block -->
      </tr>
    </thead>
    <tbody *ngIf="!loading" id="respontable-body">
      <tr *ngFor="let row of _data.data | paginate : { id: dataType, itemsPerPage: _data.pageSize, currentPage: _data.page, totalItems: _data.totalElements }" [ngClass]="{ 'is-selected': row.respontableIsShowed, 'row-select': settings.hasOnClickRow }" (click)="settings.hasOnClickRow ? settings.actionOnClickRow(row) : true">
        <!-- Controls column -->
        <td *ngIf="settings.hasSelect && settings.hasEditPermissions" class="align-center respontable__controls">
          <ng-container *ngIf="shouldRender(row)">
            <!-- default config, items not remains when paginate -->
            <input *ngIf="!settings.hasSelectedFunctions" class="hide-mobile" type="checkbox" [(ngModel)]="row.respontableIsSelected" (change)="changeSelectedItems(row, 'id', settings.hasSingleSelect, $event)" />
            <!-- settings['dataId'] doesn't exist I think is because ngcontainer - (change)="changeSelectedItems(row, settings['dataId'])" -->

            <!-- Checkboxes and state of items remains when paginate, functions belongs to component to manage data -->
            <input *ngIf="settings.hasSelectedFunctions" class="hide-mobile" type="checkbox" [ngModel]="isSelected(settings.isSelected, row, _data)" (change)="changeItems(settings.changeSelectedItems, row, _data)" />

            <div class="hide-desktop">
              <i *ngFor="let act of settings.actionsOnResponsive" [ngClass]="act.class" (click)="act.click(row)"></i>
            </div>
          </ng-container>
        </td>
        <!-- Rest of the data columns -->
        <td class="responsive-title" [attr.responsive-label]="settings.responsiveTitle.second ? row[settings.responsiveTitle.label][settings.responsiveTitle.second] : row[settings.responsiveTitle.label]" (click)="showTableElement(row)">
          <!-- for Cell Being a link setting.clickLink -->
          <div class="responsive-title__link" *ngIf="settings.clickLink" (click)="settings.clickLink(row, $event)"></div>
          <!-- Show the Information of the cell format or not formatDat or status -->
          <div class="responsive-title__info">
            <div *ngIf="settings.responsiveTitle.formatDate">
              {{
              row[settings.responsiveTitle.value]
              | moment : settings.responsiveTitle.formatDate
              }}
            </div>
            <div *ngIf="!settings.responsiveTitle.formatDate">
              {{ row[settings.responsiveTitle.value] }}
            </div>
            <div *ngIf="settings.responsiveTitle.status">
              <span class="text-status" [ngClass]="getStatusClass(row.statusData.class)">
                {{ 'status.' + row.statusData.value | translate }}
              </span>
            </div>
          </div>
        </td>
        <td *ngFor="let col of columns" [ngClass]="col.class" [attr.responsive-label]="col.header | translate" >
          <app-column-responsive-table *ngIf="!col.optional || row[col.name]" [column]="col" [rowData]="row"></app-column-responsive-table>
        </td>

        <!-- Option to show a bunch of icons with actions-->
        <td *ngIf="settings.hasDownloadOptions" class="download">
          <i *ngFor="let act of settings.actionsOnDownload(row)" [ngClass]="act.class" [title]="act.title | translate" (click)="act.click(row, act.type)" aria-hidden="true"></i>
        </td>

        <td *ngFor="let col of settings.extraColumns" [attr.responsive-label]="'responsiveTable.' + col | translate" class="ellipsis-text medium-size-column" [title]="row.extra_columns[col]">
          <ng-container *ngIf="row.extra_columns[col]" title="row.extra_columns[col]">
            {{ row.extra_columns[col] }}
          </ng-container>
        </td>
      </tr>
    </tbody>
    <caption id="no-data" *ngIf="_data.data?.length == 0 && !loading && !backgroundLoading" style="color: white;">
      {{ (alternativeNoDataMessage ? alternativeNoDataMessage : 'common.noData') | translate }}
    </caption>
  </table>
  <div *ngIf="loading" class="respontable__main-spinner">
    <app-table-spinner-ball [spinnerStyles]="'la-ball-circus'"></app-table-spinner-ball>
  </div>
</div>
<!-- end table -->

<!-- start pagination -->
<div *ngIf="(hasRecords || loading || backgroundLoading) && !isEndlessScroll" class="custom-pagination" [ngClass]="settings.paginationClass">
  <app-table-spinner-ball *ngIf="loading || backgroundLoading"
    [spinnerStyles]="'la-ball-circus'"></app-table-spinner-ball>
<!--  <span class="show">Show 10 <i class="fas fa-chevron-down"></i></span>-->
  <pagination-controls *ngIf="!loading && !backgroundLoading && showPagination"
    (pageChange)="onPageChange($event, !settings.hasSelectedFunctions)" [id]="dataType"
                       previousLabel=""
                       nextLabel="">
  </pagination-controls>
</div>
<!-- end pagination -->
