import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-archive-offering-modal',
  templateUrl: './archive-offering-modal.component.html',
  styleUrls: ['./archive-offering-modal.component.scss']
})
export class ArchiveOfferingModalComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
  ){}

  ngOnInit(){}

  archive(){
    this.activeModal.close({
      action: 'archive',
    });
  }

  cancel() {
    this.activeModal.close({
      action: 'close'
    });
  }

}
