export const StatusCSSClasses = {
  NOTSTARTED_CLASS: 'text-status--not-started',
  STARTED_CLASS:  'text-status--started',
  TRAINING_CLASS: 'text-status--training',
  COMPLETED_CLASS: 'text-status--completed',
  TRANSCRIBING_CLASS: 'text-status--transcribing',
  PROCESSING_CLASS: 'text-status--processing',
  ANALYZING_CLASS:'text-status--analyzing',
  FAILED_CLASS: 'text-status--failed',
  SUCCESS_CLASS:'text-status--success',
}


export enum Status{
  NOTSTARTED =  'notStarted',
  STARTED =  'started',
  TRAINING =  'training',
  COMPLETED =  'completed',
  TRANSCRIBING =  'transcribing',
  PROCESSING = 'processing',
  ANALYZING = 'analyzing',
  FAILED = 'failed',
  SUCCESS = 'success',
}


export interface StatusData{
  class: string;
  value: string;
}
