import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';

@Component({
  selector: 'app-validation-content-modal',
  templateUrl: './validation-content-modal.component.html',
  styleUrls: ['./validation-content-modal.component.scss']
})
export class ValidationContentModalComponent {
  myForm!: FormGroup;
  userName!:any;
  role!:any;

  constructor(
    private authService: AuthenticationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) {
    this.myForm = this.formBuilder.group({
      userName: [''],
      role: [''],
      comment: ['', Validators.required],
    });
  }

  ngOnInit() {
   const user= this.authService.getLoggedInUser();
    this.myForm.patchValue({
      userName:user.username,
      role: user.role.name,
      comment:"",
    });
  }

  closeModal($event: MouseEvent) {
    this.activeModal.close({ action: 'close' })
  }

}
