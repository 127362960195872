<div class="filter-container">
    <div class="filter-heading-line">
      <div class="left">
        <h5>{{ filter?.name }}</h5>
        <mat-icon class="refresh-icon" (click)="clear()">refresh</mat-icon>
        <span class="clear-text" (click)="clear()">Clear</span>
      </div>
      <div class="right">
        <mat-icon class="remove-icon" (click)="remove()">clear</mat-icon>
      </div>
    </div>

    <div class="slider-wrapper">
        <span class="min-value">{{ formatLabel(minLabel) }}</span>
        <mat-slider
          [min]="minLabel"
          [max]="maxLabel"
          tickInterval="1"
          thumbLabel
          discrete
          [displayWith]="formatLabel"
          [disabled]="disabled"
          [title]="disabledTitle"
        >
          <input [value]="minSliderValue" (input)="minSliderChange($event)" matSliderStartThumb>
          <input [value]="maxSliderValue" (input)="maxSliderChange($event)" matSliderEndThumb>
        </mat-slider>         
        <span class="max-value">{{ formatLabel(maxLabel) }}</span>
    </div>

    <div class="inputs-container">
      <div>
        <mat-label class="label-text">Min Value</mat-label>
        <input 
          #minInput
          class="slider-input" 
          type="number" 
          [min]="globalMin" 
          [max]="globalMax - 1" 
          (input)="validateMinRangeInput($event)"
          (blur)="updateMinRange($event)"
          (keydown.enter)="updateMinRange($event)"
          [ngClass]="{ invalid: !validMinRange }"
          [disabled]="disabled"
          [title]="disabledTitle"
        />
        <mat-hint class="hint" *ngIf="!validMinRange">{{ minRangeValidMessage }}</mat-hint>
      </div>
        
      <div>
        <mat-label class="label-text">Max Value</mat-label>
        <input
          #maxInput
          class="slider-input" 
          type="number" 
          [min]="globalMin + 1" 
          [max]="globalMax" 
          (input)="validateMaxRangeInput($event)"
          (blur)="updateMaxRange($event)"
          (keydown.enter)="updateMaxRange($event)"
          [ngClass]="{ invalid: !validMaxRange }"
          [disabled]="disabled"
          [title]="disabledTitle"
        />
        <mat-hint class="hint" *ngIf="!validMaxRange">{{ maxRangeValidMessage }}</mat-hint>
      </div>
    </div>      
</div>