<div class="spinner-container" *ngIf="loading">
  <app-spinner-ball
    [spinnerStyles]="'la-ball-circus'"
  ></app-spinner-ball>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="myForm" *ngIf="!loading">
  <div class="modal__body">
    <div class="modal__row modal__row--flex">
      <mat-form-field>
        <mat-label>{{'components.modal.user.selectLicense' | translate : 'EY License'}}</mat-label>
        <mat-select formControlName="licenseName">
          <mat-option *ngFor="let license of licenseOptions" [value]="license.name">
            {{ 'components.modal.product.licenseList.' + license.name | translate : license.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <ng-container *ngIf="myForm.get('licenseName')?.value === 'Restricted License'">
        <mat-form-field>
          <mat-label>{{ 'components.modal.product.restriction' | translate : 'Restriction' }}</mat-label>
          <input
            matInput
            formControlName="restrictionName"
            name="restriction"
            required
          />
        </mat-form-field>
      </ng-container>
    </div>    
  </div>
  <div class="btn-container">
    <button class="pagehead__secondary-button" (click)="close()">Cancel</button>      
    <button 
      class='pagehead__primary-button' 
      [ngClass]="{ 'pagehead__primary-button--disabled': !myForm.valid }" 
      (click)="update()"
      [disabled]='!myForm.valid'
    >Update</button>
  </div>
</form>
  
