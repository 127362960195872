import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductEditorComment } from 'src/app/models/product-editor-comment.model';
import { ProductEditorCommentsService } from 'src/app/models/product-editor-comments.service';
import { Product } from 'src/app/models/product.model';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { ApiService } from 'src/app/services/api/api-service/api.service';
import { PageModel } from 'src/app/services/api/pagination-service/model/page.model';
import { PaginationModel } from 'src/app/services/api/pagination-service/model/pagination.model';
import { GenerateCrudModalFormService } from 'src/app/services/generate-crud-modal-form/generate-crud-modal-form.service';
import { UserService } from 'src/app/services/user-service/user.service';
import { ColumnBuilderService } from '../../responsive-table/column-builder/column-builder.service';
import { SettingTableType } from '../../responsive-table/model/setting-table.model';
import { PaginationService } from 'src/app/services/api/pagination-service/pagination.service';
import { tap } from 'rxjs';
import { FormGroup , FormBuilder, Validators } from '@angular/forms';
import { DEFAULT_MAX_LENGTH } from 'src/app/models/constants.model';

@Component({
  selector: 'app-product-editor-comments-modal',
  templateUrl: './product-editor-comments-modal.component.html',
  styleUrls: ['./product-editor-comments-modal.component.scss']
})
export class ProductEditorCommentsModalComponent  implements OnInit{

  @Input() product!:any;
  option = '';
  action = 'create';
  showWarning: boolean = false;
  totalItems = 0;
  rows?: PageModel<ProductEditorComment>;
  pagination?: PaginationModel;
  paginationType: string = 'product';  
  loading = true;
  backgroundLoading = true;
  rolesList: any[] = [];
  showCommentBox = false;
  productTableRows?: PageModel<ProductEditorComment>;
  productPaginationConfiguration = PaginationModel.createDefaultConfiguration('lastUpdatedAt', 1, 10,1);
  productTableColumnConfiguration: { header: string; name: keyof ProductEditorComment; class: string; orderBy?: string | undefined; }[] = [];
  settingTable = new SettingTableType<ProductEditorComment>();
  fields: [ { name: keyof ProductEditorComment, type: string, data?: any} ] | any;
  filters: any = [];
  myForm!:FormGroup;

  closeModal($event: MouseEvent) {
    this.activeModal.close({ action: 'close' })
  }
  
  constructor(
    public activeModal: NgbActiveModal,
    public columnBuilder: ColumnBuilderService, 
    protected modalService: NgbModal,
    public modalCreator: GenerateCrudModalFormService,
    public productEditorCommentService: ProductEditorCommentsService,
    public userService: UserService,
    private apiService: ApiService,
    private paginationService: PaginationService,
    public alertService: AlertService,
    private translate: TranslatePipe,
    private formBuilder: FormBuilder
  ) {
      this.myForm = this.formBuilder.group({
        comment:  ['', [Validators.required, Validators.maxLength(DEFAULT_MAX_LENGTH)]],
      });
  }

  ngOnInit(): void {
    this.pagination = this.paginationService.getDefaultPagination(
      'products' 
    );
    
    this.fillFields();
    this.setproductTableColumConfiguration();
    this.setSettingsTable();
    this.getproducts();
  }

  ngOnChanges() {
    this.pagination = this.paginationService.getDefaultPagination(
      'products' 
    );
    this.setproductTableColumConfiguration();
    this.setSettingsTable();
    this.getproducts();
  }
 

  setproductTableColumConfiguration() {
    const confColumns = this.columnBuilder.configureColumns<ProductEditorComment>(ProductEditorComment, ProductEditorComment.getEmptyProductEditorComment());
    this.productTableColumnConfiguration = this.columnBuilder.getTableColumnConfiguration<ProductEditorComment>(confColumns,
      [],
      ['username','lastUpdatedAt', 'comment']
    )
    for (const col of this.productTableColumnConfiguration) {
      const customCol = col as any;
      // Customize product name column
      switch(col.name){
        case 'username':
          customCol.header = this.translate.transform('username', 'User Name');
          customCol.name = 'username';
          break;
        case 'lastUpdatedAt':
          customCol.format = 'll'
          break;
        case 'comment':
          customCol.type = 'long-text';
          break;
      }

      // Add translation to headers
      col.header = `page.admin.table.editorComment.column.${col.header}`;
    }
  }

  removeNullValuesFromObject(obj: any) {
    for (const key in obj) {
      if (obj[key] == null)
        delete obj[key]
    }

    return obj;
  }

  
  refresh(): void {
    this.getproducts();
  }

  setSettingsTable() {
    this.settingTable.dataId = 'id';
    this.settingTable.hasSelect = false;
    this.settingTable.getDataFromDatabase = this.getproducts.bind(this);
    
    
    this.settingTable.responsiveTitle.label = 'username';

  }
  
  private setParams() {
    this.productPaginationConfiguration.desc = 1;
    this.productPaginationConfiguration.orderBy = 'lastUpdatedAt';
    this.productPaginationConfiguration.page = 1;
  }

  getproducts(params: { [keyof: string]: any } = {}) {
    console.log('getDocumentsFromDatabase/setproducts')

    this.getDataFromDatabase(this.productPaginationConfiguration);
  }
 
  getproducts$(params: { [keyof: string]: any } = {}) {
    return  this.productEditorCommentService
    .getProductEditorComment$(this.product._id, { ...this.productPaginationConfiguration, ...params }).pipe(
      tap(data => this.productTableRows = data),
      tap(data => this.totalItems = data.totalElements),
      tap(_ => {
        this.loading = false;
        this.backgroundLoading = false;
      })
    )
  }
  

  private getDataFromDatabase(params: any) {
    if (!params){
      params = this.setParams();
    }
    const slug = `historicProductEditorComments/${this.product._id}`;
    const getproducts$ = this.apiService.get<PageModel<ProductEditorComment>>(slug, params, '');
    const assignproductsToTable$ = getproducts$.pipe(
      tap(data => {
        this.productTableRows = data;
        this.totalItems = data.totalElements;
        this.loading = false;
        this.backgroundLoading = false;
      }));
    const assignToPagination$ = assignproductsToTable$.pipe(
      tap(data => {
        this.paginationService.setPage<ProductEditorComment>('products', data);
        if(this.paginationService.getPage('products').orderBy === null || this.paginationService.getPage('products').orderBy === undefined)
        {
          // even when we are seeing that big pageSize here, it must be changed in the corresponding .srv file in api
          const paginationObj = PaginationModel.createDefaultConfiguration('lastUpdatedAt', 1, 9999, this.productPaginationConfiguration.page);
          this.pagination = this.paginationService.setPagination(
            'products', paginationObj);
        }
        
        this.loading = false;
        this.backgroundLoading = false;
      })
    );
    assignToPagination$.subscribe();
  }

  
 
  public onLoadingChange(isLoading: boolean) {
    this.loading = isLoading;
  }

  
  onSelected($event: Event) {
    // console.log(`onSelected:`, $event);
  }
  selectedItems: Product[] = [];
  onSelectedItems(selectedItems: Product[]) {

    // console.log(`Selected items: ${selectedItems.length}`);
    this.selectedItems = selectedItems;
  }
  showFilters: boolean = false;
  isTextBoxVisible: boolean = false;
  

  /**
  * Variable to show the filter panel or not
  */
  public toggleShowFilters(event: MouseEvent) {
    this.showFilters = !this.showFilters;
  }

  toggleTextBox() {
    this.isTextBoxVisible = !this.isTextBoxVisible;
  }
  
  /**
   * When pagination is going to start a change
   * @param pageToChange: page to change
   * @param resetSelected: maintain or not the current selection
  */
  onTablePageChange([pageToChange, resetSelected]: [number, boolean]) {
    this.productPaginationConfiguration.page = pageToChange;
  }
  /**
   * When click on sort a field
   * @param orderBy: fieldName that is going to be orderBy
   * @param resetSelected:
   */
  onTableSort([orderBy, resetSelected]: [string, boolean]) {
    this.productPaginationConfiguration.orderBy = orderBy;
    this.productPaginationConfiguration.page = 1;
    this.productPaginationConfiguration.desc = this.productPaginationConfiguration.desc === 0 ? 1 : 0;
  }

  public onSearch(filters: any) {
    this.filters = {
      username:
        filters['username'] !== undefined && 
        filters['username'] !== null ? 
        filters['username'] : 
        '',
      lastUpdatedAt: 
        filters['lastUpdatedAt'] !== undefined && 
        filters['lastUpdatedAt'] !== null ? 
        filters['lastUpdatedAt'] : 
        '',
      comment: 
        filters['comment'] !== undefined && 
        filters['comment'] !== null ? 
        filters['comment'] : 
        ''
    };
    let filterConfig = {...this.productPaginationConfiguration, ...this.filters}
    this.getDataFromDatabase(filterConfig);
  }

  fillFields(){
    this.fields = [ 
      { name: "username", type: "input" },  
      { name: "lastUpdatedAt", type: "input"},
      { name: "comment", type: "input"}
    ]
  }

  toggleShowCommentBox(event: MouseEvent): void {
    event.preventDefault();
    this.showCommentBox = !this.showCommentBox;
  }

  hasError(): boolean {
    const commentControl = this.myForm.get('comment');
    return commentControl ? commentControl.hasError('maxlength') : false;
  }

}
