<div class="breadcrumbs">
	<ul>
		<li *ngFor="let bc of breadcrumbs; let i = index">
			<span (click)="link.goToRoute(bc.url)">
				<span class="icon" *ngIf="bc.icon">
					<i [class]="'fa fa-'+bc.icon" aria-hidden="true"></i>
        		</span>
				<span class="name" *ngIf="bc.label">{{bc.label}}</span>
			</span>
		</li>
	</ul>
</div>
