import { Provider } from "@angular/core";
import { UserModel } from "./user.model";


/**
 * HistoricProviderValidation.
 */
export class HistoricProviderValidation {
  static getEmptyHistoricProviderValidation(): HistoricProviderValidation | undefined {
    const result = new HistoricProviderValidation();
    result.lastValidatedBy = UserModel.getEmptyUser();
    return result;
  }
  username?:string;
  role?:string;
  lastValidatedBy: UserModel |any ;
  lastValidatedAt: string = HistoricProviderValidation.formattedTimestamp(new Date());
  comment: string = '';
  provider:Provider| any;

  static formattedTimestamp(date: Date){
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2,'0');
    const hours = String(date.getUTCHours()).padStart(2,'0');
    const minutes = String(date.getUTCMinutes()).padStart(2,'0');
    const seconds = String(date.getUTCSeconds()).padStart(2,'0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
