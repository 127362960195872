import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { ProviderModel } from 'src/app/models/provider.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderToExcelService {

  constructor() { }

  downloadExcel(provider: any, fieldsToSend: any, filename: string) {

    const propertiesToIgnore: (keyof ProviderModel)[] | string[] = [
      "id", "eyResponsible", "lastUpdatedBy", "attachments", "contact", "createdDate", 
      "active", "lastUpdated", "lastUpdatedByMetadata", "lastUpdatedMetadata", 
      "attentionRequired", "notes", "slug", "flag", "percentageOfFilledFields", 
      "status", "providerResponsible", "url", "flagDescription", "lastFlagUpdatedBy", 
      "lastValidated", "lastValidatedBy", "lastValidationDescription", "editorComment", 
      "developmentPipeline", "playerURL", "shortPlayerDescription", "lowerCaseProvider", 
      "lowerCaseDescription", "commentsCount",
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Changes');

    // Set up headers for the Excel file
    worksheet.columns = [
      { header: 'Property', key: 'property' },
      { header: 'Value', key: 'value' }
    ];

    // Iterate over the properties of the provider object
    Object.keys(provider).forEach(key => {

      if (propertiesToIgnore.includes(key)) {
        return;
      }

      const providerValue = provider[key];
      const sentValue = fieldsToSend[key];

      // Add the property and value to the worksheet
      const row = worksheet.addRow({ property: key, value: providerValue });

      // Check if the value has changed and highlight the cell if it has
      if (fieldsToSend.hasOwnProperty(key) && sentValue === providerValue) {
        row.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFEB5757' }, // Rose red color
          };
        });
      }
    });

    // Write Excel file to buffer and save
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), filename + '.xlsx');
    });
  }


}
