import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './security/services/authentication/authentication.service';
import { DataMasterService } from './services/data-master-service/data-master';
import { tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'EY ESG Platform';
  loading = true;
  isChatOpen = false;
  constructor(
    private dataMasterService: DataMasterService,
    public authService: AuthenticationService,
  ) {

    this.authService.isLoggedSuccessEvent$.subscribe({
      next: () => {
        this.loading = false;
        console.log('this.authService.isLoggedSuccessEvent$ Complete');
      },
      error: (e) => {
        this.loading = false;
      },
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false
    }, 1000)
    // this.dataMasterService.getDropdownData();
  }
}
