import { Component, Input} from '@angular/core';
import { PageModel } from 'src/app/services/api/pagination-service/model/page.model';
import { SettingTableType } from '../../responsive-table/model/setting-table.model';
import { Changelog } from 'src/app/models/changelog.model';
import { ColumnBuilderService } from '../../responsive-table/column-builder/column-builder.service';
import { PaginationModel } from 'src/app/services/api/pagination-service/model/pagination.model';
import { ProvidersFieldsBackupService } from 'src/app/services/providers-fields-backup/providers-fields-backup.service';
import { tap, forkJoin } from 'rxjs';
import { DataMasterService } from 'src/app/services/data-master-service/data-master';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';

@Component({
  selector: 'app-provider-datafield-changelog',
  templateUrl: './provider-datafield-changelog.component.html',
  styleUrls: ['./provider-datafield-changelog.component.scss']
})
export class ProviderDatafieldChangelogComponent {
  @Input() field!: any;
  @Input() providerId!: string | any;
  @Input() providerGlossary!: any;
  @Input() providerName!: string | any;

  loading: boolean = true;
  showFilters: boolean = false;
  filterFields: any[] = []
  totalItems: number = 0;
  currentListLength: number = 0;
  filters: any = {}; // to send to the api
  showHeading: boolean = false

  changelogTableRows: PageModel<Changelog> | any;
  changelogTableRowsBackup: Changelog[] | any = [];
  changelogPaginationConfiguration = PaginationModel.createDefaultConfiguration('', 0, 5);
  changelogTableColumnConfiguration: { header: string; name: keyof Changelog; class: string; orderBy?: string | undefined; }[] = [];
  settingTable = new SettingTableType<Changelog>();
  selectedItem: any;

  constructor(
    public columnBuilder: ColumnBuilderService, // Configure the columns of your table
    private providersFieldsBackupService: ProvidersFieldsBackupService,
    private dataMasterService: DataMasterService,
    private authService: AuthenticationService,
    private providerService: ProviderCollectionService,
    private activeModal: NgbActiveModal,
  ){}

  ngOnInit(){

    this.fillFields();
    this.setChangelogTableColumConfiguration();
    this.setSettingsTable();

    if(this.providerId && this.field.name){
      this.setChangelogs();
    }
  }

  public toggleShowFilters(event: MouseEvent) {
    this.showFilters = !this.showFilters;
  }

  onTablePageChange([pageToChange, resetSelected]: [number, boolean]) {
    this.changelogPaginationConfiguration.page = pageToChange;
  }

  onTableSort([orderBy, resetSelected]: [string, boolean]) {
    this.changelogPaginationConfiguration.orderBy = orderBy;
    this.changelogPaginationConfiguration.page = 1;
    this.changelogPaginationConfiguration.desc = this.changelogPaginationConfiguration.desc === 0 ? 1 : 0;
  }

  fillFields(){

    const roles = this.dataMasterService.getRoles()

    roles.forEach(r => delete r['id']); // we delete it so it does not take the id as value for this dropdown options

    this.filterFields = [ 
      { name: "enteredValue", type: "input" }, 
      { name: "editorName", type: "input" }, 
      { name: "editorRole", type: "dropdown", data: roles }, 
      { name: "timeOfChange", type: "datepicker-range" , placeholder:"Enter a date range"}, 
    ];
  }

  onSelectedItems(selectedItems: any[]) {
    this.selectedItem = selectedItems[0];
  }

  setSettingsTable() {
    this.settingTable.dataId = 'id';
    this.settingTable.hasSelect = true;
    this.settingTable.hasSingleSelect = true;
    this.settingTable.actionsOnSelected = [];
    this.settingTable.getDataFromDatabase = this.setChangelogs.bind(this);
  }

  reApplyValue(field: any){
    const currentUser = this.authService.getLoggedInUser();

    const fieldBackup = {
      providerId : this.providerId,
      fieldName: this.field.name,
      changeLog: [
        {
          editor: {
            _id: currentUser._id,
            username: currentUser.username,
            role: currentUser.role.name
          },
          date: new Date(),
          previousValue: field.previousValue
        }
      ]
    };

    const key = this.field.name;
    const value = field.previousValue;
    const characterictic = this.providerGlossary[key];
    const fieldToSend = { [characterictic.front_name]: value };

    forkJoin([
      this.providersFieldsBackupService.saveFieldBackup(fieldBackup),
      this.providerService.updateProviderChangedFieldsOnly$(this.providerId, fieldToSend)
    ]).subscribe({
      next: () => {
        this.activeModal.close({
          field: { ...field, fieldName: this.field.name, providerId: this.providerId },
          action: 'submit',
        })
        // this.setChangelogs();
        // this.selectedItem = null;
      }, 
      error: () => {

      }
    })
  }

  setChangelogTableColumConfiguration() {
    const confColumns = this.columnBuilder.configureColumns<Changelog>(Changelog, new Changelog());

    this.changelogTableColumnConfiguration = this.columnBuilder.getTableColumnConfiguration<Changelog>(
      confColumns,
      ['_id', 'id'],
      ['previousValue', 'editor', 'date']
    );

    for (const col of this.changelogTableColumnConfiguration) {
      // Customize product attribute objects: notes, categories, attributes
      const customCol = col as any;
      switch (col.name) {
        case 'previousValue':
          customCol.type = "description"
          break;
        case "editor":
          customCol.type = "editor-and-role"
          customCol.second = "username"
          customCol.third = "role"
          break;
        case "date":
          break;
      }
      // Add translation to headers
      col.header = `page.product.profile.changelog.modal.column.${col.header}`;
    }
  }

  setChangelogs(params: { [keyof: string]: any } = {}) {
    this.loading = true;
    this.setChangelogs$(params).subscribe(); 
  }

  setChangelogs$(params: { [keyof: string]: any } = {}) {
    if(Object.keys(params).length === 0 && Object.keys(this.filters).length > 0){
      params = this.filters;
    }

    return this.providersFieldsBackupService.getFieldsBackupWithPagination$(this.providerId, { ...this.changelogPaginationConfiguration, ...params, fieldName: this.field.name }).pipe(
      tap(data => this.changelogTableRows = data),
      tap(data => this.totalItems = data.totalElements),
      tap(data => this.currentListLength = data.data.length),
      tap(_ => {
        this.loading = false;
        this.showHeading = true;
      })
    )
  }

  onSearch(filters: any){
    this.filters = {
      enteredValue:
        filters['enteredValue'] !== undefined && 
        filters['enteredValue'] !== null ? 
        filters['enteredValue'] : 
        '',
      editorName: 
        filters['editorName'] !== undefined && 
        filters['editorName'] !== null ? 
        filters['editorName'] : 
        '',
      editorRole: 
        filters['editorRole'] !== undefined && 
        filters['editorRole'] !== null ? 
        filters['editorRole'] : 
        '',
      timeOfChange: 
        filters['timeOfChange'] !== undefined ? 
        filters['timeOfChange'] : 
        ''
    };

    this.setChangelogs(filters)
  }

}
