import { Component, OnInit, Input, } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  ValidatorFn, AbstractControl,
} from '@angular/forms';
import { Observable, map, startWith, tap } from 'rxjs';
import { DataMasterService } from 'src/app/services/data-master-service/data-master';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { ProviderModel } from 'src/app/models/provider.model';
import { UserModel } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user-service/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataValidationService } from 'src/app/services/data-validation/data-validation.service';

@Component({
  selector: 'app-admin-provider-modal',
  templateUrl: './admin-provider-modal.component.html',
  styleUrls: ['./admin-provider-modal.component.scss']
})
export class AdminProviderModalComponent implements OnInit{
  myForm!: FormGroup;
  @Input() data!:ProviderModel;
  option = '';
  action = 'create';
  @Input() eyResponsibleList: UserModel[] = [];
  showWarning: boolean = false;
  eyResponsibleArray: string[] = [];
  filteredEyResponsible: Observable<string[]> | undefined;

  closeModal($event: MouseEvent) {
    this.activeModal.close({ action: 'close' })
  }
  constructor(
    //private roleService: RoleService,
    public activeModal: NgbActiveModal,
    private userService : UserService,
    public dataMasterService: DataMasterService,
    protected alertService: AlertService,
    protected translate: TranslatePipe,
    private formBuilder: FormBuilder,
    public dataValidationService: DataValidationService
  ){
    this.myForm = new FormGroup({
      playerName: new FormControl('', [Validators.required]),
      playerDescription: new FormControl('', [Validators.required]),
      eyResponsibleName: new FormControl('', [Validators.required, this.eyResponsibleNameValidator()]),
      providerResponsible: new FormControl('', [Validators.email, this.emailValidator.bind(this)]),
      eyResponsible: new FormControl('')
    });
    this.generateCategoriesStructure()
  }

  ngOnInit(){
    if (this.data && this.data.playerName) {
      this.myForm = this.formBuilder.group({
        id: this.data.id,
        playerName: this.data.playerName,
        playerDescription: this.data.playerDescription,
        eyResponsibleName: this.data.eyResponsible.username,
        providerResponsible: this.data.providerResponsible,
        eyResponsible: this.data.eyResponsible.id,
      });
      this.mapResponsibleName();
    }
    this.filteredEyResponsible = this.myForm.valueChanges.pipe(
      startWith(''),
      map(value => this.filterEyResponsible(value))
    );
  }

  generateCategoriesStructure(){
    const requiredFields = ["ID_P_042", "ID_P_003", "ID_P_005"]; // Player ID, Player Name, Player Description - EY Responsible must be done separately
    let filteredCharacteristics: any[] = [];

    this.dataValidationService.getProviderDataValidation$().subscribe((dataValidation: any) => {
      filteredCharacteristics = dataValidation.characteristics.filter((characteristic: any) => requiredFields.includes(characteristic.unique_id));
    });

    for (const characteristic of filteredCharacteristics) {
      if (characteristic.unique_ID === "ID_P_003" || characteristic.unique_ID === "ID_P_005"){
        this.myForm.addControl(characteristic.front_name, new FormControl('', [Validators.required]))
      }
      if (characteristic.unique_ID === "ID_P_042"){
        this.myForm.addControl(characteristic.front_name, new FormControl(''))
      }
    };
  }

  eyResponsibleNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOption = this.eyResponsibleList.some(eyResponsible => eyResponsible.username === control.value);
      return isOption ? null : { 'invalidOption': true };
    };
  }

  mapResponsibleName(){
    const eyResponsible = this.eyResponsibleList.find(r => r.username === this.myForm.value.eyResponsibleName);
    this.myForm.patchValue({"eyResponsible": eyResponsible?.id})
    //variable showWarning será true si el ey responsible tiene el rol EY Contributor
    if(eyResponsible?.role.name == "EY Contributor"){
      this.showWarning = true;
    } else {
      this.showWarning = false;
    }
  }

  emailValidator(control: FormControl): { [key: string]: any } | null {
    const emailRegEx = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (control.value && !emailRegEx.test(control.value)) {
      return { 'invalidEmail': true };
    }
    return null;
  }

  filterEyResponsible(value: any): string[]{
    let filterValue = '';
    if(value.eyResponsibleName != undefined){
      filterValue = value.eyResponsibleName.toLowerCase();
    }
    return this.eyResponsibleArray.filter(eyResponsible => eyResponsible.toLowerCase().includes(filterValue));
  }

  fill(){
    this.eyResponsibleArray = this.eyResponsibleList!.map(eyResponsible=> eyResponsible.username);
    this.myForm.get('eyResponsibleName')?.setValue('');
  }

  mapEYResponsibleId(){
    const eyResponsible = this.eyResponsibleList.find((eyResponsible: UserModel) => eyResponsible.username === this.myForm.value.eyResponsibleName)
    this.myForm.get('eyResponsible')?.setValue(eyResponsible?._id);
  }

}
