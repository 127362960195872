import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject, filter, map, mergeMap, tap } from 'rxjs';
import { ProviderModel } from 'src/app/models/provider.model';

@Injectable({
  providedIn: 'root'
})
export class RoutingInfoService implements OnInit {

  protected _currentId: string = '';
  onProviderPage$ = new Subject<ProviderModel>();
  protected _providerId: string = '';
  protected _productId: string = '';
  protected _url: string = '';

  get providerId() { return this._providerId; }
  set providerId(value: string) { this._providerId = value; }

  get productId() { return this._productId; }
  set productId(value: string) { this._productId = value; }

  get currentId() { return this._currentId; }
  set currentId(value: string) { this._currentId = value; }

  get url() { return this._url; }
  set url(value: string) { this._url = value; }

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router) {
    // this.router.events // All events

    this.navigationEnded$.pipe(
      filter(event => !!this.isProviderProductPage(event.urlAfterRedirects) || !!this.isProductPage(event.urlAfterRedirects) || !!this.isProviderPage(event.urlAfterRedirects)),
      map((event) => {
        if (this.isProviderProductPage(event.urlAfterRedirects)) {
          this.productId = event.url.split('/')[5];
          this.providerId = event.url.split('/')[3];
          this.url = event.url;
          return '';
        } else if (this.isProductPage(event.urlAfterRedirects)) {
          this.productId = event.url.split('/')[3];
          this.url = event.url;
          return '';
        } else {
          this.providerId = event.url.split('/')[3];
          this.url = event.url;
          return '';
        }
      }),
    ).subscribe();
  }

  ngOnInit() {

  }

  navigationEnded$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(event => event as NavigationEnd),
  );

  isProviderPage(url: string) {
    return url.split('/').length === 4 && url.split('/')[2] === 'provider';
  }

  isProviderProductPage(url: string) {
    return url.split('/').length === 6;
  }

  isProductPage(url: string) {
    return url.split('/').length === 4 && url.split('/')[2] === 'product' || url.split('/').length === 4 && url.split('/')[2] === 'offering';
  }

  isFrameworkPage(url: string): boolean{
    return url.split('/').length === 2 && url.split('/')[1] === "framework-coverage";
  }
  isVisualMarketPage(url: string): boolean{
    return url.split('/').length === 3 && url.split('/')[1] === "visual-market";
  }

  isMarketDiscoveryPage(url: string): boolean{
    return url.split('/').length === 2 && url.split('/')[1] === "market-discovery";
  }


  isProfileLanding(url: string): boolean{
    return url.split('/').length >= 3 && url.split('/')[1] === "profile";
  }

  goToProviderProductPage(productId: string) {
    if (this.url.split('/')[1] === 'market-discovery') {
      return this.router.navigateByUrl(`/market-discovery/provider/${this.providerId}/product/${productId}`);
    } else if(this.url.split('/')[1] === 'visual-market'){
      return this.router.navigateByUrl(`/visual-market/provider/${this.providerId}/product/${productId}`);
    } else if(this.url.split('/')[1] === 'framework-coverage'){
      return this.router.navigateByUrl(`/framework-coverage/provider/${this.providerId}/product/${productId}`);
    } else {
      return this.router.navigateByUrl(`/editor-panel/provider/${this.providerId}/product/${productId}`);
    }
  }

  goToProductPage(productId: string) {
    if (this.url.split('/')[1] === 'market-discovery') {
      return this.router.navigateByUrl(`/market-discovery/product/${productId}`);
    } else if(this.url.split('/')[1] === 'visual-market'){
      return this.router.navigateByUrl(`/visual-market/product/${productId}`);
    } else if(this.url.split('/')[1] === 'framework-coverage'){
      return this.router.navigateByUrl(`/framework-coverage/product/${productId}`);
    } else {
      return this.router.navigateByUrl(`/editor-panel/product/${productId}`);
    }
  }

  goToProductPageNewTab(productId: string) {
    if (this.url.split('/')[1] === 'market-discovery') {
      window.open(`/market-discovery/product/${productId}`);
    } else if(this.url.split('/')[1] === 'visual-market'){
      window.open(`/visual-market/product/${productId}`);
    } else if(this.url.split('/')[1] === 'framework-coverage'){
      window.open(`/framework-coverage/product/${productId}`);
    } else {
      window.open(`/editor-panel/product/${productId}`);
    }
  }
  
  goToProductPageNewTabFromEditor(productId: string){
    window.open(`/profile/offering/${productId}`);
  }

  goToProviderPage(providerId: string) {
    if (this.url.split('/')[1] === 'market-discovery') {
      return this.router.navigateByUrl(`/market-discovery/provider/${providerId}`)
    } else if(this.url.split('/')[1] === 'visual-market'){
      return this.router.navigateByUrl(`/visual-market/provider/${providerId}`);
    } else if (this.url.split('/')[1] === 'framework-coverage') {
      return this.router.navigateByUrl(`/framework-coverage/provider/${providerId}`)
    } else {
      return this.router.navigateByUrl(`/editor-panel/provider/${providerId}`)
    }
  }

  goToProviderPageNewTab(providerId: string) {
    if (this.url.split('/')[1] === 'market-discovery') {
      window.open(`/market-discovery/provider/${providerId}`, '_blank');
    } else if(this.url.split('/')[1] === 'visual-market'){
      window.open(`/visual-market/provider/${providerId}`, '_blank');
    } else if (this.url.split('/')[1] === 'framework-coverage') {
      window.open(`/framework-coverage/provider/${providerId}`, '_blank');
    } else {
      window.open(`/editor-panel/provider/${providerId}`, '_blank');
    }
  }
  
  goToProviderPageNewTabFromEditor(providerId: string){
    window.open(`/profile/player/${providerId}`, '_blank');
  }

  getIdFromCurrentPage$(idName: string) {
    return this.navigationEnded$.pipe(
      mergeMap(_ => this._getIdFromCurrentPage(idName))
    );
  }

  protected _getIdFromCurrentPage(idName: string): string {
    let child = this.activatedRoute.firstChild;
    while (child) {
      if (child.snapshot.params[idName]) {
        return child.snapshot.params[idName];
      }
      child = child.firstChild;
    }
    return '';
  }

  routeToPath(url :string){
    this.router.navigateByUrl(url);
  }

}
