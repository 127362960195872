import { EY_EXPERT, ADMINISTRATOR, PROVIDER, VIEWER } from "src/app/constants/userRoles";

declare const window: any;

export const environment = {
  mock_authentication: false, // true, // This is use because in DEBUG is awful login in from the browser, OTA, E&Y login restrictions.
  env: "dev",
  roleName: ADMINISTRATOR, // VIEWER, // PROVIDER, // EY_EXPERT,
  //API URL AND VERSION
  apiUrl: "https://euwdtdfevdwap08.azurewebsites.net",
  genAiApiUrl: "https://euwdtdfevdwap09.azurewebsites.net",
  apiVersion: '/api/v1/',
  // MSAL AZURE AUTHENTICATION
  clientId: "4fa62133-4854-498e-9e02-64965c3af6f7",
  tenantId: "5b973f99-77df-4beb-b27d-aa0c70b8482c", 
  redirectUri: "https://euwdtdfevdwap07.azurewebsites.net",
  msalApiScope: "api://CTP-CoDev-ASSURANCE-TDF_ESG_VENDORS-AUT-D00/access_as_user",  
}