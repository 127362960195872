import { SettingTableType, TableAction } from 'src/app/components/responsive-table/model/setting-table.model';
import { PageModel } from 'src/app/services/api/pagination-service/model/page.model';
import { Subject } from 'rxjs';
// import { UtilsService } from 'src/app/services/utils.service';
import { Component, Input, OnInit, Output, EventEmitter, ElementRef, OnChanges } from '@angular/core';
// import { Status } from 'src/app/constants/completitionStatus';
// import { PaginationService, PaginationType } from 'src/app/services/pagination.service';
// import { ExternalFilters, FilterLabel, FilterResults } from '../filters/filters.interfaces';
import { ResponsiveTableButton } from '../responsive-table-header/responsive-table-header.component';
import { PaginationService } from 'src/app/services/api/pagination-service/pagination.service';
import { ExternalFilters, FilterResults } from '../filters/filters.interfaces';
import { Status } from 'src/app/constants/completitionStatus';
import { UtilsService } from 'src/app/services/utils-service/utils.service';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { Page } from 'powerbi-client';

@Component({
  selector: 'app-responsive-table',
  templateUrl: './responsive-table.component.html',
  styleUrls: ['./responsive-table.component.scss'],
})

export class ResponsiveTableComponent implements OnInit {

  selectedItems: Array<number> = [];
  @Input() showPagination = true;
  @Input() tempRows: any[] = [];
  @Input() isEndlessScroll = false;
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() filtered = new EventEmitter<FilterResults>();
  @Output() clearedFilters = new EventEmitter<any>();
  @Output() onSelected = new EventEmitter<[boolean, any]>();
  @Output() onSelectedItems = new EventEmitter<any[]>();
  // Our new events
  @Output() onSortEvent = new EventEmitter<[string, boolean]>();
  @Output() onPageChangeEvent = new EventEmitter<[number, boolean]>();

  @Input()

  set data(value: any) {
    if (this.settings?.maintainSelectedDeep && value?.data && this.settings?.previouslySelected?.length > 0) {
      this.retrieveSelectedItems(value.data, this.settings.previouslySelected);
    }
    this._data = value;
  }
  _data!: PageModel<any>;

  // dataType Seems like the name of the table 'users'
  @Input() dataType: string = 'users';
  @Input() currentColumn: any = 'username';
  @Input() columns: any[] = [];
  @Input() settings = new SettingTableType<any>();
  @Input() loading: boolean = false;
  @Input() backgroundLoading: boolean = false;
  @Input() showElementCount: boolean = true;
  @Input() buttons: ResponsiveTableButton[] = [];
  @Input() externalFilters?: ExternalFilters;
  @Input() alternativeNoDataMessage: string = 'No data available';
  @Input() attrdesc: number = 0;
  @Input() newOrderBy: string = '';
  @Input() currentListLength: number = 0;

  get selectedItemsArray(): any[] | void {
    if (this._data?.data) {
      return this._data['data'].filter(
        (d: any) => d.respontableIsSelecteds).map((d: any) => d[this.settings['dataId']]);
    }
  }
  get hasRecords() {
    return this._data?.data?.length > 0
  }

  titleKey(column: any) {
    return `responsive-table.table.header.column.value.${column.header}`
  }

  get amountOfRecordsPerPage() { return this._data?.data?.length ?? 0; }
  get totalElements() { return this._data?.totalElements ?? 0; }
  get pageSize() { return this._data?.pageSize ?? 0; }

  isOpen: boolean = false;
  showFilters: boolean = false;
  filterLabels: any[] = [];
  percentageTooltip!: string;
  allElements: number = 0;
  // filterLabels: FilterLabel[] = [];

  constructor(
    protected paginationService: PaginationService,
    protected utilsService: UtilsService,
    protected translate: TranslatePipe,
  ) {
  }

  ngOnInit() {
    this.tempRows = this._data.data;
    this.allElements = this._data.totalElements;
    this.resetSelect();
    this.setPercentageTooltip();
  }

  ngOnChanges() {
    this.tempRows = this._data.data;

    //This ensures that when all the elements of the last page are deleted we don't end up with an empty page and no possibility of navigating back to the previous pages
    if (this._data.data.length == 0 && this._data.page != 1){
      this.onPageChange((this._data.page - 1), true)
    }
  }


  /**
   * Return CSS Class for Status Colors
   */
  getStatusClass(status: Status) {
    switch (status) {
      case Status.NOTSTARTED:
        return 'text-status--not-started';
      case Status.STARTED:
        return 'text-status--started';
      case Status.TRAINING:
        return 'text-status--training';
      case Status.COMPLETED:
        return 'text-status--completed';
      case Status.TRANSCRIBING:
        return 'text-status--transcribing';
      case Status.PROCESSING:
        return 'text-status--processing';
      case Status.ANALYZING:
        return 'text-status--analyzing';
      case Status.FAILED:
        return 'text-status--failed';
      case Status.SUCCESS:
        return 'text-status--success';
    }
    return 'some status'
  }

  titleRowCol(row: any, col: any) {
    if (col.name === 'description' || col.name === "role" ) {
      return "";
    }
    if (col.title) {
      if (col.second) {
        return row[col.name][col.second];
      }
      else if (col.customValueExtraction) {
        return col.customValueExtraction(row);
      }
      else {
        return row[col.title];
      }
      // return col.title ? col.second  ? row[col.name][col.second]   : row[col.title]    : (col.header | translate)
    }
    return this.translate.transform(col.header);
  }

  /**
   * Clean the selectedItems (array and items from data)
   */
  resetSelect() {
    this.selectedItems = [];
    if (this._data && this._data.data.length > 0) {
      this._data.data.map((item: any) => item.respontableIsSelected = false);
    }
  }

  /**
    * Order displayed elements and update pagination
    */
  onSort(orderBy: string, reset = true) {
  if(orderBy == "attributeName"|| orderBy == "attributeDescription" || orderBy == "dataSetName")
  {
      this.newOrderBy = orderBy;
      this.sortByParameter(this.tempRows,orderBy,this.attrdesc);
      const currentPage = this._data.page;
      const desc = this.attrdesc;
      if (this.paginationService.hasPage(this.dataType)) {
      this.paginationService.changePagination(this.dataType, 'desc', desc);
      this.paginationService.changePagination(this.dataType, 'orderBy', orderBy);
      this.paginationService.changePagination(this.dataType, 'page', currentPage);
    }
  }
  else
  {
    this.onSortEvent.next([orderBy, reset]);
    this.loadingChange.emit(true);
    const desc = (this._data.orderBy === orderBy || orderBy === 'playerName' || orderBy === 'name') && this._data.desc === 0 ? 1 : 0;
    const currentPage = this._data.page;
    if (this.paginationService.hasPage(this.dataType)) {
      this.paginationService.changePagination(this.dataType, 'desc', desc);
      this.paginationService.changePagination(this.dataType, 'orderBy', orderBy);
      this.paginationService.changePagination(this.dataType, 'page', currentPage);
    }
    if (reset) {
      this.resetSelect();
    }
      this.settings['getDataFromDatabase']();
    }
  }

  sortByParameter(rows: any[], orderBy: string,attrdesc: number)
  {
    const propertyMapping: any = {
      attributeName: "attributeName",
      attributeDescription: "attributeDescription",
      dataSetName: "dataSetName"
   }
    if (!(orderBy in propertyMapping)) {
        throw new Error("Invalid orderBy parameter");
    }
    const sortingKey = propertyMapping[orderBy];
    if(attrdesc == 0)
    {
      rows.sort((a: any, b: any) => a[sortingKey].localeCompare(b[sortingKey]));
      this.attrdesc = 1;
    }
    else if(attrdesc == 1)
    {
      rows.sort((a: any, b: any) => b[sortingKey].localeCompare(a[sortingKey]));
      this.attrdesc = 0;
    }
    this._data.data = rows;

    return rows;
  }

  /**
   * Is executed when change page event is emitted
   * @param changeToPage number of the page we want to change to
   * @param resetCurrentSelection
   */
  onPageChange(changeToPage: number, resetCurrentSelection = true) {
    this.loadingChange.emit(true);
    this.onPageChangeEvent.next([changeToPage, resetCurrentSelection]);
    //const currentSortOrder = this._data.desc;
    //const currentColumn = this._data.orderBy;
    if (this.paginationService.hasPage(this.dataType)) {
      this.paginationService.changePagination(this.dataType, 'page', changeToPage);
      //this.paginationService.changePagination(this.dataType, 'desc', currentSortOrder);
      //this.paginationService.changePagination(this.dataType, 'orderBy', currentColumn);
    }
    if (resetCurrentSelection) this.resetSelect();
    // TODO:
    this.settings['getDataFromDatabase']();
  }


  /**
  * Toggle expanded row in table (only showed on mobile sizes)
  */
  showTableElement(el: any) {
    el.respontableIsShowed = !el.respontableIsShowed;
  }

  /**
  * Event handler to detect selected inputs
  */
  changeSelectedItems(row: any, dataId: string, hasSingleSelect: boolean = false, event: any) {
    console.log(`changeSelectedItems clicked, this is only shown when *ngIf="!settings.hasSelectedFunctions"`)
    console.log(`Loose reference of this: this.settings['dataId']`, this.settings['dataId']);
    console.log(`this.settings['dataId']:`, this.settings['dataId'])
    if (this.settings['dataId']) {
      dataId = this.settings['dataId']
    }

    let selectedItemsObj = [];

    if (hasSingleSelect) {
      // If single select is enabled, clear previous selections
      this._data['data'].forEach((d: any) => d.respontableIsSelected = false);

      const isChecked = event.target.checked;

      if (isChecked) {
        row.respontableIsSelected = true; // Select the clicked row
        this.selectedItems = [row[dataId]]; // Only one item is selected
        selectedItemsObj = [row]; // Add the selected row to the selected items array
      } else {
        row.respontableIsSelected = false;
        this.selectedItems = [];
      }

    } else {
      selectedItemsObj = this._data['data'].filter((d: any) => d.respontableIsSelected)
      this.selectedItems = selectedItemsObj.map((d: any) => d[dataId]); // Just Ids
    }

    this.onSelected.emit([row.respontableIsSelected, row]);
    this.onSelectedItems.emit(selectedItemsObj);
  }

  /**
   * Manages selected items from parent component
   * @param func function that came from settings in parent component
   * @param row row data, one item
   * @param data all table data, need to know number of page and manage stored data
   */
  changeItems(func: any, row: any, data: any) {
    console.log(`changeItems clicked, this is only shown when *ngIf="settings.hasSelectedFunctions" IS THE OPPOSITE OF the other is absurd this solution`)

    if (func) {
      this.selectedItems = func(row, data);
    } else {
      console.warn('changeItems on settingsTable is not configures')
    }
  }

  /**
   * Check if need to be checked an item when paginate from parent component
   * @param func function that came from settings in parent component
   * @param row row data, one item
   * @param data all table data, need to know number of page and manage stored data
   */
  isSelected(func: any, row: any, data: any) {
    if (func) {
      return func(row, data);
    } else {

      console.warn('isSelected on settingsTable is not configures')
    }
  }

  /**
   * Return CSS Classes for the columns of the table
   */
  getDynamicClass(column: any)
  {
    let dynamicClass = '';
    dynamicClass = column.class ? column.class : '';
    dynamicClass = column.orderBy ? 'sort' : dynamicClass;
    if(column.orderBy == "attributeName"|| column.orderBy == "attributeDescription" || column.orderBy == "dataSetName")
    {
      dynamicClass = (column.orderBy && this.newOrderBy === column.orderBy && this.attrdesc === 0) ? dynamicClass + ' sort--desc' : dynamicClass;
      dynamicClass = (column.orderBy && this.newOrderBy === column.orderBy && this.attrdesc === 1) ? dynamicClass + ' sort--asc' : dynamicClass;
    }
    else
    {
      dynamicClass = (column.orderBy && this._data.orderBy === column.orderBy && this._data.desc === 1) ? dynamicClass + ' sort--desc' : dynamicClass;
      dynamicClass = (column.orderBy && this._data.orderBy === column.orderBy && this._data.desc === 0) ? dynamicClass + ' sort--asc' : dynamicClass;
    }
    return dynamicClass;
  }

  /**
   * Check if the selectedItems has rows
   * @param action the action icon clicked
   */
  limitMinimumItems(action: TableAction) {

    if (action.limitItems) {
      return (this.selectedItems && this.selectedItems.length == action.limitItems);
    }

    if (action.minimumItems) {
      return (this.selectedItems && this.selectedItems.length >= action.minimumItems);
    }

    return (this.selectedItems && this.selectedItems.length > 0);

  }

  /**
  * Toggle user menu
  */
  onChangeDropdown() {
    this.isOpen = !this.isOpen;
  }


  /*
   * Calcule table min height
   */
  getMinHeight() {
    return this._data.data.length < this._data.totalElements
      ? (this._data.data.length * 41) + 'px'
      : '';
  }

  /**
   * Returns wether it should render checkbox or not
   * depending of status of row
   * @param row row to check its status
   */
  shouldRender(row: any) {
    if (this.settings['renderFunction']) {
      return this.settings['renderFunction'](row);
    } else {
      return true;
    }
  }
  /**
 *Gets the class in the case of a action of type button
 * @param action
 */
  getButtonActionClass(condition: boolean, action: any) {
    if (!condition && action.secondaryClass) {
      return `${action.class} ${action.secondaryClass}`;
    }
    else {
      return action.class;
    }
  }
  /**
   * Callback when the filters component emits a filtered event.
   * @param result
   */
  onFiltered(result: any) {
    // onFiltered(result: FilterResults) {
    this.filterLabels = result.labels;
    // this.filtered?.emit(result);
  }

  /**
   * Callback when the filters component emits a clear filters event.
   */
  onClearedFilters() {
    this.filterLabels = [];
    this.clearedFilters?.emit();
  }

  /**
   * Execute the action of a header button passing the selected elements ids as the argument
   * @param action
   */
  executeHeaderButtonAction(event: (data: any) => void) {
    event(this.selectedItems);
  }

  private async retrieveSelectedItems(data: any, selectedItems: string[]) {
    this.backgroundLoading = true;
    await this.utilsService.asyncForEach(data.data, async (dataPiece: any) => {
      for (const result of selectedItems) {
        if (data[this.settings.dataId] === result) {
          dataPiece.respontableIsSelected = true;
          const index = selectedItems.indexOf(result);
          selectedItems.splice(index, 1);
          break;
        }
      }
    })
    this.backgroundLoading = false;
  }

   private setPercentageTooltip(): void {
    this.percentageTooltip = this.dataType === 'providers'
      ? 'page.admin.table.tooltip.providers'
      : 'page.admin.table.tooltip.products';
  }
}
