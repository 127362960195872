import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from 'src/app/models/close-reason.model';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { StringData } from 'src/app/services/translate-service/translate';

/**
 * Confirm modal options.
 */
export interface ConfirmModalOptions {
  title?: StringData | string;
  // can be string for backwards compatibility
  message: StringData | string;
  yesLabel?: StringData | string;
  hasNoButton?: boolean;
  noLabel?: StringData | string;
}

/**
 * Confirm/cancel modal.
 */
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Input() options!: ConfirmModalOptions;

  title?: StringData | string | undefined;
  message?: string;
  yesLabel?: StringData | string | undefined;
  hasNoButton?: boolean;
  noLabel?: StringData | string | undefined;

  reason = Reason;

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslatePipe
  ) { }

  ngOnInit() {
    this.title = this.options.title
      // ? this.options.title.getText(this.translate)
      // : this.translate.transform('common.confirmAction');

    this.message =
      typeof this.options.message == 'string'
        ? this.options.message
        : this.options.message.getText(this.translate);

    this.yesLabel = this.options.yesLabel
      // ? this.options.yesLabel.getText(this.translate)
      // : this.translate.transform('components.modal.confirm.agree');

    if (this.options.hasNoButton) {
      this.hasNoButton = true;

      this.noLabel = this.options.noLabel
        // ? this.options.noLabel.getText(this.translate)
        // : this.translate.transform('components.modal.common.cancel');
    } else {
      this.hasNoButton = false;
    }
  }

  onClickButton(reason: Reason) {
    this.activeModal.close(reason);
  }
}
