import { FiltersComponent } from './filters/filters.component';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { DirectivesModule } from './../../directives/directive.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { SubmitFilterModalComponent } from './validations-filter/modal/submit-filter/submit-filter-modal.component';
import { ValidationsFilterComponent } from './validations-filter/validations-filter.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { ResponsiveTableRoutingModule } from './responsive-table-routing.module';
import { ColumnResponsiveTableComponent } from './column-responsive-table/column-responsive-table.component';
import { ResponsiveTableHeaderComponent } from './responsive-table-header/responsive-table-header.component';
import { TableSpinnerBallComponent } from './table-spinner-ball/table-spinner-ball.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ResponsiveTableComponent } from './responsive-table/responsive-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

// TODO: MatChipList doesn't exist in MatChipsModule anymore pending migration, current solution MatLegacyChipsModule
// https://stackoverflow.com/questions/74437274/mat-chip-list-is-not-a-known-element

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { TotalElementDetailsModule } from '../total-element-details/total-element-details.module';

@NgModule({
  declarations: [
    ResponsiveTableComponent,
    ResponsiveTableHeaderComponent,
    TableSpinnerBallComponent,
    ColumnResponsiveTableComponent,
    ValidationsFilterComponent,
    SubmitFilterModalComponent,
    FiltersComponent,

  ],
  imports: [
    CommonModule,
    ResponsiveTableRoutingModule,
    MatChipsModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxPaginationModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DirectivesModule,
    PipeModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatIconModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatFormFieldModule,
    AutocompleteModule,
    TotalElementDetailsModule,
  ],
  exports: [
    ResponsiveTableComponent,
    ResponsiveTableHeaderComponent,
    TableSpinnerBallComponent,
    ColumnResponsiveTableComponent,
    ValidationsFilterComponent,
    SubmitFilterModalComponent,
    FiltersComponent,

  ],
  providers: [
  ]
})
export class ResponsiveTableModule { }
