<div class="container-accordion" [ngClass]="{ 'openedStyles': isOpen, 'editingStyles': isEditingMode }">
  <!-- visible always -->
  <div class="container-accordion--row-info"  (click)="toggleAccordion()" >

    <div class="container-accordion--row-info__left">
      <div class="icon-container">
        <img [src]="categoryIcon">
      </div>
      <div class="title-container">
        <p class="title-text">{{ category.names }}</p>
        <p class="title-info">(Showing {{category.fields.length}} out of {{numOfAllFields}} characteristics)</p>
      </div>
    </div>

    <!-- Collapse arrow -->
    <span class="collapse-arrow">
      <mat-icon class="collapse-arrow" [class.rotated]="isOpen">
        keyboard_arrow_down
      </mat-icon>
    </span>

  </div>

  <!-- Collapsed content -->
  <div
    *ngIf="isOpen"
    [@openClose]="isOpen ? 'open' : 'closed'"
    style="overflow:hidden;"
    class="container-accordion--collapse-info"
  >
    <div class="content-headers">
      <p>Characteristic</p>
      <p>Value</p>
    </div>
    <div class="content-characteristic" *ngFor="let characteristic of category.fields">

      <div class="content-characteristic--name">
        <p>{{characteristic.name}}</p>
        <mat-icon
          class="small-icon"
          style="font-size: 14px !important; margin-top: 12px !important;"
          matTooltip="{{ getFieldDescription(category.names, characteristic) }}"
        >info</mat-icon>
      </div>

      <div *ngIf="!isEditingMode">
        <ng-container [ngSwitch]="dataType(characteristic)" style='width: 100%'>

          <ng-container *ngSwitchCase="'Multi-Select'">
            <div class="multi-select-container" *ngIf="characteristic.value" style='width: 100%'>
              <div class="selected-values">
                <div *ngFor="let option of characteristic.value.split(';')">
                  {{ option }}
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'Single-Select'">
            <div class="multi-select-container" *ngIf="characteristic.value && isFieldEditable(characteristic)" style='width: 100%'>
              <div class="selected-values">
                <div *ngFor="let option of characteristic.value.split(';')">
                  {{ option }}
                </div>
              </div>
            </div>
            <div *ngIf="characteristic.value && !isFieldEditable(characteristic)">
              {{characteristic.value}}
            </div>
          </ng-container>

           <ng-container *ngSwitchDefault style='width: 100%'>
             {{ characteristic.value | formatFieldValue }}
           </ng-container>

        </ng-container>
      </div>

      <p *ngIf="isEditingMode && !isFieldEditable(characteristic)" title="This characteristic is not editable.">{{characteristic.value}}</p>

      <div *ngIf="isEditingMode && isFieldEditable(characteristic)" class="editor-container">

        <!-- Free Text -->
        <ng-container  *ngIf="dataType(characteristic) == 'Free Text'" >
          <p class="free-text-instructions">
            <span>Enter text</span>
            <mat-icon
              class="small-icon"
              style="font-size: 14px !important; margin-top: 12px !important; color: #ffffff;"
              matTooltip="{{getFieldInstructionsForEditorMode(category.names, characteristic)}}"
              matTooltipShowDelay="0"
            >info</mat-icon>
          </p>

          <textarea #textarea
            (input)="textAreaAdjust($event.target)"
            class="editing-option" type="text"
            [(ngModel)]="characteristic.value"
            placeholder="Fill value for this characteristic..."
          ></textarea>
        </ng-container>

        <!-- Numeric -->
        <ng-container  *ngIf="dataType(characteristic) == 'Numeric'" >
          <p class="free-text-instructions">
            <span>Enter number</span>
            <mat-icon
              class="small-icon"
              style="font-size: 14px !important; margin-top: 12px !important; color: #ffffff;"
              matTooltip="{{getFieldInstructionsForEditorMode(category.names, characteristic)}}"
              matTooltipShowDelay="0"
            >info</mat-icon>
          </p>
          <input
            class="editing-option"
            type="number"
            [(ngModel)]="characteristic.value"
          />
        </ng-container>

        <!-- Multi-select with sections -->
        <div  class="multi-select-container" *ngIf="dataType(characteristic) == 'Multi-Select' && hasSections(characteristic)">
          <div>
            <label for="options">
              Select values
              <mat-icon
                class="small-icon"
                style="font-size: 14px !important; margin-top: 12px !important; color: #ffffff;"
                matTooltip="{{getFieldInstructionsForEditorMode(category.names, characteristic)}}"
                matTooltipShowDelay="0"
              >info</mat-icon>
            </label>
            <select (change)="addMultiValues($event, characteristic.value, characteristic)">
              <option [value]="null">Select values</option>
              <ng-container *ngFor="let section of multiSelectOptionsWithSections">
                <optgroup *ngIf="!isSectionSelected(characteristic.value, section)" label="{{ section.name }}">
                  <ng-container *ngFor="let option of section.options">
                    <option *ngIf="!isOptionSelected(characteristic.value, option)" [value]="option">
                      {{option}}
                    </option>
                  </ng-container>
                </optgroup>
              </ng-container>
            </select>
          </div>

          <div class="selected-values">
            <ng-container *ngIf=" characteristic.value">
              <div *ngFor="let option of characteristic.value.split(';')">
                {{ option }}
                <button class="remove-value" (click)="removeMultiValues(option,  characteristic.value, characteristic)">
                  <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.113536 0.133534C0.259983 -0.0129121 0.49742 -0.0129123 0.643866 0.133534L3.00002 2.48969L5.35618 0.133534C5.50262 -0.0129121 5.74006 -0.0129123 5.88651 0.133534C6.03295 0.279981 6.03295 0.517418 5.88651 0.663864L3.53035 3.02002L5.88651 5.37617C6.03295 5.52262 6.03295 5.76006 5.88651 5.9065C5.74006 6.05295 5.50262 6.05295 5.35618 5.9065L3.00002 3.55035L0.643866 5.9065C0.49742 6.05295 0.259983 6.05295 0.113536 5.9065C-0.0329104 5.76006 -0.0329104 5.52262 0.113536 5.37617L2.46969 3.02002L0.113536 0.663864C-0.0329104 0.517418 -0.0329104 0.279981 0.113536 0.133534Z" fill="#A4A3B1"/>
                  </svg>
                </button>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Multi-select with No section -->
        <div class="multi-select-container" *ngIf="dataType(characteristic) == 'Multi-Select' && !hasSections(characteristic)">
          <div>
            <label for="options">
              Select values
              <mat-icon
                class="small-icon"
                style="font-size: 14px !important; margin-top: 12px !important; color: #ffffff;"
                matTooltip="{{getFieldInstructionsForEditorMode(category.names, characteristic)}}"
                matTooltipShowDelay="0"
              >info</mat-icon>
            </label>
            <select (change)="addMultiValues($event, characteristic.value, characteristic)">
              <option [value]="null">Select values</option>
              <ng-container *ngFor="let option of singleSelectOptions" >
                <option *ngIf="!isOptionSelected(characteristic.value, option)" [value]="option">
                  {{option}}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="selected-values" *ngIf="characteristic.value">
            <div *ngFor="let option of characteristic.value.split(';')">
              {{ option }}
              <button class="remove-value" (click)="removeMultiValues(option,  characteristic.value, characteristic)">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.113536 0.133534C0.259983 -0.0129121 0.49742 -0.0129123 0.643866 0.133534L3.00002 2.48969L5.35618 0.133534C5.50262 -0.0129121 5.74006 -0.0129123 5.88651 0.133534C6.03295 0.279981 6.03295 0.517418 5.88651 0.663864L3.53035 3.02002L5.88651 5.37617C6.03295 5.52262 6.03295 5.76006 5.88651 5.9065C5.74006 6.05295 5.50262 6.05295 5.35618 5.9065L3.00002 3.55035L0.643866 5.9065C0.49742 6.05295 0.259983 6.05295 0.113536 5.9065C-0.0329104 5.76006 -0.0329104 5.52262 0.113536 5.37617L2.46969 3.02002L0.113536 0.663864C-0.0329104 0.517418 -0.0329104 0.279981 0.113536 0.133534Z" fill="#A4A3B1"/>
                </svg>
              </button>
            </div>
          </div>
        </div>

        <!-- Single-select -->
        <div class="single-select-container" *ngIf="dataType(characteristic) === 'Single-Select' && setSelectOptions(characteristic)?.length > 0">
          <label>
            Select value
            <mat-icon
              class="small-icon"
              style="font-size: 14px !important; margin-top: 12px !important; color: #ffffff;"
              matTooltip="{{getFieldInstructionsForEditorMode(category.names, characteristic)}}"
              matTooltipShowDelay="0"
            >info</mat-icon>
          </label>
          <select *ngIf="characteristic.name !== 'Headquarters City'" [(ngModel)]="characteristic.value">
            <option [value]="null">No value selected</option>
            <option *ngFor="let option of singleSelectOptions" [value]="option">
              {{option}}
            </option>
          </select>
          <select *ngIf="characteristic.name === 'Headquarters City'" [(ngModel)]="characteristic.value">
            <option [value]="null">No value selected</option>
            <option *ngFor="let option of cities$ | async" [value]="option">
              {{option}}
            </option>
          </select>
        </div>

        <img
          src="assets/img/icons/changelog-clock.svg"
          alt="changelog-clock-icon"
          class="clock"
          title="Changelog"
          (click)="onShowChangeLog(characteristic)"
        />

      </div>
    </div>
  </div>
</div>
