import { Injectable } from '@angular/core';
import { PageModel } from './model/page.model';

import { PaginationModel } from './model/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  private pages: { [key: string]: PageModel<unknown> } = {};
  private paginations: { [key: string]: PaginationModel } = {};

  constructor() { }

  /**
   * Set current page
   */
  setPage<DataItem>(
    model: string,
    page: PageModel<DataItem>
  ): PageModel<DataItem> {
    this.pages[model] = page;
    return this.pages[model] as PageModel<DataItem>;
  }

  /**
   * Return current page
   */
  getPage<DataItem>(model: string): PageModel<DataItem> {
    return this.pages[model] as PageModel<DataItem>;
  }


  hasPage(model: string): boolean {
    return !!this.pages[model];
  }

  /**
   * Return default pagination model
   */
  getDefaultPagination(model: string): PaginationModel {
    switch (model) {
      // TODO: model should be the class
      case 'users':
        return new PaginationModel(9999, 1, '', 0, '');
      case 'modal':
        return new PaginationModel(5, 1, '', 1, '');
      case 'comments':
        return new PaginationModel(50, 1, 'documentid', 1, '');
      case 'editrole':
        return new PaginationModel(10, 1, 'permissionid', 1, '');
      case 'assignchecklist':
        return new PaginationModel(10, 1, 'validationid', 1, '');
      case 'applications':
        return new PaginationModel(10, 1, 'appname', 1, '');
      case 'forms':
        return new PaginationModel(10, 1, 'name', 1, '');
      case 'clients':
        return new PaginationModel(10, 1, 'name', 0, '');
      case 'attributes' :
          return new PaginationModel(1, 1, 'attributeName', 0, '');
      case 'response':
        return new PaginationModel(10, 1, 'createdAt', 1, ''); //CHECK
      case 'providers' :
        return new PaginationModel(2000, 1, '', 0, '');
      case 'products' :
        return new PaginationModel(50, 1, '', 0, '');
      case 'license':
        return new PaginationModel(9999, 1, '', 0, '');
      case 'notes':
        return new PaginationModel(10, 1, '', 1, '');
    }

    throw new Error(`An unknown model ${model} was passed`);
  }

  /**
 * Change pagination property
 *
 * @param model : model which the pagination will be made. WHY? We have this?
 * @param property: 'page' this is really weird. Is this a type of cache?
 * @param value: the value of the property
 * @returns
 */
  changePagination<T>(
    model: string,
    property: keyof PaginationModel,
    value: T
  ): PaginationModel {
    (<any>this.paginations[model][property]) = value;
    return this.paginations[model];
  }

  /**
   * Set current pagination model
   */
  setPagination(model: string, obj: PaginationModel): PaginationModel {
    this.paginations[model] = new PaginationModel(
      obj.pageSize,
      obj.page,
      obj.orderBy,
      obj.desc,
      obj.query
    );
    return this.paginations[model];
  }

  /**
   * Return pagination model
   */
  getPagination(model: string): PaginationModel {
    return this.paginations[model]
      ? this.paginations[model]
      : this.getDefaultPagination(model);
  }
}
