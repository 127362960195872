/**
 * An object used to store pagination information.
 */
export class PaginationModel {
  /**
   * @param  pageSize Max number of elements in the page
   * @param  page The current/requested page number
   * @param  orderBy The attribute we choose for sorting
   * @param  desc The chosen order (1 = desc true or 0 = desc false)
   * @param  query The optional query
   */
  constructor(
    public pageSize: number, // The number of elements in the page
    public page: number, // The current page number
    public orderBy: string, // The attribute we choose for sorting
    public desc: number, // The chosen order (1 = desc true or 0 = desc false)
    public query = '' // The optional query
  ) { }


  static createDefaultConfiguration(
    orderBy?: string,
    desc = 0,
    pageSize = 10,
    page = 1,
    query = '') {

    if (orderBy == undefined || orderBy == null) {
      orderBy = '';
    }

    return new PaginationModel(
      pageSize,
      page,
      orderBy,
      desc,
      query
    )
  }

}
