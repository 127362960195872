<mat-card class="product-attachment-card">
    <mat-card-header>
      <div class="card-header-row">
        <mat-card-title class="product-attachment-title" [title]="attachment?.name">
          <img src="assets/img/icons/document.svg" alt="doc-icon" class="doc-icon" height="80px;" width="80px;" [ngStyle]="{'padding-top': '1rem'}">
          {{ attachmentName }}
        </mat-card-title>
        <div ngbDropdown [appHasPermission]="['can-edit-attachments', 'can-download-attachments']">
          <mat-icon ngbDropdownToggle class="mat-toggle-icon">more_vert</mat-icon>
          <div ngbDropdownMenu class="dropdown-cont" >
            <!-- TODO: Manage showing of these options -->
            <button *ngIf="canEditAndDelete" [appHasPermission]="['can-edit-attachments']" ngbDropdownItem (click)="onEditAttachment($event, attachment)"> 
              <img src="assets/img/icons/edit.svg" alt="edit-icon" class="ed-icon">
              {{ 'page.product.attachments.card.edit' | translate : 'Edit Document'}}
            </button>
            <button  *ngIf="canEditAndDelete" [appHasPermission]="['can-edit-attachments']"  ngbDropdownItem (click)="deleteAttachment(attachment)"> 
              <img src="assets/img/icons/garbage-2.svg" alt="delete-icon" class="del-icon">
              {{ 'page.product.attachments.card.delete' | translate : 'Delete Document'}}
            </button>
            <button  [appHasPermission]="['can-download-attachments']" ngbDropdownItem (click)="downloadAttachment(attachment)"> 
              <img src="assets/img/icons/download.svg" alt="download-icon" class="down-icon">
              {{ 'page.product.attachments.card.download' | translate : 'Download Document'}}
            </button>
            <button  ngbDropdownItem (click)="previewAttachment(attachment)"> 
              <img src="assets/img/icons/eye.svg" alt="download-icon" class="down-icon">
              {{ 'page.product.attachments.card.preview' | translate : 'Preview Document'}}
            </button>
          </div>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <p class="product-attachment-text" [ngStyle]="{'padding-top': '1rem'}">{{ attachment?.description }}</p>
    </mat-card-content>
  </mat-card>

  