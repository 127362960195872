<div class="ws-app" [ngClass]="{'covered': isChatOpen}">
  <div *ngIf="isChatOpen" class="overlay"></div>
  <div *ngIf="loading" class="spinner-overlay">
    <div class="spinner-container">
      <img src="../assets/img/ey_logo_black.svg" alt="Loading Image" class="loading-image">
      <br />
      <app-spinner-ball [spinnerStyles]="'la-ball-circus'"></app-spinner-ball>
    </div>
  </div>
  <router-outlet *ngIf="!loading"></router-outlet>
  <app-chatbot (chatOpened)="isChatOpen = $event" *ngIf="!loading"></app-chatbot>
</div>
