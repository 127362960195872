import { Component, Input, OnInit} from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { MarketDiscoveryService } from 'src/app/services/market-discovery/market-discovery.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-product-card-list',
  templateUrl: './product-card-list.component.html',
  styleUrls: ['./product-card-list.component.scss']
})
export class ProductCardListComponent implements OnInit{

  @Input() products!: Product[] | any[];
  @Input() changeGrid!: boolean;

  get showMatch(): boolean { return this.marketDiscoveryService.atLeastOneProductFilterApplied() }

  constructor(
    private productService: ProductService,
    private marketDiscoveryService: MarketDiscoveryService
  ){}

  ngOnInit(): void {}

  getPath(productId: string): string{
    // return `/market-discovery/product/${productId}`;
    return `/profile/offering/${productId}`;
  }

  getProviderPath(providerId: string): string{
    // return `/market-discovery/provider/${providerId}`;
    return `/profile/player/${providerId}`;
  }

  getOfferingTypes(product: Product): string[]{
    const value = this.productService.getProductFieldValueByUniqueId(product, "ID_O_012");
    return value ? value.toLowerCase().split(/[;,]/) : "";
  }

  getOfferingIconClass(type: string): { [key: string]: boolean }{
    return {
      'data': type === 'data',
      'software': type === 'software',
      'service': type === 'service',
      'physical-good': type === 'physical good',
      'financial-product': type === 'financial product',
      '': type === ''
    };
  }

  getOfferingIconTooltip(type: string): string {
    const base = "page.admin.icons.market.tooltip";
    const tooltips: { [key: string]: string } = {
      'data': `${base}.data`,
      'software': `${base}.software`,
      'service': `${base}.service`,
      'financial product': `${base}.financialProduct`,
      'physical good': `${base}.physicalGood`
    };
    return tooltips[type] || "";
  }

  getOfferingIconPath(type: string): string{

    let value: string = ''

    switch(type){
      case 'data':
        value = "assets/img/icons/db-check.svg";
        break;
      case 'software':
        value = "assets/img/icons/app-window.svg";
        break;
      case 'service':
        value = "assets/img/icons/headset-help.svg";
        break;
      case 'financial product':
        value = "assets/img/icons/bank.svg";
        break;
      case 'physical good':
        value = "assets/img/icons/pot-plant.svg";
        break;
      default:
        value = ""
        break;
    }

    return value;
  }

}
