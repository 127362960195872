import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent {
  selectCheckbox(event: MouseEvent) {
    this.checked = !this.checked;
    this.isSelected.emit(this.checked)
  }

  @Input() imageSource?: string;
  @Input() fileName?: string;
  checked = false;
  @Output() isSelected = new EventEmitter<boolean>();



  selectedChange(event: MatCheckboxChange) {
    this.isSelected.emit(event.checked)
  }
}
