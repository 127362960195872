import { Component, Input, OnInit } from '@angular/core';
import { Status } from 'src/app/constants/completitionStatus';
import { MomentPipe } from 'src/app/pipes/moment.pipe';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';

@Component({
  selector: 'app-column-responsive-table',
  templateUrl: './column-responsive-table.component.html',
  styleUrls: ['./column-responsive-table.component.scss']
})
export class ColumnResponsiveTableComponent implements OnInit {

  @Input() column: any;
  @Input() rowData: any;

  public Status = Status;


  constructor(
    private translate: TranslatePipe,
    private moment: MomentPipe
  ) {
  }

  ngOnInit() {

  }

  getTwoRecordsText(rowData: any, column: any) {
    const first = rowData[column.name] || '';
    const second = rowData[column.second] || '';

    if (first.length > 0 && second.length > 0) {
      return `${first}, ${second}`;
    }

    if (first.length > 0) {
      return first
    }

    if (second.length > 0) {
      return second;
    }

    return '';
  }
  getGeography(rowData: any, column: any) {
    const first = rowData[column.name] || '';
    const second = rowData.region || '';

    if (first && second) {
      return `${first}, ${second}`;
    }

    if (first.length > 0) {
      return first
    }

    if (second.length > 0) {
      return second;
    }

    return '';
  }
  getColumnSecond(item: any, column: any) {
    return column.second ? item[column.second] : item.tagname
  }

  columnSecondValue() {
    return this.column.second
      ? this.rowData[this.column.name][this.column.second]
      : this.rowData[this.column.name]
  }

  /**
   * Return CSS Class for Status Colors
   */
  public getStatusClass(rowData: any, status: Status = Status.SUCCESS) {

    if (rowData[this.column.name] && rowData[this.column.name].length > 0) {
      // return Status.SUCCESS
      return 'text-status--success'
    } else {
      return 'text-status--failed'
      // return Status.FAILED
    }
    switch (status) {
      case Status.NOTSTARTED:
        return 'text-status--not-started';
      case Status.STARTED:
        return 'text-status--started';
      case Status.TRAINING:
        return 'text-status--training';
      case Status.COMPLETED:
        return 'text-status--completed';
      case Status.TRANSCRIBING:
        return 'text-status--transcribing';
      case Status.PROCESSING:
        return 'text-status--processing';
      case Status.ANALYZING:
        return 'text-status--analyzing';
      case Status.FAILED:
        return 'text-status--failed';
      case Status.SUCCESS:
        return 'text-status--success';
    }
  }
  getListProvider(rowData: any) {
    const fieldList = rowData[this.column.name] || [];
    const fieldsProperty = fieldList.map((p: any) => p[this.column.second])
    return fieldsProperty.join(",")
  }

  getStatusText(rowData: any) {
    return this.getStatusClass(rowData) == 'text-status--success' ? "Can't edit players" : 'Can edit players'
  }
  /**
   * translate will be done if has translation
   */
  public getStatusMessageTranslation(statusmessage: string) {
    if (statusmessage.split('.').length > 1 &&
      this.translate.transform(`errors.${statusmessage}`) === statusmessage.split('.')[0]) {
      return statusmessage;
    }
    return this.translate.transform(`errors.${statusmessage}`);
  }

  separateUpperString(text: string): string{

    text = text.replace(/^_+|_+$/g, '');

    if(text === ''){
      return '';
    }

    let words = text.split('_');

    for(let i = 0; i < words.length; i++){
      words[i] = words[i][0].toUpperCase() + words[i].slice(1).toLowerCase();
    }

    const separated = words.join(' ');

    return separated;
  }


  getCategoryPercentageTooltip(rowData: any): string {
    return ''
  }

  toCamelCase(str: string): string {
    return str
    .replace(/_/g, ' ')
    .replace(/\b\w/g, match => match.toUpperCase());
  }

  getFlagTooltip(rowData: any): string {
    const flagDescription = rowData['flagDescription'];
    const lastFlagUpdatedBy = rowData['lastFlagUpdatedBy'];
    let tooltipText = '';

    if (lastFlagUpdatedBy && lastFlagUpdatedBy.username) {
      tooltipText += `Flag updated by ${lastFlagUpdatedBy.username}`;
      if (flagDescription) {
        tooltipText += `: ${flagDescription}`;
      }
    }
    return tooltipText;
  }

  getLastUpdatedDetails(rowData:any){
    const user = rowData['lastUpdatedBy'];
    const data = user?.username + ', ' + rowData['lastUpdated']?.split(' ')[0]
    return data ;
  }

  getLastValidatedDetails(rowData: any): string{
    const user = rowData['lastValidatedBy'];
    if (!user || Object.keys(user).length === 0){
      return '';
    }
    const data = user?.username + ', ' + this.moment.transform(rowData['lastValidated'], 'll');
    return data;
  }

  transformBack(value: string): string {
    if (!value) {
      return '';
    }

    return value
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  }

  getTitle(rowData: any, column: any): string {
    return column.second ? rowData[column.name][column.second] : rowData[column.name];
  }
  getLinkTooltip(className: string): string {
    if (className === 'new-tab' || className === 'new-tab new-tab-ellipsis') {
      return 'Click to open the profile';
    } else {
      return 'Click to edit the information';
    }
  }
}
