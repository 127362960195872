import { Injectable } from '@angular/core';
import { ApiService } from '../api/api-service/api.service';
import { Observable } from 'rxjs';
import { NoteCategory } from 'src/app/models/note-category.model';

@Injectable({
  providedIn: 'root'
})
export class NoteCategoryService {
  private _productORproviderNote: string = '';
  readonly modelName = 'note-categories';
  constructor(
    private apiService: ApiService
  ) { }
  
  get productORproviderNoteType(): string {
    return this._productORproviderNote;
  }

  set productORproviderNoteType(value: string) {
    this._productORproviderNote = value;
  }
 

  /**
   * Retrieves the note categories for a note.
   * @returns An Observable that emits the note category data.
   */
  getNoteCategories$() : Observable<NoteCategory[]>{
    const slug = `${this.modelName}`; 
    const requestNoteCategories$ = this.apiService.get<NoteCategory[]>(slug, {}, '');
    return requestNoteCategories$;
  }
}
