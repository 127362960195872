
/**
 * Changelog.
 */
export class Changelog {

    static getEmptyChangelog(): Changelog | undefined {
        const result = new Changelog();
        return result;
    }

    id: string = '';
    _id?: string = '';
    previousValue: any = "";
    editor: { _id: string, username: string } = { _id: "", username: ""};
    date: Date = new Date();
}


