<app-table-spinner-ball *ngIf="loading" [spinnerStyles]="'la-ball-circus'"></app-table-spinner-ball>

<div class="sections-container" *ngIf="!loading">
    <div class="fields-column">

        <div class="fields-info-box">
            <div class="fields-title">{{ 'page.comparison.tab.product.charscompared' |
              translate : 'Characteristics' }}</div>
        </div>
        <!-- Dynamic fields -->
        <div #field class="field" *ngFor="let field of fields; let i = index">
            <app-auto-complete-select 
                [placeholder]="'Select or search characteristic'"
                appearance="fill"
                (onSelectedItem)="onAddFieldAutocomplete($event, i)" 
                [multilevel]="true" 
                [showArrowIconSuffix]="true"
                [width]="'small'"
                [formControl]="fieldSelect" 
                [source]="listOfCategoriesWithValues"
                [defaultFieldValue]='field' 
            ></app-auto-complete-select>

            <mat-icon *ngIf="i > 0 || (i === 0 && fields.length > 1)" class="del-field-icon"
                (click)="removeField(i)">close</mat-icon>
        </div>
        <!-- Add field box -->
        <div class="add-field-box" (click)="addField()">
            <img src="assets/img/icons/plus.svg" alt="add-icon">
        </div>

    </div>

    <div class="comparison-column">
        <div class="comparison-header">
            <h4>{{ 'page.comparison.tab.product.prodcomparison' | translate : "Offerings" }}</h4>
        </div>

        <div class="product-horizontal-list">
          <button class="expand-space"></button>
            <div class="product" *ngFor="let product of products; let i = index">
              <span>{{ productValues[i]?.provider }}</span>
                <app-auto-complete-select 
                    label="{{ 'Offering ' + (i + 1)}}"
                    [placeholder]="'Select or search offering'"
                    appearance="fill"
                    (onSelectedProvider)="onAddProductAutocomplete($event, i)"
                    (onEmptyInputValue)="deleteProduct(i)"
                    [multilevel]="true"
                    [showArrowIconSuffix]="true"
                    [showHint]="true"
                    [width]="'small'"
                    [formControl]="productSelect"
                    [source]="productsData"
                ></app-auto-complete-select>

                <mat-icon *ngIf="products.length > 2" class="del-field-icon" (click)="removeProduct(i)">close</mat-icon>
            </div>
            <div class="add-prod-box" *ngIf="products.length < 5" (click)="addProduct()">
                <img src="assets/img/icons/plus.svg" alt="add-icon">
            </div>
        </div>

        <div #fieldValueBoxRow class="field-value-boxes" *ngFor="let field of fieldValues; let i = index">
          <button class="expand" (click)="toggle(i)" [ngClass]="{'disabled': !isTextOverflow(i) && !buttonClickedStates.get(i)}">
            <img [src]="buttonClickedStates.get(i) ? 'assets/icons/collapse.svg' : 'assets/icons/expand.svg'">
          </button>
            <div class="field-value-box" *ngFor="let product of productValues; let j = index" [class.expanded-box]="isExpanded(j, i)">
                <div class="tooltip-container">
                    <span id="{{ i }}-{{ j }}" [class.ellipsis]="!isExpanded(j, i)" *ngIf="!product.isLoading">
                        {{ getProductFieldValue(product.name, field.name) | formatFieldValue }}
                    </span>
                    <app-table-spinner-ball *ngIf="product.isLoading" class="spinner" [spinnerStyles]="'la-ball-circus'"></app-table-spinner-ball>
                </div>
            </div>
            <div class="add-prod-box empty" *ngIf="products.length < 5"></div>
        </div>
    </div>
</div>
