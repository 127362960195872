<div class="reviewers__head">
  <div class="results"></div>
</div>


<div class="filter-content">
  <!-- start table -->
  <app-table-spinner-ball
    *ngIf="loading"
    [spinnerStyles]="'la-ball-circus'"
  ></app-table-spinner-ball>
  <app-responsive-table
    *ngIf="!loading"
    [loading]="loading"
    [backgroundLoading]="backgroundLoading"
    [settings]="settingTable"
    [columns]="permissionTableColumnConfiguration"
    [dataType]="'permissions'"
    [data]="permissionTableRows"
    (loadingChange)="onLoadingChange($event)"
    (selected)="onSelected($event)"
    (onSelectedItems)="onSelectedItems($event)"
    (onSortEvent)="onTableSort($event)"
    (onPageChangeEvent)="onTablePageChange($event)"
    [showPagination]="false"
    [showElementCount]="false"
  >
  </app-responsive-table>
</div>
