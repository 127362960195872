import { Injectable } from '@angular/core';
import { HistoricProductValidation } from '../../models/historic-product-validation.model';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api/api-service/api.service';
import { Observable, map } from 'rxjs';
import { PageModel } from '../api/pagination-service/model/page.model';

@Injectable({
  providedIn: 'root'
})
export class HistoricProductValidationService {
  readonly modelName = 'historicProductValidation';

  constructor(
    public apiService: ApiService,
    private http: HttpClient,
  ) { }

  /**
  * Create a Product Validation Comment
  * @param data
  * @returns
  */
  createValidationComment$(data: Omit<HistoricProductValidation, 'id'>) {
    const request$ = this.apiService.post(
      `${this.modelName}`,
      JSON.stringify(data),
      'components.modal.validation.createSuccessMsg'
    );
    return request$;
  }

   /**
  * Get a Product Validation Comment
  * @param data
  * @returns
  */
   getProductValidationData$(id: string, params: { [keyof: string]: any } = {}) {    
    const slug = `${this.modelName}/${id}`;
    const request$ = this.apiService.get<PageModel<HistoricProductValidation>>(
      slug,
      params,
      ''
    );
    return request$;
  }

  getProductValidationDataWithoutPagination$(id: string) {
    return this.getProductValidationData$(id).pipe(
      map(result=> result.data),
    );
  }
}
