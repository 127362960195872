import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { FilesCrudRealTimeComponent } from '../files-crud-real-time/files-crud-real-time.component';
import { ProductService } from 'src/app/services/product/product.service';
import { UploadFilesService } from 'src/app/services/upload-files/upload-files.service';
import { GenerateCrudModalFormService } from 'src/app/services/generate-crud-modal-form/generate-crud-modal-form.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { ProviderModel } from 'src/app/models/provider.model';
import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';
import { DeleteModalComponent } from '../modals/delete/delete.component';
import { Observable, Subscription, filter, from, mergeMap, tap } from 'rxjs';
import { Reason } from 'src/app/models/close-reason.model';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { ProductAttachmentCreationComponent } from '../product-attachment-creation/product-attachment-creation.component';
import { saveAs } from 'file-saver';
import { AttachmentsViewerComponent } from '../attachments-viewer/attachments-viewer.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';

@Component({
  selector: 'app-documents-detail',
  templateUrl: './documents-detail.component.html',
  styleUrls: ['./documents-detail.component.scss']
})
export class DocumentsDetailComponent{
  @Input() product! :Product;
  @Input() provider! :ProviderModel;
  @Input() data! : any;
  @Input() attachment: any;
  @ViewChild(FilesCrudRealTimeComponent) filesComponents!: FilesCrudRealTimeComponent;
  @Output() refresh = new EventEmitter();
  loading = false;
  id : String = "";
  attachmentName: String = "";
  canEditAndDelete: boolean = false;
  constructor(
    public productService: ProductService,
    public providerService: ProviderCollectionService,
    public uploadFilesService: UploadFilesService,
    public modalCreator: GenerateCrudModalFormService,
    protected modalService: NgbModal,
    public alertService: AlertService,
    private sanitizer: DomSanitizer,
    protected authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(){
    const currentUser = this.authenticationService.getLoggedInUser();
    const fullName = this.attachment?.name.split('.');
    this.attachmentName = fullName[0];
    if(this.attachment.createdById == currentUser._id || currentUser.role.name == 'EY Admin'){
      this.canEditAndDelete = true;
    }
  }

  /**
 * Load modal to confirm user(s) removal
 */
  private deleteAttachmentModal(attachment: any) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: "attachment",
      notAllowed: attachment.length < 1
    };

    if (this.product) {
      from(modalWindowRef.result).pipe(
        filter((reason: Reason) => Reason.submitBtn == reason),
        tap(_ => console.log(attachment._id)),
        mergeMap(_ => this.productService.deleteProductAttachment$(this.product.id, attachment._id)),
        tap(_ => this.refresh.emit())
      ).subscribe({
        next: () => {
          const message = 'components.modal.product.attachment.deleteSuccessMsg';
          const keepAlertOnNavigate = false;
          this.alertService.success(message, keepAlertOnNavigate);
        },
        error: (error) => {
          const message = 'components.modal.product.attachment.deleteErrorMsg';
          const keepAlertOnNavigate = false;
          this.alertService.error(message, keepAlertOnNavigate);
        }
      })
      // TODO: Unsubscribe
      
    }
    else {
      from(modalWindowRef.result).pipe(
        filter((reason: Reason) => Reason.submitBtn == reason),
        tap(_ => console.log(attachment._id)),
        mergeMap(_ => this.providerService.deleteProviderAttachment$(this.provider.id, attachment._id)),
        tap(_ => this.refresh.emit())
      ).subscribe({
        next: () => {
          const message = 'components.modal.product.attachment.deleteSuccessMsg';
          const keepAlertOnNavigate = false;
          this.alertService.success(message, keepAlertOnNavigate);
        },
        error: (error) => {
          const message = 'components.modal.product.attachment.deleteErrorMsg';
          const keepAlertOnNavigate = false;
          this.alertService.error(message, keepAlertOnNavigate);
        }
      })
      // TODO: Unsubscribe
    }

    
  }

  onEditAttachment($event: Event, attachment: any) {
    //TODO: change this method for the method 
    // of classic in the modal crud service
    let attachments: any[];
    let creationFormValues: any;

    const modalTitle: string = 'Edit document' 
    const buttonText: string = 'Update' 
    
    const modalRef = this.modalCreator.createClassicModalWithInjectedComponent(ProductAttachmentCreationComponent, modalTitle, buttonText);
    const aSub: Subscription = modalRef.shown.pipe(
      tap(_=> modalRef.componentInstance.componentInjectedInstance.data = this.attachment ),
      tap(_=> modalRef.componentInstance.componentInjectedInstance.ngOnInit() ),
      tap(_=> this.modalCreator.formSub.next(modalRef.componentInstance.componentRef.instance.myForm) ),
    ).subscribe(_ => aSub.unsubscribe());
    

    from(modalRef.result).pipe(
      filter(r => r.action !== 'close'),
      tap(r => {
        creationFormValues = r.componentRef.instance.myForm.value;
        // join the name with the extension
        creationFormValues.attachments.forEach((attachment: any) => attachment.name = attachment.name + attachment.extension);
        attachments = creationFormValues.attachments;
        delete creationFormValues.attachments;
        attachments[0]["description"] = creationFormValues.description;
        if (this.attachment.name == creationFormValues.name){
          this.attachment.name = "";
        }
        else{
          this.attachment.name = creationFormValues.name;// + this.attachmentNameExtention;
        }
        
      }),
      mergeMap(() => {
        if(this.product){
          return this.productService.updateAttachment$(this.product.id, this.attachment.id, this.attachment.name, attachments)
        }
        if(this.provider){
          return this.providerService.updateAttachment$(this.provider.id, this.attachment.id, this.attachment.name, attachments)
        }
        
      }),
      tap(_ => this.refresh.emit())
      //tap((note: any) => this.uploadFiles(attachments, note)),
    ).subscribe({
      next: () => {
        const message = 'components.modal.product.attachment.editSuccessMsg';
        const keepAlertOnNavigate = false;
        this.alertService.success(message, keepAlertOnNavigate);
      },
      error: (error) => {
        const message = 'components.modal.product.attachment.editErrorMsg';
        const keepAlertOnNavigate = false;
        this.alertService.error(message, keepAlertOnNavigate);
      }
    });
      /**TODO: Unsubscribe */
    
  }

  deleteAttachment(attachment: any){
    this.deleteAttachmentModal(attachment)
  }

  downloadAttachment(attachment: any) {
    //const aSub: Subscription = this.productService.downloadProductAttachmentFromBlob(this.product._id, attachment._id).subscribe( (response:any) => {
    //  saveAs(response.body, attachment.name);
    //  aSub.unsubscribe()})

    let aSub: Subscription;


    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      ConfirmModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      title: "Download Attachment",
      message: "Do you want to download this attachment?",
      hasNoButton: true,
      yesLabel: "Download",
      noLabel: "Cancel",
    };

    if (this.product) {
      from(modalWindowRef.result).pipe(
        filter((reason: Reason) => Reason.submitBtn == reason),
        tap(_ => console.log(attachment._id)),
        mergeMap(() => {
          return (this.productService.downloadProductAttachmentFromBlob(this.product._id, attachment._id))
        }))
        .subscribe({
        next: (response:any) => {
  
          saveAs(response.body, attachment.name);
          const message = 'components.modal.product.attachment.downloadSuccessMsg';
          const keepAlertOnNavigate = false;
          this.alertService.success(message, keepAlertOnNavigate);
        },
        error: (error) => {
          const message = 'components.modal.product.attachment.downloadErrorMsg';
          const keepAlertOnNavigate = false;
          this.alertService.error(message, keepAlertOnNavigate);
        }
      })
    }
    else {
      from(modalWindowRef.result).pipe(
        filter((reason: Reason) => Reason.submitBtn == reason),
        tap(_ => console.log(attachment._id)),
        mergeMap(() => {
          return (this.providerService.downloadProviderAttachmentFromBlob(this.provider.id, attachment._id))
        }))
        .subscribe({
        next: (response:any) => {
  
          saveAs(response.body, attachment.name);
          const message = 'components.modal.product.attachment.downloadSuccessMsg';
          const keepAlertOnNavigate = false;
          this.alertService.success(message, keepAlertOnNavigate);
        },
        error: (error) => {
          const message = 'components.modal.product.attachment.downloadErrorMsg';
          const keepAlertOnNavigate = false;
          this.alertService.error(message, keepAlertOnNavigate);
        }
      })
    }
  }

  previewAttachment(attachment:any){
    const modalTitle: string = 'Document Viewer'    
    const modalRef = this.modalCreator.createClassicModalWithInjectedComponent(AttachmentsViewerComponent, modalTitle, 'noshow');
    let id!:any,isProduct!:boolean;
    if(this.product){
      id=this.product._id;
      isProduct=true;
    }else{
      id= this.provider.id;
      isProduct=false;
    }
    const aSub: Subscription = modalRef.shown.pipe(
      tap(_=> modalRef.componentInstance.componentInjectedInstance.id = id ),
      tap(_=> modalRef.componentInstance.componentInjectedInstance.isProduct=isProduct ),
       tap(_=> modalRef.componentInstance.componentInjectedInstance.attachment = this.attachment ),
       tap(_=> modalRef.componentInstance.componentInjectedInstance.ngOnInit() ),
       tap(_=> this.modalCreator.formSub.next(modalRef.componentInstance.componentRef.instance.myForm) ),
     ).subscribe(_ => aSub.unsubscribe()); 
     from(modalRef.result).pipe(
      filter(r => r.action !== 'close'),
      tap(_ => this.refresh.emit())
    ).subscribe({
      next: () => {
        const message = 'components.modal.product.attachment.editSuccessMsg';
        const keepAlertOnNavigate = false;
        this.alertService.success(message, keepAlertOnNavigate);
      },
      error: (error) => {
        const message = 'components.modal.product.attachment.editErrorMsg';
        const keepAlertOnNavigate = false;
        this.alertService.error(message, keepAlertOnNavigate);
      }
    });
  }
}
