import { Component, Input, OnInit } from '@angular/core';
import { ProductDatasetService } from 'src/app/services/product-dataset-service/product-dataset-service.service';
import { Product } from 'src/app/models/product.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//import * as xlsx from 'xlsx';
import * as ExcelJS from 'exceljs';
import { AlertService } from 'src/app/services/alert-service/alert.service';

@Component({
  selector: 'app-dataset-upload',
  templateUrl: './dataset-upload.component.html',
  styleUrls: ['./dataset-upload.component.scss']
})
export class DatasetUploadComponent implements OnInit{

  public myForm: FormGroup | any;
  public selectedFile: any;
  private fileToSend: any;
  public isValidFormat: boolean = false;
  @Input() product!: Product;

  fileWarningMessage: string = ''

  constructor(
    private productDatasetService: ProductDatasetService,
    private alertService: AlertService
  ){}

  ngOnInit(): void {
      this.initializeForm()
  }

  initializeForm(){
    this.myForm = new FormGroup({
      dataSetName: new FormControl('', Validators.required),
      dataSetFile: new FormControl(null, Validators.required)
    })
  }

  getAcceptedFormats(): string {
    const acceptedFormats = [".xls", ".xlsx", ".xlm"];
    return acceptedFormats.join(",");
  }

  onFileSelect(event: any) {
    const file = event.target.files[0];
    const maxFileSizeInBytes = 20 * 1024 * 1024; // 20MB
    const allowedFormats = [".txt", ".doc", ".docx", ".pdf", ".csv", ".xlsx", ".png", ".jpg", ".jpeg", ".gif", ".bmp"];
  
    if (file.size <= maxFileSizeInBytes) {
      // Check if the file format is allowed
      const fileExtension = '.' + file.name.split('.').pop();
      if (allowedFormats.includes(fileExtension.toLowerCase())) {
        const filesReader = new FileReader();
  
        // SWAP XLSX FOR ExcelJS
        filesReader.onload = (e: any) => {
          const arrayBuffer = e.target.result;
          const workbook = new ExcelJS.Workbook();
          const typedArray = new Uint8Array(arrayBuffer);
          const buffer = typedArray.buffer;
  
          workbook.xlsx.load(buffer).then(() => {
            const firstSheet = workbook.getWorksheet(1)!;
            const excelData = firstSheet.getSheetValues();
  
            const validationErrors = this.validateData(excelData);
            // console.log(validationErrors, "errors");
            if (validationErrors.length === 0) {
              this.fileWarningMessage = '';
              this.fileToSend = file;
              this.selectedFile = { name: file.name, file: file };
              this.myForm.patchValue({ 'dataSetFile': file });
              this.isValidFormat = true;
            } else {
              this.fileWarningMessage = 'The format of the uploaded excel is not correct, please review it and upload it again';
              this.isValidFormat = false;
              this.myForm.patchValue({ 'dataSetFile': null });
            }
          });
        };
  
        filesReader.readAsArrayBuffer(file);
      } else {
        // Handle the case where the file format is not allowed (you can show an error message)
        const message = `File format "${fileExtension}" is not allowed for "${file.name}".`;
        this.alertService.error(message, false);
      }
    } else {
      // Handle the case where the file size exceeds the limit (you can show an error message)
      const message = `File "${file.name}" exceeds the maximum allowed size (20MB).`;
      this.alertService.error(message, false);
    }
  }


  validateData(data: any[]) {
    const errors = [];

    // Ensure first two rows exist.
    if (!data[1] || !data[2]) {
      errors.push('Row 1 or 2 is missing.');
      return errors;
    }

    // Check the first row (header)
    const expectedHeaders = [
      'Attribute name (Required)',
      'Attribute description (Required)',
      'Physical attribute name (Optional)',
      'Units (Optional)',
      'Data type (Optional)',
      'Tags/Categories (Optional)'
    ];

    for (let i = 0; i < expectedHeaders.length; i++) {
      if (data[1][i + 1] !== expectedHeaders[i]) {
        errors.push(`Header cell at index ${i} should be "${expectedHeaders[i]}".`);
      }
    }

    // Check the second row (description)
    const expectedDescriptions = [
      'The business (common) name of the attribute/metric/characteristic',
      'The explanation of what the attribute/metric/characteristic refers to or how it\'s used',
      'Name of the attribute/metric/characteristic as it is referred to in the products or tables',
      'The way in which the attribute/metric/characteristic is measured (%, percentile, M€, date, text...)',
      'The type of data that the attribute/metric/characteristic is (Double, Integer, String, Boolean...)',
      'Any categorical information about the attribute such as the ESG category it refers to or internal tags it has assigned'
    ];

    for (let i = 0; i < expectedDescriptions.length; i++) {
      if (data[2][i + 1] !== expectedDescriptions[i]) {
        errors.push(`Description cell at index ${i} should be "${expectedDescriptions[i]}".`);
      }
    }

    // Check the third row
    for (let i = 1; i < 3; i++) {
      if (!data[3][i]) {
        errors.push(`Row 3: Cell at index ${i} must be filled.`);
      }
    }

    return errors;
  }



  cleanInput(){
    this.selectedFile = null;
    this.myForm.patchValue({'dataSetFile': null});
  }

}
