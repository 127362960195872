import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service/alert.service';

@Component({
  selector: 'app-product-attachment-creation',
  templateUrl: './product-attachment-creation.component.html',
  styleUrls: ['./product-attachment-creation.component.scss']
})
export class ProductAttachmentCreationComponent implements OnInit{

  public selectedFiles: any[] = []; //change this
  public filesFromDB: any[] = []; 
  public myForm: FormGroup | any;
  @Input() data!:any
  originalName: string = '';

  constructor(
    private alertService: AlertService
  ) {}

  ngOnInit(): void {     
    if(this.data){
      this.fillForm();
    }else{
      this.createForm();
    }
  }

  createForm(){
    this.myForm = new FormGroup({
      name: new FormControl('', /*Validators.required*/),
      description: new FormControl('', [Validators.pattern(/^[A-Za-z0-9 áéíóúÁÉÍÓÚ,.:]*$/), Validators.required]),
      attachments: new FormControl([], Validators.required),
    })
  }

  fillForm(){
    
    const file = this.data;
    const blobed = new File([''], file.name);
    // TODO: Unify backend parameters to receive the same
    // in all cases`

    const fileName = this.extractFileName(this.data.name);
    this.originalName = this.extractFileName(this.data.name);

    this.selectedFiles.push({name: fileName, file: blobed}); //to show chips for attachments
    this.filesFromDB.push(file)
    
    this.myForm = new UntypedFormGroup({
      name: new UntypedFormControl(file.name, /*Validators.required*/),
      description: new UntypedFormControl(this.data.description, [Validators.pattern(/^[A-Za-z0-9 áéíóúÁÉÍÓÚ,.:]*$/), Validators.required]),
      attachments: new UntypedFormControl(this.selectedFiles, Validators.required)
    })

  }

  extractFileName(fileNameWithExtension: string): string{
    // Use a regular expression to match the name at the beginning of the string
    const match = fileNameWithExtension.match(/^(.+)\.[a-zA-Z0-9]+$/);

    if (match) {
      // Index 1 of the match contains the name
      const name = match[1];
      return name;
    } else {
      // If no match is found, return the original string or handle it as needed
      return fileNameWithExtension;
    }
  }

  onFilesSelected(event: any) {
    const maxFileSize = 20 * 1024 * 1024; // 20MB
    const allowedFormats = [".txt", ".doc", ".docx", ".pdf", ".csv", ".xlsx", ".png", ".jpg", ".jpeg", ".gif", ".bmp"];
  
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      // Get file extension
      const fileExtension = '.' + file.name.split('.').pop();
      // Verify if the file has multiple extensions
      const nameParts = file.name.split('.');
      const hasMultipleExtensions = nameParts.length > 2;
  
      if (!hasMultipleExtensions && file.size <= maxFileSize && allowedFormats.includes(fileExtension.toLowerCase())) {
        this.selectedFiles.push({ name: this.extractFileName(file.name), extension: fileExtension, file: file, changed: true });
      } else {
        // Manage the cases where the file is not allowed
        if (hasMultipleExtensions) {
          const message = `File "${file.name}" is not allowed because it has multiple extensions.`;
          this.alertService.error(message, false);
        } else if (file.size > maxFileSize) {
          const message = `File "${file.name}" exceeds the maximum allowed size (20MB).`;
          this.alertService.error(message, false);
        } else {
          const message = `File format "${fileExtension}" is not allowed for "${file.name}".`;
          this.alertService.error(message, false);
        }
      }
    }
  
    this.myForm.patchValue({ 'attachments': this.selectedFiles });
  }
  
  onRemove(){
    this.selectedFiles = [];
    this.myForm.patchValue({'attachments': this.selectedFiles})
  }

  handleRenamedAttachment(event: any) {
    let fileName: string = '';
    
    if(this.data){
      const extension = this.data.name.split(/[.]/)[1];
      fileName = `${event.newName}.${extension}`
    } else {
      fileName = `${event.newName}${event.extension}`
    }

    this.myForm.patchValue({'name': fileName});
  }

  hasErrorPattern(formField: string): boolean {
    return this.myForm.get(formField).hasError('pattern');
  }
}
