import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { ProviderEditorComment } from './provider-editor-comment.model';
import { ApiService } from '../services/api/api-service/api.service';
import { PageModel } from '../services/api/pagination-service/model/page.model';
import { AlertService } from '../services/alert-service/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderEditorCommentsService {
  readonly modelName = 'historicProviderEditorComments';

  constructor(
    public apiService: ApiService,
    private http: HttpClient
  ) { }

  /**
  * Create a Provider Editor Comment
  * @param data
  * @returns
  */
  createProviderEditorComment$(data: Omit<ProviderEditorComment, 'id'>) {
    const request$ = this.apiService.post(
      `${this.modelName}`,
      JSON.stringify(data),
      'components.modal.editorComment.createSuccessMsg'
    );
    return request$;
  }

   /**
  * Get a Provider Editor Comment
  * @param data
  * @returns
  */
   getProviderEditorComment$(id: string, params: { [keyof: string]: any } = {}) {    
    const slug = `${this.modelName}/${id}`;
    const request$ = this.apiService.get<PageModel<ProviderEditorComment>>(
      slug,
      params,
      ''
    );
    return request$;
  }

  getProviderEditorCommentWithoutPagination$(id: string) {
    return this.getProviderEditorComment$(id).pipe(
      map(result=> result.data),
    );
}

}
