import { Component, EventEmitter, Output } from '@angular/core';
import { ChatbotService } from 'src/app/services/chatbot-service/chatbot.service';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss']
})
export class ChatHistoryComponent {
  @Output() newChatEmit = new EventEmitter();

  loggedUser: any;
  isLoadingHistory: boolean = false;
  // All conversations
  chatHistory: any[] = [];
  chatHistorySearch = new FormControl('');

  // Conversations sorted by date after calling sortChatHistoryByDate()
  todaysConversations: any[] = [];
  yesterdaysConversations: any[] = [];
  previousChatsConversations: any[] = [];

  constructor(
    private chatbotService: ChatbotService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.isLoadingHistory = true;
    this.loggedUser = this.authService.getLoggedInUser();
    this.chatbotService.getChatHistoryByUserId().subscribe((chatHistory) => {
      this.chatHistory = chatHistory;
      console.log("Chat History",this.chatHistory);
      
      this.sortChatHistoryByDate(chatHistory);
      this.isLoadingHistory = false;
    });
  }

  // filterChat(event: any) {
  //   let filteredHistory = this.chatHistory.filter((chat:any)=> {
  //    return chat.first_question.toLowerCase().includes(event.target.value.toLowerCase())
  //   })
  //   this.sortChatHistoryByDate(filteredHistory);
  // }

  searchTimeout: any;

  onSearchChange(event: any) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.filterChat(event.target.value);
    }, 800);
  }

  filterChat(search: string) {
    this.chatbotService.filterChatHistoryBySearch(search).subscribe((chatHistory: any) => {
    this.sortChatHistoryByDate(chatHistory);
    } );
  }

  createNewChat() {
    this.newChatEmit.emit();
  }

  openChat(chat_id: string, title: string) {
    this.chatbotService.setChatState({ chat_id, title });
  }

  clearSearch(input: HTMLInputElement): void {
    input.value = ''; 
    this.chatHistorySearch.setValue('');
    this.filterChat(''); 
  }

  // TODO: Is grouping by today, yesterday and previous days good. But is not using the hours, so inside the group, the order is not correct.
  sortChatHistoryByDate(chatHistory: any[]) {
    const today = new Date();
    const todayWithoutTime = new Date(today.setHours(0, 0, 0, 0));
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const yesterdayWithoutTime = new Date(yesterday.setHours(0, 0, 0, 0));
  
    const todayObjects = [];
    const yesterdayObjects = [];
    const previousChatsObjects = [];
  
    for (const obj of chatHistory) {
      const date = new Date(obj.last_timestamp);
      const dateWithoutTime = new Date(date.setHours(0, 0, 0, 0));
      if (dateWithoutTime.getTime() === todayWithoutTime.getTime()) {
        todayObjects.unshift(obj);
      } else if (dateWithoutTime.getTime() === yesterdayWithoutTime.getTime()) {
        yesterdayObjects.unshift(obj);
      } else if (date < yesterdayWithoutTime) {
        previousChatsObjects.unshift(obj);
      }
    }
    this.todaysConversations = todayObjects;
    this.yesterdaysConversations = yesterdayObjects;
    this.previousChatsConversations = previousChatsObjects;
  
    console.log("Yesterdays",this.yesterdaysConversations);
    console.log("Previous Chats",this.previousChatsConversations);
  }
// TODO: Call also the enpoint to delete chat from the database (is not really a delete, it changes the active flag on DB, but from user's perspective is a delete)
  deleteChat(chat_id: string) {
    this.chatbotService.deleteChatByChatId(chat_id).subscribe(() => {
      this.chatHistory = this.chatHistory.filter((chat) => chat._id !== chat_id);
      this.sortChatHistoryByDate(this.chatHistory);
    });
  }
  
}
