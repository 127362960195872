import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AppliedFilterInfo, ChipOption } from '../advance-filters.interfaces';
import { MarketDiscoveryService } from 'src/app/services/market-discovery/market-discovery.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-advance-filter-chips',
  templateUrl: './advance-filter-chips.component.html',
  styleUrls: ['./advance-filter-chips.component.scss']
})
export class AdvanceFilterChipsComponent implements OnInit {

  resetSub: Subscription = new Subscription();

  @Input() filter!: any;
  @Input() showOfferings!: boolean;
  @Output() onSelected = new EventEmitter<AppliedFilterInfo>();
  @Output() emitRemoval = new EventEmitter<AppliedFilterInfo>();

  constructor(
    private marketDiscoveryService: MarketDiscoveryService
  ){}

  ngOnInit(): any {
    this.resetSub = this.marketDiscoveryService.resetTypesOne.subscribe((option: ChipOption) => {
      if(option){

        const charName = this.marketDiscoveryService.toCamelCase(this.filter.name);
        const currentProvsInProducts = this.marketDiscoveryService.products.map((prod:any) => prod.provider.playerName);
        const provs = this.marketDiscoveryService.providers;
        
        const structure = this.showOfferings ? 
          provs.filter((prov:any) => currentProvsInProducts.some((playerName: string) => playerName === prov.playerName)) :
          provs;
        
        const existingValues = this.marketDiscoveryService.getPropertyValuesFromProvider(charName, structure);
        
        if(existingValues.includes(option.name)){
          option.disabled = false;
        }
      }
        
    });
  }

  selectOption(option: ChipOption){    
    
    if(!option.disabled){      
      this.clearAllButSelected(option);

      let toEmit: AppliedFilterInfo;

      if(option.selected) {
        toEmit = {
          name: this.filter.name,
          type: this.filter.type,
          options: [option]
        };   
      } else { // this case is to consider the possibility of the same option deselection
        toEmit = {
          name: this.filter.name,
          type: this.filter.type,
          options: []
        };   
      }
      this.onSelected.emit(toEmit);
    } 

  }

  //TODO: Evaluate how this behaves on emitters and when the logic gets bigger
  clear(){
    this.filter.options.forEach((option: ChipOption) => option.selected = false);

    const toEmit: AppliedFilterInfo = {
      name: this.filter.name,
      type: this.filter.type,
      options: []
    };

    this.onSelected.emit(toEmit);
  }
  
  clearAllButSelected(option: ChipOption){
    const charName = this.marketDiscoveryService.toCamelCase(this.filter.name);
    const currentProvsInProducts = this.marketDiscoveryService.products.map((prod:any) => prod.provider.playerName);
    const provs = this.marketDiscoveryService.providers;

    const structure = this.showOfferings ? 
      provs.filter((prov:any) => currentProvsInProducts.some((playerName: string) => playerName === prov.playerName)) :
      provs;
    const existingValues = this.marketDiscoveryService.getPropertyValuesFromProvider(charName, structure);

    this.filter.options.forEach((opt: ChipOption) => {
      if(opt === option){
        opt.selected = !opt.selected;
      }else {
        opt.selected = false;
        if(existingValues.includes(opt.name)){
          opt.disabled = false;
        }
      }
    });
  }

  remove(){
    this.clear();
    this.emitRemoval.emit();
  }

  ngOnDestroy():void {
    if(this.resetSub){
      this.resetSub.unsubscribe();
    }
  }

}
