<div class="filter-content">
  <div *ngIf="!loading && !backgroundLoading && enableDataLoading" class="action-buttons">
  <button
    [ngStyle]="showFilters ? {'opacity': '0.6'} : {}"
    id="filter-table"
    class="pagehead__button pagehead__button--filter"
    (click)="toggleShowFilters($event)"
  >
    {{ 'page.admin.button.filter.user' | translate : 'Filter' }}
  </button>

    <button
      id="create-user"
      class="pagehead__button pagehead__button--new"
      (click)="onCreateUser($event)"
      >
        {{ 'page.admin.button.create.user' | translate : 'Create user' }}
    </button>
  </div>

  <!-- start filters panel -->
  <div *ngIf="enableDataLoading" [hidden]="!showFilters" class="reviewers__filters">
    <app-validations-filter (onSearch)="onSearch($event)" [rolesList]="rolesList" [paginationType]="paginationType"
      [fields]="fields" [filtersTitle]="'Users'" #validationsFilter>
    </app-validations-filter>
  </div>
  <!-- end filters panel -->
  <!-- start table -->
  <app-spinner-ball *ngIf="loading" [spinnerStyles]="'la-ball-circus'"></app-spinner-ball>

  <app-responsive-table
  *ngIf="!loading"
   [dataType]="paginationType"
   [loading]="loading"
   [backgroundLoading]="backgroundLoading"
   [settings]="settingTable"
   [data]="userTableRows"
   [columns]="userTableColumnConfiguration"
   [dataType]="'users'"
   [isEndlessScroll]="true"
   (loadingChange)="onLoadingChange($event)"
   (selected)="onSelected($event)"
   (onSelectedItems)="onSelectedItems($event)"
   (onSortEvent)="onTableSort($event)"
   (onPageChangeEvent)="onTablePageChange($event)">
  </app-responsive-table>
</div>
<!-- end table -->
