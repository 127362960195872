import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/models/product.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { DataMasterService } from 'src/app/services/data-master-service/data-master';


@Component({
  selector: 'app-provider-flag-edit-modal',
  templateUrl: './provider-flag-edit-modal.component.html',
  styleUrls: ['./provider-flag-edit-modal.component.scss']
})
export class ProviderFlagEditModalComponent {
  myForm!: FormGroup;
  private _data!: ProviderModel;
  private _isDataLoaded: boolean = false;

  @Input()
  set data(value: ProviderModel) {
    this._data = value;
    this._isDataLoaded = true;
    if (value) {
      this.myForm = this.formBuilder.group({
        flag: value.flag,
        flagDescription: value.flagDescription,
      });
    }
  }

  get data(): ProviderModel {
    return this._data;
  }

  @Input() product!: Product;
  option = '';
  action = 'create';
  @Input() flagsList: any = [];
  showWarning: boolean = false;

  closeModal($event: MouseEvent) {
    this.activeModal.close({ action: 'close' })
  }
  
  constructor(
    public activeModal: NgbActiveModal,
    public dataMasterService: DataMasterService,
    protected alertService: AlertService,
    protected translate: TranslatePipe,
    private formBuilder: FormBuilder
  ) {
    this.myForm = this.formBuilder.group({
      flag: ['', Validators.required],
      flagDescription:  [''],
    });
  }

  ngOnInit() {
    if (this._isDataLoaded) {
      this.setInitialValues(this.data);
    }
    this.myForm.get('flag')?.valueChanges.subscribe(value => {
      const flagDescriptionControl = this.myForm.get('flagDescription');
      if (value === 'No Flag') {
        flagDescriptionControl?.setValue('');
        flagDescriptionControl?.disable();
      } else {
        flagDescriptionControl?.enable();
      }
    });
  }

  setInitialValues(provider: ProviderModel) {
    if (provider) {
      this.myForm.patchValue({
        flag: this.transform(provider.flag),
        flagDescription: provider.flagDescription,
      });
      if (this.myForm.get('flag')?.value === 'No Flag') {
        this.myForm.get('flagDescription')?.disable();
        this.myForm.get('flagDescription')?.setValue('');
      }
    }
  }

  transform(value: string): string {
    return value
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  onSubmit() {}
}
