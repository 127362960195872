import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ProductService } from 'src/app/services/product/product.service';
import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';

@Component({
  selector: 'app-attachments-viewer',
  templateUrl: './attachments-viewer.component.html',
  styleUrls: ['./attachments-viewer.component.scss']
})
export class AttachmentsViewerComponent implements OnInit{
  @Input() id!:any;
  @Input() isProduct!:boolean;
  @Input() attachment!:any;
  fileUrl!:any;
  imageUrl!:any;
  isImage!:any;
  isLoading: boolean = true; 

  constructor(
    public productService: ProductService,
    public providerService: ProviderCollectionService,
    private sanitizer: DomSanitizer
  ){}

  ngOnInit(): void {
    let downloadObservable: Observable<any>;

    if(this.attachment){
      if(this.isProduct){
        downloadObservable = this.productService.downloadProductAttachmentFromBlob(this.id, this.attachment._id);
      }else{
        downloadObservable = this.providerService.downloadProviderAttachmentFromBlob(this.id, this.attachment._id);
      }
      downloadObservable.subscribe({
        next: (response: any) => {        
          this.isImage = /(jpeg|jpg|gif|png)$/i.test(this.attachment.filepath);
          const blob = new Blob([response.body]);           
          if(this.isImage){
            const fileUrl = window.URL.createObjectURL(blob);
            this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
          }else{
            this.fileUrl = window.URL.createObjectURL(blob);
          }
          this.isLoading = false;
        }
      });
    }
  }

}
