import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild, forwardRef, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { Subscription, filter, map, tap } from 'rxjs';
import { FlattenedTreeItemOption, TreeGroup } from './tree-group.model';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { AutocompleteUtilsService } from 'src/app/services/autocomplete-utils/autocomplete-utils.service';

@Component({
  selector: 'app-auto-complete-tree-source',
  templateUrl: './auto-complete-tree-source.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteTreeSourceComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutoCompleteTreeSourceComponent),
      multi: true
    }
  ],
  styleUrls: ['./auto-complete-tree-source.component.scss'],
})
export class AutoCompleteTreeSourceComponent implements ControlValueAccessor, Validator, OnChanges, OnDestroy {

  control = new FormControl();
  displayControl = new FormControl();
  @Input() required = false;
  @Input() cssMulti = false;
  @Input() searchOnRemove = false;
  @Input() placeholder = 'Search a ...'
  @Output() onSelectedItem = new EventEmitter();
  @Output() onRemovedItem = new EventEmitter();
  @Output() onClearSingleAutocomplete = new EventEmitter();
  componentSubscriptions: Subscription = new Subscription();
  // Validator function to check if the value is set when required
  validate(control: AbstractControl): ValidationErrors | null {
    return this.required && !control.value ? { required: true } : null;
  }

  // Methods required for ControlValueAccessor
  writeValue(value: any): void {
    // reset is called
    if (value === null) {
      this.filteredOptions = this.flattenedOptions;
      this.selectedFlattenedOptions = [];
      value = "";
    }

    if (typeof value === 'string' && value.trim() !== '') {
      this.addItemFromText(value);
    } else {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  clearInput(inputControl: FormControl) {
    inputControl.setValue("");
    this.onClearSingleAutocomplete.emit();
  }

  registerOnTouched(fn: any): void {
    /**
     *  It is used to register a callback function that should be called when the control "blurs", i.e., loses focus. This can be useful in situations where you want to perform some validation or other actions when the user moves away from the form control.
     */
    // You can implement this if we needed
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  @Input() allowMultiple: boolean = true;  // default to true for backward compatibility

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;

  /**
   * Key codes for special keys used to separate tags/chips.
   * Currently supports ENTER and COMMA.
   */
  separatorKeysCodes: number[] = [ENTER, COMMA];
  /**
   * Label to be displayed for the autocomplete input field.
   * Defaults to an empty string.
   */
  @Input() label = '';
  stateForm!: FormGroup;
  /**
   * Input property for state groups to be displayed in the autocomplete dropdown.
   */
  @Input() source: TreeGroup<any>[] = [];
  /**
   * Flattened structure derived from `stateGroups` for display purposes.
   */
  flattenedOptions: FlattenedTreeItemOption<any>[] = [];  // Flattened structure for display
  /**
   * List of selected options in flattened format.
   */
  selectedFlattenedOptions: FlattenedTreeItemOption<any>[] = []

  /**
 * Flattened options after being filtered based on user input.
 */
  filteredOptions: FlattenedTreeItemOption<any>[] = [];  // Filtered options for display
  /**
   * Reference to the autocomplete input element.
   * This is used to clear the input value once an option is selected.
   */
  @ViewChild('autocompleteInput') autocompleteInput?: ElementRef<HTMLInputElement>;

  /**
   * Reference to the autocomplete trigger.
   * This is used to programmatically control the showing/hiding of the autocomplete dropdown.
   */
  constructor(
    private autocompleteUtilsService: AutocompleteUtilsService,
    protected translate: TranslatePipe
  ) {}

  ngOnDestroy(): void {
    this.componentSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.fillSource(this.source);
    this.displayControl.valueChanges
      .pipe(
        filter(value => typeof value === 'string'),
        tap((value: string) => {
          this.filteredOptions = this._filterShownListOfAutocomplete(value);
        })
      ).subscribe();
  }

  private transformValueForDisplay(value: any): any {
    return value?.displayValue;
  }

  private fillSource(source: TreeGroup<any>[]) {
    this.flattenedOptions = this.convertToFlattenGroups(source);
    this.filteredOptions = this.flattenedOptions;
    this.componentSubscriptions = this.control.valueChanges.pipe(
      filter(value => typeof value === 'string'),
      map((value: string) => this._filterShownListOfAutocomplete(value))
    ).subscribe(filteredOptions => this.filteredOptions = filteredOptions);
  }

  /**
   * TODO: Move it to a service
   * Flattens the given nested groups into a single array of options.
   * @param groups - The nested groups to flatten.
   * @param level - The current level of nesting.
   * @returns The flattened list of options.
   */
  convertToFlattenGroups(groups: any[], level: number = 0): FlattenedTreeItemOption<any>[] {
    let flattened: FlattenedTreeItemOption<any>[] = [];

    for (let group of groups) {
      flattened.push(this.createFlattenTreeItemOptionFromGroup(group, level));

      if (group.subgroups.length > 0) {
        flattened = flattened.concat(this.convertToFlattenGroups(group.subgroups, level + 1));
      }
    }

    return flattened;
  }

  createFlattenTreeItemOptionFromGroup<T>(group: TreeGroup<T>, level: number) {
    return {
      displayValue: group.letter,
      selectedValue: group,
      level: level,
      isDisabled: group.subgroups.length > 0
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['source'] &&
      !changes['source'].isFirstChange() &&
      changes['source'].currentValue.length !== changes['source'].previousValue.length) {
      this.fillSource(changes['source'].currentValue as any)
    }
  }
  /**
   * Filters the list of options based on the given value.
   * If the value is empty, it returns all options.
   * Otherwise, it filters based on the value.
   * @param value - The value to filter the options with.
   * @returns The filtered list of options.
   */
  private _filterShownListOfAutocomplete(value: string): FlattenedTreeItemOption<any>[] {
    const filterValue = value.trim().toLowerCase();

    let filtered = this.flattenedOptions.filter(option =>
      option.displayValue.toLowerCase().includes(filterValue)
    );

    if (filtered.length === 0) {
      filtered = [{
        displayValue: 'Invalid value',
        isDisabled: true,
        level: 0,
        selectedValue: null
      }];
    }
    return filtered;
  }

  /**
   * Handles the selection of an option from the autocomplete dropdown.
   * This is trigger with mouse selection event
   * event.option.value it will be always the object, because is selected by click
   * @param event - The event which triggers this function.
   */
  onSelected(event: MatAutocompleteSelectedEvent): void {
    const itemOption: FlattenedTreeItemOption<any> = event.option.value;
    this.addToSelectedItems(itemOption);
    this.displayControl.setValue(this.transformValueForDisplay(itemOption), { emitEvent: false });
  }

  /**
  * This method handles adding a new item from a keyboard event.
  * @param event - The keyboard event which triggers this function.
  */
  addItem(event: MatChipInputEvent): void {
    this.addItemFromText(event.value)
  }

  addItemFromText(itemText: string) {
    const itemToAdd = this.findItemFromText(itemText);
    if (itemToAdd) {
      this.addToSelectedItems(itemToAdd);
    } else {
      console.warn(`Item not found`);
    }
  }

  findItemFromText(itemText: string) {
    const normalizeItemText = (itemText || '').trim().toLowerCase();
    const itemExistInAlreadySelected = this.selectedFlattenedOptions.find(option => option.displayValue.toLowerCase() === normalizeItemText || option.selectedValue.object === normalizeItemText);
    if (itemExistInAlreadySelected) {
      console.warn(`Already added`);
      return itemExistInAlreadySelected;
    }

    const itemToAdd = this.flattenedOptions.find(option => !option.isDisabled && (option.displayValue.toLowerCase() === normalizeItemText || option.selectedValue.object === normalizeItemText))
      || this.flattenedOptions.find(option => !option.isDisabled && option.displayValue.toLowerCase().startsWith(normalizeItemText))
      || this.flattenedOptions.find(option => !option.isDisabled && option.displayValue.toLowerCase().includes(normalizeItemText));

    return itemToAdd!;
  }

  /**
  * Adds the given item to the selected items list.
  * Checks if the item already exists or not, and if it does, provides a console message.
  * Otherwise, it adds the item to the selected items.
  * @param item - The item to add to the selected list.
  */
  addToSelectedItems(itemToAdd: FlattenedTreeItemOption<any>) {
    if (!this.allowMultiple) {
      this.selectedFlattenedOptions = [];
      this.filteredOptions = [itemToAdd!]
      this.autocompleteTrigger?.closePanel();
    }
    // we make sure to compare the displayValue, not the item itself because it can be different in some cases
    const itemExistInAlreadySelected = this.selectedFlattenedOptions.find(option => option.displayValue === itemToAdd.displayValue);
    if (itemExistInAlreadySelected) {
      console.warn(`Already added`);
      return itemExistInAlreadySelected;
    }
    this.selectedFlattenedOptions.push(itemToAdd);
    this.onSelectedItem.emit(itemToAdd);

    // Clear the input value
    // this.control.setValue(itemToAdd.selectedValue.object, { emitEvent: false });  // Reset the value without emitting
    // this.control.setValue(itemToAdd.selectedValue.object);  // Reset the value without emitting
    const controlValue = this.selectedFlattenedOptions.length === 1 ? itemToAdd.selectedValue.object : this.selectedFlattenedOptions;
    this.control.setValue(controlValue);
    this.displayControl.setValue(this.transformValueForDisplay(itemToAdd), { emitEvent: false });

    if (this.autocompleteInput) {
      this.autocompleteInput.nativeElement.value = '';
    }
    this.filteredOptions = this.flattenedOptions;

    return itemToAdd;
  }

  /**
   * Removes the specified selected item from the list.
   * @param selectedItem - The item to remove.
   */
  onRemove(selectedItem: FlattenedTreeItemOption<any>) {
    this.selectedFlattenedOptions = this.selectedFlattenedOptions.filter(item => selectedItem != item);
    // this.filteredOptions = this.filteredOptions.filter(item => item.displayValue != selectedItem.displayValue)
    if (this.filteredOptions.length === 0) {
      this.filteredOptions = this.flattenedOptions;
    }

    let controlValue = null;
    if (this.selectedFlattenedOptions.length === 1) {
      controlValue = this.selectedFlattenedOptions[0];
    } else if (this.selectedFlattenedOptions.length > 1) {
      controlValue = this.selectedFlattenedOptions
    }
    this.control.setValue(controlValue);
    // this.control.setValue(selectedItem.selectedValue.object, { emitEvent: false });  // Reset the value without emitting
    if (this.autocompleteInput) {
      this.autocompleteInput.nativeElement.value = '';    // Clear the input
    }

    if(this.searchOnRemove){
      this.onRemovedItem.emit(controlValue);
    }
  }

  /**
   * Reload selectedFlattened options when we apply a changelog update
   */
  reloadSelectedFlattenedOptions(values: any[]){
    this.selectedFlattenedOptions = [];
    if(values !== undefined){
      const flattenedValues = this.autocompleteUtilsService.convertListOfStringsToFlattenedTreeItemOption(values)
      flattenedValues.forEach((value:any) => this.addToSelectedItems(value))
    }
  }

  isNoValidData(): boolean {
    return this.displayControl.value && this.filteredOptions.length === 1 && this.filteredOptions[0].displayValue === 'Invalid value';
  }
}
