import { Component, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})
export class ChatbotComponent {
  @Output() chatOpened = new EventEmitter<boolean>();

  showChatView = false;
  loggedUser: any;

  constructor(private authService: AuthenticationService) {}

  ngOnInit(): void {
    this.authService.userLoggedIn.subscribe(user => {
      this.loggedUser = user;
    });
  }

  openChatView() {
    this.showChatView = true;
    this.chatOpened.emit(true);
  }

  closeChatView() {
    this.showChatView = false;
    this.chatOpened.emit(false);
  }
}
