<div
  class="respontable-header"
  [ngClass]="{ 'respontable-header--filtering': showFilters }"
>
  <div class="respontable-header__elements-block">
    <app-total-elements-detail
      *ngIf="totalElements !== null && showElementCount"
      [totalElements]="totalElements"
      [currentListLength]="currentListLength"
      [allElements]="allElements"
      [pageSize]="pageSize"
      [dataType]="dataType"
      [isEndlessScroll]="isEndlessScroll"
    >
    </app-total-elements-detail>
    <ng-container>
      <div
        class="respontable-header__filter-label"
        *ngFor="let filter of filterLabels"
      >
        {{ filter.name | translate }}:
        <span>{{ filter.value }}</span>
      </div>
    </ng-container>
  </div>

  <div class="respontable-header__buttons-block">
    <button
      *ngFor="let button of buttons"
      class="respontable-header__button"
      [disabled]="button.noDataDisabled ? !length || length === 0 : false"
      [ngClass]="{
        'pagehead__button--disabled': button.noDataDisabled
          ? !length || length === 0
          : false
      }"
      (click)="buttonClicked.emit(button.action)"
    >
      {{ button.name | translate }}
    </button>

    <button
      *ngIf="useFilters"
      class="reviewers__button reviewers__button--filter"
      [ngClass]="{ active: showFilters }"
      (click)="onShowFilters()"
      title="Filters"
    ></button>
  </div>
</div>
