import { Injectable } from '@angular/core';
import { ApiService } from '../api/api-service/api.service';
import { Note } from 'src/app/models/note.model';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  readonly modelName = 'note';
  constructor(
    protected apiService: ApiService
  ) { }

  
  deleteNote$(note : Note | any): any {
    const slug = `${this.modelName}/`
    
    return this.apiService.delete(slug, note['_id'], 'components.modal.delete.success');
  }

  downloadNoteAttachmentFromBlob(noteId: Note | any, attachmentId: any) {
    const slug = `note/${noteId}/downloadAttachments/${attachmentId}`

    return this.apiService.downloadFile(slug, {});
  }

  deleteNoteAttachmentFromBlob(note: Note | any, attachments: any[]) {
    const slug = `${this.modelName}/${note._id}/deleteAttachments`

    return this.apiService.post(slug, { attachments : attachments }, '');
  }

  deleteManyNotes$(notes: Note[]): any {
    throw new Error('Method not implemented.');
  }

}
