<button
  mat-stroked-button
  [color]="color"
  [type]="type"
  *ngIf="color == 'accent'"
>
  {{ text }}
</button>

<button
  mat-button
  mat-flat-button
  color="primary"
  type="submit"
  *ngIf="color == 'primary'"
>
  {{ text }}
</button>
