import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-characteristics-visibility-modal',
  templateUrl: './characteristics-visibility-modal.component.html',
  styleUrls: ['./characteristics-visibility-modal.component.scss']
})
export class CharacteristicsVisibilityModalComponent implements OnInit {

  @Input() selectedFilter!: string;

  constructor(
    private activeModal: NgbActiveModal,
  ){}

  ngOnInit(){}

  apply(){
    this.activeModal.close({
      action: 'apply',
      selectedFilter: this.selectedFilter
    });
  }

  cancel() {
    this.activeModal.close({
      action: 'close'
    });
  }

}
