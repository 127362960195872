import { Note } from "./note.model";
import { DataFieldModel } from './data.model';
import { ProductCategoryField } from "./product-field-category.model";
import { AttributeProduct } from "./attribute-product.model";
import { UserModel } from "./user.model";
import { ProviderModel } from "./provider.model";
import { Status, Flag } from "./status.model";


/**
 * Product.
 */
export class Product {
  static getEmptyProduct(): Product | undefined {
    const result = new Product();
    result.eyResponsible = result.provider?.eyResponsible;
    result.lastUpdatedBy = UserModel.getEmptyUser();
    result.lastUpdatedByMetadata = UserModel.getEmptyUser();
    return result;
  }
  id: string = '';
  _id?: string = '';
  name: string = '';
  data: DataFieldModel[] = [];
  description: string = '';
  notes: Note[] = [];
  categories: ProductCategoryField[] = [];
  attributes: AttributeProduct[] = [];
  createdBy: string = '';
  eyResponsible!: UserModel;
  productId: string = '';

  // Person responsible for updating the information: Who is this
  responsable: UserModel = UserModel.getEmptyUser();
  lastUpdated: string = Product.formattedTimestamp(new Date());
  lastUpdatedBy: UserModel = UserModel.getEmptyUser();
  lastUpdatedByMetadata!: UserModel;
  lastValidated: string = Product.formattedTimestamp(new Date());
  lastValidatedBy: UserModel = UserModel.getEmptyUser();
  lastUpdatedMetadata: Date = new Date();
  // provider: ProviderModel = ProviderModel.getEmptyProvider();
  provider: any = {}
  status: string = '';
  flag: string = '';
  flagDescription: string='';
  percentageOfFilledFields = 0;
  typeOfEsgData: string = ''; // I think this one is no loger needed because the offering types is a charcteristic inside one of the categories 
  extentOfSpecialization: string = '';
  license: string = '';
  restriction: string = '';
  attachments: any[] = [];
  editAction?: string='';
  lastFlagUpdatedBy: UserModel |any ;
  static formattedTimestamp(date: Date){
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2,'0');
    const hours = String(date.getUTCHours()).padStart(2,'0');
    const minutes = String(date.getUTCMinutes()).padStart(2,'0');
    const seconds = String(date.getUTCSeconds()).padStart(2,'0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}

export class ProductCreationStructure {
  constructor(
    public name: string,
    public provider: Partial<ProviderModel>,
    public description: string,
    public offeringType: string,
    public categories: any[],
    // Should be empty on creation
    public lastValidatedBy: {
      // userId: string,
      // username: string,
      // role: string,
    },
    public createdBy: string,
    public eyResponsible: UserModel
  
  ) { }
}
