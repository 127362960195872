<div class="bar-chart">
  <apx-chart
    [series]="series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [legend]="chartOptions.legend"
    [yaxis]="chartOptions.yaxis"
    [colors]="colors"
    [stroke]="chartOptions.stroke"
    [tooltip]="chartOptions.tooltip"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [grid]="chartOptions.grid"
  ></apx-chart>
</div>

