import { Pipe, PipeTransform } from "@angular/core";
import { Subscription } from "rxjs";
import { TranslateService } from "src/app/services/translate-service/translate.service";

@Pipe({
  name: "translate",
  pure: false,
})
export class TranslatePipe implements PipeTransform {

  public isStarted: boolean = false;
  notFoundKeys: { [keyof: string]: string } = {};

  constructor(private translate: TranslateService) {
    this.isStarted = true;
    const aSub = this.translate.languageUpdated$.subscribe(_ => {
      this.isStarted = true;
      aSub.unsubscribe();
    })
  }

  /**
   * It has the translation files from src/assets/locale
   * @param key: the key of the words
   * @param defaultText : If key not found
   * @returns the translation
   */
  transform(key: string, defaultText?: string): string {
    defaultText = defaultText ? defaultText : key;
    if (this.isStarted && this.isEmpty(this.translate.data)) {
      // console.warn(`There is not translation loaded`);
      return defaultText;
    } else if (this.isEmpty(this.translate.data as {})) {
      return defaultText;
    } else {
      let wording = this.getWording(this.translate.data, key);
      if (wording || wording === '') {
        return wording;
      }

      this.setValueAtPath(key, this.translate.data, defaultText);

      if (defaultText) {
        return defaultText;
      }

      return key
    }
  }

  isEmpty(obj: any) {
    let count = 0;
    for (let i in obj) {
      if (obj.hasOwnProperty(i)) {
        count++;
      }
    }
    return count == 0;
  }

  normalizeText(s: string) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    return s;
  }

  isString(s: string | String) {
    return typeof s == 'string' || s instanceof String
  }

  setValueAtPath(path: string, dictionary: any, defaultValue: any = {}) {
    const keys = path.split('.');
    let current = dictionary;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!current.hasOwnProperty(key)) {
        current[key] = i === keys.length - 1 ? defaultValue : {};
      }
      current = current[key];
    }

    // console.warn(`The path '${path}' is not valid with the source dict: `, dictionary);
    // console.warn(`Your translation file should be like this (copy the last output):`, dictionary);
    // console.warn(`Copy the object (right-click storage object as global variable) and apply copy(JSON.stringify(temp1))`, dictionary);

    return current;
  }
  /**
   * Get the translation associated with the key
   * @param sourceOfWords
   * @param dotNotationKey
   * @returns
   */
  getWording(sourceOfWords: any, dotNotationKey: string): string | null {
    const s = this.normalizeText(dotNotationKey);
    const keys = s.split(".");
    let tmpLocation = sourceOfWords;
    for (const aKey of keys) {
      if (aKey in tmpLocation) {
        tmpLocation = tmpLocation[aKey];
      }

      const isLastItem = keys.at(-1) == aKey;
      if (!isLastItem && this.isString(tmpLocation)) {
        // throw Error(`The path ${dotNotationKey} is not valid with the source dict: `, sourceOfWords)
        return null;
      }
    }

    if (this.isString(tmpLocation)) {
      return tmpLocation.toString();
    }

    return null;
    // for (let i = 0, n = keys.length; i < n; ++i) {
    //   let k = keys[i];
    //   if (k in sourceOfWords) {
    //     sourceOfWords = sourceOfWords[k];
    //   } else {
    //     return k;
    //   }
    // }
    // return sourceOfWords;
  }
}
