import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Note } from 'src/app/models/note.model';
import { NoteService } from 'src/app/services/note/note.service';
import { saveAs } from 'file-saver';
import { AlertService } from 'src/app/services/alert-service/alert.service';


@Component({
  selector: 'app-attachment-carousel',
  templateUrl: './attachment-carousel.component.html',
  styleUrls: ['./attachment-carousel.component.scss']
})
export class AttachmentCarouselComponent {

  @Input() note!: Note | any;
  @Input() attachments!: any;
  @Input() blobReferences!: any;
  @Input() action!: string;
  @Output() deletedAttachment = new EventEmitter<boolean>();
  @Output() onRemove = new EventEmitter();
  @Output() renamedAttachment = new EventEmitter<any>();
  @Input() showEdit: boolean=true;

  constructor(
    protected noteService: NoteService,
    private alertService: AlertService
  ) {}

  downloadAttachment(attachment: any){
    const aSub: Subscription = this.noteService.downloadNoteAttachmentFromBlob(this.note._id, attachment._id).subscribe( (response:any) => {
      saveAs(response.body, attachment.name);
      aSub.unsubscribe();
      const message: string = "Downloading Attachment"
      this.alertService.success(message)
    })
  }

  removeAttachment(index: number){
    /*if(this.note !== undefined){
      //TODO: Improve indexing in array of blob
      const aSub: Subscription = this.noteService.deleteNoteAttachmentFromBlob(this.note, [this.blobReferences[index-1]]).subscribe( _ => aSub.unsubscribe())
    }*/
    //here is where we call
    this.attachments.splice(index, 1)
    this.deletedAttachment.emit(true);
    this.onRemove.emit();
  }

  renameAttachment(i: number) {
    const fileName = this.attachments[i].name;
    const extension = this.attachments[i].extension;
    this.renamedAttachment.emit({index: i, newName: fileName, extension: extension });
  }


}
