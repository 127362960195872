export class ChatMessage {
    userId: string;
    chat_id: string;
    question: string;
    timestamp: Date;
    response!: string;
    flag!: boolean;
    like!: any;
    message_id!: string;

    constructor(userId: string, chatId: string, question: string) {
        this.userId = userId;
        this.question = question;
        this.chat_id = chatId;
        this.timestamp = new Date();
        this.response = '';
    }
}