import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiService } from '../api/api-service/api.service';
import { PageModel } from '../api/pagination-service/model/page.model';
import { RoleModel } from 'src/app/models/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  readonly modelName = 'role';

  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Get list of roles including its pagination
   * @param params: It will specify query conditions to the general collection.
   * @returns a list of roles with the pagination
   */
  getRolesWithPagination$(params: { [keyof: string]: any } = {}) {
    // TODO: Roles could be extract from constructor name of the model
    return this.apiService.get<PageModel<RoleModel>>(this.modelName, params, "");
  }

  /**
   * Get list of roles including its pagination
   * @param params: It will specify query conditions to the general collection.
   * @returns a list of roles with the pagination
   */
  getRolesWithoutPagination$(params: { [keyof: string]: any } = {}) {
    return this.getRolesWithPagination$(params).pipe(
      map(result => result.data),
    );
  }
}
