import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiService } from '../api/api-service/api.service';
import { PageModel } from '../api/pagination-service/model/page.model';
import { LicenseModel } from 'src/app/models/license.model';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  readonly modelName = 'license';

  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Get list of roles including its pagination
   * @param params: It will specify query conditions to the general collection.
   * @returns a list of roles with the pagination
   */
  getLicensesWithPagination$(params: { [keyof: string]: any } = {}) {
    // TODO: Licenses could be extract from constructor name of the model
    return this.apiService.get<PageModel<LicenseModel>>(this.modelName, params, "");
  }

  /**
   * Get list of roles including its pagination
   * @param params: It will specify query conditions to the general collection.
   * @returns a list of roles with the pagination
   */
  getLicensesWithoutPagination$(params: { [keyof: string]: any } = {}) {
    return this.getLicensesWithPagination$(params).pipe(
      map(result => result.data),
    );
  }
}
