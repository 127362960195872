
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ProviderModel } from 'src/app/models/provider.model';

@Component({
  selector: 'app-files-crud-real-time',
  templateUrl: './files-crud-real-time.component.html',
  styleUrls: ['./files-crud-real-time.component.scss']
})
export class FilesCrudRealTimeComponent {
  @Input() provider!: ProviderModel;
  @Input() files: any[] = [];
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() loading = false;
  @Output() onRemove = new EventEmitter<number>();
  @Output() onFilesDropped$ = new EventEmitter<any>();

  constructor(

  ) { }

  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    this.manageFiles(selectedFiles);
  }

  manageFiles(selectedFiles: any) {
    this.loading = true;

    const filesToEmit = [];
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        this.files.push({ name: file.name, description: '', file });
        filesToEmit.push({ name: file.name, description: '', file });
      }
    }

    this.onFilesDropped$.emit(filesToEmit)

  }

  _onRemove(index: number) {
    this.onRemove.emit(index);
  }

  onChooseFiles(event: MouseEvent) {
    this.fileInput.nativeElement.click();
  }
  onFilesDropped(event: any) {
    const selectedFiles = event.dataTransfer.files;
    event.preventDefault();
    this.manageFiles(selectedFiles);
  }

  onDragOver(event: any) {
    // console.log('onDragOver', event);
    event.preventDefault();
  }


}
