<form [formGroup]="myForm">
    <div class="modal__body">
        <div class="modal__row modal__row--flex">
            <mat-form-field>
                <mat-label>{{
                  'components.modal.provider.status' | translate : 'Status'
                }}</mat-label>
                <mat-select formControlName="status" required >
                  <mat-option
                    *ngFor="let status of statusList"
                    [value]="status"
                  >
                    {{status}}
                  </mat-option>
                </mat-select>
            </mat-form-field>  
        </div>
    </div>
</form>