import { Product } from "./product.model";
import { UserModel } from "./user.model";


/**
 * HistoricProductValidation.
 */
export class HistoricProductValidation {
  static getEmptyHistoricProductValidation(): HistoricProductValidation | undefined {
    const result = new HistoricProductValidation();
    result.lastValidatedBy = UserModel.getEmptyUser();
    return result;
  }
  username?:string=''
  role?:string=''
  lastValidatedBy: UserModel |any ;
  lastValidatedAt: string = HistoricProductValidation.formattedTimestamp(new Date());
  comment: string = '';
  product:Product| any;

  static formattedTimestamp(date: Date){
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2,'0');
    const hours = String(date.getUTCHours()).padStart(2,'0');
    const minutes = String(date.getUTCMinutes()).padStart(2,'0');
    const seconds = String(date.getUTCSeconds()).padStart(2,'0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
