import { Component, Input } from '@angular/core';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';

@Component({
  selector: 'app-total-elements-detail',
  templateUrl: './total-elements-detail.component.html',
  styleUrls: ['./total-elements-detail.component.scss']
})
export class TotalElementsDetailComponent {

  @Input() totalElements!: number;
  @Input() currentListLength!: number;
  @Input() allElements!: number;
  @Input() pageSize!: number;
  @Input() dataType!: string;
  @Input() isEndlessScroll = false;
  public text: string = '';

  constructor(
    public translate: TranslatePipe
  ) {  }

  public getText() {
    const dataTypeText = this.dataType === 'providers' ? 'players' : this.dataType === 'products' ? 'offerings' : this.dataType;
    // if (this.isEndlessScroll) {
    //   return `${this.totalElements} ${dataTypeText}`;
    // }
    const actualElementsCount = 
      this.currentListLength === 0 ? // first question
      this.pageSize > this.totalElements ? this.totalElements : this.pageSize
      : this.currentListLength
    
    const middle = actualElementsCount === 1 ?
      this.translate.transform('responsiveTable.outOfSingular') : this.translate.transform('responsiveTable.outOf', ' out of ');
    return `${this.translate.transform('responsiveTable.showing', 'Showing')} ${actualElementsCount} ${middle} ${this.allElements} ${dataTypeText}`;
  }
}

