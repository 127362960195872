<div id="chart" class="chart">
  <apx-chart
    [series]="series"
    [colors]="colors"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [title]="chartOptions.title"
    [legend]="chartOptions.legend"
    [tooltip]="chartOptions.tooltip"
    [fill]="chartOptions.fill"
    [stroke]="chartOptions.stroke"
    [dataLabels]="chartOptions.dataLabels"
  ></apx-chart>
</div>
