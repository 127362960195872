import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, tap } from 'rxjs';
import { ATTRIBUTE_TEMPLATE_TOOLTIPS } from 'src/app/models/constants.model';
import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';
import { ProviderModel } from 'src/app/models/provider.model';

@Component({
  selector: 'app-provider-comparison-tool',
  templateUrl: './provider-comparison-tool.component.html',
  styleUrls: ['./provider-comparison-tool.component.scss']
})
export class ProviderComparisonToolComponent implements OnInit {
  @ViewChildren('fieldValueBoxRow') fieldValueBoxRow?: QueryList<ElementRef>;
  @ViewChildren('field') field?: QueryList<ElementRef>;
  tooltips= ATTRIBUTE_TEMPLATE_TOOLTIPS;

  // these two are for the boxes with the inputs
  fields: any[] = [];
  providers: any[] = [{}, {}];

  // these two are for the values inside the cells
  fieldValues: any[] = [];
  providerValues: any[] = [{}, {}];

  // variables for mat-autocomplete inputs
  providersData: any[] = [];
  listOfProviderProperties: string[] = [];

  // a copy of the providers to be able to pick the values from there
  wholeProviders: any[] = [];

  providerSelect = new FormControl('');
  fieldSelect = new FormControl('');
  expandedStates: Map<string, boolean> = new Map();
  buttonClickedStates = new Map<number, boolean>();

  loading: boolean = true;

  private provCharSub: Subscription = new Subscription();

  // for the expand arrow in the value boxes
  toggle(fieldIndex: number): void {
    const buttonStatus: boolean =  this.buttonClickedStates.get(fieldIndex) || false;
    this.buttonClickedStates.set(fieldIndex, !buttonStatus);
    this.providers.forEach((_, productIndex: number): void => {
      const key: string = `${productIndex}-${fieldIndex}`;
      const currentState: boolean = this.expandedStates.get(key) || false;
      this.expandedStates.set(key, !currentState);
    });

    this.cdRef.detectChanges();
    this.measureHeights();
  }
  isExpanded(productIndex: number, fieldIndex: number): boolean {
    const key = `${productIndex}-${fieldIndex}`;
    return this.expandedStates.get(key) || false;
  }

  constructor(
    private providerService: ProviderCollectionService,
    private cdRef: ChangeDetectorRef
  ){}

  ngOnInit():void {
    this.getProviders();
  }
  private measureHeights(): void {
    this.fieldValueBoxRow!.forEach((fieldValueBoxRef, index): void => {
      const height = fieldValueBoxRef.nativeElement.offsetHeight;
      const fieldElementRef = this.field?.get(index);
      if (fieldElementRef && fieldElementRef.nativeElement) {
        fieldElementRef.nativeElement.style.height = `${height}px`;
      }
    });
  }
  public isTextOverflow(productIndex: number): boolean {
    const fieldValueBoxRef = this.fieldValueBoxRow?.get(productIndex);
    if (!fieldValueBoxRef) {
      return false;
    }
    const checkOverflow = (element: HTMLElement): boolean => {
      if (element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight) {
        return true;
      }
      for (const child of Array.from(element.children) as HTMLElement[]) {
        if (checkOverflow(child)) {
          return true;
        }
      }
      return false;
    };
    return checkOverflow(fieldValueBoxRef.nativeElement);
  }

  setDefaultFields(): void {
    const defaultFieldNames = ["Player Description", /*"Type Of Organization",*/ "Headquarters Location", "Number Of Clients", "Type Of Player"];
    this.listOfProviderProperties.forEach(item => {
      if (defaultFieldNames.includes(item)) {
        this.fields.push({name: item});
        this.fieldValues.push({name: this.revertToCamelCase(item)});
        this.fieldSelect.setValue(item);
      }
    });
    this.setExpandedStates();
  }

  // field logic related functions
  addField(){
    const field = {};
    this.fields.push(field);
    this.fieldValues.push(field);
  }

  removeField(index: number){
    this.fields.splice(index, 1);
    this.fieldValues.splice(index, 1);
    this.cdRef.detectChanges();
    this.measureHeights();
  }

  // provider logic related functions
  addProvider(){
    const provider = {};
    this.providers.push(provider);
    this.providerValues.push(provider);
    const providerIndex = this.providers.length - 1;
    for (let i = 0; i < this.fields.length; i++) {
      const key = `${providerIndex}-${i}`;
      const k = `${providerIndex-1}-${i}`;
      const currentState = this.expandedStates.get(k);
      this.expandedStates.set(key, currentState!);
    }
    this.cdRef.detectChanges();
    this.measureHeights();
  }

  removeProvider(index: number) {
    this.providers.splice(index, 1);
    this.providerValues.splice(index, 1);
    this.cdRef.detectChanges();
    this.measureHeights();
  }

  getProviders(){
    this.providerService.getProvidersWithoutPagination$({ queryByRole: false, useCustomAggregate: true, aggregateFor: 'comparison' }).pipe(
      tap((data) => data.filter(p => p.status.toLowerCase() !== 'dummy')),
      tap((data) => this.wholeProviders = data),
      tap((data) => this.listOfProviderProperties = this.mapProviderProperties(data)),
      tap((data) => this.providersData = this.mapProviderNames(data)),
      tap(() => {
        this.loading = false;
        this.setDefaultFields();
      })
    ).subscribe();
  }
  private setExpandedStates() {
    for (let i = 0; i < this.fields.length; i++) {
      for (let j = 0; j < this.providers.length; j++) {
        const key = `${j}-${i}`;
        this.expandedStates.set(key, false);
      }
    }
  }

  mapProviderProperties(providers: ProviderModel[]): string[]{
    // properties that may not be interesting
    const excludeProperties: string[] = [
      'provider', 'notes', 'slug', 'contact', 'active',
      'attachments', 'url', 'id', 'lowerCaseDescription',
      'lowerCaseProvider', 'createdDate', 'lastUpdated',
      'lastUpdatedByMetadata', 'lastUpdatedMetadata',
      'lastFlagUpdatedBy', 'attentionRequired'
    ];

    return Object.keys(providers[0])
    .filter(property => !excludeProperties.includes(property))
    .map(property => this.formatPropertyName(property));
  }

  mapProviderNames(providers: ProviderModel[] | any){
    return providers.map((provider: ProviderModel | any) => provider.playerName);
  }

  onAddFieldAutocomplete(itemAdded: any, index: number) {
    const fieldName: string = itemAdded;
    this.fieldValues[index] = { name: this.revertToCamelCase(fieldName) }
    this.cdRef.detectChanges();
    this.measureHeights();
  }

  onAddProviderAutocomplete(itemAdded: any, index: number) {
    let provider = this.wholeProviders.find(prov => prov.playerName === itemAdded);
    const providerName = itemAdded;
    this.providerValues[index] = { name: providerName, isLoading: true }
    this.cdRef.detectChanges();
    this.measureHeights();

    this.provCharSub = this.providerService.getProviderCharacteristicsForComparison$(provider.id).pipe(
      tap(prov => provider = prov),
      tap(_ => {
        this.providerValues[index] = { name: providerName, isLoading: false }
        this.cdRef.detectChanges();
        this.measureHeights();            
      }),
    ).subscribe();
  }

  deleteProvider(index: number): void {
    this.providerValues[index] = {  provider: '' };
    this.cdRef.detectChanges();
    this.measureHeights();
  }


  getProviderFieldValue(providerName: string, fieldName: string){
    const provider = this.wholeProviders.find(provider => provider.playerName === providerName);
    let value: any;

    if(!providerName || providerName === ''){
      value = '';
    } else if(!fieldName || fieldName === ""){
      value = '';
    }else{
      if (provider && provider[fieldName]) {
        if(typeof provider[fieldName] === 'string') {
          value = provider[fieldName];
        }else{
          // for the cases of 'Last Updated By' and 'Ey Responsible'
          value = provider[fieldName]?.username;
        }
      } else {
        value = 'No value available'
      }
    }

    return value;
  }

  //utilities
  formatPropertyName(propertyName: string) {
    return propertyName
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        .replace(/^./, str => str.toUpperCase());
  }

  revertToCamelCase(formattedName: string) {
    return formattedName
        .replace(/\s(.)/g, (_, match) => match.toUpperCase())
        .replace(/^\w/, str => str.toLowerCase());
  }

  ngOnDestroy(): void {
    if(this.provCharSub){
      this.provCharSub.unsubscribe()
    }
  }
}
