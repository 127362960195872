<div class="modal__header">
  <h3 class="modal__title">
    {{ title }}
  </h3>
  <button
    type="button"
    class="modal__close"
    aria-label="Close"
    (click)="onClickButton(reason.closeBtn)"
  ></button>
</div>

<div class="modal__body">
  <div class="modal__row">
    <p>{{ message }}</p>
  </div>
</div>

<div class="modal__footer">
  <button
    *ngIf="hasNoButton"
    mat-stroked-button
    type="button"
    (click)="onClickButton(reason.closeBtn)"
  >
    {{ noLabel }}
  </button>
  <button
    mat-flat-button
    color="primary"
    type="button"
    class="btn-submit" 
    (click)="onClickButton(reason.submitBtn)"
  >
    {{ yesLabel }}
  </button>
</div>
