import { PermissionModel } from "./permission.model";

/**
 * User role.
 */
export class RoleModel {
  constructor(
    public id: string,
    public name: string = "",
    public permissions: PermissionModel[] = [],
  ) {

  }

  static getEmptyRoleModel(): RoleModel {
    return new RoleModel("", "", []);
  }

}
