<div class="filter-container">
    <div class="filter-heading-line">
        <div class="left">
            <h5>{{filter?.name}}</h5>
            <mat-icon class="refresh-icon" (click)="clear()">refresh</mat-icon>
            <span class="clear-text" (click)="clear()">Clear</span>
        </div>
        <div class="right">
            <mat-icon class="remove-icon" (click)="remove()">clear</mat-icon>
        </div>
    </div>
    
    <div class="options-list">
        <span 
            class="chip" 
            [ngClass]="{ 'active': option.selected, 'disabled': option.disabled }" 
            *ngFor="let option of filter?.options" 
            (click)="selectOption(option)"
        >
            {{option.name}}
        </span>
    </div>
</div>