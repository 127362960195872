import { UntypedFormGroup, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { AlertService } from 'src/app/services/alert-service/alert.service';

type ResourceBasicOperation = 'create' | 'retrieve' | 'update' | 'delete';

export interface IModalFormData<T> {
  type: 'new' | 'edit', // Dunno why is that TODO: search explanation
  action: ResourceBasicOperation, // Asuume that is crud, default is 'create'
  data: T
}

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralModalComponent implements OnInit {

  @Input() classType!: any;
  @Input() options!: IModalFormData<typeof this.classType>; // Options receive for use, we could just add more @inputs
  @Input() title = '';
  @Input() excludeFields: string[] = []
  myForm!: FormGroup<typeof this.classType>;

  // So we can custom validations from outside
  @Output() onFormInitialized = new EventEmitter<FormGroup>();
  protected _formControls!: { name: string; label: string; type: string; }[];

  get instanceOfGenericClass() {
    const typeOfTheClass = this.classType;
    return new typeOfTheClass();
  }
  // get fields() { return Object.getOwnPropertyNames(this.instanceOfGenericClass); }
  // get fieldsOf() { return Object.keys(this.data) as Array<keyof T>; }

  constructor(
    public activeModal: NgbActiveModal, //TODO: Do a class service that map activeModal so is not dependent of Bootstrap
    public alertService: AlertService,
    public translate: TranslatePipe,
    public formBuilder: FormBuilder,
  ) {

  }

  // Turn data into a form config
  // TODO: Move to utility functions
  toFormConfig(data: any): { [key: string]: any } {
    let formConfig: { [key: string]: any } = {};
    Object.keys(data).forEach(key => {
      formConfig[key] = [data[key]]; // Wrap in an array to conform to form group expectations
    });
    return formConfig;
  }

  ngOnInit(): void {
    if (!this.options) {
      this.alertService.error(this.translate.transform('options.@input.must.be.filled.on.user.modal'), false, 'options.@input.must.be.filled.on.modal');
      this.activeModal.close();
      return;
    }
    if (this.options.type === 'edit') {
      this.myForm = this.formBuilder.group(this.toFormConfig(this.options.data));
    } else {
      this.myForm = this.formBuilder.group(this.toFormConfig(this.instanceOfGenericClass));
    }
    this.onFormInitialized.emit(this.myForm);
    this.calculateFormControls();
    // this.ClassForm = new UntypedFormGroup()
  }

  calculateFormControls() {
    const allFieldsName = Object.keys(this.myForm.controls)
    const allControls = allFieldsName.map(key => this.getControlFromKey(key, this.myForm));

    const lowerExcludeFields = this.excludeFields.map(f => f.toLowerCase())
    const excludeUnwantedFields = allControls.filter(c => !lowerExcludeFields.includes(c.name.toLowerCase()))
    this._formControls = excludeUnwantedFields;
  }
  get formControls() {
    return this._formControls;
  }

  getControlFromKey(key: string, myForm: FormGroup) {
    const control = myForm.controls[key];
    let type;

    if (control.value instanceof Array) {
      type = 'select';
    } else if (typeof control.value === 'number') {
      type = 'number';
    } else if (typeof control.value === 'boolean') {
      type = 'checkbox';
    } else {
      type = 'text';
    }

    return {
      name: key,
      label: key.charAt(0).toUpperCase() + key.slice(1),
      type: type
    };
  }
  onSubmit() {
    console.log(this.myForm);
    this.activeModal.close({
      data: this.myForm.getRawValue(),
      action: this.options.action,
    })
  }

  closeModal($event: MouseEvent) {
    this.activeModal.close({ action: 'close' })
    // activeModal.close({ user: userForm.value, action: 'close' })
  }

}
