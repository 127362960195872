import { Injectable } from '@angular/core';
import { FlattenedTreeItemOption, TreeGroup } from 'src/app/components/autocomplete/auto-complete-tree-source/tree-group.model';
import { COUNTRY_NAMES } from 'src/app/models/constants.model';
import { UserModel } from 'src/app/models/user.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { Product } from 'src/app/models/product.model';

type DataType4 = {
  name:string;
  values:string;
}
@Injectable({
  providedIn: 'root'
})
export class AutocompleteUtilsService {
  transformDataType4ToTreeGroup(data: DataType4[]): TreeGroup<any>[] {
   return data.map(item => ({
                letter:item.name,
                object:item,
                subgroups: item.values.split(",").map(lv2 => { 
                  return {letter:lv2, object:lv2, subgroups:[]};
                })
            }));
  }

  constructor() { }

  convertListOfStringsToTreeGroup(text: string[]) {
    return text.map(c => { return { letter: c, subgroups: [], object: c }; })
  }

  convertListOfStringsToFlattenedTreeItemOption(text: string[]){
    return text.map( c => { return { displayValue: c, selectedValue: c, level: 1, isDisabled: false }; })
  }

  convertCountriesToTreeGroup(countries: { name: string }[] = COUNTRY_NAMES) {
    return countries.map(c => { return { letter: c.name, subgroups: [], object: c.name }; })
  }

  convertEYResponsiblesToTreeGroup(responsibles: UserModel[]) {
    return responsibles.map(user => { return { letter: user.username, subgroups: [], object: user.id } })
  }

  convertUsersToTreeGroup(users: UserModel[]) {
    return users.map(user => { return { letter: user.username, subgroups: [], object: user.username } })
  }

  convertProvidersToTreeGroup(providers: ProviderModel[]) { 
    return providers.map(provider => { return { letter: provider.playerName, subgroups: [], object: provider.playerName} })
  }

  convertProductsToTreeGroup(products: Product[]) {
    return products.map(product => { return { letter: product.name, subgroups: [], object: product.name} })
  }

  fromFlattenedTreeItemOptionToInitialObject<T>(item: FlattenedTreeItemOption<TreeGroup<T>>) {
    return item.selectedValue.object;
  }

  convertCategoriesToTreeGroupOneNestedLevel(categories: { names: string, fields: { name: string }[] }[]) {
    let result = [];
    for (const itemCategory of categories) {
      let subgroups: TreeGroup<any>[] = [];
      if (itemCategory.fields?.length > 0) {
        subgroups = itemCategory.fields.map(f => { return { letter: f.name, subgroups: [] as any, object: f } })
      }

      result.push({
        letter: itemCategory.names,
        subgroups,
        object: itemCategory,
      })
    }

    return result;
  }

  convertNestedAttributeValuesToTreeGroup(data: InputItem[]): TreeGroup<any>[] {
    const splitValuesResult = this.splitValues(data);
    return this.transformToTreeGroup(splitValuesResult);
  }


  splitValues(data: InputItem[]): TransformedItem[] {
    return data.map(item => {
      const transformedItem: TransformedItem = {
        name: item.name,
        values: item.value ? item.value.split(',').map(v => ({ name: v, values: [] })) : []
      };
      if (item.values && item.values.length > 0) {
        transformedItem.values.push(...this.splitValues(item.values));
      }
      return transformedItem;
    });
  }


  transformToTreeGroup(data: TransformedItem[]): TreeGroup<any>[] {
    return data.map(item => ({
      letter: item.name,
      object: item.values.map(v => v.name),
      subgroups: this.transformToTreeGroup(item.values)
    }));
  }
}


type InputItem = {
  name: string;
  value?: string;
  values?: InputItem[];
};


type TransformedItem = {
  name: string;
  values: TransformedItem[];
};
