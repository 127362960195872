import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication, RedirectRequest, SilentRequest } from '@azure/msal-browser';

import { environment } from 'src/environments/environment';

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function msalInstanceConfig(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: 'https://login.microsoftonline.com/'+ environment.tenantId,
      redirectUri: window.location.origin + '/',
      postLogoutRedirectUri: window.location.origin + '/'
    },
    cache: {
      // cacheLocation: BrowserCacheLocation.LocalStorage, //sandbox
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function msalInterceptorConfig(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, [environment.msalApiScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function msalGuardConfig(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', environment.msalApiScope]
    },
    loginFailedRoute: '/unauthorized'
  };
}

export function msalDefaultSilentRequest(forceRefresh?: boolean): SilentRequest {
  const request: SilentRequest = {
    scopes: [environment.msalApiScope],
    forceRefresh
  }
  return request;
}

export function msalDefaultRedirectRequest(): RedirectRequest {
  const request: RedirectRequest = {
    scopes: [environment.msalApiScope],
  }
  return request;
}



