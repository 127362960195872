import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CloudData, ZoomOnHoverOptions } from 'angular-tag-cloud-module';

import { ChartsService } from 'src/app/services/charts-service/charts.service';

class ChartOptions {
  height: number = 0;
  width: number = 0;
  overflow: boolean = false;
  font: string = '';
  zoomOnHover: ZoomOnHoverOptions = { scale: 1 };
}

@Component({
  selector: 'app-word-cloud-chart',
  templateUrl: './word-cloud-chart.component.html',
  styleUrls: ['./word-cloud-chart.component.scss'],
})
export class WordCloudChartComponent implements OnInit {
  @Output() mounted = new EventEmitter<void>();

  @Input() series: CloudData[] = [];
  @Input() height?: number;

  public chartOptions?: ChartOptions;

  private readonly fontFamily: string;
  private readonly minWidth = 250;
  private readonly maxWidth = 600;
  private readonly defaultHeight: number;

  constructor(private chartsService: ChartsService) {
    this.fontFamily = this.chartsService.getFontFamily();
    this.defaultHeight = 300;
  }

  ngOnInit(): void {
    this.setChartOptions();
    this.mounted?.emit();
  }

  protected setChartOptions() {
    this.chartOptions = {
      height: this.height ?? this.defaultHeight,
      width: this.getWidth(),
      overflow: false,
      font: this.fontFamily,
      zoomOnHover: {
        scale: 1.2,
        transitionTime: 0.5,
        delay: 0.1,
      },
    };
  }

  private getWidth(): number {
    let result = (this.maxWidth / 70) * this.series.length;
    if (result < this.minWidth) {
      return this.minWidth;
    }
    if (result > this.maxWidth) {
      return this.maxWidth;
    }
    return result;
  }
}
