<div class="filter-content">
  <div class="spinner-container">
    <app-table-spinner-ball
      *ngIf="loading"
      [spinnerStyles]="'la-ball-circus'"
    ></app-table-spinner-ball>
  </div>
  <div class="right">
    <button
      *ngIf="!loading"
      id="create-user"
      class="pagehead__button pagehead__button--filter"
      (click)="toggleShowFilters($event)"
    >
      {{ 'page.admin.button.filter.user' | translate : 'Filter' }}
    </button>
  </div>
  <div [hidden]="!showFilters" class="reviewers__filters">
    <app-validations-filter (onSearch)="onSearch($event)" [rolesList]="rolesList" [paginationType]="paginationType"
      [fields]="fields" [key]="'validations.'" #validationsFilter>
    </app-validations-filter>
  </div>
  <app-responsive-table
    *ngIf="!loading"
    [loading]="loading"
    [backgroundLoading]="backgroundLoading"
    [settings]="settingTable"
    [columns]="productTableColumnConfiguration"
    [dataType]="'validations'"
    [data]="productTableRows"
    [isEndlessScroll]="true"
    (loadingChange)="onLoadingChange($event)"
    (selected)="onSelected($event)"
    (onSelectedItems)="onSelectedItems($event)"
    (onSortEvent)="onTableSort($event)"
    (onPageChangeEvent)="onTablePageChange($event)">
  </app-responsive-table>
</div>