export const TYPE_OF_ORGANIZATIONS = ['Private enterprise', 'Public company', 'Non-profit organization (NGO)', 'Joint venture', 'Other']


export const COUNTRY_NAMES = [
    {name: "Afghanistan"},
    {name: "Albania"},
    {name: "Algeria"},
    {name: "Andorra"},
    {name: "Angola"},
    {name: "Anguilla"},
    {name: "Antigua and Barbuda"},
    {name: "Argentina"},
    {name: "Armenia"},
    {name: "Aruba"},
    {name: "Australia"},
    {name: "Austria"},
    {name: "Azerbaijan"},
    {name: "Bahamas"},
    {name: "Bahrain"},
    {name: "Bangladesh"},
    {name: "Barbados"},
    {name: "Belarus"},
    {name: "Belgium"},
    {name: "Belize"},
    {name: "Benin"},
    {name: "Bermuda"},
    {name: "Bhutan"},
    {name: "Bolivia"},
    {name: "Bosnia and Herzegovina"},
    {name: "Botswana"},
    {name: "Brazil"},
    {name: "British Indian Ocean Territory"},
    {name: "Brunei"},
    {name: "Bulgaria"},
    {name: "Burkina Faso"},
    {name: "Myanmar"},
    {name: "Burundi"},
    {name: "Cambodia"},
    {name: "Cameroon"},
    {name: "Canada"},
    {name: "Cape Verde"},
    {name: "Cayman Islands"},
    {name: "Central African Republic"},
    {name: "Chad"},
    {name: "Christmas Island"},
    {name: "Cocos (Keeling) Islands"},
    {name: "Cook Islands"},
    {name: "Chile"},
    {name: "China"},
    {name: "Colombia"},
    {name: "Comoros"},
    {name: "Congo"},
    {name: "Costa Rica"},
    {name: "Croatia"},
    {name: "Cuba"},
    {name: "Cyprus"},
    {name: "Czech Republic"},
    {name: "Denmark"},
    {name: "Djibouti"},
    {name: "Dominica"},
    {name: "Dominican Republic"},
    {name: "Timor-Leste"},
    {name: "Tokelau"},
    {name: "Turks and Caicos Islands"},
    {name: "Ecuador"},
    {name: "Egypt"},
    {name: "El Salvador"},
    {name: "Equatorial Guinea"},
    {name: "Eritrea"},
    {name: "Estonia"},
    {name: "Ethiopia"},
    {name: "Faroe Islands"},
    {name: "Falkland Islands"},
    {name: "Fiji"},
    {name: "Finland"},
    {name: "France"},
    {name: "French Polynesia"},
    {name: "Gabon"},
    {name: "Gambia"},
    {name: "Georgia"},
    {name: "Gibraltar"},
    {name: "Germany"},
    {name: "Ghana"},
    {name: "Greece"},
    {name: "Greenland"},
    {name: "Guadeloupe"},
    {name: "Grenada"},
    {name: "Guernsey"},
    {name: "Guatemala"},
    {name: "Guam"},
    {name: "Guinea"},
    {name: "Guinea-Bissau"},
    {name: "Guyana"},
    {name: "Haiti"},
    {name: "Heard Island and McDonald Islands"},
    {name: "Jordan"},
    {name: "Honduras"},
    {name: "Hong Kong"},
    {name: "Hungary"},
    {name: "Iceland"},
    {name: "India"},
    {name: "Indonesia"},
    {name: "Iran"},
    {name: "Iraq"},
    {name: "Ireland"},
    {name: "Isle of Man"},
    {name: "Israel"},
    {name: "Italy"},
    {name: "Ivory Coast"},
    {name: "Jamaica"},
    {name: "Jersey"},
    {name: "Japan"},
    {name: "Jordan"},
    {name: "Kazakhstan"},
    {name: "Kenya"},
    {name: "Kiribati"},
    {name: "North Korea"},
    {name: "Kosovo"},
    {name: "Kuwait"},
    {name: "Kyrgyzstan"},
    {name: "Laos"},
    {name: "Latvia"},
    {name: "Lebanon"},
    {name: "Lesotho"},
    {name: "Liberia"},
    {name: "Libya"},
    {name: "Liechtenstein"},
    {name: "Luxembourg"},
    {name: "Macedonia"},
    {name: "Madagascar"},
    {name: "Marshall Islands"},
    {name: "Macau"},
    {name: "Malawi"},
    {name: "Malaysia"},
    {name: "Maldives"},
    {name: "Mali"},
    {name: "Malta"},
    {name: "Martinique"},
    {name: "Mauritania"},
    {name: "Mauritius"},
    {name: "Mayotte"},
    {name: "Mexico"},
    {name: "Moldova"},
    {name: "Mongolia"},
    {name: "Montenegro"},
    {name: "Monaco"},
    {name: "Montserrat"},
    {name: "Morocco"},
    {name: "Mozambique"},
    {name: "Myanmar"},
    {name: "Namibia"},
    {name: "Nauru"},
    {name: "Nepal"},
    {name: "Netherlands"},
    {name: "New Caledonia"},
    {name: "New Zealand"},
    {name: "Nicaragua"},
    {name: "Niger"},
    {name: "Nigeria"},
    {name: "Norway"},
    {name: "Niue"},
    {name: "Norfolk Island"},
    {name: "Northern Mariana Islands"},
    {name: "Oman"},
    {name: "Pakistan"},
    {name: "Pitcairn"},
    {name: "Palau"},
    {name: "Panama"},
    {name: "Papua New Guinea"},
    {name: "Paraguay"},
    {name: "Peru"},
    {name: "Philippines"},
    {name: "Poland"},
    {name: "Portugal"},
    {name: "Puerto Rico"},
    {name: "South Korea"},
    {name: "Lithuania"},
    {name: "Qatar"},
    {name: "Romania"},
    {name: "Russia"},
    {name: "Rwanda"},
    {name: "Réunion"},
    {name: "Samoa"},
    {name: "Saint Lucia"},
    {name: "San Marino"},
    {name: "Saint Kitts and Nevis"},
    {name: "Saint Pierre and Miquelon"},
    {name: "Saint Vincent and the Grenadines"},
    {name: "Saudi Arabia"},
    {name: "Senegal"},
    {name: "South Georgia and the South Sandwich Islands"},
    {name: "Sao Tome and Principe"},
    {name: "Sierra Leone"},
    {name: "Serbia"},
    {name: "Seychelles"},
    {name: "Singapore"},
    {name: "Slovakia"},
    {name: "Slovenia"},
    {name: "Solomon Islands"},
    {name: "Somalia"},
    {name: "South Africa"},
    {name: "Spain"},
    {name: "Sri Lanka"},
    {name: "Sudan"},
    {name: "Suriname"},
    {name: "Swaziland"},
    {name: "Sweden"},
    {name: "Switzerland"},
    {name: "Syria"},
    {name: "Taiwan"},
    {name: "Tanzania"},
    {name: "Thailand"},
    {name: "Togo"},
    {name: "Tonga"},
    {name: "Trinidad and Tobago"},
    {name: "Tunisia"},
    {name: "Turkey"},
    {name: "Turkmenistan"},
    {name: "Uganda"},
    {name: "Ukraine"},
    {name: "United Arab Emirates"},
    {name: "United Kingdom"},
    {name: "United States"},
    {name: "Uruguay"},
    {name: "Uzbekistan"},
    {name: "Vanuatu"},
    {name: "Vatican City State (Holy See)"},
    {name: "Wallis and Futuna"},
    {name: "Venezuela"},
    {name: "Vietnam"},
    {name: "Yemen"},
    {name: "Zambia"},
    {name: "Zimbabwe"}
]

export const REGION_NAMES = [
    {name: 'Americas-Canada'},
    {name: 'Americas-FSO'},
    {name: 'Americas-Israel'},
    {name: 'Americas-Latam North'},
    {name: 'Americas-Latam'},
    {name: 'Americas-South'},
    {name: 'Americas-US-Central'},
    {name: 'Americas-US-East'},
    {name: 'Americas-US-West'},
    {name: 'Asia-Pacific-Asean'},
    {name: 'Asia-Pacific-FSO'},
    {name: 'Asia-Pacific-Greater China'},
    {name: 'Asia-Pacific-Japan'},
    {name: 'Asia-Pacific-Korea'},
    {name: 'Asia-Pacific-Oceania'},
    {name: 'EMEIA-Africa'},
    {name: 'EMEIA-CESA'},
    {name: 'EMEIA-Europe West'},
    {name: 'EMEIA-FSO'},
    {name: 'EMEIA-India'},
    {name: 'EMEIA-MENA'},
    {name: 'EMEIA-Nordics'},
]

export const SECTOR_FOCUS = [
    {name: 'Agriculture'},
    {name: 'Automotive'},
    {name: 'Banking and Finance'},
    {name: 'Construction'},
    {name: 'Education'},
    {name: 'Energy'},
    {name: 'Healthcare'},
    {name: 'Hospitality and Tourism'},
    {name: 'Information Technology (IT)'},
    {name: 'Manufacturing'},
    {name: 'Retail'},
    {name: 'Telecommunications'},
    {name: 'Transportation and Logistics'},
    {name: 'Real Estate'},
    {name: 'Media and Entertainment'},
    {name: 'Environmental and Sustainability'}
]

export const SERVICE_LINES = [
    {name: 'Tax'},
    {name: 'Consulting'},
    {name: 'Assurance'},
    {name: 'Strategy and Transaction'},
    {name: 'CBS'},
]

export const SUB_SERVICE_LINES = [
    {name: 'Business Tax Services', group: 'tax', groupId: 1},
    {name: 'Global Compliance and Reporting', group: 'tax', groupId: 1},
    {name: 'Global Law', group: 'tax', groupId: 1},
    {name: 'Indirect Tax', group: 'tax', groupId: 1},
    {name: 'International Tax and Transaction Services', group: 'tax', groupId: 1},
    {name: 'People Advisory Services', group: 'tax', groupId: 1},
    {name: 'Tax Technology and Transformation', group: 'tax', groupId: 1},
    {name: 'Business Consulting', group: 'consulting', groupId: 2},
    {name: 'Technology Consulting', group: 'consulting', groupId: 2},
    {name: 'People Advisory Services (PAS)', group: 'consulting', groupId: 2},
    {name: 'Audit', group: 'assurance', groupId: 3},
    {name: 'Climate Change and Sustainability Services (CCaSS)', group: 'assurance', groupId: 3},
    {name: 'Financial Accounting Advisory Services', group: 'assurance', groupId: 3},
    {name: 'Forensic and Integrity Services', group: 'assurance', groupId: 3},
    {name: 'Transactions and Corporate Finance', group: 'strategy and transaction', groupId: 4},
    {name: 'EY-Parthenon', group: 'strategy and transaction', groupId: 4},
    {name: 'International Tax and Transaction Services', group: 'strategy and transaction', groupId: 4},
    {name: 'Human Resources', group: 'cbs', groupId: 5},
    {name: 'EY Technology', group: 'cbs', groupId: 5},
    {name: 'Other CBS support services', group: 'cbs', groupId: 5},
]

export const STATUS = [
    {name: 'Live'},
    {name: 'Empty Draft'},
    {name: 'Dummy'},
    {name: 'Archived'}
]

export const FLAG = [
    {name: 'Needs Revision'},
    {name: 'In progress'},
    {name: 'Pending information'},
    {name: 'On hold'}
]

export const ROLE_TOOLTIPS_TEXT: { [key: string]: string } = {
  'External Player': 'Can only see and edit their own player and offering profiles (incl. documents and data set attributes). Cannot see or add notes, interact with genAI, or see EY license information. Click to see in detail.',
  'EY Consultant': 'Can see all profiles, but cannot edit them. Can also see visual market insights, tools, EY license info, and all profile notes. Click to see in detail.',
  'EY Contributor': 'Can see all profiles, but can only edit those that are assigned to them (player and its associated offerings). This includes the ability to edit notes for the profiles they are assigned. Can also see visual market insights, tools,, EY license info, and all profile notes. Click to see in detail.',
  'EY Expert': 'Can see and edit all profiles (incl. adding notes, etc.) without restrictions. Can also see visual market insights, tools, EY license info, and all profile notes. Click to see in detail.',
  'EY Admin': 'Can see and edit all areas of the portal (incl. updating EY license info). Can create and modify users and their permissions. Can add and delete players or offerings on the portal. Click to see in detail.',
};

export const ATTRIBUTE_TEMPLATE_TOOLTIPS = {
    DOWNLOAD_TEMPLATE:
      "Download the template file to fill in your dataset's dictionary. Add attribute names, their descriptions, and other optional information.",
    DOWNLOAD_EXISTING:
      "Download the existing file to modify or add to the dictionary.",
    UPLOAD_DATA_SET:
      "Once you've locally filled the template file with your data, click here to upload/overwrite it.",
    REMOVE_DATA_SET:
      "Once you've uploaded the template file with your data, click here to remove it.",
    PRODUCT_COMPARISON_TOOL:
      "To compare characteristics of more than 5 offerings, please go to the Export Tool and download a CSV file with the desired offerings and characteristics",
    PROVIDER_COMPARISON_TOOL:
      "To compare characteristics of more than 5 players, please go to the Export Tool and download a CSV file with the desired players and characteristics",
    FRAMEWORK_COVERAGE_PERCENTAGE:
      "This represents the coverage of data for the selected frameworks per player. For example, 50% (3/6) means that the respective player has data offerings covering 3 out of the 6 currently selected frameworks",
  };

export const DEFAULT_MAX_LENGTH: number = 400;