import { Component, OnInit } from '@angular/core';

import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

@Component({
  selector: 'app-apex-donut-chart',
  templateUrl: './apex-donut-chart.component.html',
  styleUrls: ['./apex-donut-chart.component.scss'],
})
export class ApexDonutChartComponent extends ApexChartBase implements OnInit {
  constructor(chartsService: ChartsService, utilsService: UtilsService) {
    super(chartsService, utilsService);
    this.defaultWidth = 300;
    this.defaultWidth = 150;
  }

  protected setChartOptions(): void {
    this.chartOptions = {
      chart: {
        type: 'donut',
        fontFamily: this.fontFamily,
      },
      plotOptions: {
        pie: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
        },
      },
      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 50,
        offsetY: 10,
        labels: {
          colors: this.fontFamily,
          useSeriesColors: false,
        },
        formatter: (seriesName: string, opts: any) => {
          return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex];
        },
        itemMargin: {
          horizontal: 3,
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],

      stroke: {
        width: 2,
        colors: [this.chartsService.getBackgroundColor()],
      },

      dataLabels: {
        style: {
          fontFamily: this.fontFamily,
          fontWeight: 400,
        },
        dropShadow: {
          enabled: false,
        },
      },
    };
  }
}
