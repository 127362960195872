<form [formGroup]="myForm">
    <div class="modal__body">
        <div class="modal__row modal__row--flex">
            <mat-form-field>
                <mat-label>{{
                  'components.modal.provider.flag' | translate : 'Flag'
                }}</mat-label>
                <mat-select formControlName="flag" required>
                  <mat-option value="" disabled>-- Select --</mat-option>
                  <mat-option
                    *ngFor="let flag of flagsList"
                    [value]="flag"
                  >
                    {{flag}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div class="modal__row modal__row--flex">
            <mat-form-field class="mat-form-textarea">
              <mat-label>{{ 'components.modal.provider.flagDescription' | translate : 'Flag Description' }}</mat-label>
              <textarea
                  matInput
                  formControlName="flagDescription"
                  id="flagDescription"
                  name="flagDescription"
                  >
              </textarea>
            </mat-form-field>
      </div>
    </div>
</form>
