import { Component, Input, OnInit } from '@angular/core';

import { Toggleable } from 'src/app/base-classes/toggleable';

@Component({
  selector: 'app-toggle-collapse',
  templateUrl: './toggle-collapse.component.html',
  styleUrls: ['./toggle-collapse.component.scss'],
})
export class ToggleCollapseComponent implements OnInit {
  @Input() isCollapsed: Toggleable = new Toggleable(false);

  constructor() {}

  ngOnInit() {}
}
