import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserModel } from 'src/app/models/user.model';
import { AlertService } from '../alert-service/alert.service';
import { ApiService } from '../api/api-service/api.service';
import { PageModel } from '../api/pagination-service/model/page.model';
import { UtilsService } from '../utils-service/utils.service';

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private utils: UtilsService,
    private alertService: AlertService
  ) { }

  /**
   *
   * @returns a list of all the users on the database
   */
  getUsers(): Observable<PageModel<UserModel>> {
    return this.apiService.get('user', '', '');
  }

  getUserData(email: string): Observable<UserModel> {
    return this.apiService.get(`user/email/`, { email }, '');
    // return this.apiService.get(`user/email/${email}`, {email}, '');
  }

  /**
   *
   * @param id of the user we want to patch
   * @param user data, the new data we want the user to have
   * @param message to display via the alertService
   * @param keepAlertOnNavigate flag to close or to mantain the message on navigation
   * @returns an observable containing the backend response (data of the modified user or an error)
   */
  patchUser(
    id: string,
    user: UserModel,
    message: string,
    keepAlertOnNavigate: boolean = false
  ): Observable<Object> {
    const patchUrl = this.utils.getApiUrl(`user/${id}`);
    return this.httpClient.patch<Object>(patchUrl, user).pipe(
      map((response: Object) => {
        if (message) {
          this.alertService.success(message, keepAlertOnNavigate);
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // const errorMessage = 'user.updateUserError';
        const errorMessage = error.error.message;
        this.utils.checkIfLogged(error);
        return this.utils.handleError(error, errorMessage);
      })
    );
  }

  getUsersWithPagination$(params: { [keyof: string]: any } = {}) {
    // TODO: Products could be extract from constructor name of the model
    return this.apiService.get<PageModel<UserModel>>('user', params, "");
  }


  /**
   *
   * @param user data to be created
   * @param message message to display via the alertService once a response comes from the back
   * @param keepAlertOnNavigate flag to keep alert on navigation
   * @returns an observable with the back response (the user created or an error)
   */
  postUser(
    user: UserModel,
    message: string,
    keepAlertOnNavigate: boolean = false
  ): Observable<Object> {
    const postUrl = this.utils.getApiUrl('user');
    return this.httpClient.post(postUrl, user).pipe(
      map((response: Object) => {
        if (message) {
          this, this.alertService.success(message, keepAlertOnNavigate);
        }
        return response;
      }),
      catchError(error => {
        // console.log(error)
        // const errorMessage = 'user.createUserError';
        const errorMessage = error.error.message;
        this.utils.checkIfLogged(error);
        return this.utils.handleError(error, errorMessage);
      })
    );
  }

  /**
   *
   * @param users list of user ids to delete
   * @param message to pass the alert service and display when response comes back from the back
   * @param keepAlertOnNavigate to keep or close the alert message on navigation
   * @returns an observable containing the back response (database delete success or error)
   */
  deleteMany(
    users: { ids: string[] },
    message: string,
    keepAlertOnNavigate: boolean = false
  ): Observable<Object> {
    const deleteUrl = this.utils.getApiUrl('user/delete');
    return this.httpClient.post(deleteUrl, users).pipe(
      map((response: Object) => {
        if (message) {
          this.alertService.success(message, keepAlertOnNavigate);
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = 'user.deleteUsersError';
        this.utils.checkIfLogged(error);
        return this.utils.handleError(error, errorMessage);
      })
    );
  }
}
