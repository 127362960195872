<div class="container" *ngIf="!loading">
  <!-- DRAG AND DROP AREA, TODO: MOVE IT TO A COMPONENT -->
  <div class="file-attachment">
    <input #fileInput type="file" multiple (change)="onFileSelected($event)" />
    <div
      class="dropzone"
      (drop)="onFilesDropped($event)"
      (dragover)="onDragOver($event)"
      (click)="onChooseFiles($event)"
    >
      <p>Drag and drop files here, or click to select files</p>
    </div>
  </div>
  <!-- END OF DRAG AND DROP AREA, MOVE IT TO A COMPONENT -->
  <app-file-management-grid 
  [provider]="provider"
  [files]="files" 
  (onRemove)="_onRemove($event)">
  </app-file-management-grid>
</div>
<app-table-spinner-ball
  *ngIf="loading"
  [spinnerStyles]="'la-ball-circus'"
></app-table-spinner-ball>
