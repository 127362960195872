<div *ngIf="series">
    <apx-chart
      [series]="series"
      [colors]="colors"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [tooltip]="chartOptions.tooltip"
      [grid]="chartOptions.grid"
      [fill]="chartOptions.fill"
      [stroke]="chartOptions.stroke"
    ></apx-chart>
  </div>
