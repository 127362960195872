<div class="modal__header">
  <h3 class="modal__title">
    {{ 'components.modal.refreshModal.title' | translate }}
  </h3>
  <button
    type="button"
    class="modal__close"
    aria-label="Close"
    (click)="activeModal.close(0)"
  ></button>
</div>

<div class="modal__body">
  <div class="modal__row">
    <p>{{ (isAboutToExpire
      ? 'components.modal.refreshModal.aboutToExpire'
      : 'components.modal.refreshModal.refreshSession') | translate }}</p>
  </div>
</div>

<div class="modal__footer">

  <button
    class="pagehead__secondary-button"
  type="button"
  (click)="activeModal.close(0)"
  >
    {{ 'components.modal.common.close' | translate }}
  </button>

  <button
    class="pagehead__primary-button"
  type="button"
  (click)="onConfirm(1)"
  >
    {{ 'components.modal.refreshModal.confirm' | translate }}
  </button>
</div>
