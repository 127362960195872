export const speakerColorsHex: string[] = [
  '#98c1d9',
  '#e56b6f',
  '#9d4edd',
  '#FF4136',
  '#FF6D00',
  '#2DB757',
  '#27AAAA',
  '#188CE5',
  '#3D108A',
  '#750E5C',
];

export const speakerColorsByNameGenesys: any = {
  ivr: speakerColorsHex[0],
  agent: speakerColorsHex[1],
  customer: speakerColorsHex[2],
};

export const sentimentColorsHex: string[] = ['#57E188', '#4EBEEB', '#F95D54'];

export const alternativeColors: string[] = [
  '#5D78FF',
  '#FFB720',
  '#40C4FF',
  '#2CD9C5',
  '#FC3995',
];

export const languageColorsHex: string[] = [
  '#003f5c',
  '#ffa600',
  '#d45087',
  '#2f4b7c',
  '#a05195',
  '#f95d6a',
  '#ff7c43',
  '#665191',
];

export const timeColorsHex: string[] = [
  '#01b8aa',
  '#8ad4eb',
  '#fd625e',
  '#f2c80f',
  '#5f6b6d',
  '#28383c',
];

export const fontColor: string = '#F6F6FA';
export const axisColor: string = '#4f4f56';
export const lightBackgroundColor: string = '#3B3B44';
export const backgroundColor: string = '#2E2E38';
export const confidenceColor: string = '#57E188';
export const highlightColor: string = '#FFE600';
export const fontFamily: string =
  '"Notosans-Regular", Helvetica, Arial, sans-serif';
