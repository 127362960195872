import { Injectable } from '@angular/core';
import { AppliedFilterInfo } from 'src/app/components/provider-product-filter/advance-filters.interfaces';
import { DataValidationService } from '../data-validation/data-validation.service';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { ProviderCollectionService } from '../providers/provider-collection/provider-collection.service';

//TODO: Export to a file
interface DataValidations {
  id: string;
  description: string;
  fields?: any[];
  characteristics?: any[];
}

@Injectable({
  providedIn: 'root'
})
export class MarketDiscoveryService {

  products: Product[] | any[] = [];
  serviceProductsBackup: Product[] | any[] = [];
  providers: ProviderModel[] | any[] = [];
  serviceProvidersBackup: ProviderModel[] | any[] = [];

  // shared variables that will communicate the filters between market discovery components
  appliedAdvancedFilters: AppliedFilterInfo[] = [];

  advancedFiltersCount: number = 0;

  productDataValidations: DataValidations | any; // data glossary validations for product/offering
  providerDataValidations: DataValidations | any; // data glossary validations for provider/player

  offeringTypes: string[] = [];
  playerTypes: string[] = [];

  // THIS
  potentialProducts: Product[] | any[] = [];
  includePartialProducts: boolean = false;

  potentialProviders: ProviderModel[] | any[] = [];
  includePartialProviders: boolean = false;

  quickFiltersAppliedForProducts: boolean = false;
  quickFiltersAppliedForProviders: boolean = false;

  resetTypesOne = new BehaviorSubject<any>(null);
  sendNumericDisabled = new BehaviorSubject<{ name: string, disabled: boolean } | any>(null);

  getNumericDisabled(): Observable<{ name: string, disabled: boolean }> {
    return this.sendNumericDisabled.asObservable();
  }

  constructor(
    private dataValidationService: DataValidationService,
    private providerService: ProviderCollectionService
  ) {
    this.initializeData();
  }

  private initializeData(): void {
    const aSub : Subscription = forkJoin([
      this.dataValidationService.getProductDataValidation$(),
      this.dataValidationService.getProviderDataValidation$()
    ]).subscribe({
      next: ([product$, provider$]) => {
        this.productDataValidations = product$;
        this.providerDataValidations = provider$;

        this.offeringTypes = this.mapProductDataFieldValue("ID_O_012").split(/[;,]/).map((type: string) => type.trim());
        this.playerTypes = this.mapProviderDataFieldValue("ID_P_007").split(/[;,]/).map((type: string) => type.trim());

        aSub.unsubscribe();
      },
      error: () => {
        aSub.unsubscribe();
      }
    });
  }

  // this is for Products/Offerings quick filters using the glossary
  private mapProductDataFieldValue(uniqueID: string){
    let value: any = "";

    // Loop through each field
    for (const field of this.productDataValidations?.fields) {
      // Check if characteristic_name matches the desired value
      if (field.unique_ID.toLowerCase() === uniqueID.toLowerCase()) {
        // Extract the possible_values
        value = field.possible_values;
        // Break out of the loop since you found what you were looking for
        break;
      }

      // Check if the desired value is found and break out of the outer loop
      if (value) {
          break;
      }
    }

    return value;
  }

  // this is for Providers/Players quick filters
  private mapProviderDataFieldValue(uniqueId: string){
    let value: any;

    for (const characteristic of this.providerDataValidations?.characteristics) {
      // Check if characteristic_name matches the desired value
      if (characteristic.unique_ID.toLowerCase() === uniqueId.toLowerCase()) {
        // Extract the possible_values
        value = characteristic.possible_values;
        // Break out of the loop since you found what you were looking for
        break;
      }

      // Check if the desired value is found and break out of the outer loop
      if (value) {
          break;
      }
    }

    return value;
  }

  // this will return the properties I need for the
  // market discovery modal, the properties that have
  // is_filter_criteria_for_market_discovery to "yes"
  mapPropertiesToFilter(filtersSource: any[]){
    const providerFilteredFields = this.providerDataValidations.characteristics.filter((characteristic: any) =>
      characteristic.is_filter_criteria_in_market_discovery === 'yes'
    );

    const providerGroupedFields = this.groupFieldsByCharacteristic(providerFilteredFields, false);

    providerGroupedFields.forEach(group => {
      const filterItems: any[] = group.fields.map(field => {
        const filterItem: any = {
          name: field.characteristic_name,
          applied: false,
          type: field.data_type_number,
          options: this.createFilterOptions(field.characteristic_name, field.unique_ID, field.data_type_number, false, field.possible_values)
        };

        return filterItem;
      });

      const filterGroup = {
        header: group.groupName,
        collapsed: true,
        items: filterItems
      };

      if(!filtersSource.some(filter => filter.header === group.groupName)){
        filtersSource.push(filterGroup);
      }
    });

    // now for products

    const productFilteredFields = this.productDataValidations.fields.filter((field: any) =>
        field.is_filter_criteria_in_market_discovery === 'yes'
    );

    const productGroupedFields = this.groupFieldsByCharacteristic(productFilteredFields, true);

    productGroupedFields.forEach(group => {
      const filterItems: any[] = group.fields.map(field => {
        const filterItem: any = {
          name: field.characteristic_name,
          applied: false,
          type: field.data_type_number,
          options: this.createFilterOptions(field.characteristic_name, field.unique_ID, field.data_type_number, true, field.possible_values)
        };

        return filterItem;
      });

      const filterGroup = {
        header: group.groupName,
        collapsed: true,
        items: filterItems
      };

      if(!filtersSource.some(filter => filter.header === group.groupName)){
        filtersSource.push(filterGroup);
      }
    });

    //
  }

  groupFieldsByCharacteristic(fields: any[], isOfferingsArray: boolean): { groupName: string, fields: any[] }[] {
    const groupedFields: { [groupName: string]: any[] } = {};

    fields.forEach(field => {
      const groupName = isOfferingsArray ? field.group_of_offering_characteristics : field.group_of_player_characteristics;

      if (!groupedFields[groupName]) {
        groupedFields[groupName] = [];
      }

      groupedFields[groupName].push(field);
    });

    return Object.keys(groupedFields).map(groupName => ({ groupName, fields: groupedFields[groupName] }));
  }

  // THIS FUNCTION CAN BE OPTIMIZED
  createFilterOptions(characteristicName: string, uniqueID: string, dataTypeNumber: number, isOfferingsArray: boolean, possibleValues?: string): any[]{
    let existingValues: any[] = [];

    if (!isOfferingsArray && this.providers) {
      const charName = this.toCamelCase(characteristicName);
      // Compute existing values based on getPropertyValuesFromProvider
      existingValues = this.getPropertyValuesFromProvider(charName, this.providers);
      // REVIEW REDUCTION AND AVAILABILITY ON PLAYERS FILTERS 

      // city, country
      if (uniqueID === 'ID_P_015' || uniqueID === 'ID_P_016') {
        // this is to always take all the values even if the list reduces
        existingValues = this.getPropertyValuesFromProvider(charName, this.serviceProvidersBackup);
        const currentProvsValues = this.getPropertyValuesFromProvider(charName, this.providers);
        const appliedFilter = this.appliedAdvancedFilters.find(advancedFilter => advancedFilter.name === characteristicName);

        // For specific characteristic names, apply existingValues directly
        if(appliedFilter){
          return existingValues.map(value => ({
            name: value,
            selected: appliedFilter.options[0].name === value,
            disabled: appliedFilter.options[0].name !== value
          }));
        }
        return existingValues.map(value => ({
          name: value,
          selected: false,
          disabled: !currentProvsValues.includes(value)
        }));
      } 

      if (dataTypeNumber === 0) {
        const optionsArray = possibleValues ? possibleValues.split(/[;,]/).map(option => option.trim()) : [];
        return optionsArray.map(option => ({
          name: option,
          checked: false,
          disabled: !existingValues.includes(option)
        }));
      } else if (dataTypeNumber === 1) {
        // NOTE: its not the same as the first if statement,
        // this object uses selected, not checked
        const optionsArray = possibleValues ? possibleValues.split(/[;,]/).map(option => option.trim().toLowerCase()).map(option => option.charAt(0).toUpperCase() + option.slice(1)) : [];
        return optionsArray.map(option => ({
          name: option,
          selected: false,
          disabled: !existingValues.includes(option) // If its there, return false so its not disabled
        }));
      } else if(dataTypeNumber === 3){
        // this is to always take all the values even if the list reduces
        existingValues = this.getPropertyValuesFromProvider(charName, this.serviceProvidersBackup);

        if(existingValues?.length > 0){
          const appliedFilter = this.appliedAdvancedFilters.find(advancedFilter => advancedFilter.name === characteristicName);
          const valuesFloat = existingValues.map(value => parseFloat(value));
          const minValue = Math.min(...valuesFloat);
          const maxValue = Math.max(...valuesFloat);

          if(appliedFilter) {
            return [
              {
                name: "guide",
                min: minValue,
                max: maxValue,
              }, 
              appliedFilter.options[0]
            ];
          }
          return [{
            name: "guide",
            min: minValue,
            max: maxValue,
          }];

        } else {
          // if there is the case that the numeric field does not have values, we apply a "template" and tell it it's disabled
          return [{
            name: "guide",
            min: 0,
            max: 100,
            disabled: true
          }];
        }
      } else if(dataTypeNumber === 4) {
        const optionsArray = Array.isArray(possibleValues) ? possibleValues : [];
        return optionsArray.map((option: any) => ({
          name: option.name, // this will be the header of the hierarchy
          values: option.values.split(/[;,]/).map((value: string) => value.trim()).filter((value: string) => value !== '').map((trimmedValue: string) => ({
            name: trimmedValue,
            checked: false,
            disabled: !existingValues.includes(trimmedValue),
          })),
        }));
      } else {
        return [];
      }
    } else {      
      
      existingValues = this.getFieldValuesFromProduct(characteristicName, this.products);

      //  scientific Expertise, free trial, data for country-specific regulation,
      // data for esg opportunities, gri labelling of data
      if (uniqueID === 'ID_O_045' || uniqueID === 'ID_O_047' || uniqueID === 'ID_O_074' || uniqueID === 'ID_O_079' || uniqueID === 'ID_O_129') {
        const optionsArray = possibleValues ? possibleValues.split(/[;,]/).map(option => option.trim().toLowerCase()).map(option => option.charAt(0).toUpperCase() + option.slice(1)) : [];
        return optionsArray.map(option => ({
          name: option,
          checked: false,
          disabled: !existingValues.includes(option)
        }));
      }

      if (dataTypeNumber === 0) {
        const optionsArray = possibleValues ? possibleValues.split(/[;,]/).map(option => option.trim()) : [];
        return optionsArray.map(option => ({
          name: option,
          checked: false,
          disabled: !existingValues.includes(option)
        }));
      } else if (dataTypeNumber === 1) {
        // NOTE: its not the same as the first if statement,
        // this object uses selected, not checked
        const optionsArray = possibleValues ? possibleValues.split(/[;,]/).map(option => option.trim()) : [];
        return optionsArray.map(option => ({
          name: option,
          selected: false,
          disabled: !existingValues.includes(option) // If its there, return false so its not disabled
        }));
      } else if (dataTypeNumber === 3){
        // this is to always take all the values even if the list reduces
        // existingValues = this.getFieldValuesFromProduct(characteristicName, this.serviceProductsBackup);        
        existingValues = this.getFieldValuesFromProduct(characteristicName, this.products);
         
        if(existingValues?.length > 0){
          const appliedFilter = this.appliedAdvancedFilters.find(advancedFilter => advancedFilter.name === characteristicName);
          const valuesFloat = existingValues.map(value => parseFloat(value));
          const minValue = Math.min(...valuesFloat);
          const maxValue = Math.max(...valuesFloat);
          
          if(appliedFilter) {
            return [
              {
                name: "guide",
                min: minValue,
                max: maxValue,
                disabled: false
              },
              appliedFilter.options[0]
            ];
          }
          
          return [{
            name: "guide",
            min: minValue,
            max: maxValue,
            disabled: false
          }];
          
        } else {
          // if there is the case that the numeric field does not have values, we apply a "template" and tell it it's disabled
          return [{
            name: "guide",
            min: 0,
            max: 100,
            disabled: true
          }];
        }
      } else if(dataTypeNumber === 4) {
        const optionsArray = Array.isArray(possibleValues) ? possibleValues : [];
        return optionsArray.map((option: any) => ({
          name: option.name, // this will be the header of the hierarchy
          values: option.values.split(/[;,]/).map((value: string) => value.trim()).filter((value: string) => value !== '').map((trimmedValue: string) => ({
            name: trimmedValue,
            checked: false,
            disabled: !existingValues.includes(trimmedValue)
          })),
        }));
      } else {
        return [];
      }
      //
    }
    //
  }

  // This method is for the search input inside the modal
  // It creates the array with the information that will go
  // in the options
  makeFilterOptions(filtersSource: any[]){
    return filtersSource.map((source: any) => {
      return {
        header: source.header,
        items: source.items.map((item: any) => item.name)
      }
    });
  }

  convertStringOfferingTypesToAdditive(types: string[], products: Product[]){
    const fieldNumber: number = 12;

    return types.map((type: string) => {
      const count: number = products.reduce((accumulator: number, product: Product | any) => {
        for (const category of product.categories) {
          for (const field of category.fields) {
            if (field.fieldNumber === fieldNumber && field.value?.includes(type)) {
              return accumulator + 1;
            }
          }
        }
        return accumulator;
      }, 0);

      return {
        name: type,
        checked: false,
        number: count
      };
    });
  }

  countOnOfferingQuickFilters(additiveProductFilters: {name: string, checked: boolean, number: number}[], products: Product[]){
    const fieldGlossaryId: string = "ID_O_012"; // this is the field number in the product, in the glossary ID_O_012

    additiveProductFilters.forEach((filter:{name: string, checked: boolean, number: number}) => {
      const count: number = products.reduce((accumulator: number, product: Product | any) => {
        for (const category of product.categories) {
          for (const field of category.fields) {
            if (field.fieldGlossaryId === fieldGlossaryId && field.value?.includes(filter.name)) {
              return accumulator + 1;
            }
          }
        }
        return accumulator;
      }, 0);

      filter.number = count;
    })

  }

  convertStringPlayerTypesToAdditive(types: string[], providers: ProviderModel[] | any){
    return types.map((type: string) => {
      const count = providers.reduce((accumulator: number, provider: ProviderModel | any) => {
        if (provider.typeOfPlayer?.includes(type)) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);

      return {
        name: type,
        checked: false,
        number: count
      };
    });
  }

  countOnPlayerQuickFilters(additiveProviderFilters: {name: string, checked: boolean, number: number}[], providers: ProviderModel[]){
    additiveProviderFilters.map((filter: {name: string, checked: boolean, number: number}) => {
      const count = providers.reduce((accumulator: number, provider: ProviderModel | any) => {
        if (provider.typeOfPlayer?.includes(filter.name)) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);

      filter.number = count;
    });
  }

  applyQuickFiltersForProducts(appliedAdditiveFilters: string[], products: Product[]){
    this.potentialProducts = [];
    const fieldGlossaryId: string = "ID_O_012"; // fieldGlossaryId for Offering Type in the product

    return products.filter((product: Product | any) => {
      // Check if the product has a category with "ID_O_012" field unique_ID
      const category = product.categories.find((cat: any) => cat.fields.some((field: any) => field.fieldGlossaryId === fieldGlossaryId));

      delete product['exact'];
      delete product['potential'];

      // If the product has the category and field, check if it matches all filter values
      if (category) {
        const offeringTypeField: any = category.fields.find((field: any) => field.fieldGlossaryId === fieldGlossaryId);

        const temp = offeringTypeField?.value?.split(/[;,]/).map((value: string) => value.trim());

        const match = appliedAdditiveFilters.every((value: any) => temp?.includes(value));

        if(match){
          product['exact'] = true;
          return match;
        }else{
          if(!temp || temp === '') {
            product['potential'] = true;
            if (!this.potentialProducts.some((p: Product) => p.id === product.id)) {
              this.potentialProducts.push(product);
            }
          }
          return false;
        }
      }

      return false;
    });
  }

  applyQuickFiltersForProviders(appliedAdditiveFilters: string[], providers: ProviderModel[]){
    this.potentialProviders = [];

    return providers.filter((provider: ProviderModel | any) => {
      const temp = provider?.typeOfPlayer?.split(/[;,]/).map((value: string) => value.trim());
      const match = appliedAdditiveFilters.every((value: any) => temp?.includes(value));

      delete provider['exact'];
      delete provider['potential'];

      if(match){
        provider['exact'] = true;
        return match;
      }else{
        if(!temp || temp === '') {
          provider['potential'] = true;
          if (!this.potentialProviders.some((p: ProviderModel) => p.id === provider.id)) {
            this.potentialProviders.push(provider);
          }
        }
        return false;
      }
    });
  }

  //TODO: Comment on this function
  applyAdvancedFilters(filters: any[], structure: any, showOfferings: boolean): any[]{
    if(showOfferings){
      // for products
      if (filters.length === 0) {
        this.potentialProducts = []; //
        structure.map((product: Product | any) => {
          delete product['exact']; // keep an eye on this 
          delete product['potential'];
          return product;
        });
      }
      return this.advanceFilterLogicForProduct(filters, structure);
    }else{
      // for providers
      if (filters.length === 0) {
        this.potentialProviders = []; //
        structure.map((provider: ProviderModel | any) => {
          delete provider['exact'];
          delete provider['potential'];
          return provider;
        });
      }
      return this.advanceFilterLogicForProvider(filters, structure);
    }
  }

  // advance filters helpers
  advanceFilterLogicForProduct(filters: any[], products: Product[] | any): any[]{
    return products.filter((product: Product | any) => {
      return filters.every((filter: any) => {

        const category = product.categories.find((category: any) => category.fields.some((f: any) => f.name === filter.name));

        if(category){
          const field = category.fields.find((f: any) => f.name === filter.name);

          if(field) {
            const filterOption = filter.options.find((option: any) => option.selected || option.checked);
            const filterValue = filterOption.name;

            if(!field.value || field.value === ''){
              delete product['exact'];
              product['potential'] = true;
              if (!this.potentialProducts.some((p: ProviderModel) => p.id === product.id)) {
                this.potentialProducts.push(product);
              }
              return false;
            }

            let match = false;

            if (!isNaN(Number(field.value))){
              const min = Number(filterValue.split(/[-]/)[0]); 
              const max = Number(filterValue.split(/[-]/)[1]); 
              match = field.value >= min && field.value <= max; 
            }else {
              match = field.value.includes(filterValue);
            }

            if(match){
              delete product['potential'];
              product['exact'] = true; //
            }
            return match;
          }

          return true;
        } else {
          // go to providers
          const provider = product.provider;
          const filterName = this.toCamelCase(filter.name);

          if(provider.hasOwnProperty(filterName)){
            // we do the match in the product
            const filterOption = filter.options.find((option: any) => option.selected || option.checked);

            if (filterOption) {
              const filterValue = filterOption.name;
              const providerValue = provider[filterName];

              if (!providerValue || providerValue === '') {
                delete product['exact'];
                product['potential'] = true;
                if (!this.potentialProducts.some((p: Product) => p.id === product.id)) {
                    this.potentialProducts.push(product);
                }
                // true will add them to the same array
                // false not
                return false; // Continue checking other filters, but add the provider to potential
              }

              let match = false;

              if (!isNaN(Number(providerValue))){
                const min = Number(filterValue.split(/[-]/)[0]); 
                const max = Number(filterValue.split(/[-]/)[1]); 
                match = providerValue >= min && providerValue <= max; 
              } else {
                match = providerValue.includes(filterValue);
              }

              if (match) {
                delete product['potential'];
                product['exact'] = true;
              }

              return match;
            }
            return false;
          } else {
            if (!this.potentialProducts.some((p: Product) => p.id === product.id)) {
              this.potentialProducts.push(product);
            }
            product['potential'] = true; // Mark as potential
            return false;
          }
        }
      });
      // end of the every function on filters
    });
  }

  advanceFilterLogicForProvider(filters: any[], providers: ProviderModel[] | any[]): any[]{
    return providers.filter((provider: ProviderModel | any) => {
      return filters.every((filter: any) => {
        const filterName = this.toCamelCase(filter.name);

        if(provider.hasOwnProperty(filterName)){
          // provider has the property, so compare to that
          const filterOption = filter.options.find((option: any) => option.selected || option.checked);

          if (filterOption) {
            const filterValue = filterOption.name;
            const providerValue = provider[filterName];

            if (!providerValue || providerValue === '') {
              provider['potential'] = true;
              if (!this.potentialProviders.some((p: ProviderModel) => p.id === provider.id)) {
                this.potentialProviders.push(provider);
              }    
              // true will add them to the same array
              // false won't
              return false; // Continue checking other filters, but add the provider to potential
            }

            let match = false;

            if (!isNaN(Number(providerValue))){
              const min = Number(filterValue.split(/[-]/)[0]); 
              const max = Number(filterValue.split(/[-]/)[1]); 
              match = providerValue >= min && providerValue <= max; 
            }else {
              match = providerValue.includes(filterValue);
            }

            return match;
          }

          return true; // If no filter option is selected, consider it a match
          //
        } else {
          // go into the products
          return provider.products.some((product: Product | any) => {
            // category of interest
            const category = product.categories.find((category: any) => category.fields.some((f: any) => f.name === filter.name));

            delete provider['exact'];
            delete provider['potential'];
            
            delete product['exact'];
            delete product['potential'];

            if(category){
              const field = category.fields.find((f: any) => f.name === filter.name);

              if(field) {
                const filterOption = filter.options.find((option: any) => option.selected || option.checked);
                const filterValue = filterOption.name;

                if(!field.value || field.value === ''){
                  provider['potential'] = true;
                  product['potential'] = true; //
                  if (!this.potentialProviders.some((p: ProviderModel) => p.id === provider.id)) {
                    this.potentialProviders.push(provider);
                  }    
                  return false;
                }

                let match = false;

                if (!isNaN(Number(field.value))){
                  const min = Number(filterValue.split(/[-]/)[0]); 
                  const max = Number(filterValue.split(/[-]/)[1]); 
                  match = field.value >= min && field.value <= max; 
                }else {
                  match = field.value.includes(filterValue);
                }

                if(match){
                  provider['exact'] = true;
                  product['exact'] = true; //
                }
                return match;
              }
              return true; // If no filter option is selected, consider it a match
            }else {
              provider['potential'] = true;
              if (!this.potentialProviders.some((p: ProviderModel) => p.id === provider.id)) {
                this.potentialProviders.push(provider);
              }
              return false;
            }
          });
          // end of going into products with the function some
        }
      }); 
      // end of the every function applied to filters
    });
    // end of the main return statement to filter the providers array
  }

  // helpers
  toCamelCase(str: string): string {
    str = str.toLowerCase();
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s|\(.+?\))/g, (word, index) => {
      // Include logic to handle parentheses
      if (index === 0) {
        return word.toLowerCase(); // Keep the first letter in lowercase
      } else if (/\s|\(/.test(word)) {
        return ''; // Remove spaces and open parentheses
      } else if (/\)/.test(word)) {
        return ''; // Remove close parentheses, but don't change the case of the following word
      } else {
        return word.toUpperCase();
      }
    }).replace(/\s+/g, ''); // Remove remaining spaces
  }

  getPropertyValuesFromProvider(property: string, providers: ProviderModel[] | any): any[]{
    const uniqueValuesSet = new Set<any>();

    return providers.map((provider: ProviderModel | any) => {
      const value: any = provider[property];

      // Check if the value is null or empty, and filter it out
      if (value !== null && value !== '' && !uniqueValuesSet.has(value)) {
        uniqueValuesSet.add(value);
        return value;
      } else {
          return null;
      }
    }).filter((value: any) => value !== null);// the array will contain the values, possibly as strings
  }

  getFieldValuesFromProduct(fieldName: string, products: Product[] | any): any[] {
    const uniqueValuesSet = new Set<any>();

    products.forEach((product: Product | any) =>
      product.categories.forEach((category: any) =>
        category.fields.forEach((field: any) => {
            if (field.name === fieldName && field.value !== null && field.value !== '') {
              if(!isNaN(field.value)){
                uniqueValuesSet.add(field.value)
              }else {
                field.value.split(/[;,]/)
                .map((value: any) => value.trim())
                .filter((value: any) => value !== '')
                .forEach((value: any) => uniqueValuesSet.add(value));
              }
            }
        })
      )
    );

    return Array.from(uniqueValuesSet);

  }

  atLeastOneProductFilterApplied(): boolean{
    return this.appliedAdvancedFilters.length > 0 || this.quickFiltersAppliedForProducts;
  }

  atLeastOneProviderFilterApplied(): boolean {
    return this.appliedAdvancedFilters.length > 0 || this.quickFiltersAppliedForProviders;
  }

}
