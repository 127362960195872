<div class="modal__header">
  <h3 class="modal__title">
    {{ 'components.modal.logout.title' | translate }}
  </h3>
  <button
    type="button"
    class="modal__close"
    aria-label="Close"
    (click)="activeModal.close(0)"
  ></button>
</div>

<div class="modal__body">
  <div class="modal__row">
    <p>{{ 'components.modal.logout.body' | translate }}</p>
  </div>
</div>

<div class="modal__footer">
  <button mat-flat-button color="warn" type="button" (click)="onConfirm(1)">
    {{ 'components.modal.logout.agree' | translate }}
  </button>
</div>
