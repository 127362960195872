import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { ApiService } from '../api/api-service/api.service';
import { PageModel } from '../api/pagination-service/model/page.model';
import { PermissionModel } from 'src/app/models/permission.model';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {
 
  private permissionUrl = 'permission';

  constructor(
    protected apiService: ApiService,
    private http: HttpClient
  ) { }

  getPermissionsWithPagination$(params: { [keyof: string]: any } = {}): Observable<PageModel<PermissionModel>> {
    return this.apiService.get<PageModel<PermissionModel>>(this.permissionUrl, { params },'').pipe(
      catchError((error) => {
        console.error('Error fetching permissions:', error);
        return throwError(error);
      })
    );
  }
}