import { STORAGE_KEY_PROJECTS, StorageKeys } from './../storage-service/storage.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../storage-service/storage.service';
import { ProjectModel } from 'src/app/models/project.model';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  constructor(
    private router: Router,
    private storageService: StorageService,
  ) {
  }

  /**
   * Go to generic route
   */
  public goToRoute(routes: Array<string>) {
    this.router.navigate(routes);
  }

  /**
   * Go to project
   */
  public goToProject(project: ProjectModel) {
    this.storageService.setItem(STORAGE_KEY_PROJECTS, project);
    this.router.navigate(
      [STORAGE_KEY_PROJECTS, project.slug]
    );
  }

  //   /**
  //    * Go to collection
  //    */
  //   public goToCollection(collection: CollectionModel) {
  //     this.storageService.setItem(StorageKeys.collections, collection);
  //     this.router.navigate(['projects', collection.project.slug, collection.slug])
  //   }

  //   /**
  //    * Go to document
  //    */
  //   public goToTranscription(transcription: TranscriptionModel) {
  //     this.storageService.setItem(StorageKeys.transcriptions, transcription);
  //     this.router.navigate(['projects', transcription.project.slug, transcription.collection.slug, transcription.slug]);
  //   }

  //   public goToTranscriptionFromGeneral(projectSlug: string, collectionSlug: string, transcriptionSlug: string) {
  //     this.router.navigate(['projects', projectSlug, collectionSlug, transcriptionSlug]);
  //   }

  //   /**
  //    * Go to voicemodels
  //    */
  //   public goToVoiceModelProject(voicemodel: VoicemodelProjectModel) {
  //     this.storageService.setItem(StorageKeys.voicemodelProject, voicemodel);
  //     this.router.navigate(
  //       ['voicemodels', voicemodel.slug]
  //     );
  //   }

  //   /**
  //    * Go to voicemodels dataset
  //    * @param dataset
  //    */
  //   public goToVoiceModelDataset(dataset: VoicemodelDatasetModel) {
  //     this.storageService.setItem(StorageKeys.voicemodelsDataset, dataset);
  //     this.router.navigate(
  //       ['voicemodels', dataset.voiceModelProject.slug, 'datasets', dataset.slug]
  //     );
  //   }

  //   /**
  //  * Go to languagemodels
  //  */
  //   public goToLanguageModel(languagemodel: LanguagemodelModel) {
  //     this.storageService.setItem(StorageKeys.languagemodels, languagemodel);
  //     this.router.navigate(
  //       ['languagemodels', languagemodel.slug]
  //     );
  //   }

  //   /**
  // * Go to Agent
  // */
  //   public goToAgent(agent: AgentModel) {
  //     this.storageService.setItem(StorageKeys.agents, agent);
  //     this.router.navigate(
  //       ['agents/agentsperfomace', agent.slug]
  //     );
  //   }

  /**
   * Check that project slug is correct
   * If not, redirect to correct one
   */
  public checkProjectSlug(slug: string, project: ProjectModel) {
    if (!this.checkEntitySlug(slug, project.id, project.name)) {
      this.goToProject(project);
    }
  }

  /**
   * Check that slug is correct
   */
  public checkEntitySlug(slug: string, id: string, name: string) {
    return slug === this.getSlugFromEntity(id, name);
  }

  /**
   * Construct slug structure (12-lorem-ipsum-dolor)
   */
  public getSlugFromEntity(id: string, name: string) {
    return `${id}-${name}`;
  }

  /**
   * Get id from slug (12-lorem-ipsum returns 12)
   */
  public getIdFromSlug(slug: any) {
    return slug.split('-')[0];
  }

  /**
   * get tenant route
   *
   */
  getTenantRoute() {
    //const tenantName = this.authService.getTenantName();
    //return [tenantName]
  }

}
