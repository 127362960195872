
<form [formGroup]="myForm">

    <div class="modal__body">
        <div class="modal__row modal__row--flex">
            <mat-form-field> 
                <mat-label>{{ 'datasetname' | translate : 'Data Set Name' }}</mat-label>
                <input 
                matInput 
                formControlName="dataSetName" 
                id="dataSetName" 
                name="dataSetName" 
                required
                />
            </mat-form-field>
        </div>
        <button 
            mat-flat-button 
            type="button" 
            class="btn-submit" 
            color="primary"             
            (click)="fileInput.click()"
        >
            {{ 'upload.button' | translate : 'Upload Data Set' }}
        </button>
        <input 
            type="file" 
            #fileInput 
            formControlName="dataSetFile" 
            [accept]="getAcceptedFormats()" 
            hidden
            (change)="onFileSelect($event)"
            [required]="isValidFormat"
        >

        <div class="attch-list">
            <app-attachment-carousel 
                *ngIf="selectedFile"
                [attachments]="[selectedFile]"
                [action]="'remove'"
                (deletedAttachment)="cleanInput()"
                [showEdit]="false"
            ></app-attachment-carousel>
        </div>    

        <span class="warning-hint" *ngIf="fileWarningMessage !== ''">
            <mat-icon class="warn-icon">warning</mat-icon>
            {{ fileWarningMessage }}
        </span>
    </div>    
</form>