import { RouterModule } from '@angular/router';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipeModule } from "../pipes/pipe.module";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { GraphsModule } from "./graphs/graphs.module";
import { DeleteModalComponent } from "./modals/delete/delete.component";
import { UserModalComponent } from "./modals/user-modal/user-modal.component";
import { ProviderProductFilterModule } from "./provider-product-filter/provider-product-filter.module";
import { ResponsiveTableModule } from "./responsive-table/responsive-table.module";
import { SpinnerBallComponent } from "./spinner-ball/spinner-ball.component";
import { ToggleCollapseModule } from "./toggle-collapse/toggle-collapse.module";
import { UsersTableComponent } from "./users-table/users-table.component";
import { GeneralModalComponent } from './modals/general-modal/general-modal.component';
import { GeneralTableTreeModule } from './general-table-tree/general-table-tree.module';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ModalForInjectComponentComponent } from './modals/modal-for-inject-component/modal-for-inject-component.component';
import { UploadFilesComponentsComponent } from './upload-files-components/upload-files-components.component';
import { NoteCardComponent } from './note-card/note-card.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReadModeModalComponent } from './modals/read-mode-modal/read-mode-modal.component';
import { NoteCreationComponent } from './note-creation/note-creation.component';
import { AttachmentCarouselComponent } from './attachment-carousel/attachment-carousel.component';
import { EyButtonComponent } from './ey-button/ey-button.component';
import { IconGridComponent } from './icon-grid/icon-grid.component';

import { DirectivesModule } from '../directives/directive.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { FileComponent } from './file/file.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FilesCrudRealTimeComponent } from './files-crud-real-time/files-crud-real-time.component';
import { FileWithRemoveWithoutSelectionComponent } from './file-with-remove-without-selection/file-with-remove-without-selection.component';
import { FileManagementGridComponent } from './file-management-grid/file-management-grid.component';
import { UserPermissionsTableComponent } from './user-permissions-table/user-permissions-table.component';
import { RefreshTokenComponent } from './modals/refresh-token/refresh-token.component';
import { LogoutModalComponent } from './modals/logout/logout.component';
import { LicenseManagementTableComponent } from './license-management-table/license-management-table.component';
import { LicenseModalComponent } from './modals/license-modal/license-modal.component';
import { AdminProviderModalComponent } from './modals/admin-provider-modal/admin-provider-modal.component';
import { ProductCreationComponent } from './product-creation/product-creation.component';
import { ProductAttachmentCreationComponent } from './product-attachment-creation/product-attachment-creation.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { DatasetUploadComponent } from './dataset-upload/dataset-upload.component';
import { DocumentsDetailComponent } from './documents-detail/documents-detail.component';
import { ProductInfoEditModalComponent } from './modals/product-info-edit-modal/product-info-edit-modal.component';
import { ValidationContentModalComponent } from './validation-content-modal/validation-content-modal.component';
import { RemoveDatasetComponent } from './remove-dataset/remove-dataset.component';
import { AutocompleteModule } from './autocomplete/autocomplete.module';

import { AttachmentsViewerComponent } from './attachments-viewer/attachments-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { HistoricalDisplayProductComponent } from './historical-display-product/historical-display-product.component';
import { HistoricalDisplayProviderComponent } from './historical-display-provider/historical-display-provider.component';
import { ProductDatafieldChangelogComponent } from './product-datafield-changelog/product-datafield-changelog.component';
import { ProductComparisonToolComponent } from './product-comparison-tool/product-comparison-tool.component';
import { ProviderComparisonToolComponent } from './provider-comparison-tool/provider-comparison-tool.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { ChatViewComponent } from './chatbot/chat-view/chat-view.component';
import { ChatHistoryComponent } from './chatbot/chat-history/chat-history.component';
import { ProductStatusEditModalComponent } from './modals/product-status-edit-modal/product-status-edit-modal.component';
import { ProviderStatusEditModalComponent } from './modals/provider-status-edit-modal/provider-status-edit-modal.component';
import { ProviderFlagEditModalComponent } from './modals/provider-flag-edit-modal/provider-flag-edit-modal.component';
import { ProductFlagEditModalComponent } from './modals/product-flag-edit-modal/product-flag-edit-modal.component';
import { ProviderEditorCommentsModalComponent } from './modals/provider-editor-comments-modal/provider-editor-comments-modal.component';
import { ProductEditorCommentsModalComponent } from './modals/product-editor-comments-modal/product-editor-comments-modal.component';
import { NotesAttachmentsViewerComponent } from './notes-attachments-viewer/notes-attachments-viewer.component';
import { ProductEditorControlComponent } from './product-editor-control/product-editor-control.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AdminContactComponent } from './admin-contact/admin-contact.component';
import { ProviderDatafieldChangelogComponent } from './provider-datafield-changelog/provider-datafield-changelog/provider-datafield-changelog.component';
import { CharacteristicsVisibilityModalComponent } from './characteristics-visibility-modal/characteristics-visibility-modal/characteristics-visibility-modal.component';
import { ArchiveOfferingModalComponent } from './archive-offering-modal/archive-offering-modal.component';
import { ProviderEditorControlComponent } from './provider-editor-control/provider-editor-control/provider-editor-control.component';

@NgModule({
  declarations: [
    SpinnerBallComponent,
    BreadcrumbComponent,
    UsersTableComponent,
    UserModalComponent,
    DeleteModalComponent,
    GeneralModalComponent,
    ModalForInjectComponentComponent,
    UploadFilesComponentsComponent,
    NoteCardComponent,
    ReadModeModalComponent,
    NoteCreationComponent,
    AttachmentCarouselComponent,
    FilesCrudRealTimeComponent,
    EyButtonComponent,
    IconGridComponent,
    FileComponent,
    FileWithRemoveWithoutSelectionComponent,
    FileManagementGridComponent,
    UserPermissionsTableComponent,
    RefreshTokenComponent,
    LogoutModalComponent,
    LicenseManagementTableComponent,
    LicenseModalComponent,
    AdminProviderModalComponent,
    ProductCreationComponent,
    ProductAttachmentCreationComponent,
    ConfirmModalComponent,
    DatasetUploadComponent,
    DocumentsDetailComponent,
    ProductInfoEditModalComponent,
    RemoveDatasetComponent,
    ValidationContentModalComponent,
    RemoveDatasetComponent,
    AttachmentsViewerComponent,
    HistoricalDisplayProductComponent,
    HistoricalDisplayProviderComponent,
    AttachmentsViewerComponent,
    ProductDatafieldChangelogComponent,
    ProductComparisonToolComponent,
    ProviderComparisonToolComponent,
    ChatbotComponent,
    ChatViewComponent,
    ChatHistoryComponent,
    ProductStatusEditModalComponent,
    ProviderStatusEditModalComponent,
    ProviderFlagEditModalComponent,
    ProductFlagEditModalComponent,
    ProviderEditorCommentsModalComponent,
    ProductEditorCommentsModalComponent,
    NotesAttachmentsViewerComponent,
    ProductEditorControlComponent,
    AccordionComponent,
    AdminContactComponent,
    ProviderDatafieldChangelogComponent,
    CharacteristicsVisibilityModalComponent,
    ArchiveOfferingModalComponent,
    ProviderEditorControlComponent
  ],

  imports: [
    CommonModule,
    RouterModule,
    MatChipsModule,
    ResponsiveTableModule,
    PipeModule,
    GraphsModule,
    ProviderProductFilterModule,
    ToggleCollapseModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    GeneralTableTreeModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    NgbModule,
    MatGridListModule,
    MatCardModule,
    MatBadgeModule,
    MatCheckboxModule,
    DirectivesModule,
    MatTooltipModule,
    AutocompleteModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    SpinnerBallComponent,
    BreadcrumbComponent,
    UsersTableComponent,
    ResponsiveTableModule,
    GraphsModule,
    ProviderProductFilterModule,
    ToggleCollapseModule,
    GeneralTableTreeModule,
    EyButtonComponent,
    IconGridComponent,
    UploadFilesComponentsComponent,
    FilesCrudRealTimeComponent,
    FileWithRemoveWithoutSelectionComponent,
    FileManagementGridComponent,

    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,

    NoteCardComponent,
    MatGridListModule,
    MatCardModule,
    MatBadgeModule,
    UserPermissionsTableComponent,
    RefreshTokenComponent,
    LogoutModalComponent,
    LicenseManagementTableComponent,
    AttachmentCarouselComponent,
    ProductCreationComponent,
    DocumentsDetailComponent,
    UserModalComponent,
    ProductComparisonToolComponent,
    ProviderComparisonToolComponent,
    ChatbotComponent,
    AccordionComponent,
    ProductEditorControlComponent

  ],
})
export class ComponentsModule { }
