<div class="container">
<div class="chatview-container" appResizable>
  <!-- Chat Header -->
  <div class="chat-header">
    <div
      class="history-btn"
      [ngClass]="{ 'history-btn-filled': showChatHistory }"
      (click)="toggleChatHistory()"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.99935 4.37533C10.3445 4.37533 10.6243 4.65515 10.6243 5.00033L10.6244 9.37533H14.9993C15.3445 9.37533 15.6243 9.65515 15.6243 10.0003C15.6243 10.3455 15.3445 10.6253 14.9993 10.6253H9.99935C9.65417 10.6253 9.37435 10.3455 9.37435 10.0003L9.37435 5.00033C9.37435 4.65515 9.65417 4.37533 9.99935 4.37533Z"
          fill="#BFBFC8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.99935 2.29199C5.74215 2.29199 2.29102 5.74313 2.29102 10.0003C2.29102 14.2575 5.74215 17.7087 9.99935 17.7087C14.2565 17.7087 17.7077 14.2575 17.7077 10.0003C17.7077 5.74313 14.2565 2.29199 9.99935 2.29199ZM1.04102 10.0003C1.04102 5.05277 5.0518 1.04199 9.99935 1.04199C14.9469 1.04199 18.9577 5.05277 18.9577 10.0003C18.9577 14.9479 14.9469 18.9587 9.99935 18.9587C5.0518 18.9587 1.04102 14.9479 1.04102 10.0003Z"
          fill="#BFBFC8"
        />
      </svg>
    </div>
    <p class="chat-title" *ngIf="chatMessages.length > 0  && !isLoadingChat">
      {{ chatMessages[0].question }}
    </p>
  </div>

  <!-- Conversation -->
  <div class="chat-content" #chatContainerElement >
    <app-table-spinner-ball *ngIf="isLoadingChat" [spinnerStyles]="'la-ball-circus'"></app-table-spinner-ball>

  <!-- <div class="intro-wrapper"> -->
    <div class="welcome-container" *ngIf="chatMessages.length == 0 && !isLoadingChat">
      <img class="welcome-image" src="assets/img/genAI-chatBot-welcome.svg" alt="no-items-image">
    </div>
    <div class="intro-message" *ngIf="chatMessages.length == 0 && !isLoadingChat">
      <h2>Hello! How can I help?</h2>
      <p>Start by typing a question or select a suggestion</p>
      <span>Thanks in advance for helping us improve the model by using the feedback options for the answers you receive</span>
    </div>


    <div class="prompts-example" *ngIf="chatMessages.length == 0  && !isLoadingChat">
      <ng-container *ngFor="let item of questions">
        <p class="prompts-example--item" (click)="sendMessage(item.question)">{{item.question}}</p>
      </ng-container>
    </div>
  <!-- </div> -->

    <div
      class="chat-conversation"
      *ngIf="chatMessages.length > 0  && !isLoadingChat"
    >
      <!-- Question - response pair -->
      <div
        class="chat-message"
        *ngFor="let message of chatMessages; let i = index"
      >
        <!-- Question -->
        <div class="chat-message--user">
          <header>{{ loggedUser.username }}</header>
          <p>{{ message.question }}</p>
        </div>
        <!-- Response -->
        <div class="chat-message--response" *ngIf="message.response.length > 0">

          <p class="chat-message--response__text" [innerHTML]="message.response">
          </p>
          <!-- Response Actions-->
          <div class="chat-message--response__actions">
            <button [matTooltip]="'Accurate and helpful'" class="action-btn" (click)="likeMessage(message)">
              <svg
              [ngClass]="{
                'action-icon': true,
                'action-icon__active':  message.like === true
              }"
              width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1512 5.16726C12.8315 4.76767 12.2114 4.80938 11.9481 5.24818L9.68941 9.01266C9.27525 9.70292 8.5293 10.1253 7.72432 10.1253H5.54297V18.0419H15.7283C16.198 18.0419 16.6097 17.7275 16.7333 17.2744L18.3242 11.441C18.5049 10.7784 18.0061 10.1253 17.3192 10.1253H13.5066C12.7531 10.1253 12.2126 9.39882 12.4291 8.67701L13.2811 5.83698C13.3504 5.60595 13.3018 5.35561 13.1512 5.16726ZM14.1272 4.38639C13.2634 3.30659 11.5877 3.4193 10.8762 4.60506L8.61754 8.36954C8.42929 8.6833 8.09022 8.87528 7.72432 8.87528H5.41797C4.79665 8.87528 4.29297 9.37895 4.29297 10.0003V18.1669C4.29297 18.7883 4.79665 19.2919 5.41797 19.2919H15.7283C16.7617 19.2919 17.6673 18.6003 17.9392 17.6033L19.5301 11.7699C19.9277 10.3121 18.8303 8.87528 17.3192 8.87528H13.6747L14.4784 6.19616C14.6657 5.57185 14.5344 4.89536 14.1272 4.38639Z"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.83464 19.2919C7.48946 19.2919 7.20964 19.0121 7.20964 18.6669V9.50028C7.20964 9.1551 7.48946 8.87528 7.83464 8.87528C8.17981 8.87528 8.45963 9.1551 8.45963 9.50028V18.6669C8.45963 19.0121 8.17981 19.2919 7.83464 19.2919Z"/>
            </svg>
            </button>

            <button class="action-btn" (click)="disLikeMessage(message)" [matTooltip]="'Inaccurate or unhelpful'">
              <svg
              [ngClass]="{
                'action-icon': true,
                'action-icon__active':  message.like === true
              }"
              width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1512 18.4167C12.8315 18.8163 12.2114 18.7746 11.9481 18.3358L9.68941 14.5713C9.27525 13.881 8.5293 13.4587 7.72432 13.4587H5.54297V5.54199H15.7283C16.198 5.54199 16.6097 5.85639 16.7333 6.30958L18.3242 12.1429C18.5049 12.8056 18.0061 13.4587 17.3192 13.4587H13.5066C12.7531 13.4587 12.2126 14.1851 12.4291 14.9069L13.2811 17.747C13.3504 17.978 13.3018 18.2283 13.1512 18.4167ZM14.1272 19.1975C13.2634 20.2773 11.5877 20.1646 10.8762 18.9789L8.61754 15.2144C8.42929 14.9006 8.09022 14.7087 7.72432 14.7087H5.41797C4.79665 14.7087 4.29297 14.205 4.29297 13.5837V5.41699C4.29297 4.79567 4.79665 4.29199 5.41797 4.29199H15.7283C16.7617 4.29199 17.6673 4.98367 17.9392 5.98068L19.5301 11.814C19.9277 13.2718 18.8303 14.7087 17.3192 14.7087H13.6747L14.4784 17.3878C14.6657 18.0121 14.5344 18.6886 14.1272 19.1975Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.83464 14.7087C7.48946 14.7087 7.20964 14.4288 7.20964 14.0837V4.91699C7.20964 4.57181 7.48946 4.29199 7.83464 4.29199C8.17981 4.29199 8.45963 4.57181 8.45963 4.91699V14.0837C8.45963 14.4288 8.17981 14.7087 7.83464 14.7087Z"/>
            </svg>

            </button>

            <button class="action-btn"  (click)="toggleFlag(message)" [matTooltip]="'Inaproppriate answer'">
              <svg
              [ngClass]="{
                'action-icon': true,
                'action-icon__active':  message.flag === true
              }"
              width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.60503 20.1219C5.26156 20.0875 5.01097 19.7812 5.04532 19.4378L5.54532 14.4378L6.33678 5.73144C6.38946 5.15197 6.8753 4.70829 7.45716 4.70829H18.953C19.6146 4.70829 20.1333 5.27632 20.0734 5.93514L19.331 14.1018C19.2783 14.6813 18.7925 15.125 18.2106 15.125H6.73283L6.28911 19.5621C6.25477 19.9056 5.94849 20.1562 5.60503 20.1219ZM6.85161 13.875H18.0965L18.8162 5.95829H7.57131L6.85161 13.875Z"/>
            </svg>

            </button>

            <button class="action-btn" (click)="handleCopyToClipboard(message.response)" [matTooltip]= "'Copy'">
              <svg class="copy-action" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.8737 9.99967C8.8737 9.37835 9.37738 8.87467 9.9987 8.87467H18.1654C18.7867 8.87467 19.2904 9.37835 19.2904 9.99967V18.1663C19.2904 18.7877 18.7867 19.2913 18.1654 19.2913H9.9987C9.37738 19.2913 8.8737 18.7877 8.8737 18.1663V9.99967ZM10.1237 10.1247V18.0413H18.0404V10.1247H10.1237Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.70703 5.83301C4.70703 5.21169 5.21071 4.70801 5.83203 4.70801H13.9987C14.62 4.70801 15.1237 5.21169 15.1237 5.83301V9.49967C15.1237 9.84485 14.8439 10.1247 14.4987 10.1247C14.1535 10.1247 13.8737 9.84485 13.8737 9.49967V5.95801H5.95703V13.8747H9.4987C9.84388 13.8747 10.1237 14.1545 10.1237 14.4997C10.1237 14.8449 9.84388 15.1247 9.4987 15.1247H5.83203C5.21071 15.1247 4.70703 14.621 4.70703 13.9997V5.83301Z"/>
              </svg>

            </button>
          </div>
        </div>

      </div>
      <!-- <button class="chat-message--regenerate" *ngIf="!isWaitingForResponse">
        Regenerate
      </button> -->
      <button class="chat-message--regenerate" *ngIf="isWaitingForResponse" (click)="stopGeneratingResponse()">
        Stop generating
      </button>

      <div id="typing-animation" *ngIf="isWaitingForResponse">
        <app-table-spinner-ball [spinnerStyles]="'la-ball-circus'"></app-table-spinner-ball>
      </div>
      <div #scrollResponseIntoView></div>

    </div>

  </div>

  <!--Text input -->
  <div class="chat-input" [ngClass]="{'chat-input-focus': inputOnFocus}">
    <!-- <button class="go-to-bottom-btn">{{isChatAtBottom}}</button> -->
    <input
      [(ngModel)]="inputText"
      [disabled]="isWaitingForResponse"
      (keyup.enter)="sendMessage()"
      type="text"
      placeholder="Prompt here..."
      (click)="inputOnFocus = true"
      (blur)="inputOnFocus = false"
    />
    <button class="send-btn" (click)="sendMessage()" cdkDrag>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.04946 6.47936C1.09141 6.22638 1.28364 6.02469 1.53432 5.97064L18.3281 2.35003C18.5739 2.29703 18.8275 2.39652 18.9718 2.60251C19.116 2.80849 19.1227 3.08086 18.9889 3.29373L9.8428 17.8364C9.70627 18.0534 9.451 18.1651 9.19892 18.118C8.94684 18.0709 8.74912 17.8746 8.7002 17.6228L7.3613 10.7322L1.34418 7.11735C1.12436 6.98529 1.00751 6.73235 1.04946 6.47936ZM9.62587 15.8333L17.1405 3.88479L3.34236 6.85956L8.2436 9.80406C8.39547 9.89529 8.50148 10.0467 8.53527 10.2206L9.62587 15.8333Z"
          fill="#23232F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.36711 10.7282C7.16912 10.4454 7.23784 10.0557 7.52059 9.85772L18.1013 2.44902C18.3841 2.25104 18.7738 2.31975 18.9718 2.60251C19.1697 2.88526 19.101 3.27498 18.8183 3.47296L8.23756 10.8817C7.95481 11.0796 7.56509 11.0109 7.36711 10.7282Z"
          fill="#23232F"
        />
      </svg>
    </button>
  </div>

  <!--Close Chat View button-->
  <div class="close-chat-bubble" (click)="closeChatView()">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.04102 10.0003C6.04102 9.65515 6.32084 9.37533 6.66602 9.37533H13.3327C13.6779 9.37533 13.9577 9.65515 13.9577 10.0003C13.9577 10.3455 13.6779 10.6253 13.3327 10.6253H6.66602C6.32084 10.6253 6.04102 10.3455 6.04102 10.0003Z"
        fill="#BFBFC8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99935 2.29199C5.74215 2.29199 2.29102 5.74313 2.29102 10.0003C2.29102 14.2575 5.74215 17.7087 9.99935 17.7087C14.2565 17.7087 17.7077 14.2575 17.7077 10.0003C17.7077 5.74313 14.2565 2.29199 9.99935 2.29199ZM1.04102 10.0003C1.04102 5.05277 5.0518 1.04199 9.99935 1.04199C14.9469 1.04199 18.9577 5.05277 18.9577 10.0003C18.9577 14.9479 14.9469 18.9587 9.99935 18.9587C5.0518 18.9587 1.04102 14.9479 1.04102 10.0003Z"
        fill="#BFBFC8"
      />
    </svg>
  </div>
  <app-chat-history
    *ngIf="showChatHistory"
    (newChatEmit)="emptyChat()"
  ></app-chat-history>
</div>
</div>
