<div class="editor-control-container">
    <app-table-spinner-ball *ngIf="loading" [spinnerStyles]="'la-ball-circus'"></app-table-spinner-ball>

    <div class="editor-control-info" *ngIf="!loading">
        <span> Player: {{ providerName }}</span>
    </div>

    <div class="changes-info" *ngIf="!loading">
        <h4>{{ "page.product.profile.editor.control.modal.userandtime" | translate : 'User and time of saved changes' }}</h4>

        <span> User: {{ editorControl.user.username }} ({{ "role: " + editorControl.user.role }})</span>
        <span> Email: {{ editorControl.user.email }}</span>
        <span> Timestamp of Change(s): {{ editorControl.editingEndedAt }}</span>
    </div>

    <div class="info-text-container" *ngIf="!loading">
        <p>
            {{ editorControl.user.username }} ({{ "role: " + editorControl.user.role }}) saved changes on this player profile while
            you were editing it. There might be merge conflicts with the changes you are about
            to save. By saving your changes, you might overwrite the changes made
            by {{ editorControl.user.username }} ({{ "role: " + editorControl.user.role }}). Note that all changes are saved in the
            change log of the respective characteristics.
        </p>
    </div>

    <div class="actions-row" *ngIf="!loading">
        <div class="download-line" (click)="downloadMyChanges()">
            <i class="fa fa-download" aria-hidden="true"></i>
            <span>{{ "page.product.profile.editor.control.modal.download" | translate : 'Download your current changes' }}</span>
        </div>

        <div class="buttons-container">
            <button
                mat-flat-button
                class="cancel"
                (click)="discardMyChanges()"
            >
                {{ "page.product.profile.editor.control.modal.discard" | translate : 'Discard My Changes' }}
            </button>

            <button
                mat-flat-button
                class="save-btn"
                color="primary"
                (click)="saveMyChanges()"
            >
                {{ "page.product.profile.editor.control.modal.save" | translate : 'Save My Changes' }}
            </button>
        </div>
    </div>
</div>
