<mat-form-field class='search-field'
    appearance="fill"
    [ngClass]="{
    'medium-field': width === 'medium',
    'small-field': width === 'small',
    'no-valid-data': isInvalidData }">
    <input
        type="text"
        matInput
        matTooltip=""
        [ngClass]="{'with-arrow': showArrowIconSuffix}"
        [placeholder]="placeholder"
        [formControl]="control"
        (keyup.enter)="onEnterPressed()"
        [matAutocomplete]="auto"
        #autoInput
    />

    <mat-icon *ngIf="showSearchIconPrefix" matPrefix>search</mat-icon>
    <mat-icon *ngIf="showArrowIconSuffix && !control.value" matSuffix>arrow_drop_down</mat-icon>

    <mat-hint class="custom-hint" *ngIf="multilevel && showHint && selectedProvider" [title]="selectedProvider"> {{ selectedProvider }}</mat-hint>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectItem($event.option)">
        <ng-container *ngIf="multilevel; else regularOptions">
            <mat-optgroup
                *ngFor="let section of filteredOptions | async"
                [label]="section?.header"
                class="custom-optgroup"
            >
            <mat-option *ngFor="let option of section?.items" [value]="option" [disabled]="option === 'Invalid value'">
                {{ option }}
            </mat-option>
            </mat-optgroup>
        </ng-container>
        <ng-template #regularOptions>
            <mat-option
                *ngFor="let data of filteredOptions | async"
                [value]="data"
                [disabled]="data === 'Invalid value'">
            {{ data }}
            </mat-option>
        </ng-template>
    </mat-autocomplete>
    <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="control.setValue('')"
            [style.display]="control.value && control.value.length > 0 ? 'block' : 'none'"
            >
            <mat-icon>{{'clear'}}</mat-icon>
        </button>
  </mat-form-field>
