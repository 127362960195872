import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/models/product.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { DataMasterService } from 'src/app/services/data-master-service/data-master';
import { UserService } from 'src/app/services/user-service/user.service';


@Component({
  selector: 'app-provider-status-edit-modal',
  templateUrl: './provider-status-edit-modal.component.html',
  styleUrls: ['./provider-status-edit-modal.component.scss']
})
export class ProviderStatusEditModalComponent {
  myForm!: FormGroup;
  private _data!: ProviderModel;
  private _isDataLoaded: boolean = false;

  @Input()
  set data(value: ProviderModel) {
    this._data = value;
    this._isDataLoaded = true;
    if (value) {
      this.myForm = this.formBuilder.group({
        status: value.status,
      });
    }
  }

  get data(): ProviderModel {
    return this._data;
  }

  @Input() product!: Product;
  option = '';
  action = 'create';
  @Input() statusList: any = [];
  showWarning: boolean = false;

  closeModal($event: MouseEvent) {
    this.activeModal.close({ action: 'close' })
  }
  
  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    public dataMasterService: DataMasterService,
    protected alertService: AlertService,
    protected translate: TranslatePipe,
    private formBuilder: FormBuilder
  ) {
    this.myForm = this.formBuilder.group({
      status: ['', Validators.required],
    });
  }

  ngOnInit() {
    if (this._isDataLoaded) {
      this.setInitialValues(this.data);
    }
  }

  setInitialValues(provider: ProviderModel) {
    if (provider) {
      this.myForm.patchValue({
        status: this.transform(provider.status),
      });
    }
  }

  transform(value: string): string {
    return value
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  onSubmit() {}
}
