// FROM FNN
import { PermissionModel } from '../models/permission.model';
import { definitions } from './permissions';

/**
 * Object whose properties are permissions.
 * 
 * Property names are permission names.
 */
const userPermissions: { [keyof: string]: PermissionModel } = {};

definitions.forEach((permissionDefinition: {
    id: number,
    name: string,
    description: string;
}) => {
    userPermissions[permissionDefinition.name] = new PermissionModel(
        permissionDefinition.id,
        permissionDefinition.name,
        permissionDefinition.description
    )
})

/**
 * Get permission by name.
 * 
 * Notify by console if permission is not defined.
 * 
 * @param name permission name.
 */

export function getPermission(name: string): PermissionModel {
    const permission = userPermissions[name];
    return permission;
}

export function getPermissionById(id: number): PermissionModel {
    const permission = Object.values(userPermissions).find(
        (permission: PermissionModel) => permission.id === id
    ) as PermissionModel;
    return permission;
}
