import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-read-mode-modal',
  templateUrl: './read-mode-modal.component.html',
  styleUrls: ['./read-mode-modal.component.scss']
})
export class ReadModeModalComponent {

  @Input() title = '';
  @Input() data: any;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  downloadDoc(){
  }
  
  closeModal($event: MouseEvent) {
    this.activeModal.close({ action: 'close' })
  }
}
