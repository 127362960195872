
import { Component, ElementRef, ViewChild } from '@angular/core';
import { IAttachment } from './attachment.interface';
import { AlertService } from 'src/app/services/alert-service/alert.service';
@Component({
  selector: 'app-upload-files-components',
  templateUrl: './upload-files-components.component.html',
  styleUrls: ['./upload-files-components.component.scss']
})
export class UploadFilesComponentsComponent {
  files: IAttachment[] = [];
  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    private alertService: AlertService
  ) { }

  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    const maxTotalFileSizeInBytes = 20 * 1024 * 1024; // 20MB
    const allowedFormats = [".txt", ".doc", ".docx", ".pdf", ".csv", ".xlsx", ".png", ".jpg", ".jpeg", ".gif", ".bmp"];
    let totalFileSize = 0;
  
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        // Verify if the file has more than one extension
        const nameParts = file.name.split('.');
        const hasMultipleExtensions = nameParts.length > 2;
  
        if (!hasMultipleExtensions) {
          totalFileSize += file.size;
  
          // Verify if the total size of the files exceeds the maximum allowed size
          if (totalFileSize <= maxTotalFileSizeInBytes) {
            // Verify if the file format is allowed
            const fileExtension = '.' + file.name.split('.').pop();
            if (allowedFormats.includes(fileExtension.toLowerCase())) {
              this.files.push({ name: file.name, description: '', file });
            } else {
              // Manage the case where the file format is not allowed
              const message = `File format "${fileExtension}" is not allowed for "${file.name}".`;
              this.alertService.error(message, false);
              break; // Stop adding more files after finding a file with a disallowed format
            }
          } else {
            // Manage the case where the total size of the files exceeds the maximum allowed size
            const message = `Total size of selected files exceeds the maximum allowed size (20MB).`;
            this.alertService.error(message, false);
            break; // Stop adding more files after finding a file that exceeds the maximum allowed size
          }
        } else {
          // Manage the case where the file has multiple extensions
          const message = `File "${file.name}" is not allowed because it has multiple extensions.`;
          this.alertService.error(message, false);
          break; // Stop adding more files after finding a file with multiple extensions
        }
      }
    }
  }

  onChooseFiles(event: MouseEvent) {
    this.fileInput.nativeElement.click();
  }

  onFilesDropped(event: any) {
    // console.log('onFilesDropped', event);

    event.preventDefault();
    const selectedFiles = event.dataTransfer.files;
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        this.files.push({ name: file.name, description: '', file });
      }
    }
  }

  onDragOver(event: any) {
    // console.log('onDragOver', event);
    event.preventDefault();
  }


}
