<div class="modal__header">
  <h3 class="modal__title">{{ option | translate | titlecase }}</h3>
  <button
    (click)="closeModal($event)"
    aria-label="Close"
    class="modal__close"
    type="button"
  ></button>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="userForm">
  <div class="modal__body">
    <div class="modal__row modal__row--flex">
      <div>
        <mat-form-field class="w-100" [ngClass]="{ 'user-error': !this.userForm.controls['username'].valid && this.userForm.controls['username'].dirty && this.userForm.controls['username'].value }">
          <mat-label>{{'components.modal.user.user' | translate : 'user'}}</mat-label>
          <input
            matInput
            formControlName="username"
            id="username"
            name="username"
            required
          />
        </mat-form-field>
        <div *ngIf="!this.userForm.controls['username'].valid && this.userForm.controls['username'].dirty && this.userForm.controls['username'].value" class="error-message">
          {{'components.modal.user.hint.username' | translate : "Only letters and '-', '_' are allowed for this field" }}
        </div>
      </div>

      <div>
        <mat-form-field class="w-100" [ngClass]="{'email-exists': userForm.get('email')?.errors?.['emailExists'] || userForm.get('email')?.errors?.['invalidEmail']}">
          <mat-label>{{
            'components.modal.user.email' | translate : 'email'
            }}</mat-label>
          <input
            matInput
            formControlName="email"
            id="email"
            name="email"
            type="email"
            required
            email
          />
        </mat-form-field>
        <div *ngIf="userForm.get('email')?.errors?.['invalidEmail'] && userForm.controls['email'].dirty" class="error-message">
          {{'components.modal.user.warningEmail' | translate : 'Incorrect Email format: example@example.com'}}
        </div>
        <div *ngIf="userForm.get('email')?.errors?.['emailExists']" class="error-message">
          {{ 'components.modal.user.emailExists' | translate }}
        </div>
      </div>

      <mat-form-field [matTooltip]="userForm.get('roleId')?.disabled ? 'Enter a valid email to enable this dropdown' : ''">
        <mat-label>{{'User Role'}}</mat-label>
        <mat-select formControlName="roleId">
          <mat-option *ngFor="let role of roleArray" [value]="role">
            {{ role }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="modal__row modal__row--flex" *ngIf="!isExternalProvider">
      <mat-form-field>
        <mat-label>
          {{ 'components.modal.user.sector' | translate : 'sector' }}
        </mat-label>
        <mat-select formControlName="sector" [required]="!isExternalProvider">
          <mat-option
            *ngFor="let option of sectorOptions"
            [value]="option.name"
            >{{ option.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{ 'components.modal.user.serviceLine' | translate : 'serviceLine' }}
        </mat-label>
        <mat-select
          (selectionChange)="onChangeServiceLine($event)"
          formControlName="serviceLine"
          [required]="!isExternalProvider"
        >
          <mat-option
            *ngFor="let option of serviceLineOptions"
            [value]="option.name"
            >{{ option.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{
            'components.modal.user.subServiceLine'
              | translate : 'subServiceLine'
          }}
        </mat-label>
        <mat-select
          formControlName="subServiceLine"
          [required]="!isExternalProvider"
        >
          <mat-option
            *ngFor="let option of subServiceLineOptions"
            [value]="option.name"
            >{{ option.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'Country'}}</mat-label>
        <input
          type="text"
          matInput
          formControlName="country"
          id="country"
          name="country"
          type="country"
          [matAutocomplete]="auto"
          [required]="!isExternalProvider"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let country of filteredCountries | async"
            [value]="country"
          >
            {{ country }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{ 'components.modal.user.region' | translate : 'EY Region' }}
        </mat-label>
        <mat-select formControlName="region" [required]="!isExternalProvider">
          <mat-option
            *ngFor="let option of regionOptions"
            [value]="option.name"
            >{{ option.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="modal__row modal__row--flex centered"
      *ngIf="isEYContributor || isExternalProvider"
    >
      <mat-form-field class="custom-chip-row" appearance="fill">
        <mat-label>{{
          'components.modal.user.providers'
            | translate : 'Select the player(s) this user will be able to edit'
        }}</mat-label>
        <mat-chip-grid #chipGrid aria-label="Players selection">
          <mat-chip-row
            *ngFor="let provider of selectedProviders"
            (removed)="onRemove(provider)"
          >
            {{ provider }}
            <button matChipRemove [attr.aria-label]="'remove ' + provider">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input
          placeholder="{{
            'component.modal.user.provider.placeholder'
              | translate : 'Assign players to user'
          }}"
          #providersInput
          [formControl]="providerCtrl"
          [matChipInputFor]="chipGrid"
          [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="onAdd($event)"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onSelected($event)"
        >
          <mat-option
            *ngFor="let fruit of filteredProviders | async"
            [value]="fruit"
          >
            {{ fruit }}
          </mat-option>
        </mat-autocomplete>
        <mat-hint class="hint" *ngIf="noProvidersSelected()">
          {{
            'components.modal.user.hint.noProvidersSelected'
              | translate : 'Warning: No Player Selected'
          }}
          <mat-icon class="warn-icon">warning</mat-icon>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="modal__footer">
    <button
      class="pagehead__secondary-button"
      type="button"
      (click)="closeModal($event)"
    >
      {{ 'components.modal.common.cancel' | translate }}
    </button>
    <button
      class="pagehead__primary-button"
      [ngClass]="{ 'pagehead__primary-button--disabled': !userForm.valid || noProvidersSelected() }"
      type="submit"
      [disabled]="!userForm.valid || noProvidersSelected()"
    >
      {{ options.action | translate }}
    </button>
  </div>
</form>
