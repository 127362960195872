import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, forkJoin, tap } from 'rxjs';
import { ATTRIBUTE_TEMPLATE_TOOLTIPS } from 'src/app/models/constants.model';
import { Product } from 'src/app/models/product.model';
import { DataValidationService } from 'src/app/services/data-validation/data-validation.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-product-comparison-tool',
  templateUrl: './product-comparison-tool.component.html',
  styleUrls: ['./product-comparison-tool.component.scss']
})
export class ProductComparisonToolComponent implements OnInit, OnDestroy{
  @ViewChildren('fieldValueBoxRow') fieldValueBoxRow?: QueryList<ElementRef>;
  @ViewChildren('field') field?: QueryList<ElementRef>;
  tooltips= ATTRIBUTE_TEMPLATE_TOOLTIPS;

  // these two are for the boxes with the inputs
  fields: any[] = [];
  products: any[] = [{}, {}];

  // these two are for the values inside the cells
  fieldValues: any[] = [];
  productValues: any[] = [{}, {}];

  // variables for mat-autocomplete inputs
  productsData: any[] = [];
  listOfCategoriesWithValues: any[] = [];

  // a copy of the products to be able to pick the values from there
  wholeProducts: any[] = [];

  validationFields: any;

  fieldSelect = new FormControl('');
  productSelect = new FormControl('');

  loading: boolean = true;
  buttonClickedStates = new Map<number, boolean>();
  expandedStates: Map<string, boolean> = new Map();

  private combinedSubscription: Subscription = new Subscription();
  private prodCharSub: Subscription = new Subscription();

  // for the expand arrow in the value boxes
  toggle(fieldIndex: number): void {
    const buttonStatus: boolean =  this.buttonClickedStates.get(fieldIndex) || false;
    this.buttonClickedStates.set(fieldIndex, !buttonStatus);
    this.products.forEach((_, productIndex: number):  void => {
      const key: string = `${productIndex}-${fieldIndex}`;
      const currentState: boolean = this.expandedStates.get(key) || false;
      this.expandedStates.set(key, !currentState);
    });

    this.cdRef.detectChanges();
    this.measureHeights();
  }

  isExpanded(productIndex: number, fieldIndex: number): boolean {
    const key = `${productIndex}-${fieldIndex}`;
    return this.expandedStates.get(key) || false;
  }

  constructor(
    private productService: ProductService,
    private cdRef: ChangeDetectorRef,
    private dataValidationService: DataValidationService
  ){}

  ngOnInit():void {
    this.getProducts();
    this.measureHeights();
  }

  private measureHeights(): void {
    this.fieldValueBoxRow!.forEach((fieldValueBoxRef, index): void => {
      const height = fieldValueBoxRef.nativeElement.offsetHeight;
      const fieldElementRef = this.field?.get(index);

      if (fieldElementRef && fieldElementRef.nativeElement) {
        fieldElementRef.nativeElement.style.height = `${height}px`;
      }
    });
  }

  public isTextOverflow(productIndex: number): boolean {
    const fieldValueBoxRef = this.fieldValueBoxRow?.get(productIndex);
    if (!fieldValueBoxRef) {
      return false;
    }
    const checkOverflow = (element: HTMLElement): boolean => {
      if (element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight) {
        return true;
      }
      for (const child of Array.from(element.children) as HTMLElement[]) {
        if (checkOverflow(child)) {
          return true;
        }
      }
      return false;
    };
    return checkOverflow(fieldValueBoxRef.nativeElement);
  }

  // field logic related functions
  addField(){
    const field = {};
    this.fields.push(field);
    this.fieldValues.push(field);
  }

  removeField(index: number){
    this.fields.splice(index, 1);
    this.fieldValues.splice(index, 1);
    this.cdRef.detectChanges();
    this.measureHeights();
  }

  // product logic related functions
  addProduct(){
    const product: any = {};
    this.products.push(product);
    this.productValues.push(product);
    const productIndex = this.products.length - 1;
    for (let i = 0; i < this.fields.length; i++) {
      const key = `${productIndex}-${i}`;
      const k = `${productIndex-1}-${i}`;
      const currentState = this.expandedStates.get(k);
      this.expandedStates.set(key, currentState!);
    }
    this.cdRef.detectChanges();
    this.measureHeights();
  }

  removeProduct(index: number) {
    this.products.splice(index, 1);
    this.productValues.splice(index, 1);
    this.cdRef.detectChanges();
    this.measureHeights();
  }

  getProducts(){
    const products$ = this.productService.getProductsForComparisonTool$();
    const validation$ = this.dataValidationService.getProductDataValidation$();

    this.combinedSubscription.add(
      forkJoin([products$, validation$]).subscribe({
        next: ([products, validationData]) => {
          this.wholeProducts = products;
          this.productsData = this.mapProviderProducts(products);

          this.validationFields = validationData.fields;
          this.listOfCategoriesWithValues = this.mapFields(this.validationFields);

          this.loading = false;
          this.setDefaultFields();
        },
        error: () => {}
      })
    );
  }
  private setExpandedStates() {
    for (let i = 0; i < this.fields.length; i++) {
      for (let j = 0; j < this.products.length; j++) {
        const key = `${j}-${i}`;
        this.expandedStates.set(key, false);
      }
    }
  }

  setDefaultFields(): void {
    const defaultFieldNames = ["Offering Type", "Use Cases", "Typical Client Sectors"];
    this.listOfCategoriesWithValues.forEach((field: { items: string[] }) => {
      field.items.forEach((item: string) => {
        if (defaultFieldNames.includes(item)) {
          this.fields.push(item);
          this.fieldValues.push({name: item});
          this.fieldSelect.setValue(item);
        }
      });
    });
    this.setExpandedStates();
  }

  mapProviderProducts(products: Product[]) {
    const groupedProducts: any = {};

    products.forEach(product => {
      const providerName: string | any = product.provider.name;

      if (groupedProducts[providerName]) {
        groupedProducts[providerName].items.push(product.name);
      } else {
        groupedProducts[providerName] = { header: providerName, items: [product.name] };
      }
    });

    return Object.values(groupedProducts);
  }

  mapFields(fields: any[]) {
    const groupedFields = fields.reduce((result, field) => {
      const groupName = field.group_of_offering_characteristics;

      const groupEntry = result.find((entry: any) => entry.header === groupName);

      const fieldName = field.characteristic_name;

      if (groupEntry) {
        groupEntry.items.push(fieldName);
      } else {
        if(groupName !== "not applicable"){
          result.push({ header: groupName, items: [fieldName] });
        }
      }

      return result;
    }, []);

    return groupedFields;
  }

  public mapTitle(title: string): string {
    title = title.replace(/^_+|_+$/g, '').toLowerCase();

    let words = title.split('_').map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    const result = words.join(' ');

    return result;
  }

  onAddFieldAutocomplete(itemAdded: any, index: number) {
    const fieldName: string = itemAdded;
    this.fieldValues[index] = { name: fieldName };
    this.cdRef.detectChanges();
    this.measureHeights();
  }

  onAddProductAutocomplete(itemAdded: any, index: number) {    
    const product = this.wholeProducts.find(prod => prod.name === itemAdded.value);
    const productName = itemAdded.value;
    const providerName = itemAdded.group?.label;
    this.productValues[index] = { name: productName, provider: providerName, isLoading: true };
    
    this.prodCharSub = this.productService.getProductCharacteristicsForComparison$(product.id).pipe(
      tap((prod: any) => product['categories'] = prod.categories ),
      tap(_ => {
        this.productValues[index] = { name: productName, provider: providerName, isLoading: false };
        this.cdRef.detectChanges();
        this.measureHeights();
      })
    ).subscribe();
  }

  deleteProduct(index: number): void {
    this.productValues[index] = {  provider: '' };
    this.cdRef.detectChanges();
    this.measureHeights();
  }

  getProductFieldValue(productName: string, fieldName: string) {
    let value: any;
    fieldName = fieldName?.replace(/&/g, 'and');

    if(!productName || productName === ""){
      value = '';
    }else if(!fieldName || fieldName === ""){
      value = '';
    }else{
      value = 'No value available';
      for (const product of this.wholeProducts) {
        if (product.name === productName) {
          if(product.categories || product.categories?.length > 0){
            for (const category of product?.categories) {
              for (const field of category.fields) {
                if (field.name === fieldName) {
                  if(field.value === "" || field.value === null){
                      value = 'No value available';
                  } else {
                    value = field.value;
                  }
                }
              }
            }
          }else {
            continue;
          }
        }
      }
    }
    return value;
  }

  ngOnDestroy(){
    if(this.combinedSubscription){
      this.combinedSubscription.unsubscribe();
    }
    if(this.prodCharSub){
      this.prodCharSub.unsubscribe();
    }
  }

}
