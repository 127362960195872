import { PageModel } from 'src/app/services/api/pagination-service/model/page.model';
import { ApiService } from 'src/app/services/api/api-service/api.service';
import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs';
import { ProviderModel } from 'src/app/models/provider.model';
import { UtilsService } from '../../utils-service/utils.service';
import { Product } from 'src/app/models/product.model';
import { Note } from 'src/app/models/note.model';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';
import { UploadFilesService } from '../../upload-files/upload-files.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

/**
 * TODO: This class should and will represent a wrap from a collection of providers,
 * it an abstraction from the source of the information. If it is API, DB, Local or Mock
 * TODO: Define a interface
 */
@Injectable({
  providedIn: 'root',
})
export class ProviderCollectionService {

  // TODO: This can be extracted from Model.constructor.name
  readonly modelName = 'provider';
  constructor(
    public apiService: ApiService,
    public authService: AuthenticationService,
    protected utils: UtilsService,
    public uploadFilesService: UploadFilesService,
    private http: HttpClient) { }

  /**
   * Get list of providers including its pagination
   * @param params: It will specify query conditions to the general collection.
   * @returns a list of providers with the pagination
   */
  getProvidersWithPagination$(params: { [keyof: string]: any } = {}) {
    // TODO: Providers could be extract from constructor name of the model
    return this.apiService.get<PageModel<ProviderModel>>(
      this.modelName,
      params,
      ''
    );
  }

  // 642d83187a1d035ccdf011bb
  /**
   * list of products associated with a specific provider
   * @param id providerId
   * @param params filter, sort, etc
   * @returns
   */
  getProvidersProducts$(id: string, params: { [keyof: string]: any } = {}) {
    const slug = `${this.modelName}/${id}/products`;
    return this.apiService.get<Product[]>(
      slug,
      params,
      ''
    );
  }

  getProvider$(id: string, params: { [keyof: string]: any } = {}) {
    // TODO: Providers could be extract from constructor name of the model
    const slug = `${this.modelName}/${id}`;
    return this.apiService.get<ProviderModel>(
      slug,
      params,
      ''
    );
  }

  /**
   * Get list of providers including its pagination
   * @param params: It will specify query conditions to the general collection.
   * @returns a list of providers with the pagination
   */
  getProvidersWithoutPagination$(params: { [keyof: string]: any } = {}) {
    return this.getProvidersWithPagination$(params).pipe(
      map(result => result.data),
    );
  }

  getProviderCharacteristicsForComparison$(providerId: string){
    return this.apiService.get<ProviderModel>(`${this.modelName}/comparison/${providerId}`, {}, '');
  }

  /**
   * Delete a provider
   * @param provider
   * @returns
   */
  deleteProvider$(provider: ProviderModel) {
    return this.deleteManyProviders$([provider.id]);
  }

  updateAttachment$(providerId: string, attachmentId: any, attachmentName: string, attachments: any) {

    // If we removed the previous attachment and added a new one, we have to delete the first and upload the second one.
    if (attachments[0].changed){

      const {formData, errors} = this.uploadFilesService.createFormData(attachments);
      formData.append('name', attachmentName);
      //its taking a little too much time on this request
      const url: string = `${environment.apiUrl}${environment.apiVersion}`;
      const endpoint = `provider/${providerId}/editAttachment/${attachmentId}`;
      
      return this.http.post(`${url}${endpoint}`, formData)
    }
    //if we changed the attachment's name, attachmentName will have a value, otherwise it will be an empty string.
    else {
      if(attachmentName){
        const slug = `/provider/${providerId}/editAttachment/${attachmentId}`;
        return this.apiService.post<any>(slug, { description: attachments[0].description, name: attachmentName, formData: ''}, '');
      }
      else{
        const slug = `/provider/${providerId}/editAttachment/${attachmentId}`;
        return this.apiService.post<any>(slug, { description: attachments[0].description, name:'', formData: ''}, '');
      }
      
    }
    
  }

  downloadProviderAttachmentFromBlob(providerId: any, attachmentId: any) {
    const slug = `provider/${providerId}/downloadAttachments/${attachmentId}`

    return this.apiService.downloadFile(slug, {});
  }

  deleteProviderAttachments$(providerId: any, attachmentId: any[]) {
    const slug = `provider/${providerId}/deleteAttachments`

    return this.apiService.post(slug, { attachments : attachmentId }, '');
  }

  deleteProviderAttachment$(providerId: any, attachmentId: any) {
    const slug = `provider/${providerId}/deleteAttachment/${attachmentId}`

    return this.apiService.post(slug, {}, '');
  }

  /**
   * Delete bulk provider
   * @param providerIds
   * @returns
   */
  deleteManyProviders$(providerIds: string[]) {
    return this.apiService.post(
      `${this.modelName}/delete`,
      { ids: providerIds },
      'components.modal.delete.success'
    );
  }

  /**
   * Create a Provider
   * @param provider
   * @returns
   */
  createProvider$(provider: Omit<ProviderModel, 'id'> | any, providerDataValidations: any[]) {
    const user = this.authService.getLoggedInUser();

    providerDataValidations.forEach((validation: any) => {
      if(validation.front_name !== "playerName" && validation.front_name !== "playerDescription"){
        const key: string = validation.front_name;

        if(validation.data_type_number === 3){
          provider[key] = null;
        }else {
          provider[key] = "" as string;
        }
      }
    });

    // review these properties to determine if they are necessary or not
    provider.url = "";
    provider.otherTypeOfOrganization = null;
    provider.branchOfficeLocations = "N/A"
    provider.headquartersCity = "";
    provider.headquartersCountry = "";
    provider.headquartersLocation = "";
    provider.otherHeadquarterLocation = null;
    provider.numberOfClients = "";
    provider.otherProviderType = null;
    provider.slug = "";
    provider.offeringsBeyondESG = "";
    provider.contact = "";
    provider.flagDescription = "";
    provider.lastUpdatedBy = {
      userId: user.id,
      username: user.username,
    }

    // console.log("Desde el service: \n", provider);

    // also must assign the createdBy

    return this.getProvidersWithoutPagination$({ playerName: provider.playerName }).pipe(
      mergeMap((providers: ProviderModel[]) => {
        if (providers && providers.length > 0) {
          throw new Error('Player already present');
        }
        return this.apiService.post(
          `${this.modelName}`,
          JSON.stringify(provider),
          'components.modal.provider.createSuccessMsg'
        );
      })
    );
  }

  createNote$(providerId: string, note: Note): any {
    const user = this.authService.getLoggedInUser();
    note.provider = { providerId: providerId } as any;
    const slug = `note`;
    note.user = { userId: user.id, ...user } as any
    const requestNote$ = this.apiService.post<Note>(slug, note, 'Note created successfully');
    return requestNote$;
  }
  updateNote$(providerId: string, note: Note): any{
    const user = this.authService.getLoggedInUser();
    note.product = { providerId: providerId } as any;
    const slug = `note/`;
    note.user = { userId: user.id, ...user } as any
    const requestNote$ = this.apiService.put<Note>(slug, note.id, note, 'Note updated successfully');
    return requestNote$;
  }
  
  /**
   * Update a provider.
   * provider All attributes are optional except id
   * @param provider: provider to be updated
   * @returns
   */
  updateProvider$(provider: Partial<ProviderModel> & { id: string }) {
    const request$ = this.apiService.put(
      `${this.modelName}/`,
      provider.id,
      JSON.stringify(provider),
      'components.modal.provider.editSuccessMsg'
    );

    return request$;
  }

  updateProviderChangedFieldsOnly$(providerId: string, fields: any){
    const request$ = this.apiService.put(
      `${this.modelName}/single-fields/`,
      providerId,
      JSON.stringify(fields),
      'components.modal.provider.editSuccessMsg'
    );

    return request$;
  }

  getProviderNotesWithoutPagination$(id: string) {
    return this.getProvider$(id).pipe(
      map(p => p.notes),
    );
  }

  getProductsNotesFull$(id: string) {
    return this.getProvidersProducts$(id);
  }

  getProviderAttachmentsWithoutPagination$(id: string) {
    return this.getProvider$(id).pipe(
      map(p => p.attachments),
    );
  }

  deleteProviderttachment$(providerId: any, attachmentId: any) {
    const slug = `product/${providerId}/deleteAttachment/${attachmentId}`

    return this.apiService.post(slug, {}, '');
  }

  replaceNoteAttachment(attachments: any, noteId: string, attachmentId: string, attachmentName: string): any {

    const {formData, errors} = this.uploadFilesService.createFormData(attachments);
    formData.append('name', attachmentName);

    const url: string = `${environment.apiUrl}${environment.apiVersion}`;
    const endpoint = `note/${noteId}/replaceNoteAttachment/${attachmentId}`;
      
    return this.http.post(`${url}${endpoint}`, formData)
  }

  renameNoteAttachment$(noteId: string, attachmentId: string, attachmentName: string, newName: string): any {

    const url: string = `${environment.apiUrl}${environment.apiVersion}`;
    const endpoint = `note/${noteId}/renameNoteAttachment/${attachmentId}`;
      
    return this.http.post(`${url}${endpoint}`, {'oldName': attachmentName, 'newName': newName})
  }

  downloadProvidersForExportTool(providers: any[], characteristics: any[]){
    const slug: string = `${this.modelName}/export`;

    return this.apiService.post(slug, { providers, characteristics }, '')
  }
}
