<div class="filter-container">
    <div class="filter-heading-line">
        <div class="left">
            <h5>{{filter?.name}}</h5>
            <mat-icon class="refresh-icon" (click)="clear()">refresh</mat-icon>
            <span class="clear-text" (click)="clear()">Clear</span>
        </div>
        <div class="right">
            <mat-icon class="remove-icon" (click)="remove()">clear</mat-icon>
        </div>
    </div>

    <div class="options-grid" *ngIf="filter.type === 0">
        <div class="option" *ngFor="let option of filter?.options">
            <mat-checkbox [checked]="option.checked" [disabled]="option.disabled" (change)="checkOption(option)"></mat-checkbox>
            <span [title]="option.name" [ngClass]="{ 'disabled': option.disabled }">{{option.name}}</span>
        </div>
    </div>     

    <div *ngIf="filter.type === 4">
        <div class="" *ngFor="let option of filter?.options">
            <h5 class="option-title">{{option.name}}</h5>
            <div class="options-grid">
                <div class="option" *ngFor="let value of option.values">
                    <mat-checkbox [checked]="value.checked" [disabled]="value.disabled" (change)="checkOption(value)"></mat-checkbox>
                    <span [title]="value.name" [ngClass]="{ 'disabled': value.disabled }">{{value.name}}</span>
                </div>
            </div>
        </div>
    </div>               
</div>