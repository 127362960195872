import {Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators } from '@angular/forms';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { LicenseService } from 'src/app/services/license-service/license.service';
import { LicenseModel } from 'src/app/models/license.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';
import { DataValidationService } from 'src/app/services/data-validation/data-validation.service';
import { Product } from 'src/app/models/product.model';
import { Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-license-modal',
  templateUrl: './license-modal.component.html',
  styleUrls: ['./license-modal.component.scss']
})
export class LicenseModalComponent implements OnInit{
  @Input() data!: Product;
  option = '';
  action = 'create';
  myForm!: FormGroup;
  licenseOptions: LicenseModel[] | any;
  restrictionMode = false;
  loading: boolean = true;
  licenseSub!: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    protected alertService: AlertService,
    protected translate: TranslatePipe,
    protected licenseService: LicenseService,
    protected providerService: ProviderCollectionService,
    protected dataValidationService: DataValidationService,
    private formBuilder: FormBuilder
  ) {
    this.myForm = new FormGroup({
      licenseName: new FormControl('', [Validators.required]),
      restrictionName: new FormControl('', [Validators.required])
     })
  }

  ngOnInit() {
    if (this.data) {
      this.licenseSub = this.dataValidationService.getProductDataValidation$().pipe(
        tap(validation => this.licenseOptions = this.mapLicenseValues(validation) ),
        tap(_ => this.loading = false )
      ).subscribe();

      this.myForm = this.formBuilder.group({
        licenseName: new FormControl(this.data.license, [Validators.required]),
        restrictionName: new FormControl(this.data.restriction, [Validators.required]), 
      });
      
      // Apply subscription to detect changes on license select
      this.myForm.get('licenseName')?.valueChanges.subscribe((licenseName) => {
        this.myForm.get('restrictionName')?.reset();
        if (licenseName === 'Restricted License') {
          this.myForm.get('restrictionName')?.setValidators([Validators.required]);
        } else {
          this.myForm.get('restrictionName')?.clearValidators();
        }
        this.myForm.get('restrictionName')?.updateValueAndValidity();
      });
    }
  }

  onSubmit(){}

  update(){
    this.activeModal.close({ 
      action: 'update',  
      license: this.myForm.get('licenseName')?.value,
      restriction: this.myForm.get('restrictionName')?.value
    });
  }

  close() {
    this.activeModal.close({ action: 'close' });
  }

  mapLicenseValues(validation: any): { name: string }[] {
    const field = validation.fields.filter((field:any) => field.unique_ID === "ID_O_007")[0];
    const possibleValues = field.possible_values.split(/[;,]/).map((value: string)=> value.trim());
    const toret: { name: string }[] = [];

    possibleValues.forEach((value: string) => {
      toret.push({ name: value })
    })

    return toret;
  }

  ngOnDestroy(): void {
    if(this.licenseSub){
      this.licenseSub.unsubscribe();
    }
  }

}


