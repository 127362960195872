import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductEditorControl } from 'src/app/models/product-editor-control.model';
import { ProductToExcelService } from 'src/app/services/product-to-excel/product-to-excel.service';

@Component({
  selector: 'app-product-editor-control',
  templateUrl: './product-editor-control.component.html',
  styleUrls: ['./product-editor-control.component.scss']
})
export class ProductEditorControlComponent {
  @Input() productName!: string;
  @Input() providerName!: string;
  @Input() editorControl!: ProductEditorControl;
  @Input() categories!: any[];

  loading: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
    private productToExcel: ProductToExcelService
  ){}

  ngOnInit(): void {
    if(this.productName && this.providerName && this.editorControl){
      this.loading = false;
    }
  }

  discardMyChanges() {
    this.activeModal.close({
      action: 'discard'
    });
  }

  saveMyChanges() {
    this.activeModal.close({
      action: 'save'
    });
  }

  downloadMyChanges(){
    this.productToExcel.downloadExcel(this.categories, `${this.productName} Current Changes`);
    this.activeModal.close({
      action: 'download'
    });
  }
}
