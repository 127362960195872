import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, SimpleChanges, OnInit } from '@angular/core';
import { AppliedFilterInfo } from '../advance-filters.interfaces';
import { Subscription } from 'rxjs';
import { MarketDiscoveryService } from 'src/app/services/market-discovery/market-discovery.service';

@Component({
  selector: 'app-advance-filter-slider',
  templateUrl: './advance-filter-slider.component.html',
  styleUrls: ['./advance-filter-slider.component.scss']
})
export class AdvanceFilterSliderComponent implements OnInit, AfterViewInit{

  disabledSub: Subscription = new Subscription();

  @Input() filter!: any
  @Output() onSliderValueEmitter = new EventEmitter<AppliedFilterInfo>();
  @Output() emitRemoval = new EventEmitter<AppliedFilterInfo>();

  @ViewChild('minInput') minInput!: ElementRef<HTMLInputElement>;
  @ViewChild('maxInput') maxInput!:  ElementRef<HTMLInputElement>;

  // evaluate this two
  minSliderValue: number = 0;
  maxSliderValue: number = 100;
  
  minLabel: number = 0;
  maxLabel: number = 100;

  // these two "globals" will be used as "constants" that will guide
  // us in the validations
  globalMin: number = 0;
  globalMax: number = 0;

  // validation flags
  validMinRange: boolean = true;
  validMaxRange: boolean = true;

  minRangeValidMessage: string = '';
  maxRangeValidMessage: string = '';
  tempExisting: any;
  
  disabled: boolean = false;
  disabledTitle: string = "";

  formatLabel(value: number): string{
    if (value === null || value === undefined) {
      return "0";
    }
    return value.toLocaleString('en-US').replace(/,/g, '.');
  }

  constructor(
    private marketDiscoveryService: MarketDiscoveryService
  ){}
  
  ngOnInit(){
    this.filter = this.restructureFilter(this.filter);
    this.disabledSub = this.marketDiscoveryService.getNumericDisabled().subscribe((res: any) => {
      if(res.name === this.filter.name){
        this.disabled = res.disabled;
        this.disabledTitle = this.disabled ? "There are no applicable values for this filter based on your selected criteria." : "";
      }
    })
  }

  ngAfterViewInit(): void {
    this.applyInputs()
    // this.emitSliderChange(); // If uncommented this line will apply the filter without touching it
  }

  applyInputs(){
    if(this.tempExisting){
      if(this.tempExisting.hadMinInput){
        this.minInput.nativeElement.value = `${this.minSliderValue}`;
      }

      if(this.tempExisting.hadMaxInput){
        this.maxInput.nativeElement.value = `${this.maxSliderValue}`;
      }
    }
  }
  
  restructureFilter(filter: any){
    const guideOption = filter.options.find((option: any) => option.name === "guide");
    const existingOption = filter.options.find((option: any) => option.name !== "guide");
    
    // apply the global min and max for validations
    this.globalMin = guideOption.min;
    this.globalMax = guideOption.max;
    this.disabled = guideOption.disabled;
    this.disabledTitle = this.disabled ? "There are no applicable values for this filter based on your selected criteria." : "";

    if(existingOption){
      this.tempExisting = existingOption
      this.minSliderValue = Number(existingOption.name.split(/[-]/)[0]);
      this.maxSliderValue = Number(existingOption.name.split(/[-]/)[1]);
      //labels
      this.minLabel = existingOption.minLabel;
      this.maxLabel = existingOption.maxLabel;
      
    } else { 
      this.minSliderValue = guideOption.min;
      this.maxSliderValue = guideOption.max;
      //labels
      this.minLabel = guideOption.min;
      this.maxLabel = guideOption.max;
    }

    // Restructure the object
    const updatedFilter = {
        name: filter.name,
        applied: filter.applied,
        type: filter.type,
        options: [], 
    };

    return updatedFilter;
  }

  minSliderChange(event: any) {
    this.minSliderValue = Number(event.target.value);
    this.emitSliderChange();
  }

  maxSliderChange(event: any) {
    this.maxSliderValue = Number(event.target.value);
    this.emitSliderChange();
  }

  emitSliderChange(clear: boolean = false){
    // convert to a string with mix-max values to make a between search
    const valueToSend: string = `${this.minSliderValue}-${this.maxSliderValue}`;
    let toEmit: AppliedFilterInfo | any = {};

    if(clear){
      toEmit = {
        name: this.filter.name,
        type: this.filter.type,
        options: [],
      } 
    } else {
      toEmit = {
        name: this.filter.name,
        type: this.filter.type,
        options: [{
          name: valueToSend, // this in this case because the service expects the value in the name
          selected: true,
          // helpers to apply values on re-opening the modal
          minLabel: this.minLabel,
          maxLabel: this.maxLabel,
          hadMinInput:  this.minInput.nativeElement.value !== '',
          hadMaxInput: this.maxInput.nativeElement.value !== ''
        }],
      } 
    }

    this.onSliderValueEmitter.emit(toEmit);
}

  validateMinRangeInput(event: any){
    let minRange = Number(event.target.value);
    const message = `Min Value must be between ${this.globalMin} and ${this.globalMax - 1}`;
    const crossMessage = `Min Value must be less than Max Value`;

    if(minRange === 0){
      this.minRangeValidMessage = '';
      this.validMinRange = true;    
    } else if (minRange >= this.globalMax || minRange < this.globalMin){
      this.minRangeValidMessage = message;
      this.validMinRange = false;
    } else if(minRange >= this.maxSliderValue){
      this.minRangeValidMessage = crossMessage;
      this.validMinRange = false;
    } else {
      this.minRangeValidMessage = '';
      this.validMinRange = true;
    }
  }

  updateMinRange(event: any){
    let minRange = Number(event.target.value);

    if(minRange < this.globalMax && minRange >= this.globalMin && this.validMinRange){
      this.minSliderValue = minRange;
      this.emitSliderChange();
    }
  }
  
  validateMaxRangeInput(event: any){
    let maxRange = Number(event.target.value);
    const message = `Max Value must be between ${this.globalMin + 1} and ${this.globalMax}`;
    const crossMessage = `Max Value must be greater than Min Value`;

    if(maxRange === 0){
      this.maxRangeValidMessage = '';
      this.validMaxRange = true;      
    } else if(maxRange <= this.globalMin || maxRange > this.globalMax){
      this.maxRangeValidMessage = message;
      this.validMaxRange = false;
    } else if(maxRange <= this.minSliderValue){
      this.maxRangeValidMessage = crossMessage;
      this.validMaxRange = false;
    } else {
      this.maxRangeValidMessage = '';
      this.validMaxRange = true;
    }
  }

  updateMaxRange(event: any){
    let maxRange = Number(event.target.value);

    if(maxRange !== 0 && maxRange > this.globalMin && maxRange <= this.globalMax && this.validMaxRange){
      this.maxSliderValue = maxRange;
      this.emitSliderChange();
    }
  }

  clear(){    
    this.minInput.nativeElement.value = '';
    this.maxInput.nativeElement.value = '';

    this.minSliderValue = this.globalMin;
    this.maxSliderValue = this.globalMax;

    this.disabled = false;
    this.emitSliderChange(true);
  }

  remove(){
    this.clear();
    this.emitRemoval.emit();
  }

  ngOnDestroy():void {
    if(this.disabledSub){
      this.disabledSub.unsubscribe();
    }
  }
}
