import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, filter, tap, mergeMap, Subscription, Subject } from 'rxjs';
import { ColumnBuilderService } from 'src/app/components/responsive-table/column-builder/column-builder.service';
import { SettingTableType, TableAction } from 'src/app/components/responsive-table/model/setting-table.model';
import { Product } from 'src/app/models/product.model';
import { PageModel } from 'src/app/services/api/pagination-service/model/page.model';
import { PaginationModel } from 'src/app/services/api/pagination-service/model/pagination.model';
import { GenerateCrudModalFormService } from 'src/app/services/generate-crud-modal-form/generate-crud-modal-form.service';
import { ProductService } from 'src/app/services/product/product.service';
import { RoutingInfoService } from 'src/app/services/routing-info/routing-info.service';
import { UploadFilesService } from 'src/app/services/upload-files/upload-files.service';
import { LicenseModalComponent } from '../modals/license-modal/license-modal.component';
import { LicenseModel } from 'src/app/models/license.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { DataValidationService } from 'src/app/services/data-validation/data-validation.service';
import { PaginationService } from 'src/app/services/api/pagination-service/pagination.service';
import { ApiService } from 'src/app/services/api/api-service/api.service';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { AutocompleteUtilsService } from 'src/app/services/autocomplete-utils/autocomplete-utils.service';

@Component({
  selector: 'app-license-management-table',
  templateUrl: './license-management-table.component.html',
  styleUrls: ['./license-management-table.component.scss']
})
export class LicenseManagementTableComponent implements OnInit {
  totalItems = 0;
  loading = true;
  backgroundLoading = true;
  totalProducts?: number;
  productTableRows?: PageModel<Product>;
  productPaginationConfiguration = PaginationModel.createDefaultConfiguration('');
  productTableColumnConfiguration: { header: string; name: keyof Product; class: string; orderBy?: string | undefined; }[] = [];
  settingTable = new SettingTableType<Product>();
  licenseSub!: Subscription;

  constructor(
    protected dataValidationService: DataValidationService,
    private paginationService: PaginationService,
    public columnBuilder: ColumnBuilderService, // Configure the columns of your table
    public productService: ProductService,
    public providerService: ProviderCollectionService,
    protected modalService: NgbModal,
    public modalCreator: GenerateCrudModalFormService,
    public routerInfo: RoutingInfoService,
    public uploadFilesService: UploadFilesService,
    private apiService: ApiService,
    protected autocompleteUtilsService: AutocompleteUtilsService,
    public alertService: AlertService
  ) {}
  paginationType: string = 'product';
  pagination?: PaginationModel;
  showFilters: boolean = false;
  filters: any = [];
  labelFilters: any[] = [];
  licenseList: LicenseModel[] = [];
  productsList: Product[] = [];
  providersList: ProviderModel[] = [];
  rows?: PageModel<Product>;
  //Property to emit filter to delete in the validations component
  filterEvent: Subject<string> = new Subject<string>();
  fields: [ { name: keyof Product, type: string, data?: any} ] | any;

  ngOnInit(): void {
    this.pagination = this.paginationService.getDefaultPagination('license');
    this.setProductTableColumConfiguration();
    this.setSettingsTable();
    this.getProducts();
    this.fillFields();
  }

  public toggleShowFilters(event: MouseEvent) {
    this.showFilters = !this.showFilters;
  }

  
  public onBackgroundLoadingChange(isLoadingInBackground: boolean) {
    this.backgroundLoading = isLoadingInBackground;
  }

  onSelected($event: Event){}
  selectedItems: Product[] = [];

  onSelectedItems(selectedItems: Product[]) {
    this.selectedItems = selectedItems;
  }

  /**
   * Load modal to assign a user for review the selected validations
   * @param filters
  */
  public onSearch(filters: any) {

    const previous = this.filters;

    this.filters = {
      name:
        filters['name'] !== undefined &&
        filters['name'] !== null ?
        filters['name'] :
        '',
      playerName:
        filters['playerName'] !== undefined &&
        filters['playerName'] !== null ?
        filters['playerName'] :
        '',
      license:
        filters['license'] !== undefined &&
        filters['license'] !== null ?
        filters['license'] :
        ''
    };

    //if at least we have a value when applying or clearing let's put the loader
    if(Object.values(this.filters).some(value => value !== null && value !== '') || Object.values(previous).some(value => value !== null && value !== '')){
      this.loading = true;
    }

    this.getDataFromDatabase();
  }

  /**
   * Function to set the params to
   * pass to the API call depending
   * on the parent component
   * TODO ADD IN reviwers projecstids: this.projectsIds, page_size: 5
   */
    private getParams() {
      switch (this.paginationType) {
        case 'product':
          return { ...this.pagination, ...this.filters, complete: true };
        case 'reviewers':
          return {
            ...this.pagination,
            ...this.filters,
            complete: true,
            page_size: 10
          };
        default:
          return { ...this.filters, complete: true, page_size: 10 };
      }
    }

    private getDataFromDatabase() {
      const params = this.getParams();
      const getProducts$ = this.apiService.get<PageModel<Product>>('product/license', params, '');

      const assignProductToTable$ = getProducts$.pipe(
        tap(data => {
          this.productsList = data.data;
          this.providersList = Array.from(new Set(this.productsList.map(product => JSON.stringify(product.provider))) ).map(providerString => JSON.parse(providerString));
          this.rows = data;
          this.productTableRows = data;
          this.totalProducts = data.totalElements;
          this.totalItems = data.totalElements;
          this.productTableRows.pageSize = 9999;
          this.fillFields();
        }));
        
        // pagination related observable
        const assignToPagination$ = assignProductToTable$.pipe(
          tap(data => {
            this.paginationService.setPage<Product>('products', data);
            const paginationObj = PaginationModel.createDefaultConfiguration('', 0, 9999, this.productPaginationConfiguration.page);
            this.pagination = this.paginationService.setPagination('products', paginationObj);
            // and after that we set our loaders to false
            this.loading = false;
            this.backgroundLoading = false;
        })
      );

      assignToPagination$.subscribe();
    }

  fillFields(){
    this.providersList.map(provider => provider.playerName = provider.name || ''); 
    
    this.licenseSub = this.dataValidationService.getProductDataValidation$().pipe(
      tap(validation => this.licenseList = this.mapLicenseValues(validation))
    ).subscribe()
    
    this.fields = [
      { name: "name", type: "autocomplete", data: this.autocompleteUtilsService.convertProductsToTreeGroup(this.productsList)},
      { name: "playerName", type: "autocomplete", data: this.autocompleteUtilsService.convertProvidersToTreeGroup(this.providersList)},
      { name: "license", type: "dropdown", data: this.licenseList}, 
    ]
  }

  setProductTableColumConfiguration() {
    // TODO: This should be a dictionary
    const confColumns = this.columnBuilder.configureColumns<Product>(Product, Product.getEmptyProduct());
    // Convert from dict to list
    this.productTableColumnConfiguration = this.columnBuilder.getTableColumnConfiguration<Product>(
      confColumns,
      ['id', 'data', 'categories', 'description', 'typeOfEsgData', 'lastUpdated', 'lastUpdatedBy', 'status', 'flag', 'percentageOfFilledFields', 'responsable' , 'eyResponsible', 'attributes', 'notes', 'restriction'],
      ['name', 'provider', 'license'])
      //add column of provider, license and name
    for (const col of this.productTableColumnConfiguration) {
      const customCol = col as any;
      switch (col.name) {
        case "name":
          customCol.type = 'ellipsis-link';
          customCol.class = 'pop-up'
          customCol.clickLink = (product: Product) => { this.updateLicense(product); }
          break;
        case "provider":
          customCol.second = "name";
          break;
        case 'license':
          customCol.orderBy = null;
          customCol.type = 'hover-info-restriction';
          break;
      }
      // Add translation to headers
      col.header = `page.expert.table.product.column.${col.header}`;
    }
  }

  /**
   * When pagination is going to start a change
   * @param pageToChange: page to change
   * @param resetSelected: maintain or not the current selection
  */
  onTablePageChange([pageToChange, resetSelected]: [number, boolean]) {
    this.productPaginationConfiguration.page = pageToChange;
  }

  /**
   * When click on sort a field
   * @param orderBy: fieldName that is going to be orderBy
   * @param resetSelected:
   */
  onTableSort([orderBy, resetSelected]: [string, boolean]) {
    this.productPaginationConfiguration.orderBy = orderBy;
    this.productPaginationConfiguration.page = 1;
  }

  updateLicense(product: Product) {
    const modalResponse$ = this.modalCreator.createClassicModalWithInjectedComponent<LicenseModel>(
      LicenseModalComponent,
      'Update EY License',
      'noshow'
    );

    const aSubscription: Subscription = modalResponse$.shown.pipe(
      tap(_ => modalResponse$.componentInstance.componentInjectedInstance.data = product),
      tap(_ => modalResponse$.componentInstance.componentInjectedInstance.ngOnInit()),
      tap(_=> this.modalCreator.formSub.next(modalResponse$.componentInstance.componentRef.instance.myForm) ),
    ).subscribe(_ => aSubscription.unsubscribe());

    from(modalResponse$.result).pipe(
      filter(r => r.action !== 'close'),
      mergeMap(r => this.productService.updateLicense$(product, r.license, r.restriction)),
      tap(_ => this.refresh())
    ).subscribe({
      next: (data) => {
        aSubscription.unsubscribe();
      },
      error: (error) =>{
        const keepAlertOnNavigate = false;
        this.alertService.error('components.modal.product.editErrorMsgLicense', keepAlertOnNavigate);
        aSubscription.unsubscribe();
      }
    });
  }

  refresh(): void {
    this.getProducts();
  }

  setSettingsTable() {
    // Following src/app/components/responsive-table/responsive-table/README.md:68 these are the common attributes
    this.settingTable.dataId = 'id';
    this.settingTable.hasSelect = false;
    this.settingTable.getDataFromDatabase = this.getProducts.bind(this); // pass the name of the function that make the call to the api.

    this.settingTable.actionsOnResponsive = [
      new TableAction(
        'edit',
        () => console.log('show a modal to edit item selected. E.g: onEditUser in UserTableComponent'), // this.onEditUser.bind(this),
        'fa fa-edit'
      )];

    this.settingTable.responsiveTitle.label = 'username';
  }

  getProducts(params: { [keyof: string]: any } = {}) {
    this.loading = true;
    this.backgroundLoading = true;
    this.getDataFromDatabase();// TODO: Manage unsubscription
  }

  public onLoadingChange(isLoading: boolean) {
    this.loading = isLoading;
  }

  mapLicenseValues(validation: any): { name: string }[] {
    const field = validation.fields.filter((field:any) => field.unique_ID === "ID_O_007")[0];
    const possibleValues = field.possible_values.split(/[;,]/).map((value: string)=> value.trim());
    const toret: { name: string }[] = [];

    possibleValues.forEach((value: string) => {
      toret.push({ name: value })
    })

    return toret;
  }

  ngOnDestroy(): void {
    if(this.licenseSub){
      this.licenseSub.unsubscribe();
    }
  }

}