import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PipeModule } from 'src/app/pipes/pipe.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { AdditiveFiltersComponent } from './additive-filters/additive-filters.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { AdvancedFiltersComponent } from './advanced-filters/advanced-filters.component';
import { AdvanceFilterChipsComponent } from './advance-filter-chips/advance-filter-chips.component';
import { AdvanceFilterCheckboxesComponent } from './advance-filter-checkboxes/advance-filter-checkboxes.component';
import { AdvanceFilterSliderComponent } from './advance-filter-slider/advance-filter-slider.component';
import { ProductCardListComponent } from './product-card-list/product-card-list.component';
import { ProviderCollapsibleListComponent } from './provider-collapsible-list/provider-collapsible-list.component';

@NgModule({
  declarations: [
    AdditiveFiltersComponent,
    AdvancedFiltersComponent,
    AdvanceFilterChipsComponent,
    AdvanceFilterCheckboxesComponent,
    AdvanceFilterSliderComponent,
    ProductCardListComponent,
    ProviderCollapsibleListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    PipeModule,
    MatChipsModule,
    MatAutocompleteModule,
    AutocompleteModule,
    MatCheckboxModule,
    MatSliderModule,
    MatTooltipModule,
  ],
  exports: [
    AdditiveFiltersComponent,
    ProductCardListComponent,
    ProviderCollapsibleListComponent
  ],
})
export class ProviderProductFilterModule { }
