import { Injectable } from '@angular/core';
import { ILoginProviderService } from './login-provider.interface';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { UserModel } from 'src/app/models/user.model';
import { Observable, filter, map, mergeMap, tap } from 'rxjs';
import { UserService } from 'src/app/services/user-service/user.service';
import { RoleService } from 'src/app/services/role-service/role.service';

@Injectable({
  providedIn: 'root'
})
export class LoginProviderService implements ILoginProviderService {

  // TODO: LoginProviderService should be an injection of a custom implementation
  // TODO: It shouldn't be a AccountInfo it should be a mapping to our model
  protected __activeAccount: AccountInfo | null = null;
  isALogginEvent(eventType:EventType){
    return eventType === EventType.LOGIN_SUCCESS || eventType === EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS || eventType === EventType.SSO_SILENT_SUCCESS || eventType === EventType.ACQUIRE_TOKEN_SUCCESS;
  }
  isLoggedSuccessEvent$:Observable<UserModel> = this.msalBroadcastServices.msalSubject$.pipe(
    // tap(msg => console.log(`[${msg.eventType}] isLoggedSuccessEvent$ = this.msalBroadcastServices:`, this.__activeAccount)),
    filter((msg: EventMessage) => this.isALogginEvent(msg.eventType) ),
    filter(_ => !!this.getActiveAccount()),
    // tap(msg => console.log(`ACTIVE ACCOUNT:`, this.__activeAccount)),
    filter((msg: EventMessage) => !this.user),
    // mergeMap((msg: EventMessage) => this.userService.getUserData(this.__activeAccount!.username)),
    // tap(user => this.user = user),
    // TODO: This is bad code it should be directly on the backend response
    // mergeMap(user => this.roleService.getRolesWithoutPagination$()),
    // tap(roles => roles.forEach(r => r.id === this.user.roleId ? this.user.role = r : null)),
    map(r => this.getUser()!),
  );
  user!: UserModel;

  get loginProviderEmail(){ return this.__activeAccount?.username; }// For microsoft
  constructor(
    public msalService: MsalService,
    public msalBroadcastServices: MsalBroadcastService,
    public userService: UserService,
    public roleService: RoleService,
  ) {
    //TODO: UNSUBSCRIBE and IMPLEMENT ONDESTROY OF SERVICE
    // this.msalBroadcastServices.msalSubject$.pipe(
    //   tap(msg => console.log(`EVENTS FROM msalBroadcastServices`, msg)),
    // ).subscribe();

    //TODO: UNSUBSCRIBE and IMPLEMENT ONDESTROY OF SERVICE
    // this.isLoggedSuccessEvent$.pipe().subscribe()
  }

  get isLoggedIn() { return !!this.__activeAccount; }
  isExpiredToken(activeAccount: any): boolean {
    if (!activeAccount) {
      return true;
    }

    const expirationTokenDate = this.getTokenExpirationDate(activeAccount);
    return expirationTokenDate.getTime() - Date.now() < 0
  }

  // TODO: getTokenExpirationDate shouldn't depend on activeAccount, should depend on a layer of UserData so the provider is not only Microsoft
  getTokenExpirationDate(activeAccount: any): Date {
    // getMsalTokenExpirationDate
    return new Date(activeAccount.idTokenClaims.exp * 1000)
  }

  isDatePassed(possibleExpiredDate: number) {
    const expirationTokenDate = new Date(possibleExpiredDate);
    return expirationTokenDate.getTime() - Date.now() > 0
  }

  getUser(): UserModel | null {
    if (this.isLoggedIn) {
      return this.mappingUserFromActiveAccount(this.__activeAccount);
    }

    return null;
  }

  logOut(){
    // TODO: Unsubscribe
    this.msalService.logout().subscribe();
  }

  getActiveAccount() {
    if (this.isExpiredToken(this.__activeAccount)) {
      this.__activeAccount = null;
    }

    if (this.__activeAccount) {
      return this.__activeAccount;
    }

    this.__activeAccount = this.msalService.instance.getActiveAccount();
    if (!this.__activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      this.__activeAccount = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(this.__activeAccount);
    }
    if(this.isExpiredToken(this.__activeAccount)){
     this.logOut();
      return this.__activeAccount;
    }
    return this.__activeAccount;
  }

  /**
   * 
   * @param msalActiveAccount 
   * @returns 
   */
  protected mappingUserFromActiveAccount(msalActiveAccount: AccountInfo | null): UserModel | null {

    // CHECK src\app\security\services\authentication\authentication.service.ts:40 it should be the same object type
    if (msalActiveAccount) {
      const user = {
        // ...this.user,
        "username": msalActiveAccount!.name,
        "email": msalActiveAccount!.username,
      };

      return user as UserModel;
    }

    // TODO: This should be a Factory
    // return new UserModel("")
    return null;
  }

}
