<div class="modal__header">
  <h3 class="modal__title">{{ title | translate | titlecase }} PAblo test</h3>
  <button
    (click)="closeModal($event)"
    aria-label="Close"
    class="modal__close"
    type="button"
  ></button>
</div>

<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
  <div class="modal__body">
    <div class="modal__row modal__row--flex">
      <!-- <div *ngFor="let control of formControls"> -->
      <ng-container *ngFor="let control of formControls">
        <ng-container [ngSwitch]="control.type">
          <ng-container *ngSwitchCase="'checkbox'">
            <mat-checkbox [formControlName]="control.name">
              {{
                'components.modal.form.note' + control.label
                  | translate : control.label
              }}</mat-checkbox
            >
          </ng-container>
          <ng-container *ngSwitchDefault>
            <mat-form-field class="item-fields">
              <mat-label>{{
                'components.modal.form.note' + control.label
                  | translate : control.label
              }}</mat-label>

              <input
                matInput
                formControlName="{{ control.name }}"
                type="{{ control.type }}"
                id="{{ control.name }}"
                name="{{ control.name }}"
                required
              />
            </mat-form-field>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="modal__footer">
    <button mat-flat-button class="btn-submit" color="primary" type="submit" [disabled]="!myForm.valid">
      {{ options.action | translate }}
    </button>
  </div>
</form>
