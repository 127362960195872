import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ViewChild, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { GenerateCrudModalFormService } from 'src/app/services/generate-crud-modal-form/generate-crud-modal-form.service';

@Component({
  selector: 'app-modal-for-inject-component',
  templateUrl: './modal-for-inject-component.component.html',
  styleUrls: ['./modal-for-inject-component.component.scss']
})
export class ModalForInjectComponentComponent implements AfterViewInit, OnInit, OnDestroy {


  buttonText = "Submit"
  modalTitle = "Generic Title"
  modalIcon = ""
  iconHasHue = false;
  componentInsertedClass!: any;
  componentRef?: ComponentRef<any>
  @ViewChild('componentContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
  /** Component add to the host view */
  onLoaded$ = new Subject<ComponentRef<any>>();
  /** Component created but not added to the host view */
  onCreatedChildComponent$ = new Subject<ComponentRef<any>>();
  form: FormGroup | any;
  dataSub: Subscription | any; 

  get componentInjectedInstance() {
    return this.componentRef?.instance
  }

  constructor(
    public activeModal: NgbActiveModal, //TODO: Do a class service that map activeModal so is not dependent of Bootstrap
    private resolver: ComponentFactoryResolver,
    public modalCreator: GenerateCrudModalFormService
  ) {

  }

  ngOnInit(){
    this.dataSub = this.modalCreator.formSub.subscribe(data => {
      this.form = data;
    })
  }

  ngAfterViewInit(): void {
    // TODO: On loaded should wait for onCreatedChildComponent be trigger, if is not trigger yet it should subscribe and trigger after
    // So we can be sure that a concurrent problem won't happen for inputs
    this.onLoaded$.next(this.componentRef!);
  }

  createComponent() {
    const factory = this.resolver.resolveComponentFactory(this.componentInsertedClass);
    this.componentRef = this.container.createComponent(factory);
    this.onCreatedChildComponent$.next(this.componentRef);
    return this.componentRef;
  }

  closeModal($event: MouseEvent) {
    this.activeModal.close(
      {
        action: 'close',
        componentRef: this.componentRef,
      })
  }

  onSubmit($event: MouseEvent) {
    this.activeModal.close({
      componentRef: this.componentRef,
      action: 'submit',
    })
  }

  modalHasIcon(): boolean {
    return this.modalIcon !== '';
  }

  ngOnDestroy(): void {
      if(this.dataSub){
        this.dataSub.unsubscribe();
      }
  }
}
