import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ApiService } from '../api/api-service/api.service';
import { PageModel } from '../api/pagination-service/model/page.model';
import { Product } from 'src/app/models/product.model';
import { Note } from 'src/app/models/note.model';
import { ProductCategoryField } from 'src/app/models/product-field-category.model';
import { AttributeProduct } from 'src/app/models/attribute-product.model';
import { AuthenticationService } from 'src/app/security/services/authentication/authentication.service';
import { UploadFilesService } from '../upload-files/upload-files.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public isSavingBulk = new BehaviorSubject<boolean | undefined>(false);
  public refreshEditableTableInput = new BehaviorSubject<any | undefined>(undefined);

  //these variables will be helpers in the bulk edit
  public currentProductCategories: any[] = [];
  public fieldsToUpdate: any[] = [];

  // TODO: This can be extracted from Model.constructor.name
  readonly modelName = 'product';
  constructor(
    public apiService: ApiService,
    public authService: AuthenticationService,
    public uploadFilesService: UploadFilesService,
    private http: HttpClient,
  ) {}

  /**
  * Create a Product
  * @param product
  * @returns
  */
  createProduct$(product: any) {
    const request$ = this.apiService.post(
      `${this.modelName}`,
      JSON.stringify(product),
      'components.modal.product.createSuccessMsg'
    );

    return request$;
  }

  createNote$(productId: string, note: Note): any {
    const user = this.authService.getLoggedInUser();

    note.product = { productId: productId } as any;
    const slug = `note`;
    note.user = { userId: user.id, ...user } as any
    const requestNote$ = this.apiService.post<Note>(slug, note, 'Note created successfully');

    return requestNote$;
  }

  updateNote$(productId: string, note: Note): any {
    const user = this.authService.getLoggedInUser();

    note.product = { productId: productId } as any;
    const slug = `note/`;
    note.user = { userId: user.id, ...user } as any
    const requestNote$ = this.apiService.put<Note>(slug, note.id, note, 'Note updated successfully');

    return requestNote$;

  }

  /**
   * Retrieves a product with the given ID.
   * @param id The ID of the product to retrieve.
   * @returns An Observable that emits the product data.
   */
  getProduct$(id: string): Observable<Product> {
    const slug = `${this.modelName}/${id}`;
    const requestProduct$ = this.apiService.get<Product>(slug, {}, '');
    return requestProduct$;
  }

  /**
   * Get list of products including its pagination
   * @param params: It will specify query conditions to the general collection.
   * @returns a list of products with the pagination
   */
  getProductsWithPagination$(params: { [keyof: string]: any } = {}) {
    // TODO: Products could be extract from constructor name of the model
    return this.apiService.get<PageModel<Product>>(this.modelName, params, "");
  }

  /**
   * Get list of products including its pagination
   * @param params: It will specify query conditions to the general collection.
   * @returns a list of products with the pagination
   */
  getProductsWithoutPagination$(params: { [keyof: string]: any } = {}) {
    return this.getProductsWithPagination$(params).pipe(
      map(result => result.data),
    );
  }

  getProductsForMarketDiscovery$(){
    return this.apiService.get<Product[]>(`${this.modelName}/market`, {}, '');
  }

  getProductsForLandingPage$(){
    return this.apiService.get<Product[]>(`${this.modelName}/landing`, {}, '');
  }

  getProductsForProviderPage$(providerId: any){
    return this.apiService.get<Product[]>(`${this.modelName}/provider-page`, { providerId: providerId }, '');
  }

  getProductsForComparisonTool$(){
    return this.apiService.get<Product[]>(`${this.modelName}/comparison`, {}, '');
  }

  getProductCharacteristicsForComparison$(productId: string){
    return this.apiService.get<Product>(`${this.modelName}/comparison/${productId}`, {}, '');
  }

  getProductsNotesWithoutPagination$(id: string) {
    return this.getProduct$(id).pipe(
      map(p => p.notes),
    );
  }

  getProductsAttachmentsWithoutPagination$(id: string) {
    return this.getProduct$(id).pipe(
      map(p => p.attachments),
    );
  }

  getProductsAttributesWithoutPagination$(id: string) {
    return this.getProduct$(id).pipe(
      map(p => p.attributes),
    );
  }

  // TODO: Remove those two are the same
  getProductFieldsCategoryWithoutPagination$(id: string) {
    return this.getProduct$(id).pipe(
      map(p => p.categories),
    );
  }
  // TODO: Remove those two are the same
  getProductsCategoriesWithoutPagination$(id: string) {
    return this.getProduct$(id).pipe(
      map(p => p.categories),
    );
  }

  createAttachment$(productId: string, attachment: any): any {
    const slug = `/product/attachments`

    return this.apiService.post(slug, { attachment : attachment, productId: productId }, '');
  }

  updateAttachment$(productId: string, attachmentId: any, attachmentName: string, attachments: any): any {
    // If we removed the previous attachment and added a new one, we have to delete the first and upload the second one.
    if (attachments[0].changed) {

      const {formData, errors} = this.uploadFilesService.createFormData(attachments);
      formData.append('name', attachmentName);

      //its taking a little too much time on this request
      const url: string = `${environment.apiUrl}${environment.apiVersion}`;
      const endpoint = `product/${productId}/editAttachment/${attachmentId}`;

      return this.http.post(`${url}${endpoint}`, formData)
    }
    //if we changed the attachment's name, attachmentName will have a value, otherwise it will be an empty string.
    else {
      if(attachmentName){
        const slug = `/product/${productId}/editAttachment/${attachmentId}`;
        return this.apiService.post<any>(slug, { description: attachments[0].description, name: attachmentName, formData: ''}, '');
      }
      else{
        const slug = `/product/${productId}/editAttachment/${attachmentId}`;
        return this.apiService.post<any>(slug, { description: attachments[0].description, name: '', formData: ''}, '');
      }

    }
  }

  downloadProductAttachmentFromBlob(productId: any, attachmentId: any) {
    const slug = `product/${productId}/downloadAttachments/${attachmentId}`

    return this.apiService.downloadFile(slug, {});
  }

  deleteProductAttachments$(productId: any, attachmentId: any[]) {
    const slug = `product/${productId}/deleteAttachments`

    return this.apiService.post(slug, { attachments : attachmentId }, '');
  }

  deleteProductAttachment$(productId: any, attachmentId: any) {
    const slug = `product/${productId}/deleteAttachment/${attachmentId}`

    return this.apiService.post(slug, {}, '');
  }

  downloadProductsForExportTool(providers: any[], topics: any[]){
    const slug: string = `product/export`;

    return this.apiService.post(slug, { providers, topics }, '')
  }

  deleteManyNotes$(notes: Note[]): any {
    throw new Error('Method not implemented.');
  }

  /**
   * Delete bulk products
   * @param productsIds
   * @returns
   */
  deleteManyProducts$(productsIds: string[]) {
    return this.apiService.post(
      `${this.modelName}/delete`,
      { ids: productsIds },
      ''
    );
  }

  /**
 * Update a product.
 * product All attributes are optional except id
 * @param product: product to be updated
 * @returns
 */
  updateProduct$(product: Partial<Product> & { id: string }) {
    const request$ = this.apiService.put(
      `${this.modelName}/`,
      product.id,
      JSON.stringify(product),
      'components.modal.product.editSuccessMsg'
    );

    return request$;
  }

  updateLicense$(product: Partial<Product> & { id: string }, license: string, restriction: string) {
    const field: any = product.categories?.flatMap(cat => cat.fields).find((f: any) => f.fieldGlossaryId === "ID_O_007")
    if(field){
      field.value = license;
      field.wasEdited = true;
    }
    const updatedProduct = { ...product, license, restriction};

    const request$ = this.apiService.put(
      `${this.modelName}/`,
      product.id,
      JSON.stringify(updatedProduct),
      'components.modal.product.editSuccessMsgLicense'
    );

    return request$;
  }


  deleteManyAttributes$(attributes: AttributeProduct[]): any {
    throw new Error('Method not implemented.');
  }

  deleteManyCategories$(categorys: ProductCategoryField[]): any {
    throw new Error('Method not implemented.');
  }

  deleteManyFields$(fields: AttributeProduct[]): any {
    throw new Error('Method not implemented.');
  }

  updateProductSingleField$(productId: string, field: any): Observable<any>{
    const request$ = this.apiService.put(
      `${this.modelName}/updateSingleField/`,
      productId,
      JSON.stringify(field),
      'Field restored to the selected value successfully'
    );
    return request$
  }

  replaceNoteAttachment(attachments: any, noteId: string, attachmentId: string, attachmentName: string): any {

    const {formData, errors} = this.uploadFilesService.createFormData(attachments);
    formData.append('name', attachmentName);

    const url: string = `${environment.apiUrl}${environment.apiVersion}`;
    const endpoint = `note/${noteId}/replaceNoteAttachment/${attachmentId}`;

    return this.http.post(`${url}${endpoint}`, formData)
  }

  renameNoteAttachment$(noteId: string, attachmentId: string, attachmentName: string, newName: string): any {

    const url: string = `${environment.apiUrl}${environment.apiVersion}`;
    const endpoint = `note/${noteId}/renameNoteAttachment/${attachmentId}`;

    return this.http.post(`${url}${endpoint}`, {'oldName': attachmentName, 'newName': newName})
  }

  getProductFieldValue(product: Product, fieldName: string): any {
    let value: any = "";

    for (const category of product?.categories) {
      // Loop through fields in each category
      for (const field of category.fields) {
          // Check if name matches the desired value
          if (field.name.toLowerCase() === fieldName.toLowerCase()) {
              // Extract the value
              value = field.value;
              // Break out of the loop since you found what you were looking for
              break;
          }
      }

      // Check if the desired value is found and break out of the outer loop
      if (value) {
          break;
      }
    }

    return value;
  }

  getProductFieldValueByUniqueId(product: Product | any, uniqueId: string): any {
    let value: any = "";

    for (const category of product?.categories) {
      // Loop through fields in each category
      for (const field of category.fields) {
          // Check if name matches the desired value
          if (field.fieldGlossaryId?.toLowerCase() === uniqueId?.toLowerCase()) {
              // Extract the value
              value = field.value;
              // Break out of the loop since you found what you were looking for
              break;
          }
      }

      // Check if the desired value is found and break out of the outer loop
      if (value) {
          break;
      }
    }

    return value;
  }
}
