<div class='modal__header'>
  <div class="modal__header--text-icon">  
    <div *ngIf="modalHasIcon()" [ngClass]="{ hue: iconHasHue }">
      <img [src]="modalIcon" alt="icon" class="modal-icon">
    </div>
    <h3 class='modal__title'>{{ modalTitle | translate }}</h3>
  </div>

  <button
    (click)='closeModal($event)'
    aria-label='Close'
    class='modal__close'
    type='button'
  ></button>
</div>
<div #componentContainer></div>
<div class='modal__footer' *ngIf="buttonText!=='noshow'">
  <button *ngIf="buttonText!=='noshow'"
    class="pagehead__secondary-button"
    type="button"
    (click)="closeModal($event)"
  >
    {{ 'components.modal.common.cancel' | translate }}
  </button>
  <button *ngIf="buttonText!=='noshow'"
          class='pagehead__primary-button'
          [ngClass]="{ 'pagehead__primary-button--disabled': !form?.valid }"
          type='submit'
          (click)='onSubmit($event)'
          [disabled]='!form?.valid'>
    {{ buttonText }}
  </button>
</div>
