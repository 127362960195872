<ng-container [ngSwitch]="column.type">
  <ng-container *ngSwitchCase="'custom'">
    <span
      [ngClass]="column.class"
      [innerHTML]="column.namefunc(rowData)"
      (click)="column.event ? column.event(rowData) : ''"
    ></span>
  </ng-container>
  <ng-container *ngSwitchCase="'link-edit'">
    <a [ngClass]="column.class" (click)="column.clickLink(rowData, column.name)"
      >{{
        column.second
          ? rowData[column.name][column.second]
          : rowData[column.name]
      }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'link'">
    <a [matTooltip]="getLinkTooltip(column.class)" [ngClass]="column.class" (click)="column.clickLink(rowData)"
      >{{
        column.second
          ? rowData[column.name][column.second]
          : rowData[column.name]
      }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'link-icon'">
    <a [ngClass]="column.class" (click)="column.clickLink(rowData)"
      >{{
        column.second
          ? rowData[column.name][column.second]
          : rowData[column.name]
      }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'link-underlined'">
    <a matTooltip="Click to see comments or add new ones" [ngClass]="column.class" class="underline-bold" (click)="column.clickLink(rowData)"
      >Comments ({{rowData.commentsCount}})
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'statusLink'">
    <a matTooltip="Click to edit the Status field" [ngClass]="column.class" (click)="column.clickLink(rowData)"
      >{{
        transformBack(column.second)
          ? transformBack(rowData[column.name][column.second])
          : transformBack(rowData[column.name])
      }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'link-flag'">
    <ng-container *ngIf="rowData[column.name]; else flag">
      <a matTooltip="Click to edit the Flag field" [ngClass]="column.class" (click)="column.clickLink(rowData)"
      >{{
        transformBack(column.second)
          ? transformBack(rowData[column.name][column.second])
          : transformBack(rowData[column.name])
        }}
      </a>
      <ng-container *ngIf="rowData[column.name] && rowData[column.name] !== 'NO_FLAG'">
        <mat-icon
          *ngIf="getFlagTooltip(rowData)"
          class="iconText"
          [matTooltip]="getFlagTooltip(rowData)"
          [matTooltipShowDelay]="500"
        >info</mat-icon>
      </ng-container>

    </ng-container>
    <ng-template #flag>
      <a matTooltip="Click to edit the Flag field" [ngClass]="column.class" (click)="column.clickLink(rowData)">No Flag</a>
    </ng-template>

  </ng-container>
  <ng-container *ngSwitchCase="'ellipsis-link'">
    <a
      class="has-tooltip"
      (click)="column.clickLink(rowData)"
      [ngClass]="column.class"
      [matTooltip]="rowData[column.name]"
    >
      <span class="ellipsis-text">
        {{
          column.second
            ? rowData[column.name][column.second]
            : rowData[column.name]
        }}
      </span>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'link-conditional'">
    <a
      [class]="column.class"
      [ngClass]="{
        'respontable-disabled respontable-disabled__link-conditional':
          column.conditional
            ? rowData[column.conditional] !== column.condition
            : false
      }"
      (click)="column.clickLink(rowData)"
      >{{
        column.second
          ? rowData[column.name][column.second]
          : rowData[column.name]
      }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'span-link-angle-double'">
    <a (click)="column.clickLink(rowData)">
      <i class="fa fa-angle-double-right"></i>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'link-fa-icon'">
    <a
      *ngIf="!rowData[column.name]"
      (click)="column.clickLink(rowData)"
      class="icon"
    >
      <i [ngClass]="column.classIcon"></i>
    </a>
    <ng-container *ngIf="rowData[column.name]">
      {{
        column.second
          ? rowData[column.name][column.second]
          : rowData[column.name]
      }}
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'date'">
    {{ rowData[column.name] | moment : column.format }}
  </ng-container>
  <ng-container *ngSwitchCase="'aging-date'">
    <span class="column__aging-date">
      <span class="column__date">{{
        rowData[column.name] | moment : column.format
      }}</span>
      {{ rowData[column.name] | moment : 'fromNow' }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'duration'">
    <span class="column__aging-date">
      {{ rowData[column.name] | duration }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'long-text'">
    <span 
      [matTooltip]="rowData[column.name]"
      [matTooltipShowDelay]="500" class="ellipsis"
    >
      {{ rowData[column.name] }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'hover-info-restriction'">
    <div class="tooltip-container">
      <span [matTooltip]="rowData[column.name]"
            [matTooltipPositionAtOrigin]="true"
            [matTooltipShowDelay]="500">{{
        rowData[column.name]
      }}</span>
      <mat-icon
        *ngIf="rowData[column.name] === 'Restricted EY License'"
        [matTooltip]="rowData['restriction']"
        [matTooltipPositionAtOrigin]="true"
        [matTooltipShowDelay]="500"
        >info</mat-icon
      >
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'datamodel-data'">
    <span class="column__aging-data-wrap">
      <span class="column__aging-data" *ngFor="let tag of column.name">
        <span class="column__data-datamodel">{{
          column.headerNames[tag] | translate
        }}</span>
        <span
          *ngIf="tag.includes('date')"
          [matTooltip]="rowData[tag] | moment : column.format"
        >
          {{ rowData[tag] | moment : column.format }}
        </span>
        <span
          *ngIf="!tag.includes('date')"
          [matTooltip]="rowData[tag]"
          [ngClass]="column.headerClass"
        >
          {{ rowData[tag] }}
        </span>
      </span>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'export-button'">
    <!-- <app-export-button
      *ngIf="!rowData.status_data || (rowData.status_data.class == Status.SUCCESS && !rowData.isparentdocument)"
      [value]="rowData[column.name]"></app-export-button> -->
  </ng-container>
  <ng-container *ngSwitchCase="'tags'">
    <span
      *ngIf="rowData[column.name] && rowData[column.name].length > 0"
      class="has-tooltip"
    >
      <i [ngClass]="column.tags(rowData[column.name])"></i>
      <span class="tooltip tooltip-with-border">
        <div>
          <mat-chip-grid>
            <mat-chip-row
              class="matchip"
              *ngFor="let tag of rowData[column.name]"
            >
              {{ column.second ? tag[column.second] : tag.tagname }}
            </mat-chip-row>
          </mat-chip-grid>
        </div>
      </span>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'toggle'">
    <span class="toggle-centered">
      <mat-slide-toggle
        [checked]="rowData[column.name]"
        [disabled]="true"
      ></mat-slide-toggle>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'description'">
    <span class="ellipsis has-tooltip" [matTooltip]="rowData[column.name]">
      {{ rowData[column.name] }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'wider-description'">
    <span class="wider-ellipsis has-tooltip" [matTooltip]="rowData[column.name]">
      {{ rowData[column.name] }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'note-tags'">
    <span
      *ngIf="rowData[column.name] && rowData[column.name].length > 0"
      class="has-tooltip"
    >
      <i [ngClass]="'fa fa-info'"></i>
      <span class="tooltip tooltip-with-border">
        <div>
          <mat-chip-grid>
            <mat-chip-row
              class="matchip"
              *ngFor="let item of rowData[column.name]"
            >
              {{ column.second ? item[column.second] : item.tagname }}
            </mat-chip-row>
          </mat-chip-grid>
        </div>
      </span>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'chips'">
    <span
      *ngIf="rowData[column.name] && rowData[column.name].length > 0"
      class="has-tooltip"
    >
      <i *ngIf="column.icon" [ngClass]="'fa fa-info'"></i>
      <span>
        <div>
          <mat-chip-grid>
            <mat-chip-row
              class="matchip"
              *ngFor="
                let item of rowData[column.name] | slice : 0 : column.maxChips
              "
            >
              {{ getColumnSecond(item, column) }}
            </mat-chip-row>
            <mat-chip-row *ngIf="rowData[column.name].length > column.maxChips">
              +{{
                rowData[column.name].length - column.maxChips
              }}
              more
            </mat-chip-row>
          </mat-chip-grid>
        ></div>
      </span>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'one-span-permission'">
    <span
      [ngClass]="{
        'text-green': column.second
          ? rowData[column.name][column.second]
          : rowData[column.name],
        'text-red': column.second
          ? !rowData[column.name][column.second]
          : !rowData[column.name]
      }"
      [matTooltip]="column.title | translate"
    >
      <i
        class="fa"
        [ngClass]="{
          'fa-check-circle': column.second
            ? rowData[column.name][column.second]
            : rowData[column.name],
          'fa-times-circle': column.second
            ? !rowData[column.name][column.second]
            : !rowData[column.name]
        }"
      ></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'two-span-permission'">
    <ng-container>
      <span
        [ngClass]="{
          'text-green': column.second
            ? rowData[column.name][column.second]
            : rowData[column.name],
          'text-red': column.second
            ? !rowData[column.name][column.second]
            : !rowData[column.name]
        }"
        [matTooltip]="column.title | translate"
      >
        <i
          class="fa"
          [ngClass]="{
            'fa-check-circle': column.second
              ? rowData[column.name][column.second]
              : rowData[column.name],
            'fa-times-circle': column.second
              ? !rowData[column.name][column.second]
              : !rowData[column.name]
          }"
        ></i>
      </span>
      <span
        [ngClass]="{
          'text-green': column.second
            ? rowData[column.name][column.second2]
            : rowData[column.name2],
          'text-red': column.second
            ? !rowData[column.name][column.second2]
            : !rowData[column.name2]
        }"
        [matTooltip]="column.title2 | translate"
      >
        <i
          class="fa"
          [ngClass]="{
            'fa-check-circle': column.second
              ? rowData[column.name][column.second2]
              : rowData[column.name2],
            'fa-times-circle': column.second
              ? !rowData[column.name][column.second2]
              : !rowData[column.name2]
          }"
        ></i>
      </span>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'one-span-status'">
    <span
      class="text-status"
      [matTooltip]="getListProvider(rowData)"
      [matTooltipPositionAtOrigin]="true"
      [matTooltipShowDelay]="500"
      [ngClass]="getStatusClass(rowData)"
    >
      {{ getStatusText(rowData) }}
      <!-- <span class="tooltip tooltip-with-border">
      </span> -->
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'two-records'">
    {{ getTwoRecordsText(rowData, column) }}
  </ng-container>
  <ng-container *ngSwitchCase="'geography'">
    {{ getGeography(rowData, column) }}
  </ng-container>
  <ng-container *ngSwitchCase="'underlined'">
    <ng-container *ngIf="rowData[column.name]">
      <a [ngClass]="column.class"
        style="
          display: flex;
          align-items: center;
          cursor: pointer;
        "
        (click)="column.action(rowData, column)"
        [matTooltip]="column.tooltipText(rowData)"
        [matTooltipShowDelay]="500"
      >
        {{
          column.second
            ? rowData[column.name][column.second]
            : rowData[column.name]
        }}
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'span-tooltip'">
    <span [matTooltip]="column.tooltipText(rowData)">
      {{
        column.second
          ? rowData[column.name][column.second]
          : rowData[column.name]
      }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'info'">
    <ng-container *ngIf="rowData[column.name]">
      <span
        style="display: flex; align-items: center"
        (click)="column.action(rowData, column)"
      >
        {{
          column.second
            ? rowData[column.name][column.second]
            : rowData[column.name]
        }}

        <mat-icon
          *ngIf="column.iconName"
          aria-hidden="false"
          aria-label="Example home icon"
          [fontIcon]="column.iconName"
          style="margin-left: 0.5rem; cursor: pointer"
        ></mat-icon>
      </span>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'boolean'">
    <p *ngIf="rowData[column.name] === true">
      {{ 'common.yes' | translate : 'Yes' }}
    </p>
    <p *ngIf="rowData[column.name] === false">
      {{ 'common.no' | translate : 'No' }}
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="'upper-separators'">
    {{ separateUpperString(rowData[column.name]) }}
  </ng-container>
  <ng-container *ngSwitchCase="'one-line-ellipsis'">
    <ng-container *ngIf="rowData[column.name]">
      <span
        class="one-line-ellipsis"
        [matTooltip]="
          column.second
            ? rowData[column.name][column.second]
            : rowData[column.name]
        "
      >
        {{
          column.second
            ? rowData[column.name][column.second]
            : rowData[column.name]
        }}
      </span>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'percentage'">
    <ng-container *ngIf="rowData[column.name] !== null">
      <span>
        {{
          column.second
            ? rowData[column.name][column.second]
            : rowData[column.name]
        }}
        %
      </span>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'hover-info-flag'">
    <ng-container *ngIf="rowData[column.name]">
      <span [matTooltip]="separateUpperString(rowData[column.name])">
        {{ separateUpperString(rowData[column.name]) }}
      </span>
      <mat-icon
        *ngIf="getFlagTooltip(rowData)"
        class="iconText"
        [matTooltip]="getFlagTooltip(rowData)"
        [matTooltipPositionAtOrigin]="true"
        [matTooltipShowDelay]="500"
        >info</mat-icon
      >
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'category-percentage'">
    <ng-container *ngIf="rowData[column.name] !== null">
      <span [matTooltip]="getCategoryPercentageTooltip(rowData)">
        {{
          column.second
            ? rowData[column.name][column.second]
            : rowData[column.name]
        }}
        %
      </span>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'pencil-editable'">
    <mat-icon (click)="column.clickLink(rowData)">edit</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'editor-and-role'">
    <span>
      {{ rowData[column.name][column.second] }} ({{ rowData[column.name][column.third] }})
    </span>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="rowData[column.name]">
      {{
        column.second
          ? rowData[column.name][column.second]
          : rowData[column.name]
      }}
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'updated-data'">
      <span >{{ getLastUpdatedDetails(rowData) }} </span>
  </ng-container>
  <ng-container *ngSwitchCase="'last-validated-data'">
      <span >{{ getLastValidatedDetails(rowData) }} </span>
  </ng-container>
  <ng-container *ngSwitchCase="'last-validated-data-link'">
      <a matTooltip="Click to see log of historical validations" [ngClass]="column.class" (click)="column.clickLink(rowData)">{{ getLastValidatedDetails(rowData) }} </a>
  </ng-container>
</ng-container>
