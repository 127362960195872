import { Directive, Input, ElementRef, OnInit } from '@angular/core';
// import { AuthenticationService } from '../security/authentication.service';

@Directive({
  selector: '[appNotHasPermission]'
})
export class NotHasPermissionDirective implements OnInit {
  @Input('appNotHasPermission') notHasPermission: string[] = [];
  public user: any = {};

  constructor(
    private element: ElementRef,
    // private authService: AuthenticationService
  ) {
    // this.user = authService.getLoggedInUser();
  }

  ngOnInit() {
    if (
      !this.user.isadmin
      //&& this.authService.hasPermission(this.notHasPermission)
    ) {
      this.element.nativeElement.style.display = 'none';
    }
  }
}
