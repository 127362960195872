import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import { UserModel } from 'src/app/models/user.model';
import { RoleService } from 'src/app/services/role-service/role.service';
import { Observable, Subscription, filter, from, map, tap } from 'rxjs';
import { RoleModel } from 'src/app/models/role.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { startWith } from 'rxjs/operators';
import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';
import { PaginationModel } from 'src/app/services/api/pagination-service/model/pagination.model';
import { COUNTRY_NAMES, REGION_NAMES, SERVICE_LINES, SUB_SERVICE_LINES, SECTOR_FOCUS } from 'src/app/models/constants.model';
import { DataMasterService } from 'src/app/services/data-master-service/data-master';
import { AutocompleteUtilsService } from 'src/app/services/autocomplete-utils/autocomplete-utils.service';

export interface IUserData {
  type: 'new' | 'edit',
  action: 'create',
  user: UserModel
}

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  @Input() options!: IUserData;
  @Input() usersList: UserModel[] = [];
  option = '';
  action = 'create';
  userForm!: UntypedFormGroup;
  rolesOptions$: Observable<RoleModel[]> = from([]);
  providersOptions$: Observable<ProviderModel> = from([]);
  countryOptions = COUNTRY_NAMES;
  countriesArray: string[] = [];
  roleArray: string[] = [];
  filteredCountries: Observable<string[]> | undefined;
  roleListFiltered: Observable<string[]> | undefined;
  regionOptions = REGION_NAMES;
  serviceLineOptions = SERVICE_LINES;
  serviceLineOptionsAux = SERVICE_LINES;
  subServiceLineOptions = SUB_SERVICE_LINES;
  subServiceLineOptionsAux = SUB_SERVICE_LINES;
  sectorOptions = SECTOR_FOCUS;
  emails: string[] = [];

  displayCountry(country:string): string{
    return country ? country : '';
  }

  closeModal($event: MouseEvent) {
    this.activeModal.close({ action: 'close' })
  }
  constructor(
    public activeModal: NgbActiveModal,
    public dataMasterService: DataMasterService,
    protected alertService: AlertService,
    protected translate: TranslatePipe,
    protected roleService: RoleService,
    protected providerService: ProviderCollectionService,
    protected autoCompleteUtilsService: AutocompleteUtilsService,
  ) {
    this.countriesArray = COUNTRY_NAMES.map(country => country.name);

  }

  roleList: RoleModel[] = [];

  ngOnInit() {
    this.emails = this.usersList.map(user => user.email);
    this.roleList = this.dataMasterService.getRoles();
    this.roleArray = this.roleList.map(role => role.name);
    this.serviceLineOptions = this.dataMasterService.getServiceLines();
    this.subServiceLineOptions = this.dataMasterService.getSubServiceLines();

    const aSubscription: Subscription = this.rolesOptions$.pipe(
      filter(roles => this.options.type === 'edit'),
      tap(roles => {
        const selectedRole = roles.find((role) => role.name === this.options.user.role.name);
        this.options.user.role = selectedRole!;
        this.initializeUserForm(this.options.user)
        this.updateRoles(this.userForm.get('roleId')?.value)
      })
    ).subscribe(_ => aSubscription.unsubscribe())

    if (!this.options) {
      this.alertService.error(this.translate.transform('options.@input.must.be.filled.on.user.modal'), false, 'options.@input.must.be.filled.on.user.modal');
      this.activeModal.close();
    } else {
      this.initializeUserForm(this.options.user)
      this.updateRoles(this.userForm.get('roleId')?.value)
    }

    this.option = 'components.modal.user.' + this.options.type;
    this.action = this.options.action;

    // TODO: Unsubscribe
    this.setProviders$().pipe(
      tap(_ => this.filteredProviders = this.assignFilteredProviders())
    ).subscribe()


  }

  initializeUserForm(user: UserModel) {
    // TODO: Unsubscribe
    this.hasAllProvidersFormControl.valueChanges.pipe(
      tap(selectAllProviders => selectAllProviders ? this.selectedProviders = this.allProviderNames : this.selectedProviders )
    ).subscribe()

    this.userForm = new UntypedFormGroup({
      username: new UntypedFormControl(user.username, [
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(/^[A-Za-zá-ÿÁ-Ü _–\-]*[A-Za-zá-ÿÁ-Ü][A-Za-zá-ÿÁ-Ü _–\-]*$/),
        // Validators.minLength(5) //this should not be here as a validation since we do not hint this error
      ]),
      email: new UntypedFormControl(user.email, [
        Validators.required,
        Validators.maxLength(100),
        Validators.email,
        this.emailValidator,
      ]),
      roleId: new UntypedFormControl(
        user.role.name,
      ),
      sector: new UntypedFormControl(
        user.sector,
      ),
      serviceLine: new UntypedFormControl(
        user.serviceLine,
      ),
      subServiceLine: new UntypedFormControl(
        user.subServiceLine,
      ),
      country: new UntypedFormControl(user.country),
      region: new UntypedFormControl(
        user.region,
      ),
    });
    this.setValidators();
    this.filteredCountries = this.userForm.valueChanges.pipe(
      startWith(''),
      map(value => this.filterCountries(value))
    );

    this.roleListFiltered = this.userForm.valueChanges.pipe(
      startWith(''),
      map(value => this.filterRoles(value))
    );
    // TODO: Unsubscribe
    this.userForm.get('roleId')!.valueChanges.subscribe((roleId) => this.updateRoles(roleId));
    this.userForm.get('email')!.value ? this.userForm.get('roleId')?.enable() : this.userForm.get('roleId')?.disable();
    // checkRoles function is triggered one time at initializing and as we change the email value by typing 
    this.checkRoles(this.userForm.get('email')!.value);
    this.userForm.get('email')!.valueChanges.subscribe((email) => this.checkRoles(email));
    this.selectedProviders = user.providers.map(p => p.provider);
  }

  get countriesToTreeGroup() {
    return this.autoCompleteUtilsService.convertCountriesToTreeGroup(this.countryOptions);
  }

  updateRoles(roleId: string) {
    this._isEYExpert = !!this.roleList.find(r => r.name == roleId && r.name.toLowerCase().includes('expert'));
    this._isExternalProvider = !!this.roleList.find(r => r.name == roleId && r.name.toLowerCase().includes('provider'));
    this._isEYConsultant = !!this.roleList.find(r => r.name == roleId && r.name.toLowerCase().includes('consultant'));
    this._isAdmin = !!this.roleList.find(r => r.name == roleId && r.name.toLowerCase().includes('admin'));
    this._isEYContributor = !!this.roleList.find(r => r.name == roleId && r.name.toLowerCase().includes('contributor'));

    const requiredValidator = this.isExternalProvider ? null : Validators.required;
    this.userForm.get('sector')!.setValidators(requiredValidator);
    this.userForm.get('serviceLine')!.setValidators(requiredValidator);
    this.userForm.get('subServiceLine')!.setValidators(requiredValidator);
    this.userForm.get('region')!.setValidators(requiredValidator);
    this.userForm.get('country')!.setValidators(requiredValidator);

    this.userForm.get('sector')!.updateValueAndValidity();
    this.userForm.get('serviceLine')!.updateValueAndValidity();
    this.userForm.get('subServiceLine')!.updateValueAndValidity();
    this.userForm.get('region')!.updateValueAndValidity();
    this.userForm.get('country')!.updateValueAndValidity();
  }

  checkRoles(email: string){
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const eyDomainRegex = /@.*\bey\.com$/i;

    // means that is valid and is from the domain
    if(emailRegex.test(email) && eyDomainRegex.test(email)) {
      // activate the role select and give it all the roles
      this.userForm.get('roleId')?.enable()
      this.roleArray = this.roleList.map(role => role.name);
    }
    
    // means that is valid but not from the domain
    if(emailRegex.test(email) && !eyDomainRegex.test(email)){
      // activate the role select and give it only "External Provider" role
      this.userForm.get('roleId')?.enable();
      this.roleArray = ["External Provider"];
    }
    
    // we reset in this case
    if(email.length === 0){
      this.userForm.get('roleId')?.disable();
      this.roleArray = this.roleList.map(role => role.name);
    }
  }

  _isEYExpert = false;
  _isExternalProvider = false;
  _isEYConsultant = false;
  _isAdmin = false;
  _isEYContributor = false;
  get isEYConsultant() { return this._isEYConsultant; }
  get isAdmin() { return this._isAdmin; }
  get isEYExpert() { return this._isEYExpert; }
  get isExternalProvider() { return this._isExternalProvider; }
  get isEYContributor() { return this._isEYContributor; }

  onSubmit() {
    if (!this.userForm.dirty && !this.providerCtrl.dirty && !this.hasAllProvidersFormControl.dirty) {
      this.activeModal.close({ action: 'close' })
      return;
    }

    if (this.userForm.valid && this.providerCtrl.valid) {
      const userForm = this.userForm.getRawValue();
      // userForm['country'] = this.autoCompleteUtilsService.fromFlattenedTreeItemOptionToInitialObject(userForm['country']);
      // TODO: Save index or complete obj instead of doing this
      const providers = this.selectedProviders.map(name => this.allProvider.find(p => p.playerName === name))
      // for back track compatibility with user providers array model
      providers.forEach((p: ProviderModel | any) => p['provider'] = p?.playerName);
      userForm.role = this.roleList.find(r => r.id == userForm.role);

      if (this.isExternalProvider) {
        userForm['region'] = ""
        userForm['sector'] = ""
        userForm['serviceLine'] = ""
        userForm['subServiceLine'] = ""
        userForm['country'] = ""
      }
      userForm['hasAllProviders'] = !this.isExternalProvider || !this.isEYContributor;
      // convert email to lowercase uppon submitting
      userForm['email'] = userForm['email']?.toLowerCase();
      // TODO: This should only have form info, the rest could be added in users-table.component.ts:418
      let userData = { ...this.options.user, ...userForm };
      if (this.isEYContributor || this.isExternalProvider) {
        userData = { ...userData, hasAllProviders: this.hasAllProvidersFormControl.value, providers };
      }
      this.activeModal.close({
        user: userData,
        action: this.action
      });
    }
  }

  // ADDING CHIPS WITH AUTOCOMPLETE

  // PROVIDERS
  providerPaginationConfiguration = PaginationModel.createDefaultConfiguration('name', 0, Number.MAX_SAFE_INTEGER);
  setProviders$(params: { [keyof: string]: any } = {}) {
    return this.providerService.getProvidersWithPagination$({ ...this.providerPaginationConfiguration, ...params }).pipe(
      map(data => data.data),
      tap(providers => {
        this.allProvider = providers;
        this.allProviderNames = providers.map(p => p.playerName)
        this.hasAllProvidersFormControl.setValue(this.hasAllProvidersFormControl.value);
      })
    )
  }

  separatorKeysCodes: number[] = [ENTER, COMMA];
  providerCtrl = new FormControl('');
  hasAllProvidersFormControl = new FormControl(false);
  selectedProviders: string[] = [];// ['Acme Corporation'];
  allProviderNames: string[] = [];
  allProvider: ProviderModel[] = [];

  filteredProviders: Observable<string[]> = this.assignFilteredProviders();
  assignFilteredProviders() {
    return this.providerCtrl.valueChanges.pipe(
      startWith(null), // This makes that the value changes when select the field
      map(typeProviderName => typeProviderName === null ? '' : typeProviderName),
      // show only the ones that a
      map(typedProviderName => this.allProviderNames.filter(providerName => providerName.toLowerCase().includes(typedProviderName.toLowerCase())).slice()),
      // map(typedProviderName => this.allProviderNames.filter(providerName => providerName.toLowerCase().includes(typedProviderName)).slice()),
      // show only providers that are not selected
      map(providersName => providersName.filter(providerName => !this.selectedProviders.includes(providerName)))
    );

  }

  @ViewChild('providersInput') providersInput!: ElementRef<HTMLInputElement>;

  /**
   * This event is only trigger when the name is not selected from the list, so the text may be incomplete
   * @param event
   */
  onAdd(event: MatChipInputEvent): void {
    const typedProviderName = (event.value || '').trim();
    // TODO: Is Cleaner On on change in type=input we should set the variable current text and return filteredProviders as a property list
    const listOfFilteredProviders = this.allProviderNames.filter(providerName => providerName.toLowerCase().includes(typedProviderName.toLowerCase()))

    // Since text may be incomplete or wront Select the first of the listOfFilteredProviders if has items
    if (typedProviderName && listOfFilteredProviders.length > 0) {
      this.selectedProviders.push(listOfFilteredProviders[0]);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.providerCtrl.setValue(null);
  }

  onRemove(providerName: string): void {
    const index = this.selectedProviders.indexOf(providerName);

    if (index >= 0) {
      this.selectedProviders.splice(index, 1);
    }

    this.filteredProviders = this.assignFilteredProviders();
    this.providerCtrl.markAsDirty();
  }

  onChange(){
  }

  onSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedProviders.push(event.option.viewValue);
    this.providersInput.nativeElement.value = '';
    this.providerCtrl.setValue(null);
  }

  onChangeServiceLine(event: any) {
    this.serviceLineOptions = this.dataMasterService.getServiceLines();
    this.subServiceLineOptions = this.dataMasterService.getSubServiceLines();

    this.subServiceLineOptions = this.subServiceLineOptions.filter(s => s.group.includes(event.value.toLowerCase()));
  }
  noProvidersSelected(): boolean {
    return (this.isEYContributor || this.isExternalProvider) && this.selectedProviders.length === 0;
  }

  emailValidator(control: FormControl): { [key: string]: any } | null {
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (control.value && !emailRegEx.test(control.value)) {
      return { 'invalidEmail': true };
    }
    return null;
  }

  filterCountries(value: any): string[]{
    let filterValue = '';
    if(value.country != undefined){
      filterValue = value.country.toLowerCase();
    }
    return this.countriesArray.filter(country => country.toLowerCase().includes(filterValue));
  }

  filterRoles(value: any): string[]{
    let filterValue = '';
    if(value.roleId!= undefined){
      filterValue = value.roleId.toLowerCase();
    }
    //return this.roleArray.filter(role => role.toLowerCase().includes(filterValue));
    let results = this.roleArray.filter(role => role.toLowerCase().includes(filterValue));
    return results.length>=1 ? results : ["No result"];
  }

  private optionValidator(options: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (options?.includes(control.value)) {
        return null;
      }
      return { invalidOption: true };
    };
  }
  existingEmailValidator(emails: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const emailExists = emails.includes(control.value.toLowerCase());
      return emailExists ? { emailExists: true } : null;
    };
  }

  private setValidators(): void {
    this.userForm.get('roleId')?.setValidators([Validators.required, this.optionValidator(this.roleArray)]);
    this.userForm.get('roleId')?.updateValueAndValidity();
    this.userForm.get('country')?.setValidators([Validators.required, this.optionValidator(this.countriesArray)]);
    this.userForm.get('country')?.updateValueAndValidity();
    this.userForm.get('email')?.setValidators([
      Validators.required,
      Validators.email,
      this.existingEmailValidator(this.emails),
      this.emailValidator
    ]);
  }
}
