<div class="advanced-filters-modal-container" *ngIf="!loading">
    <!---->
    <div class="select-filters-column">            
        
        <div class="search-input-container">
            <app-auto-complete-select
                label="{{ label }}"
                [placeholder]="placeholder"
                appearance="fill"
                (onSelectedItem)="onSelectFilterAutocomplete($event)"
                (onEmptyInputValue)="onResetFilters()"
                (onEnter)="onApplyAllFilters($event)"
                [multilevel]="true"
                [showArrowIconSuffix]="true"
                [formControl]="filterSelect"
                [source]="filtersOptions"
            >
            </app-auto-complete-select>
        </div>

        <span class="select-filters-column--subtitle">{{ "page.framework.search.test" |  translate : "Select filter criteria below"}}</span>

        <div class="group-box-container">
            <div *ngFor="let group of filtersSource" class="group-box">
                <div class="header-line">                
                    <h5 class="group-header">{{group.header}}</h5>
                    <!-- Collapse arrow -->
                    <span class="collapse-arrow" (click)="uncollapseOne(group)">
                        <mat-icon>{{ group.collapsed ? 'keyboard_arrow_down' : 'remove' }}</mat-icon>
                    </span>
                </div>
                
                <ul class="subcategories" *ngIf="!group.collapsed">
                    <li *ngFor="let item of group.items" class="subcategory" [ngClass]="{ 'applied': item.applied }">
                        <h5 [title]="item.name">{{item.name}}</h5>
                        <mat-icon (click)="addToSelectedFilters(item)" class="arrow-mat-icon" [ngClass]="{ 'not-applied': !item.applied }">{{ item.applied ? 'clear' : 'arrow_forward' }}</mat-icon>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    
    <!---->

    <div class="filters-column">
        <!-- Message for when there are no filters selected -->
        <div class="no-filters-selected" *ngIf="!atLeastOneApplied()">

            <img class="no-filters-image" src="assets/img/framework-coverage-image.png" alt="no-items-image">

            <h4>{{ "page.framework.column.right.test" | translate : "No filters selected" }}</h4>

            <p class="sub-text">
                <!-- {{ "page.framework.column.right.test.test" | translate : "Choose filter areas at left to refine your search"}} -->
                Choose filter areas at left to refine your search
            </p>

        </div>

        <!-- Different filters boxes -->
        <div class="filters-list" *ngIf="atLeastOneApplied()">

            <div *ngFor="let filter of selectedFilters">
                
                <!--  -->
                <app-advance-filter-checkboxes
                    *ngIf="filter.type === 0 || filter.type === 4"
                    [filter]="filter"
                    [mappedSelectedOptions]="filter.options"
                    (onSelected)="onCheckboxesSelected($event)"
                    (emitRemoval)="addToSelectedFilters(filter)"
                ></app-advance-filter-checkboxes>
                <!--  -->

                <!--  -->
                <app-advance-filter-chips
                    *ngIf="filter.type === 1"
                    [filter]="filter"
                    [showOfferings]="showOfferings"
                    (onSelected)="onChipSelected($event)"
                    (emitRemoval)="addToSelectedFilters(filter)"
                ></app-advance-filter-chips>
                <!--  -->
                
                <!--  -->
                <app-advance-filter-slider
                    *ngIf="filter.type === 3"
                    [filter]="filter"
                    (onSliderValueEmitter)="onSliderValueChange($event)"
                    (emitRemoval)="addToSelectedFilters(filter)"
                ></app-advance-filter-slider>
                <!--  -->

            </div>
        </div>

    </div>

</div>

<div class="buttons-container" *ngIf="!loading">
    <button
        class='pagehead__secondary-button'
        type="button"
        (click)="cancelFilterApplication()"
    >
        {{ 'page.admin.tabs.users.filter.button.cancel' | translate : 'Cancel' }}
    </button>

    <button
        class='pagehead__primary-button'
        type="submit"
        (click)="applyFilters()"
    >
        {{ 'page.admin.tabs.users.filter.button.apply' | translate : 'Apply' }}
    </button>

</div>