<div class="pagehead__title" *ngIf="!loading">
  <h1 id="product-name" *ngIf="product">
    <strong>{{'page.product.title.name' | translate : 'Offering'}}:</strong> {{ product.name }}
  </h1>
  <h1 id="product-name" *ngIf="product">
    <strong>{{'page.product.title.eyResponsible' | translate : 'EY Responsible'}}:</strong> {{ product.eyResponsible.username }}
  </h1>
</div>

<div class="div">
  <div class="filter-content">

    <app-table-spinner-ball
      [hidden]="!loading"
      [spinnerStyles]="'la-ball-circus'"
    ></app-table-spinner-ball>

    <div class="right" *ngIf="!loading">
      <button
        id="create-user"
        class="pagehead__button pagehead__button--filter"
        (click)="toggleShowFilters($event)"
      >
        {{ 'page.admin.button.filter.user' | translate : 'Filter' }}
      </button>
      <button
        id="create-product"
        class="pagehead__button pagehead__button--new"
        (click)="toggleShowCommentBox($event)"
      >
        {{ 'page.admin.button.create.editorComment' | translate : 'Add Comment/s' }}
      </button>
    </div>

    <div [hidden]="!showFilters" class="reviewers__filters">
      <app-validations-filter (onSearch)="onSearch($event)" [rolesList]="rolesList" [paginationType]="paginationType"
        [fields]="fields" [key]="'comments.'" #validationsFilter>
      </app-validations-filter>
    </div>

    <div [hidden]="!showCommentBox" class="next">
      <form [formGroup]="myForm">
        <div class="modal__row modal__row--flex">
          <mat-form-field class="mat-form-textarea">
            <mat-label>{{ 'components.modal.provider.editorComment' | translate : 'Comment/s' }}</mat-label>
            <textarea
              matInput
              formControlName="comment"
              id="comment"
              name="comment"
              required
            ></textarea>
            <mat-hint class="hint" *ngIf="hasError()">
              The max length of the comment is 400 characters.
            </mat-hint>
          </mat-form-field>
        </div>
      </form>
    </div>

    <app-responsive-table
      *ngIf="!loading"
      [loading]="loading"
      [backgroundLoading]="backgroundLoading"
      [settings]="settingTable"
      [columns]="productTableColumnConfiguration"
      [dataType]="'comments'"
      [data]="productTableRows"
      [isEndlessScroll]="true"
      (loadingChange)="onLoadingChange($event)"
      (selected)="onSelected($event)"
      (onSelectedItems)="onSelectedItems($event)"
      (onSortEvent)="onTableSort($event)"
      (onPageChangeEvent)="onTablePageChange($event)"
    ></app-responsive-table>
  </div>
</div>
