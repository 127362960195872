<div class="radialbar" *ngIf="series">
  <apx-chart
    [series]="series"
    [labels]="labels"
    [colors]="colors"
    [chart]="chartOptions.chart"
    [plotOptions]="chartOptions.plotOptions"
    [legend]="chartOptions.legend"
    [responsive]="chartOptions.responsive"
    [stroke]="chartOptions.stroke"
    [fill]="chartOptions.fill"
  ></apx-chart>
</div>
