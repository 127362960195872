import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { from, Observable, Subject, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { EventMessage } from '@azure/msal-browser';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { PermissionModel } from 'src/app/models/permission.model';
import { TranslateService } from 'src/app/services/translate-service/translate.service';
import { AlertService } from 'src/app/services/alert-service/alert.service';
import * as _rolesConst from 'src/app/constants/userRoles';
import { TranslatePipe } from 'src/app/pipes/translate-pipe/translate.pipe';
import { environment } from 'src/environments/environment';
import { Reason } from 'src/app/models/close-reason.model';
import { RefreshTokenComponent } from 'src/app/components/modals/refresh-token/refresh-token.component';
import { MsalAuthenticationService } from 'src/app/security/utils/msal-authentication.service';
import { IAuthenticationService } from './authentication.service.interface';
import { LoginProviderService } from '../login-provider/login-provider.service';
import { ProviderModel } from 'src/app/models/provider.model';
import { Product } from 'src/app/models/product.model';

export enum AuthenticationType {
  msal = 'msal',
  siteminder = 'siteminder'
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService
  implements IAuthenticationService, OnDestroy {
  /**
   * Current logged in user recieved from the API.
   * @private Exposed through getters/setters
   */
  private _loggedInUser: any = null;
  /**
   * Token recieved in the of the logged in user.
   * @private Exposed through getters/setters
   *
   */
  private _token: string = '';
  /**
   * The tenant id that is included in the request's header.
   * @private Exposed through getters/setters
   */
  private _idTenantHeader: string = '';
  /**
   * The current Authentication Type
   * @summary msal, siteminder
   * @private Exposed through getters/setters
   */
  private _authType: AuthenticationType | any;

  /**
   * The name of the current tenant
   */
  private currentTenantName: string = '';

  /**
   * App language used to translate
   */
  private _appLanguage: string = '';
  public get appLanguage(): string {
    return this._appLanguage;
  }


  get isLoggedSuccessEvent$() { return this.loginProviderService.isLoggedSuccessEvent$; }

  get user() {

    if (environment.mock_authentication) {
      // return this.getMockUser();
    }

    return this.loginProviderService.getUser();

  }

  loggedUserHasRole(roleNames: string[]) {
    const hasRoleIncluded = !!this._loggedInUser?.role.name && roleNames.includes(this._loggedInUser?.role.name);

    return !!this.user || hasRoleIncluded;
  }

  /**
   * Notifies through its observable each time a new user is set.
   */
  private userLoggedInSubject = new Subject<any>();
  /**
   * Notifies the current logged in user to its subscribers
   * each time a new user is set.
   */
  public userLoggedIn: Observable<any> =
    this.userLoggedInSubject.asObservable();
  /**
   * Notifies through its observable each time the token is manually renewed.
   */
  private tokenManuallyRenewedSubject = new Subject<any>();
  /**
   * Notifies each time the token is manually renewed.
   */
  public tokenManuallyRenewed: Observable<any> =
    this.tokenManuallyRenewedSubject.asObservable();

  /**
   * Current url. Updated via router's navigationend events
   */
  private currentUrl: string = '';
  /**
   * Contains a reference to the subscriptions
   * generated in this service. Disposed on destroy.
   */
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService,
    private translate: TranslatePipe,
    private modalService: NgbModal,
    public msalAuthService: MsalAuthenticationService,
    protected loginProviderService: LoginProviderService
  ) {
    this.setAuthType('msal'); //TODO: Add to environment
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe({
        next: (event: NavigationEnd | any) => {
          this.currentUrl = event.url
        },
        error: (err: any) => {
          console.log(err); //todo: make an alert
        }
      });
  }

  //#region Getters and Setters

  /**
   * @returns The user currently logged in
   */
  public getLoggedInUser(): any {
    return this._loggedInUser;
  }

  /**
   * Sets the logged in user.
   * @param user
   */
  public setLoggedInUser(user: any): void {
    this._loggedInUser = user.user || user; //we have to access to user property to avoid errors
    if (environment.mock_authentication) {

    }
    this._loggedInUser['expiration'] = 0;
    // this._loggedInUser['isadmin'] =
    //   user.role.rolename === _rolesConst.ADMINISTRATOR;
    this.setApplicationLanguage(
      // this._loggedInUser['language']['langCode'],
      this._loggedInUser['language'],
      this._loggedInUser['translations']
    );
    this.userLoggedInSubject.next(this._loggedInUser);
  }

  /**
   * Returns the current logged in user token
   * from the auth type used.
   * @returns token
   */
  public getToken(): string {
    return this._token ?? '';
  }

  /**
   * Sets the token.
   * @summary [!Careful when setting]
   * @param token new token
   */
  public setToken(token: string): void {
    this._token = token;
  }

  /**
   * @returns The tenant id that is included in the request's header.
   */
  public getIdTenantHeader(): string {
    return this._idTenantHeader;
  }

  /**
   * Sets the tenant id that is included in the request's header.
   * @param idTenantHeader new tenant id
   */
  public setIdTenantHeader(idTenantHeader: string): void {
    this._idTenantHeader = idTenantHeader.toString();
  }

  /**
   * @returns the current Auth Type (eg: msal)
   */
  public getAuthType(): AuthenticationType {
    return this._authType;
  }

  /**
   * Sets the new auth type
   * @param authType The new auth type
   * @summary [!Careful when setting]
   */
  public setAuthType(authType: AuthenticationType | string): void {
    this._authType = authType as AuthenticationType;
  }

  /**
   *
   * @returns The user token expiration date,
   * undefined if no user is set
   */
  public getTokenExpirationDate(): Date | any {
    if (!this._loggedInUser) {
      return undefined;
    }
    return this._loggedInUser['expiration'];
  }

  /**
   * Sets the expiration date
   * @param date
   * @returns
   */
  public setTokenExpirationDate(date: Date): void {
    this._loggedInUser['expiration'] = date;
  }

  /**
   * Sets the privacy note accepted value
   * @param value
   */
  public setLoggedInUserPrivacyNote(value: boolean): void {
    this._loggedInUser['privacyNoteAccepted'] = value;
  }

  /**
   * Sets the tenant name
   */
  public setTenantName(tenantname: string): void {
    this.currentTenantName = tenantname;
  }

  //#endregion

  /**
   * Checks if the token is not valid
   * @returns
   */
  public isValidToken(): boolean {
    if (!this._loggedInUser || new Date() > this._loggedInUser['expiration']) {
      this.logOut();
      return false;
    }
    return true;
  }

  /**
   * get tenant

   */
  public getTenant(): any {
    const user = this.getLoggedInUser();
    let currentTenant;
    if (!user) {
      return undefined;
    }

    if (this.currentTenantName == '') {
      currentTenant = user.tenants[0];
    } else {
      currentTenant = this.getLoggedInUser().tenants.find(
        (tenant: any) => tenant.tenantname == this.currentTenantName
      );
    }
    return currentTenant;
  }

  /**
   * Gets the tenants from the route snapshot
   * @returns Tenant
   */
  public getTenantNameFromUrl(): string {
    const tenant = this.currentUrl.split('/')[1];
    return tenant === 'tenant' ? '' : tenant;
  }

  /**
   *
   * @returns current tenant name
   */
  public getTenantName(): string | any {
    const currentTenantId = this._idTenantHeader;
    if (!this._loggedInUser) {
      return undefined;
    }
    const currentTenant = this._loggedInUser.tenants.find(
      (tenant: any) => tenant.tenantid == currentTenantId
    );
    return currentTenant.tenantname;
  }

  /**
   *
   * @returns Tenant display name
   */
  public getTenantDisplayName(): string | any {
    const currentTenantId = this._idTenantHeader;
    const user = this.getLoggedInUser();
    // console.log('User: ', user);
    if (!user) {
      return undefined;
    }
    const currentTenant = this.getLoggedInUser().tenants.find(
      (tenant: any) => tenant.tenantid == currentTenantId
    );
    return currentTenant.tenantdisplayname;
  }

  /**
   *
   * @returns The tenant tool name
   */
  public getTenantToolName(): string | any {
    const currentTenantId = this._idTenantHeader;
    const user = this.getLoggedInUser();
    if (!user) {
      return undefined;
    }
    const currentTenant = this.getLoggedInUser().tenants.find(
      (tenant: any) => tenant.tenantid == currentTenantId
    );
    return currentTenant.toolName;
  }

  /**
   * Checks if a user has access to a module.
   * If not, an alert is raised and it navigates
   * to the corresponding route.
   * @param module
   */
  public checkAccess(module: any): void {
    this.router.navigate(['/']);
    // if (
    //   !this._loggedInUser ||
    //   !this.userCanViewModule(this._loggedInUser, module)
    // ) {
    //   this.alertService.error(
    //     this.translate.transform('login.checkAccess'),
    //     true,
    //     ''
    //   );

    //   if (this._loggedInUser.homeurl) {
    //     this.router.navigate([this._loggedInUser.homeurl]);
    //   } else {
    //     this.router.navigate(['/']);
    //   }
    // }
  }

  /**
   * Gets tenant by name
   * @param tenantname
   * @returns current Tenant
   */
  public getTenantByName(tenantname: any): string | any {
    const user = this.getLoggedInUser();
    if (!user || tenantname === undefined) {
      return undefined;
    }
    const userTenantsMatch = user.tenants.find(
      (tenant: any) => tenant.tenantname.toLowerCase() === tenantname.toLowerCase()
    );
    return userTenantsMatch;
  }

  /**
   * Sets the tenant in the local storage
   * @param tenantid
   * @param tenantname
   */
  public setTenantInLocalStorage(tenantid: any, tenantname: any): void {
    const d = JSON.stringify({
      tenantname: tenantname,
      tenantid: tenantid
    });
    localStorage.setItem('tenant', d);
  }

  /**
   *
   * @returns False if none is found, the tenant otherwise.
   */
  public getTenantFromLocalStorage(): any {
    const tenant = localStorage.getItem('tenant');
    if (tenant) {
      return JSON.parse(localStorage.getItem('tenant') || '');
    }
    return false;
  }

  /**
   * Sets the user permissions
   * @param permissions
   */
  public setPermissions(permissions: any): void {
    this._loggedInUser.permissions = permissions;
  }

  /**
   * Checks if a user has a certain permission
   * @param permission
   * @returns
   */
  public hasPermission(permission: string[] | string): boolean {
    if (!permission) {
      return true;
    }

    const arrPermissions =
      typeof permission === 'string' ? [permission] : [...permission];
    let allow = false;
    arrPermissions.forEach(perm => {
      if (
        this._loggedInUser.role.permissions.find(
          (p: PermissionModel) => p.name === perm
        )
      ) {
        allow = true;
      } else {
        allow = false;
      }
    });
    return allow;
  }

  /**
   * Checks if a user can view a module
   * @param user
   * @param moduleName
   * @returns
   */
  public userCanViewModule(user: any, moduleName: any): any {
    //return user.modules && user.modules.some(m => m.modulename === moduleName);
    // return user.role.permissions && user.role.permissions.some(p => p.permissionName === moduleName)
    return true;
  }

  /**
   * Gets the dashboards of the current user
   * @returns An array containing
   */
  public getUserDashboards(): any[] {
    const user = this.getLoggedInUser();
    return user.dashboards;
  }

  /**
   * Renews the token session
   */
  //todo: copy this modal from fnn
  public renewToken(modalOptions: {
    useModal: boolean;
    isAboutToExpire?: boolean;
  }): void {
    if (!modalOptions.useModal) {
      this.onRenewToken();
    } else {
      this.openRenewTokenModal(modalOptions.isAboutToExpire || false)
        .pipe(take(1))
        .subscribe((res: Reason) => {
          if (res === Reason.submitBtn) {
            this.onRenewToken()
          }
        });
    }
  }

  private onRenewToken(): void {
    this.msalAuthService
      .renewToken()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this._token = result.token;
          this.setTokenExpirationDate(result.expirationDate);
          const message = this.translate.transform('common.tokenRenewed');
          this.alertService.success(message);
          this.tokenManuallyRenewedSubject.next(true);
        } else {
          const message = this.translate.transform(
            'common.tokenRenewError'
          );
          this.alertService.error(message);
        }
      });

  }

  private openRenewTokenModal(isAboutToExpire: boolean): Observable<Reason> {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm',
      backdropClass: 'modal__top-backdrop',
      windowClass: 'modal__top-window'
    };
    const modalWindowRef = this.modalService.open(
      RefreshTokenComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      isAboutToExpire
    };
    return from(modalWindowRef.result);
  }

  /**
   * Reloads the page to acquire a new token.
   */
  public onTokenExpired(): void {
    switch (this._authType) {
      case AuthenticationType.msal:
        this.msalAuthService.onTokenExpired();
        break;
      case AuthenticationType.siteminder:
        location.reload();
        break;
    }
  }

  /**
   * Logs out from the current user.
   */
  public logOut(): void {
    this._loggedInUser = null;
    this._token = '';
    switch (this._authType) {
      case AuthenticationType.msal:
        this.msalAuthService
          .logOut()
          .pipe(take(1))
          .subscribe((errorMessage: EventMessage) => {
            if (errorMessage != null) {
              console.error('Error during logout: ');
              this.router.navigate(['/']);
            }
          });
        break;
      case AuthenticationType.siteminder:
        location.reload();
        break;
    }
  }

  /**
   *
   * @returns Returns the url tree to the default route
   */
  public getDefaultRouteUrlTree(): UrlTree {
    return this.router.createUrlTree(['/']);
  }

  /**
   * Navigates to the privacy note confirmation page
   * @returns
   */
  public navigateToPrivacyNoteConfirmation() {
    this.userLoggedInSubject.next(null);
    this.router.navigate([`${this.getTenantName()}/privacy-note`]);
    return false;
  }

  /**
   *
   * @returns An Url Tree object to navigate to the tenants privacy note route
   */
  public getPrivacyNoteConfirmationUrlTree(): UrlTree {
    return this.router.createUrlTree([`${this.getTenantName()}/privacy-note`]);
  }

  /**
   * Navigates to not allowed and notifies the user subject subscribers
   */
  public navigateToNotAllowed(): void {
    this.userLoggedInSubject.next(null);
    this.router.navigate(['/unauthorized']);
  }

  /**
   *
   * @returns An Url Tree object to navigate to the access-denied route
   */
  public getNotAllowedUrlTree(): UrlTree {
    return this.router.createUrlTree(['/unauthorized']);
  }

  /**
   * Navigates to server error and notifies the user subject subscribers
   */
  public navigateToServerError(): void {
    this.userLoggedInSubject.next(null);
    this.router.navigate(['/unauthorized']);
  }

  /**
   *
   * @returns An Url Tree object to navigate to the /server-unavailable route
   */
  public getServerErrorUrlTree(): UrlTree {
    return this.router.createUrlTree(['/unauthorized']);
  }

  getUserTest() {
    return localStorage.getItem('userTest');
  }

  /**
   * Sets the application language
   * @param languagecode
   * @param translations
   */
  public setApplicationLanguage(languagecode: any, translations: any): void {
    this._appLanguage = languagecode;
    //todo: uncomment
    // this.translateService.use(languagecode, translations);
  }

  public ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub?.unsubscribe();
    }
  }

  hasEditPermission(providerId: string | any, product?: Product): boolean {
    const user = this.getLoggedInUser();
    if (user.role.name === "EY Contributor" || user.role.name === "External Provider") {
      if (user.providers.some((provider: ProviderModel) => provider.id === providerId) || 
      user.providers.some((provider: ProviderModel) => provider.id === product?.provider.providerId)
        ) {
          return true;
        }
    }
    else {
      if (user.role.permissions.some((permission: any) => permission.name === 'can-edit-product-profile') && 
        user.role.permissions.some((permission: any) => permission.name === 'can-edit-provider-profile')
      ) {
          return true;
      }
    }
    return false;
  }

  canViewNotes(): boolean {
    const user = this.getLoggedInUser();
    if (user.role.name === "External Provider") {
      return false;
    } else {
      return true;
    }
  }
}
