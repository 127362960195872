import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Alert, AlertType } from './alert.model';

@Injectable({
  providedIn: "root",
})
export class AlertService {
  private keepAfterRouteChange = false;
  private alertInfo: BehaviorSubject<Alert | undefined>;

  constructor(private router: Router) {
    this.alertInfo = new BehaviorSubject<Alert | undefined>(new Alert());
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false;
        } else {
          this.clear();
        }
      }
    });
  }

  success(message: string, keepAfterRouteChange = false, optional = '') {
    this.alert('success', message, keepAfterRouteChange, optional);
    const that = this;
    setTimeout(function () {
      that.clear();
    }, 9000);
  }

  error(message: string, keepAfterRouteChange = false, optional = '') {
    this.alert('danger', message, keepAfterRouteChange, optional);
    const that = this;
    setTimeout(function () {
      that.clear();
    }, 9000);
  }

  info(message: string, keepAfterRouteChange = false, optional = '') {
    this.alert('info', message, keepAfterRouteChange, optional);
    const that = this;
    setTimeout(function () {
      that.clear();
    }, 9000);
  }

  warn(message: string, keepAfterRouteChange = false, optional = '') {
    this.alert('warning', message, keepAfterRouteChange, optional);
    const that = this;
    setTimeout(function () {
      that.clear();
    }, 9000);
  }

  alert(
    type: AlertType,
    message: string,
    keepAfterRouteChange = false,
    optional = ''
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.setValue(<Alert>{ type: type, message: message, optional: optional });
  }

  clear() {
    this.setValue(void 0);
  }

  getValue(): Observable<Alert | undefined> {
    return this.alertInfo.asObservable();
  }

  setValue(newValue?: Alert): void {
    console.log('AlertService.setValue()');
    this.alertInfo.next(newValue);
  }
}
