import { Component, OnInit } from '@angular/core';

import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

// TODO delete?
// export type ChartOptions = {
//   series: ApexAxisChartSeries;
//   chart: ApexChart;
//   dataLabels: ApexDataLabels;
//   title: ApexTitleSubtitle;
//   plotOptions: ApexPlotOptions;
//   legend: ApexLegend;
//   tooltip: ApexTooltip;
//   colors: string[];
// };

@Component({
  selector: 'app-treemap-chart',
  templateUrl: './apex-treemap-chart.component.html',
  styleUrls: ['./apex-treemap-chart.component.scss'],
})
export class ApexTreemapChartComponent extends ApexChartBase implements OnInit {
  constructor(chartsService: ChartsService, utilsService: UtilsService) {
    super(chartsService, utilsService);
    this.defaultHeight = 300;
    this.defaultWidth = 500;
  }

  protected setChartOptions() {
    this.chartOptions = {
      legend: {
        show: false,
      },
      chart: {
        type: 'treemap',
        toolbar: {
          show: false,
        },
        fontFamily: this.fontFamily,
      },
      tooltip: {
        theme: 'dark',
        enabled: true,
      },
      fill: {
        colors: this.colors,
      },
      stroke: {
        width: 5,
        colors: [this.chartsService.getBackgroundColor()],
      },
      dataLabels: {
        style: {
          fontFamily: this.fontFamily,
          fontWeight: 400,
        },
      },
    };
  }
}
