<div class="container">
  <!-- DRAG AND DROP AREA, TODO: MOVE IT TO A COMPONENT -->
  <div class="file-attachment">
    <input #fileInput type="file" multiple (change)="onFileSelected($event)" />
    <div
      class="dropzone"
      (drop)="onFilesDropped($event)"
      (dragover)="onDragOver($event)"
      (click)="onChooseFiles($event)"
    >
      <p>Drag and drop files here, or click to select files</p>
    </div>
  </div>
  <!-- END OF DRAG AND DROP AREA, MOVE IT TO A COMPONENT -->
  <app-icon-grid [files]="files" *ngIf="files.length > 0"></app-icon-grid>
</div>
<!-- <button (click)="uploadFiles()">Upload Files</button> -->
