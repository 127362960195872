<div class="chart">
    <apx-chart
      [series]="series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [stroke]="chartOptions.stroke"
      [markers]="chartOptions.markers"
      [fill]="chartOptions.fill"
      [title]="chartOptions.title"
      [plotOptions]="chartOptions.plotOptions"
      [legend]="chartOptions.legend"
      [grid]="chartOptions.grid"
      [tooltip]="chartOptions.tooltip"
      [colors]="colors"
    ></apx-chart>
</div>
