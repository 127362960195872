import { Component, OnInit } from '@angular/core';
import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

@Component({
  selector: 'app-radialbar-chart',
  templateUrl: './apex-radialbar-chart.component.html',
  styleUrls: ['./apex-radialbar-chart.component.scss'],
})
export class ApexRadialbarChartComponent
  extends ApexChartBase
  implements OnInit
{
  constructor(chartsService: ChartsService, utilsService: UtilsService) {
    super(chartsService, utilsService);
    this.defaultWidth = 300;
    this.defaultHeight = 150;
  }

  protected setChartOptions(): void {
    this.chartOptions = {
      chart: {
        type: 'radialBar',
        fontFamily: this.fontFamily,
      },

      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          track: {
            background: this.chartsService.getBackgroundColor(),
          },
          hollow: {
            margin: 5,
            size: '20%',
            background: 'transparent',
          },
          dataLabels: {
            name: {
              fontSize: '10px',
            },
            value: {
              fontSize: '8px',
            },
          },
        },
      },

      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 50,
        offsetY: 10,
        labels: {
          useSeriesColors: true,
        },
        formatter: (seriesName: string, opts: any) => {
          return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex];
        },
        itemMargin: {
          horizontal: 3,
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    };
  }
}
