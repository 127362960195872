import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { ProductDatasetService } from 'src/app/services/product-dataset-service/product-dataset-service.service';
import { RoutingInfoService } from 'src/app/services/routing-info/routing-info.service';

@Component({
  selector: 'app-remove-dataset',
  templateUrl: './remove-dataset.component.html',
  styleUrls: ['./remove-dataset.component.scss']
})
export class RemoveDatasetComponent implements OnInit {
  public myForm: FormGroup | any;
  availableDatasets: string[] = [];
  @Input() data!: any;

  constructor(
    public productDataSetService: ProductDatasetService,
    private routingInfo: RoutingInfoService
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.attributes) {
      this.availableDatasets =  this.data.attributes.map((attribute: { dataSetName: any; }) => attribute.dataSetName);
    }
    this.createForm();
  }

  createForm() {
    this.myForm = new FormGroup({
      availableDatasets: new FormControl([], Validators.required),
    });
  }
}
