import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiService } from '../api/api-service/api.service';
import { PageModel } from '../api/pagination-service/model/page.model';

interface DataValidations {
  id: string;
  description: string;
  versionDate?: string;
  fields?: any[];
  characteristics?: any[];
}

@Injectable({
  providedIn: 'root'
})
export class DataValidationService {

  readonly model: string = "data-validation";

  constructor(
    private apiService: ApiService
  ) { }

  getProviderDataValidation$(){
    const request$ = this.apiService.get<PageModel<DataValidations>>(this.model, {}, '').pipe(
      map((data: PageModel<DataValidations>) => {
        let indexOfProviderValidations = -1;
        let latestDate = '';
  
        data.data.forEach((d: DataValidations, index: number) => {
          const includesPlayerDescription = d.description.toLowerCase().includes("players") || d.description.toLowerCase().includes("player");
  
          if (includesPlayerDescription && d.versionDate) {
            // Check if the versionDate is more recent than the current latestDate
            if (d.versionDate > latestDate) {
              latestDate = d.versionDate;
              indexOfProviderValidations = index;
            }
          }
        });
  
        // Return the entry with the most recent versionDate
        return indexOfProviderValidations !== -1 ? data.data[indexOfProviderValidations] : null;
      })
    );
    return request$;
  }

  getProductDataValidation$(): Observable<any>{
    const request$ = this.apiService.get<PageModel<DataValidations>>(this.model, {}, '').pipe(
      map((data: PageModel<DataValidations>) => {
        let indexOfOfferingValidations = -1;
        let latestDate = '';

        data.data.forEach((d: DataValidations, index: number) => {
          const includesOfferingDescription = d.description.toLowerCase().includes("offerings") || d.description.toLowerCase().includes("offering");
  
          if (includesOfferingDescription && d.versionDate) {
            // Check if the versionDate is more recent than the current latestDate
            if (d.versionDate > latestDate) {
              latestDate = d.versionDate;
              indexOfOfferingValidations = index;
            }
          }
        });
        
        // Return the entry with the most recent versionDate
        return indexOfOfferingValidations !== -1 ? data.data[indexOfOfferingValidations] : null;
      }) 
    );
    return request$;
  }
}
