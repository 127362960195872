import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFieldValue'
})
export class FormatFieldValuePipe implements PipeTransform {

  transform(value: string | any): string {
    if (!value) {
      return value;
    }
    if(!isNaN(value)){
      return value.toLocaleString('en-US').replace(/,/g, '.');
    }

    let formattedValue = value.replace(/\\n/g, ' \n');

    const bullets = ['•', '\\*', '·'];
    bullets.forEach(bullet => {
      const regex = new RegExp(`\\s*${bullet}`, 'g');
      formattedValue = formattedValue.replace(regex, `\n${bullet}`);
    });

    return formattedValue.trim();
  }
}

