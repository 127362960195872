<div class="file-icon">
  <ng-container *ngTemplateOutlet="mySVG"></ng-container>

  <!-- <img
    [src]="imageSource"
    alt="File Icon"
    class="icon-image"
    (click)="selectCheckbox($event)"
  /> -->
  <div class="file-name" (click)="selectCheckbox($event)">{{ fileName }}</div>

  <mat-checkbox
    class="file-checkbox"
    color="primary"
    [(ngModel)]="checked"
    [ngClass]="{ hide: !checked }"
    (change)="selectedChange($event)"
  ></mat-checkbox>

  <!--
  <input
    class="file-checkbox"
    type="checkbox"
  /> -->
</div>

<ng-template #mySVG>
  <!-- src/assets/img/icons/document.svg -->
  <svg
    alt="File Icon"
    class="icon-image"
    (click)="selectCheckbox($event)"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
  >
    <g>
      <path
        d="M38.914,0H6.5v60h47V14.586L38.914,0z M39.5,3.414L50.086,14H39.5V3.414z M8.5,58V2h29v14h14v42H8.5z"
      />
      <path
        d="M16.5,17h9c0.552,0,1-0.447,1-1s-0.448-1-1-1h-9c-0.552,0-1,0.447-1,1S15.948,17,16.5,17z"
      />
      <path
        d="M33.5,37h-17c-0.552,0-1,0.447-1,1s0.448,1,1,1h17c0.552,0,1-0.447,1-1S34.052,37,33.5,37z"
      />
      <path
        d="M43.5,30h-7c-0.552,0-1,0.447-1,1s0.448,1,1,1h7c0.552,0,1-0.447,1-1S44.052,30,43.5,30z"
      />
      <path
        d="M16.5,25h5c0.552,0,1-0.447,1-1s-0.448-1-1-1h-5c-0.552,0-1,0.447-1,1S15.948,25,16.5,25z"
      />
      <path
        d="M31.5,25h7c0.552,0,1-0.447,1-1s-0.448-1-1-1h-7c-0.552,0-1,0.447-1,1S30.948,25,31.5,25z"
      />
      <path
        d="M25.5,24c0,0.26,0.11,0.52,0.29,0.71C25.98,24.89,26.24,25,26.5,25c0.26,0,0.52-0.11,0.71-0.29
		c0.18-0.19,0.29-0.45,0.29-0.71c0-0.261-0.11-0.521-0.29-0.71c-0.37-0.37-1.04-0.37-1.42,0C25.61,23.479,25.5,23.739,25.5,24z"
      />
      <path
        d="M21.5,30c-0.552,0-1,0.447-1,1s0.448,1,1,1h10c0.552,0,1-0.447,1-1s-0.448-1-1-1H21.5z"
      />
      <path
        d="M16.5,32c0.26,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71c0-0.261-0.11-0.521-0.29-0.71
		c-0.37-0.37-1.05-0.37-1.42,0c-0.18,0.189-0.29,0.439-0.29,0.71c0,0.26,0.11,0.52,0.29,0.71C15.98,31.89,16.24,32,16.5,32z"
      />
      <path
        d="M43.5,44h-7c-0.552,0-1,0.447-1,1s0.448,1,1,1h7c0.552,0,1-0.447,1-1S44.052,44,43.5,44z"
      />
      <path
        d="M31.5,44h-10c-0.552,0-1,0.447-1,1s0.448,1,1,1h10c0.552,0,1-0.447,1-1S32.052,44,31.5,44z"
      />
      <path
        d="M15.79,44.29c-0.18,0.18-0.29,0.439-0.29,0.71c0,0.27,0.1,0.52,0.29,0.71C15.98,45.89,16.24,46,16.5,46
		c0.27,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71c0-0.271-0.11-0.521-0.29-0.7C16.84,43.92,16.17,43.92,15.79,44.29z"
      />
      <path
        d="M42.79,23.29c-0.18,0.189-0.29,0.449-0.29,0.71c0,0.26,0.11,0.52,0.29,0.71C42.98,24.89,43.24,25,43.5,25
		c0.26,0,0.52-0.11,0.71-0.29c0.19-0.19,0.29-0.45,0.29-0.71c0-0.261-0.1-0.521-0.29-0.71C43.83,22.92,43.17,22.92,42.79,23.29z"
      />
      <path
        d="M39.21,38.71c0.18-0.19,0.29-0.45,0.29-0.71c0-0.261-0.11-0.521-0.29-0.71c-0.38-0.37-1.05-0.37-1.42,0
		c-0.18,0.189-0.29,0.449-0.29,0.71c0,0.26,0.11,0.52,0.29,0.71C37.98,38.899,38.23,39,38.5,39S39.02,38.89,39.21,38.71z"
      />
      <path
        d="M42.79,37.29c-0.18,0.189-0.29,0.449-0.29,0.71c0,0.26,0.11,0.52,0.29,0.71C42.98,38.89,43.23,39,43.5,39
		s0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71c0-0.261-0.11-0.521-0.29-0.71C43.84,36.92,43.16,36.92,42.79,37.29z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</ng-template>
