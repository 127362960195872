import { Injectable } from '@angular/core';
import * as chartConstants from 'src/app/constants/chartsConstants';

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  constructor() {}

  public getFontFamily(): string {
    return chartConstants.fontFamily;
  }

  public getFontColor(): string {
    return chartConstants.fontColor;
  }

  public getAxisColor(): string {
    return chartConstants.axisColor;
  }

  public getConfidenceColor(): string {
    return chartConstants.confidenceColor;
  }

  public getHighlightColor(): string {
    return chartConstants.highlightColor;
  }

  public getBackgroundColor(): string {
    return chartConstants.backgroundColor;
  }

  public getLightBackgroundColor(): string {
    return chartConstants.lightBackgroundColor;
  }

  public getApexColorBySpeaker() {
    return chartConstants.speakerColorsHex;
  }

  public getLanguageColors() {
    return chartConstants.languageColorsHex;
  }

  public getTimeColors() {
    return chartConstants.timeColorsHex;
  }

  public getSentimentColors() {
    return chartConstants.sentimentColorsHex;
  }

  public getAlternativeColors(length?: number) {
    if (!length) {
      return chartConstants.alternativeColors;
    } else {
      let colors = [];
      for (let i = 0; i < length; i++) {
        colors.push(chartConstants.alternativeColors[i]);
      }
      return colors;
    }
  }

  public getColorBySpeakerName(speaker: string): string {
    return chartConstants.speakerColorsByNameGenesys[speaker];
  }

  public getColorBySpeaker(speakers: number[]): string[] {
    let colors: string[] = [];
    for (const speaker of speakers) {
      if (speaker > 0) {
        colors.push(chartConstants.speakerColorsHex[speaker - 1]);
      }
    }
    return colors;
  }

  public getHexColorBySpeaker(speaker: number): string | undefined {
    if (speaker - 1 < chartConstants.speakerColorsHex.length) {
      return chartConstants.speakerColorsHex[speaker - 1];
    }
    return undefined;
  }

  public getChartColorsRawBySpeaker(speakers: number): string[] {
    let colors: string[] = [];
    for (let i = 0; i < speakers; i++) {
      colors.push(chartConstants.speakerColorsHex[i]);
    }
    return colors;
  }

  public hexToRgbA(hex: string, opacity: number | string) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = Number('0x' + c.join(''));
      return (
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        ',' +
        opacity +
        ')'
      );
    }
    throw new Error('Bad Hex');
  }
}
