<form class="valifilters" [formGroup]="myForm" (ngSubmit)="sendParamFilter()">
  <div class="valifilters__buttons">
    <div class="title">{{filtersTitle}} Filters</div>
    <div>
    <button
      class='pagehead__clear-button'
      [ngClass]="{ 'pagehead__clear-button--disabled': myForm.pristine }"
      [disabled]="myForm.pristine"
      (click)="cleanFilters()"
      type="button"
    >
      {{ 'page.admin.tabs.users.filter.button.clear' | translate : 'Clear' }}
    </button>

    <button
      class='pagehead__apply-button'
      [ngClass]="{ 'pagehead__apply-button--disabled': myForm.invalid || myForm.pristine }"
      [disabled]="myForm.invalid || myForm.pristine"
      type="submit"
    >
      {{ 'page.admin.tabs.users.filter.button.apply' | translate : 'Apply' }}
    </button>
    </div>
  </div>
  <div class="fields">
    <!--Form fields loop-->
    <ng-container *ngFor="let field of fields">
      <ng-container [ngSwitch]="field.type">
        <ng-container *ngSwitchCase="'input'">
          <!-- input -->
          <div class='input-box'>
          <mat-label> {{ 'page.admin.tabs.users.filter.' + key + field.name | translate : field.name }} </mat-label>
          <mat-form-field>
            <input matInput placeholder="{{ 'page.admin.tabs.placeholder.filter.' + key + field.name | translate : field.name }}" [formControlName]="field.name" type="text" />
            <button
              matSuffix mat-icon-button aria-label="Clear"
              [class.invisible]="!checkReset(field.name)"
              (click)="resetField(field.name)"
              >
              <mat-icon>{{'clear'}}</mat-icon>
            </button>
          </mat-form-field>
          </div>

        </ng-container>
        <ng-container *ngSwitchCase="'dropdown'">
          <!-- select/dropdown -->
          <div class='input-box'>
            <mat-label> {{ 'page.admin.tabs.users.filter.' + key + field.name | translate : field.name }} </mat-label>
            <mat-form-field>
            <mat-select placeholder="{{ 'page.admin.tabs.placeholder.filter.' + key + field.name | translate : field.name }}" [formControlName]="field.name">
              <mat-option [value]="null">No value selected</mat-option>
              <mat-option *ngFor="let option of field.data" [value]="option.id || option.name">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'autocomplete'">
          <app-auto-complete-tree-source
            label="{{
              'page.admin.tabs.users.filter.' + key + field.name
                | translate : field.name
            }}"
            [placeholder]="'page.admin.tabs.placeholder.filter.' + key + field.name | translate : field.name"
            [allowMultiple]="field.multiple"
            [formControlName]="field.name"
            [source]="field.data"
            (onClearSingleAutocomplete)="resetField(field.name)"
          ></app-auto-complete-tree-source>
        </ng-container>

        <ng-container *ngSwitchCase="'dropdown-with-sections'">
          <div class='input-box'>
            <mat-label> {{ 'page.admin.tabs.users.filter.' + key + field.name | translate : field.name }} </mat-label>
            <mat-form-field>
            <mat-select placeholder="{{ 'page.admin.tabs.placeholder.filter.' + key + field.name | translate : field.name }}" [formControlName]="field.name">
              <mat-optgroup *ngFor="let section of dropdownData" [label]="section.label" class="custom-optgroup">
                <mat-option *ngFor="let option of section.options" [value]="option.id || option.name">{{option.name}}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'usersDropdown'">
          <!-- select/dropdown -->
          <div class='input-box'>
            <mat-label> {{ 'page.admin.tabs.users.filter.' + field.name | translate : field.name }} </mat-label>
            <mat-form-field>
            <mat-select [formControlName]="field.name">
              <mat-option *ngFor="let user of field.data" [value]="user.id">{{user.username}}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'numeric'">
          <!-- numeric input -->
          <div class='input-box'>
            <mat-label> {{ 'page.admin.tabs.users.filter.' + key + field.name | translate : field.name }} </mat-label>
            <mat-form-field>
            <input matInput placeholder="{{ 'page.admin.tabs.placeholder.filter.' + key + field.name | translate : field.name }}" [formControlName]="field.name" type="number" min="0" />
            <button
              matSuffix mat-icon-button aria-label="Clear"
              *ngIf="checkReset(field.name)"
              (click)="resetField(field.name)"
            >
            <mat-icon>{{'clear'}}</mat-icon>
          </button>
          </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'datepicker-range'">
          <div class='input-merged-row-date'>
            <mat-label>{{field.placeholder}}</mat-label>
            <mat-form-field [ngClass]="{'no-valid-data': myForm.controls[field.name + 'Start'].hasError('matDatepickerParse') || myForm.controls[field.name + 'End'].hasError('matDatepickerParse')}">
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" [formControlName]="field.name + 'Start'">
                <input matEndDate placeholder="End date" [formControlName]="field.name + 'End'">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <span 
              class="custom-hint" 
              *ngIf="myForm.controls[field.name + 'Start'].hasError('matDatepickerParse') || myForm.controls[field.name + 'End'].hasError('matDatepickerParse')"
            >The correct format is: MM/DD/YYYY</span>
          </div>
      </ng-container>

      <ng-container *ngSwitchCase="'numericpicker-range'">        
        <div class='input-merged-row'> 
          <mat-label>{{field.placeholder}}</mat-label>
          <div class="form-fields-container">
            <mat-form-field [ngClass]="{'no-valid-data': noValidMinRange(field) || crossValidationForRanges(field)}">
              <input matInput [formControlName]="field.name + 'Min'" placeholder="Min" [min]="field.min" [max]="field.max - 1" type="number">
            </mat-form-field>
            <mat-form-field [ngClass]="{'no-valid-data': noValidMaxRange(field) || crossValidationForRanges(field)}">
              <input matInput [formControlName]="field.name + 'Max'" placeholder="Max" [min]="field.min + 1" [max]="field.max" type="number">
            </mat-form-field>
          </div>
          <span class="custom-hint" *ngIf="crossValidationForRanges(field)">Error: Min range cannot be higher than Max range</span>
          <span class="custom-hint" *ngIf="noValidMinRange(field) && noValidMaxRange(field)">Error: Both ranges have wrong values</span>
          <span class="custom-hint" *ngIf="noValidMinRange(field) && !noValidMaxRange(field)">Error: Min range must be between {{field.min}} and {{field.max - 1}}</span>
          <span class="custom-hint" *ngIf="!noValidMinRange(field) && noValidMaxRange(field)">Error: Max range must be between {{field.min + 1}} and {{field.max}}</span>
        </div>
      </ng-container>

      </ng-container>
    </ng-container>
  </div>


</form>
