<div class="archive-modal">
    <div class="archive-question">
      <p>Archive offering?</p>
    </div>
    <div class="archive-desc">
      <p>This will archive the selected offering. If later this offering needs to be reactivated, the status can be changed from "archived" to "live" within the Editor Panel.</p>
    </div>
    <div class="options-btn-container">
      <button class="btn options-btn cancel" (click)="cancel()">Cancel</button>
      <button class="btn options-btn" (click)="archive()">Archive offering</button>
    </div>
</div>