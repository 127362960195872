import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../security/services/authentication/authentication.service';

@Component({
  selector: 'app-admin-contact',
  templateUrl: './admin-contact.component.html',
  styleUrls: ['./admin-contact.component.scss'],
})
export class AdminContactComponent implements OnInit {
  public userRole?: string;

  constructor(public authService: AuthenticationService) {
  }

  ngOnInit() {
    const loggedUser = this.authService.getLoggedInUser();
    this.userRole = loggedUser.role.name;
  }

}
