<!-- file-item.component.html -->

<div class="file-item">
  <div class="header">
    <button mat-icon-button class="remove-button" (click)="_onRemove($event)">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="content">
    <div class="attachment">
      <img [src]="imageUrl" alt="File icon" class="doc-icon"/>
      <!--
      <div class="att-overlay" (click)="downloadAttachment()">
        <img src="assets/img/icons/download.svg" alt="download-icon" class="down-icon">
      </div>
      -->
    </div>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'Description' }}</mat-label>
      <input
        matInput
        [(ngModel)]="file.description"
        placeholder="Description for {{ file.name }}"
      />
    </mat-form-field>
  </div>

  <div class="footer">
    <span class="file-title">{{ file.name }}</span>
  </div>
</div>

<!-- file-item.component.html -->

<!-- <div class="file-item">
  <div class="header">
    <button mat-icon-button class="remove-button" (click)="_onRemove($event)">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <img [src]="imageUrl" alt="File icon" />

  <div class="footer">
    <mat-form-field appearance="fill">
      <input matInput [value]="file.name" readonly />
    </mat-form-field>
    <span class="file-title">{{ file.name }}</span>
  </div>
</div> -->

<!-- <div class="file-item">
  <img [src]="imageUrl" alt="File icon" />
  <span class="file-title">{{ file.name }}</span>
  <button
    mat-icon-button
    class="remove-button"
    *ngIf="canDelete"
    (click)="_onRemove($event)"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-form-field>
    <mat-label>{{ 'Description' }}</mat-label>
    <input [(ngModel)]="file.description" matInput type="text" />
  </mat-form-field>
</div> -->

<!-- <div class="file-item">
  <div class="with-name">
    <div class="with-description">
      <div class="icon">
        <img [src]="imageUrl" alt="File icon" />
        <button
          mat-icon-button
          class="remove-button"
          *ngIf="canDelete"
          (click)="_onRemove($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-form-field>
        <mat-label>{{ 'Description' }}</mat-label>
        <input [(ngModel)]="file.description" matInput type="text" />
      </mat-form-field>
    </div>
    <span class="file-title">{{ file.name }}</span>
  </div>
</div> -->
