import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbModel } from 'src/app/models/breadcrumb.model';
import { Product } from 'src/app/models/product.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { LinkService } from 'src/app/services/link-service/link.service';
import { ProviderCollectionService } from 'src/app/services/providers/provider-collection/provider-collection.service';
import { BehaviorSubject, Subscription, filter } from 'rxjs';
import { ProductService } from 'src/app/services/product/product.service';
import { RoutingInfoService } from 'src/app/services/routing-info/routing-info.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [TitleCasePipe]
})

export class BreadcrumbComponent implements OnInit{

  public breadcrumbs: BreadcrumbModel[] = [];
  public static ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  private routerSubscription: Subscription;
  private url: any[] = [];
  public product: Product | any;
  public providerData: ProviderModel| any;
  public dataLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public link: LinkService,
    public productService: ProductService,
    public providerService: ProviderCollectionService,
    public routerInfo: RoutingInfoService,
    public titleCasePipe: TitleCasePipe,
  ) {
    this.routerSubscription = this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      this.url = [];
      this.createBreadcrumb(this.activatedRoute.root);
    })
  }

  ngOnInit(): void {}

  private createBreadcrumb(activatedRoute: ActivatedRoute) {
    this.dataLoaded$.subscribe((isLoaded: any) => {
      if (isLoaded) {
        const children: ActivatedRoute[] = activatedRoute.children;
        if (children.length === 0) {
          return;
        }
        for (const child of children) {
          const urlSegments = child.snapshot.url;
          const routeURL = urlSegments.map(segment => segment.path);
          let emptyCount = 0;
          for (const segment of routeURL) {
            if (segment !== '') {
              this.url.push(segment);
            } else {
              emptyCount++;
            }
          }
          const isEmpty = emptyCount === routeURL.length;
          if (!isEmpty) {
            const label = this.getLabel(child, urlSegments);
            if (label) {
              const replacedLabel = this.replaceIdwithName(label);
              this.breadcrumbs.push({
                label: replacedLabel,
                url: [...this.url]
              });
            }
          }

          this.createBreadcrumb(child);
        }
      }
    });
  }

  private replaceIdwithName(label: string): string {
    const value = this.url[this.url.length - 1];
    if (value && label.includes(value)) {
      if (this.product) {
        if (this.url.includes('product')) {
          return label.replace(value,`Offering: ${this.product.name}`);
        }
      }
      if (this.url.includes('provider')) {
        return label.replace(value, `Player: ${this.providerData.provider}`);
      }
    }
    return label;
  }


  private getLabel(child: ActivatedRoute, urlSegments: UrlSegment[]): string {
    //Check if data in routing, else try to get label from url
    return child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB] ??
      this.capitalizeInitialLetter(urlSegments[urlSegments.length - 1]?.path);
  }

  /**
 * Capitalizes first letter
 */
  private capitalizeInitialLetter(url: string) {
    if (url == "search") {
      return "Search Engine";
    } else if(url == "admin"){
      return "Admin Panel"
    } else if(url == "extraction-tool"){
      return "Export Tool";
    } else if(url == "license-management"){
      return "EY License Management";
    }if (url) {
      return this.titleCasePipe.transform(url.split('-').join(' '));
    }
    return url;
  }

  /**
   * On destroy handler
   */
  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }
}
