import { Component, OnInit, Input } from '@angular/core';

import { ApexChartBase } from 'src/app/base-classes/apex-chart.base';
// import { PercentagePipe } from "src/app/pipes/percentage.pipe";
import { ChartsService } from 'src/app/services/charts-service/charts.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';

@Component({
  selector: 'app-apex-stroked-radial-chart',
  templateUrl: './apex-stroked-radial-chart.component.html',
  styleUrls: ['./apex-stroked-radial-chart.component.scss'],
})
export class ApexStrokedRadialChartComponent
  extends ApexChartBase
  implements OnInit
{
  @Input() result: any;
  constructor(
    chartsService: ChartsService,
    // private percentage: PercentagePipe,
    utilsService: UtilsService
  ) {
    super(chartsService, utilsService);
    this.defaultHeight = 300;
  }

  // TODO fix or remove this
  protected setChartOptions() {
    //     console.log("---> ", this.result);
    //     let chartColor;
    //     let label;
    //     if (this.result < 30) {
    //         chartColor = "#DD5753";
    //         label = "Detractor";
    //     } else if (this.result < 60) {
    //         chartColor = "#ffe600";
    //         label = "passive";
    //     } else {
    //         chartColor = "#55C87E";
    //         label = "promoter";
    //     }
    //     this.chartOptions = {
    //         series: [this.result],
    //         chart: {
    //             height: 350,
    //             type: "radialBar",
    //             offsetY: -10,
    //         },
    //         plotOptions: {
    //             radialBar: {
    //                 startAngle: -135,
    //                 endAngle: 135,
    //                 dataLabels: {
    //                     name: {
    //                         fontSize: "16px",
    //                         color: "white",
    //                         offsetY: 120,
    //                     },
    //                     value: {
    //                         offsetY: 76,
    //                         fontSize: "22px",
    //                         color: "white",
    //                         formatter: function(val) {
    //                             return val + "%";
    //                         },
    //                     },
    //                 },
    //                 track: {
    //                     background: "#545454",
    //                 },
    //             },
    //         },
    //         fill: {
    //             type: "solid",
    //             colors: [chartColor],
    //             gradient: {
    //                 shade: "dark",
    //                 shadeIntensity: 0.5,
    //                 inverseColors: true,
    //                 opacityFrom: 1,
    //                 opacityTo: 1,
    //                 stops: [0, 50, 65, 91],
    //             },
    //         },
    //         stroke: {
    //             dashArray: 2,
    //         },
    //         labels: [label],
    //     };
  }
}
