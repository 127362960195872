import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAttachment } from '../upload-files-components/attachment.interface';

type tempFile = { name: string; description: string; showDescriptionInput: boolean; };
@Component({
  selector: 'app-icon-grid',
  templateUrl: './icon-grid.component.html',
  styleUrls: ['./icon-grid.component.scss']
})
export class IconGridComponent {
  withActionButtons = false;
  @Input() selectedFiles: IAttachment[] = []
  @Input() files: IAttachment[] = [];
  @Output() onSelectedFiles = new EventEmitter<IAttachment[]>;
  @Input() allowEditDescriptionFile = true;

  updateSelected(attachment: any, isSelected: boolean) {
    this.showDescriptionInput(attachment, isSelected)
    if (isSelected) {
      this.selectedFiles.push(attachment);
    } else {
      const index = this.selectedFiles.indexOf(attachment);
      if (index > -1) {
        this.selectedFiles.splice(index, 1);
      }
    }

    this.onSelectedFiles.emit(this.selectedFiles);
  }

  downloadDoc() {
    throw new Error('Method not implemented.');
  }


  removeFile(file: any) {
    const index = this.files.indexOf(file);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
  }

  showDescriptionInput(file: any, isSelected: boolean) {
    file.showDescriptionInput = this.allowEditDescriptionFile && isSelected;
    console.log('file.showDescriptionInput', file.showDescriptionInput);


  }

  saveDescription(file: any) {
    file.showDescriptionInput = false;
    // Save the file description
  }
}
